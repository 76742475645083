import type BigNumber from 'bignumber.js';
import { calculateAndLocalePercentage } from '@smartfolly/common.utilities';

import type { Group } from '../types';

import { getTotalPrice } from './getTotalPrice';

/**
 * Function to append the total price and the portion to the group.
 * @param group - a group without the total price and the portion to append the missing data to.
 * @param groupsTotalPriceValue - a total price value of all groups to calculate the portion.
 * @returns the full group (with the total price and the portion).
 */
export function appendTotalPriceAndPortionToGroup(
    group: Omit<Group, 'totalPrice' | 'portion'>,
    groupsTotalPriceValue?: BigNumber,
): Group {
    // Calculate the group total price
    // Note: specify the first asset currency as the one to calculate the total price with
    // TODO: for empty group we set the currency as 'USD' for now. Pass the desired one if needed...
    const groupTotalPrice = getTotalPrice(group.assets, group.assets[0]?.price.currency ?? 'USD');

    // Append the calculated group total price to the group itself
    const fullGroup: Group = {
        ...group,
        totalPrice: groupTotalPrice,
    };

    // Append the group portion if it can be calculated
    if (groupsTotalPriceValue != null && groupTotalPrice.value != null) {
        fullGroup.portion = calculateAndLocalePercentage(
            groupTotalPrice.value,
            groupsTotalPriceValue,
        );
    }

    // Return the full group
    return fullGroup;
}
