import { observer } from 'mobx-react';

import { authService } from '../../services';

import { AuthorizedUser } from './AuthorizedUser';
import { UnauthorizedUser } from './UnauthorizedUser';

export const Connect = observer(function Connect() {
    const { session } = authService;

    if (session === undefined) {
        return null;
    }

    if (session === null) {
        return <UnauthorizedUser />;
    }

    return <AuthorizedUser />;
});
