import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

import type { HistoricalPrice, Price } from '@smartfolly/frontend.assets-service';

export type PerformanceChartPoint = {
    /**
     * A date of the chart point.
     */
    date: string;
    /**
     * The calculated performance in percentage of the chart point.
     */
    performance: number;
    /**
     * An optional BTC performance in percentage of the chart point.
     */
    btcPerformance?: number;
    /**
     * An optional ETC performance in percentage of the chart point.
     */
    ethPerformance?: number;
};

/**
 * Max number of charts points to work with by design
 */
const pointsAmount = 7;

/**
 * Function to calculate the performance knowing the current and first point prices.
 * @param price - the current prices to get the performance.
 * @param firstPointPrice - the first point price.
 * @returns the calculated performance value.
 */
function calculatePerformance(price: Price | undefined, firstPointPrice: Price | undefined) {
    if (!price || !firstPointPrice) {
        return 0;
    }

    return firstPointPrice.value != null && !firstPointPrice.value.eq(new BigNumber(0))
        ? (price.value ?? new BigNumber(0))
              .minus(firstPointPrice.value)
              .div(firstPointPrice.value)
              .toNumber() * 100
        : 0;
}

/**
 * Function to convert the historical prices list into the map with date as a key.
 * @param historicalPrices - a historical prices list to convert.
 * @returns the map of historical prices.
 */
function mapHistoricalPrices(historicalPrices: HistoricalPrice[]): { [date: string]: Price } {
    return historicalPrices.reduce<{ [date: string]: Price }>((acc, { date, price }) => {
        acc[date] = price;
        return acc;
    }, {});
}

/**
 * Hook to get the performance chart data from the sorted list of historical total prices.
 * @param prices - a sorted list of historical total prices.
 * @returns the built performance chart data.
 */
export function usePerformanceChartData(
    prices: HistoricalPrice[],
    historicalBTCPrices?: HistoricalPrice[],
    historicalETHPrices?: HistoricalPrice[],
): PerformanceChartPoint[] {
    return useMemo(() => {
        // Note we show only the desired amount of chart points by the design
        const pricesSlice = prices.slice(-pointsAmount);

        // Make a map of BTC & ETH prices on specific date
        const btcPricesMap = mapHistoricalPrices(historicalBTCPrices ?? []);
        const ethPricesMap = mapHistoricalPrices(historicalETHPrices ?? []);

        // Check if there is any data in the prices slice
        if (!pricesSlice.length) {
            return [];
        }

        // Get the total price at the first point
        const { date: firstPointDate, price: firstPointTotalPrice } = pricesSlice[0]!;
        const firstPointBTCTotalPrice: Price | undefined = btcPricesMap[firstPointDate];
        const firstPointETHTotalPrice: Price | undefined = ethPricesMap[firstPointDate];

        // Map the prices into the performance chart points to show
        return pricesSlice.map(({ date, price }) => {
            // Calculate the performance as the total price changes from the first point
            return {
                date,
                performance: calculatePerformance(price, firstPointTotalPrice),
                btcPerformance: calculatePerformance(btcPricesMap[date], firstPointBTCTotalPrice),
                ethPerformance: calculatePerformance(ethPricesMap[date], firstPointETHTotalPrice),
            };
        });
    }, [prices, historicalBTCPrices, historicalETHPrices]);
}
