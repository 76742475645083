import type { BoardFilters } from '@smartfolly/sdk';

import { Asset, doesAssetCorrespondsToFilteringOptions } from '@smartfolly/frontend.assets-service';

import type { StablecoinsMap } from '@smartfolly/frontend.currencies-service';

import { boardFiltersToFilteringOptions } from './boardFiltersToFilteringOptions';

/**
 * Function to check either the asset corresponds to the given board filters.
 * @param asset - an asset to check.
 * @param filters - the board filters to check the asset with.
 * @param stablecoins - map with all supported stablecoins.
 * @returns the result of the check.
 */
export function doesAssetCorrespondsToBoardFilters(
    asset: Asset,
    filters: BoardFilters,
    stablecoins: StablecoinsMap,
): boolean {
    return doesAssetCorrespondsToFilteringOptions(
        asset,
        boardFiltersToFilteringOptions(filters),
        stablecoins,
    );
}
