import { WalletConnectProjectID } from '../configs';

// The following code is partly taken (and then adopted) from here:
// https://github.com/WalletConnect/web3modal/blob/V2/packages/core/src/utils/ExplorerUtil.ts

// Start of block

const W3M_API = 'https://explorer-api.walletconnect.com';

interface ListingParams {
    page?: number;
    search?: string;
    entries?: number;
    version?: number;
    chains?: string;
    recommendedIds?: string;
    excludedIds?: string;
    sdks?: string;
}

interface Listing {
    id: string;
    name: string;
    homepage: string;
    image_id: string;
    app: {
        browser?: string;
        ios?: string;
        android?: string;
        mac?: string;
        windows?: string;
        linux?: string;
        chrome?: string;
        firefox?: string;
        safari?: string;
        edge?: string;
        opera?: string;
    };
    injected: {
        injected_id: string;
        namespace: string;
    }[];
    mobile: {
        native: string;
        universal: string;
    };
    desktop: {
        native: string;
        universal: string;
    };
}

interface ListingResponse {
    listings: Record<string, Listing>;
    total: number;
}

async function fetchListings(endpoint: string, params: ListingParams) {
    const url = new URL(endpoint, W3M_API);
    url.searchParams.append('projectId', WalletConnectProjectID);
    Object.entries(params).forEach(([key, value]) => {
        if (value) {
            url.searchParams.append(key, String(value));
        }
    });

    const request = await fetch(url);

    const response = (await request.json()) as Promise<ListingResponse>;

    return response;
}

function getWalletImageUrl(imageId: string): string {
    return `${W3M_API}/w3m/v1/getWalletImage/${imageId}?projectId=${WalletConnectProjectID}`;
}

// End of block

export type FetchWalletConnectWalletsOptions = {
    /**
     * Version of the Wallet Connect protocol to be supported by the wallet.
     */
    version: number;
    /**
     * Optional comma separated chain IDs required to be supported by the wallet.
     */
    chains?: string;
};

export type Wallet = Listing & { imageUrl: string };

/**
 * Utility to get a list of wallets which support WalletConnect.
 * @param options - options to get the wallets list with, e.g. a protocol version or chains.
 * @returns a list of wallets.
 */
export async function getWalletConnectWallets(
    options: FetchWalletConnectWalletsOptions,
): Promise<Wallet[]> {
    const { listings } = await fetchListings('/w3m/v1/getAllListings', options);
    return Object.values(listings).map(listing => ({
        ...listing,
        imageUrl: getWalletImageUrl(listing.image_id),
    }));
}
