import classNames from 'classnames/bind';
import QRCode from 'qrcode';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { Log } from '@smartfolly/common.utilities';

import { AuthUserError } from '@smartfolly/sdk';

import { Button, Flex, FlexContainer, Icon, Modal } from '@smartfolly/frontend.web-ui';

import { usePreviousPropValue } from '../../hooks';

import { authService } from '../../services';

import { showToast } from '../../utils';

import styles from './Connect.module.scss';

const cnb = classNames.bind(styles);

const log = new Log('AuthWihTelegramModal');

const AUTH_WITH_TELEGRAM_QR = 'auth-with-telegram-qr';

export const AuthWithTelegramModal = memo(function AuthWithTelegramModal({
    mode = 'auth',
    show,
    onHide,
    onAuthStarted,
    onAuthFinished,
}: {
    mode?: 'auth' | 'addAuth';
    show: boolean;
    onHide: () => void;
    onAuthStarted: () => void;
    onAuthFinished: (result: boolean) => void;
}) {
    // Refs

    const stopProcessingRef = useRef<() => void>();

    // States

    const [authLink, setAuthLink] = useState<string>();

    // Getters

    const previousShowValue = usePreviousPropValue(show);

    // Life-cycles

    useEffect(() => {
        // Note: do not check for the "previousShowValue == null" since we don't need to start
        // the auth flow on the initial component mount which original "show" state is "false"
        if (show && previousShowValue === false) {
            (async () => {
                try {
                    onAuthStarted();

                    if (mode === 'auth') {
                        await authService.authWithTelegramBot({
                            onLinkGenerated: (link: string, stopProcessing: () => void) => {
                                stopProcessingRef.current = stopProcessing;
                                setAuthLink(link);
                            },
                        });
                    } else {
                        await authService.addTelegramBotAuth({
                            onLinkGenerated: (link: string, stopProcessing: () => void) => {
                                stopProcessingRef.current = stopProcessing;
                                setAuthLink(link);
                            },
                        });
                    }

                    onAuthFinished(true);
                } catch (error) {
                    onAuthFinished(false);

                    if (error === AuthUserError.Providers.TelegramBot.Stopped) {
                        // User has stopped processing forcibly
                    } else if (error === AuthUserError.Providers.TelegramBot.UserIsTakenAlready) {
                        log.warning(
                            'Failed to auth via Telegram Bot as the user is taken already:',
                            error,
                        );

                        showToast('Authorization failed. User already exists');
                    } else {
                        log.error('Failed to auth via Telegram Bot with error:', error);

                        showToast('Something went wrong');
                    }
                }
            })();
        }
    }, [mode, onAuthFinished, onAuthStarted, previousShowValue, show]);

    useEffect(() => {
        if (authLink) {
            const canvas = document.getElementById(AUTH_WITH_TELEGRAM_QR);
            if (canvas) {
                QRCode.toCanvas(
                    canvas,
                    authLink,
                    { width: canvas.clientWidth, margin: 2 },
                    error => {
                        if (error) {
                            log.error('Failed to create QRCode with error:', error);
                        }
                    },
                );
            }
        }
    }, [authLink]);

    // Actions

    const openAuthLink = useCallback(() => {
        window.open(authLink, '_blank');
    }, [authLink]);

    const hide = useCallback(() => {
        if (stopProcessingRef.current) {
            stopProcessingRef.current();
        }

        onHide();
    }, [onHide]);

    // Render

    return (
        <Modal
            className={cnb('auth-with-telegram-modal')}
            show={show}
            onHide={hide}
            header={
                <FlexContainer justify="start" align="center" onClick={hide} role="button">
                    <Flex className="m-r-0.75">
                        <Icon icon="arrow-left" />
                    </Flex>
                    <Flex className="title title-normal color-text-secondary p-t-0.25">Back</Flex>
                </FlexContainer>
            }
        >
            <FlexContainer direction="column" justify="stretch" align="stretch">
                <Flex>
                    <canvas
                        id={AUTH_WITH_TELEGRAM_QR}
                        className={cnb('auth-with-telegram-modal-qr')}
                    />
                    <Flex className="paragraph paragraph-special color-text-secondary m-t-0.75 m-b-1.5 text-center">
                        Scan the QR code or follow the link below
                    </Flex>
                    <Button
                        disabled={!authLink}
                        color="telegram"
                        className={cnb('telegram-btn')}
                        onClick={openAuthLink}
                    >
                        <Icon icon="telegram-bot" /> Open Telegram
                    </Button>
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
