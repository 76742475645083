import { decode } from 'url-safe-base64';

import { AuthProvider } from '../../../../constants';

import type { StorageSessionInfo } from '../types';

/**
 * Function to create a storage session info instance from the serialized session string
 * @param session - a serialized session string
 * @returns a storage session info instance.
 */
export async function deserializeSession(session: string): Promise<StorageSessionInfo> {
    // Session string is basically an URL-safe base64. Decode it first.
    const sessionBase64 = decode(session);

    // Create a buffer with `sessionKey` and `secretKey`
    const sessionBuff = Buffer.from(sessionBase64, 'base64');

    // Parse the session info
    const [expire, secretKey, sessionKey] = sessionBuff.toString('utf8').split(';');

    // Check if the parsed data is present
    if (!expire || !secretKey || !sessionKey) {
        throw new Error('Failed to deserialize the session');
    }

    // Find the userId
    const [userId] = sessionKey.split(':');

    // Check if the userId is found
    if (!userId) {
        throw new Error('Failed to extract the user ID from the session');
    }

    // Build-up the storage session info
    return {
        userId,
        session: {
            provider: { name: AuthProvider.SimpleAuth },
            expire: Number(expire),
            secretKey,
            sessionKey,
        },
    };
}
