import type { IReleasesService } from '../types';

import { ReleasesService, ReleasesServiceOptions } from './ReleasesService';

/**
 * Create the ReleasesService instance with the provided options.
 * @param options - options which include an instance of "AuthService", the appVersion
 * and the flag either this is the first app launch to deal with the release notes.
 * @returns an instance of the ReleasesService.
 */
export function createWithOptions(options: ReleasesServiceOptions): IReleasesService {
    return new ReleasesService(options);
}
