import { releasesService } from '../services';

// TODO: implement a service which should manage the ordering of modals to show
// in order to replace the following hook with it

/**
 * Hook to learn if we possible need to show the templates to add.
 * Note: this can be used to find out if we can show other kinds of modals.
 */
export function usePossiblyNeedToShowReleaseNotes() {
    const { readyToShowReleaseNotes } = releasesService;

    // Check if "ready-to-show" status is unknown
    if (readyToShowReleaseNotes === undefined) {
        // Possible we'll need to show the release notes
        return true;
    }

    // Check if "ready-to-show" status is positive
    if (typeof readyToShowReleaseNotes === 'string') {
        // We'll definitely need to show the release notes
        return true;
    }

    // No need to show the release notes
    return false;
}
