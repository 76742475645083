import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useState } from 'react';

import type { Currency } from '@smartfolly/frontend.currencies-service';

import {
    Button,
    CurrencyIcon,
    Flex,
    FlexContainer,
    IButtonIcon,
    Icon,
    Modal,
} from '@smartfolly/frontend.web-ui';

import { ItemContent, Virtuoso } from 'react-virtuoso';
import { assetsService } from '../../services';
import styles from './Connect.module.scss';

const currencyDescriptions: Record<Currency, string> = {
    AED: 'United Arab Emirates Dirham',
    BRL: 'Brazil Real',
    CNY: 'Chinese Yuan',
    EUR: 'Euro',
    GBP: 'British Pound Sterling',
    JPY: 'Japanese Yen',
    USD: 'US Dollar',
};

const cnb = classnames.bind(styles);

const CurrencyItem = observer(function CurrencyItem({ currency }: { currency: Currency }) {
    const { selectExchangeCurrency, selectedExchangeCurrency } = assetsService;

    // Getters

    const iconLeft = useMemo<IButtonIcon>(
        () => ({
            icon:
                currency === selectedExchangeCurrency ? (
                    <Icon icon="checkcircle-on-fill" />
                ) : (
                    <Icon icon="none" />
                ),
        }),
        [currency, selectedExchangeCurrency],
    );

    // Actions

    const onClick = useCallback(
        () => selectExchangeCurrency(currency),
        [currency, selectExchangeCurrency],
    );

    // Render

    return (
        <FlexContainer
            role="button"
            className={cnb('currency-item', 'toggleable-currency')}
            justify="space-between"
            align="center"
            onClick={onClick}
        >
            <Flex className="m-r-0.5">
                <CurrencyIcon icon={currency} />
            </Flex>
            <Flex grow={1} className="action action-normal widget-text-primary">
                {currency}
                {currencyDescriptions[currency as keyof typeof currencyDescriptions] && (
                    <>
                        <span className="px-1">{` · `}</span>
                        {currencyDescriptions[currency as keyof typeof currencyDescriptions]}
                    </>
                )}
            </Flex>
            <Flex className={cnb('btn-check-wrapper')}>
                <Button
                    iconLeft={iconLeft}
                    className={`${cnb('btn-check')} bg-transparent`}
                    onClick={onClick}
                />
            </Flex>
        </FlexContainer>
    );
});

const renderCurrencyItem: ItemContent<Currency, unknown> = (_index, currency) => (
    <CurrencyItem key={currency} currency={currency} />
);

export const CurrencyModal = observer(function CurrencyModal({
    show,
    setShow,
}: {
    show: boolean;
    setShow: (confirmed: boolean) => void;
}) {
    const { availableExchangeCurrencies } = assetsService;

    // States

    const [totalHeight, setTotalHeight] = useState<number>(0);

    // Getters

    const availableExchangeCurrenciesSorted = useMemo(
        () => availableExchangeCurrencies.sort(),
        [availableExchangeCurrencies],
    );

    const listStyle = useMemo(
        () => ({
            height: totalHeight,
            maxHeight: 'calc(100vh - 260px)',
            minHeight: '56px',
        }),
        [totalHeight],
    );

    // Actions

    const onHide = useCallback(() => setShow(false), [setShow]);

    const handleTotalListHeightChanged = useCallback((h: number) => setTotalHeight(h), []);

    // Render

    return (
        <Modal
            className={cnb('currency-modal')}
            show={show}
            onHide={onHide}
            header={
                <FlexContainer justify="space-between" align="center">
                    <Flex className="title title-normal widget-text-primary">Currency</Flex>
                </FlexContainer>
            }
        >
            <FlexContainer direction="column" justify="stretch" align="stretch">
                <Flex>
                    <div className={cnb('currency-grid')}>
                        <Virtuoso
                            style={listStyle}
                            data={availableExchangeCurrenciesSorted}
                            totalCount={availableExchangeCurrenciesSorted.length}
                            itemContent={renderCurrencyItem}
                            fixedItemHeight={56}
                            totalListHeightChanged={handleTotalListHeightChanged}
                        />
                    </div>
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
