import { observer } from 'mobx-react';
import { useState, useMemo, useCallback } from 'react';
import { ItemContent, Virtuoso } from 'react-virtuoso';

import type {
    BlockchainGroup,
    ExchangeGroup,
    GroupedAssets,
    TokenGroup,
    WalletGroup,
} from '@smartfolly/frontend.assets-service';
import { Button, Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { TokenCell } from './TokenCell';
import { BlockchainCell } from './BlockchainCell';
import { ExchangeCell } from './ExchangeCell';
import { WalletCell } from './WalletCell';

export type SelectingGroup = TokenGroup | BlockchainGroup | ExchangeGroup | WalletGroup;

export const GroupsSelector = observer(function GroupSelector<T extends SelectingGroup>({
    type,
    groupedAssets: { groups, hiddenGroups },
    onSelect,
}: {
    type: 'tokens' | 'networks' | 'exchanges' | 'wallets';
    groupedAssets: GroupedAssets<T>;
    onSelect: (selectedGroups: T[]) => void;
}) {
    // States

    const [selectedGroups, setSelectedGroups] = useState<T[]>([]);
    const [totalHeight, setTotalHeight] = useState<number>(0);

    // Getters

    const listStyle = useMemo(
        () => ({
            height: totalHeight,
            maxHeight: 'calc(100vh - 380px)',
            minHeight: '64px',
        }),
        [totalHeight],
    );

    const selectingGroups = useMemo(
        () => groups.concat(hiddenGroups ?? []),
        [groups, hiddenGroups],
    );

    const resetDisabled = useMemo(() => selectedGroups.length === 0, [selectedGroups]);

    // Actions

    const toggleGroup = useCallback(
        (group: SelectingGroup) => {
            const newSelectedGroups = [...selectedGroups];
            const index = newSelectedGroups.indexOf(group as T);
            if (index >= 0) {
                newSelectedGroups.splice(index, 1);
            } else {
                newSelectedGroups.push(group as T);
            }

            setSelectedGroups(newSelectedGroups);
            onSelect(newSelectedGroups);
        },
        [onSelect, selectedGroups],
    );

    const reset = useCallback(() => {
        setSelectedGroups([]);
        onSelect([]);
    }, [onSelect]);

    const handleTotalListHeightChanged = useCallback((h: number) => {
        setTotalHeight(h);
    }, []);

    // Render

    const renderItems: ItemContent<T, T[]> = useCallback(
        (_index, group, context) => {
            type PropsType<G extends SelectingGroup> = {
                group: G;
                groupToggle: (group: G) => void;
                isChecked: boolean;
            };
            const props: PropsType<SelectingGroup> = {
                group,
                groupToggle: toggleGroup,
                isChecked: context.includes(group),
            };

            if ('token' in group) {
                return <TokenCell {...(props as PropsType<TokenGroup>)} />;
            }

            if ('blockchain' in group) {
                return <BlockchainCell {...(props as PropsType<BlockchainGroup>)} />;
            }

            if ('exchange' in group) {
                return <ExchangeCell {...(props as PropsType<ExchangeGroup>)} />;
            }

            return <WalletCell {...(props as PropsType<WalletGroup>)} />;
        },
        [toggleGroup],
    );

    return (
        <>
            <FlexContainer justify="stretch" align="center">
                <Flex grow={1}>
                    <div className="title title-normal text-capitalize">{type}</div>
                </Flex>
                <Flex>
                    <Button className="btn-link" onClick={reset} disabled={resetDisabled}>
                        Reset
                    </Button>
                </Flex>
            </FlexContainer>
            <Virtuoso
                className="scrollable"
                style={listStyle}
                totalCount={groups.length}
                context={selectedGroups}
                data={selectingGroups}
                itemContent={renderItems}
                fixedItemHeight={52}
                totalListHeightChanged={handleTotalListHeightChanged}
            />
        </>
    );
});
