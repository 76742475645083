import { logEvent } from '@amplitude/analytics-browser';
import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
import { isDesktop, isMobile } from 'react-device-detect';

import type { Exchanges } from '@smartfolly/common.exchanges';
import { Log } from '@smartfolly/common.utilities';

import { AuthUserError } from '@smartfolly/sdk';

import { detectCrypto } from '@smartfolly/frontend.currencies-service';

import { Button, Flex, FlexContainer, IButtonIcon, Icon, Modal } from '@smartfolly/frontend.web-ui';

import { openMetaMask } from '../../../helpers';

import type { WalletProvider } from '../../../hooks';

import { assetsService, authService } from '../../../services';

import { useStore } from '../../../stores';

import { showToast } from '../../../utils';

import { AuthWithTelegramModal } from '../AuthWithTelegramModal';
import { OtherWalletsModal } from '../OtherWalletsModal';
import { AddExchangeModal } from '../AddExchangeModal';

import styles from '../Connect.module.scss';

import { UnauthorizedModalHeader } from './UnauthorizedModalHeader';
import { UnauthorizedModalFooter } from './UnauthorizedModalFooter';
import { UnauthorizedInfoModal } from './UnauthorizedInfoModal';

const cnb = classnames.bind(styles);

const log = new Log('UnauthorizedUser');

export const UnauthorizedUser = observer(function UnAuthorizedUser() {
    // Stores

    const { warningModalStore } = useStore();

    const { authWithMetaMaskExt, authWithWalletConnect, authAnonymously, isAuthorizing } =
        authService;

    const { addAddressManually, isAddingAddress } = assetsService;

    // States

    const [showThisModal, setShowThisModal] = useState<boolean>(true);
    const [showAuthWithTelegram, setShowAuthWithTelegram] = useState<boolean>(false);
    const [showOtherWallets, setShowOtherWallets] = useState<boolean>(false);
    const [showAddExchangeModal, setShowAddExchangeModal] = useState<boolean>(false);

    // Refs

    const addressRef = useRef<HTMLInputElement>(null);

    // Getters

    const inputIconLeft = useMemo<IButtonIcon>(
        () => ({
            icon: isAddingAddress ? (
                <Spinner animation="border" className="spinner-icon" />
            ) : (
                <Icon icon="arrow-right" />
            ),
            animation: 'none',
        }),
        [isAddingAddress],
    );

    // Actions

    const authWithMetaMaskExtFn = useCallback(async () => {
        logEvent('Auth with MetaMask Ext clicked');

        // Metamask extension is not available for mobile devices
        if (isMobile) {
            openMetaMask();
        } else {
            try {
                logEvent('Auth with MetaMask Ext started');
                await authWithMetaMaskExt();
                logEvent('Auth with MetaMask Ext finished', { result: true });
            } catch (error) {
                logEvent('Auth with MetaMask Ext finished', { result: false });

                if (error === AuthUserError.Providers.MetaMaskExt.NoProviderFound) {
                    showToast('MetaMask is not available');
                    openMetaMask();
                } else {
                    log.error('Failed to auth via MetaMask Ext with error:', error);
                    showToast('Failed to auth via MetaMask Extension');
                }
            }
        }
    }, [authWithMetaMaskExt]);

    const authWithWalletConnectFn = useCallback(async () => {
        logEvent('Auth with WalletConnect clicked');

        try {
            logEvent('Auth with WalletConnect stared');
            // Note: WalletConnect provider should always be available...
            await authWithWalletConnect();
            logEvent('Auth with WalletConnect finished', { result: true });

            warningModalStore.openModal({
                content:
                    'Please note that Wallet Connect 2.0 cannot guarantee the availability of all wallet addresses. It is recommended that you manually check for any missing addresses and add them as needed.',
                icon: 'walletconnect-filled',
            });
        } catch (error) {
            logEvent('Auth with WalletConnect finished', { result: false });

            if (error === AuthUserError.Providers.WalletConnect.QRCodeClosed) {
                // User has closed a modal with the QR Code, do nothing
            } else {
                log.error('Failed to auth via WalletConnect with error:', error);
                showToast('Failed to auth via WalletConnect');
            }
        }
    }, [authWithWalletConnect, warningModalStore]);

    const addAddressManuallyFn = useCallback(async () => {
        logEvent('Auth anonymously with address clicked');

        if (isAuthorizing || isAddingAddress) {
            // Do nothing if already authorizing or adding an address
            return;
        }

        if (addressRef.current) {
            const address = addressRef.current.value.trim();
            try {
                const blockchains = detectCrypto(address);
                if (!blockchains.length) {
                    showToast('Invalid address or blockchain is not supported');
                    return;
                }

                logEvent('Auth anonymously with address started', { address });
                await addAddressManually(address, { prepareToAddAddresses: authAnonymously });
                logEvent('Auth anonymously with address finished', { address, result: true });
            } catch (error) {
                logEvent('Auth anonymously with address finished', { address, result: false });

                log.error('Failed to auth and add an address manually with error:', error);
                showToast('Something went wrong');
            }
        }
    }, [addAddressManually, authAnonymously, isAddingAddress, isAuthorizing]);

    const showOtherWalletsModal = useCallback(() => {
        logEvent('Auth anonymously with other wallet clicked');

        setShowThisModal(false);
        setShowOtherWallets(true);
    }, []);

    const hideOtherWalletsModal = useCallback(() => {
        setShowThisModal(true);
        setShowOtherWallets(false);
    }, []);

    const addAddressViaOKX = useCallback(async () => {
        logEvent('Auth anonymously with exchange clicked');

        setShowThisModal(false);
        setShowAddExchangeModal(true);
    }, []);

    const hideAddExchangeModal = useCallback(() => {
        setShowThisModal(true);
        setShowAddExchangeModal(false);
    }, []);

    const showAuthWithTelegramModal = useCallback(() => {
        logEvent('Auth with Telegram Bot clicked');

        setShowThisModal(false);
        setShowAuthWithTelegram(true);
    }, []);

    const hideAuthWithTelegramModal = useCallback(() => {
        setShowThisModal(true);
        setShowAuthWithTelegram(false);
    }, []);

    // Events

    const onKeyUp = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                addAddressManuallyFn();
            }
            event.stopPropagation();
        },
        [addAddressManuallyFn],
    );

    const onAuthWithTelegramStarted = useCallback(async () => {
        logEvent('Auth with Telegram Bot started');
    }, []);

    const onAuthWithTelegramFinished = useCallback(
        async (result: boolean) => {
            logEvent('Auth with Telegram Bot finished', { result });

            if (result) {
                hideAuthWithTelegramModal();
            }
        },
        [hideAuthWithTelegramModal],
    );

    const onExchangeWillAdd = useCallback((exchange: Exchanges) => {
        logEvent('Auth anonymously with exchange started', { exchange });
    }, []);

    const onExchangeAdded = useCallback(
        (exchange: Exchanges, result: boolean) => {
            logEvent('Auth anonymously with exchange finished', { exchange, result });

            if (result) {
                hideAddExchangeModal();
            }
        },
        [hideAddExchangeModal],
    );

    const onOtherWalletWillProvide = useCallback((wallet: WalletProvider) => {
        logEvent('Auth anonymously with other wallet started', { wallet: wallet.name });
    }, []);

    const onOtherWalletProvided = useCallback(
        (wallet: WalletProvider, result: boolean) => {
            logEvent('Auth anonymously with other wallet finished', {
                wallet: wallet.name,
                result,
            });

            if (result) {
                hideOtherWalletsModal();
            }
        },
        [hideOtherWalletsModal],
    );

    // Render

    return (
        <>
            {isDesktop && <UnauthorizedInfoModal />}
            <AuthWithTelegramModal
                show={showAuthWithTelegram}
                onHide={hideAuthWithTelegramModal}
                onAuthStarted={onAuthWithTelegramStarted}
                onAuthFinished={onAuthWithTelegramFinished}
            />
            <AddExchangeModal
                show={showAddExchangeModal}
                onHide={hideAddExchangeModal}
                onExchangeWillAdd={onExchangeWillAdd}
                onExchangeAdded={onExchangeAdded}
                prepareToAddExchange={authAnonymously}
            />
            <OtherWalletsModal
                show={showOtherWallets}
                isAddingAddress={isAddingAddress}
                onHide={hideOtherWalletsModal}
                onAddressWillAdd={onOtherWalletWillProvide}
                onAddressAdded={onOtherWalletProvided}
            />
            <Modal
                className={cnb('connect-modal')}
                show={showThisModal}
                header={<UnauthorizedModalHeader />}
            >
                <FlexContainer direction="column" justify="stretch" align="stretch">
                    <Flex className="title title-normal m-b-0.5 m-t-0.5">Authorize with</Flex>
                    <Flex className="paragraph paragraph-small color-text-secondary m-b-1">
                        You can restore an authorized session at any time
                    </Flex>
                    <Flex>
                        <Button
                            className={cnb('connect-btn')}
                            disabled={isAddingAddress || isAuthorizing}
                            onClick={showAuthWithTelegramModal}
                        >
                            <Icon icon="telegram-bot" /> Telegram
                        </Button>
                    </Flex>
                    <Flex>
                        <Button
                            className={cnb('connect-btn')}
                            disabled={isAddingAddress || isAuthorizing}
                            onClick={authWithMetaMaskExtFn}
                        >
                            <Icon icon="metamask" /> MetaMask
                        </Button>
                    </Flex>
                    <Flex>
                        <Button
                            className={cnb('connect-btn')}
                            disabled={isAddingAddress || isAuthorizing}
                            onClick={authWithWalletConnectFn}
                        >
                            <Icon icon="walletconnect" /> WalletConnect
                        </Button>
                    </Flex>

                    <Flex className="title title-normal m-t-0.5 m-b-1.5 m-l-0.25 m-r-0.25">
                        or continue with
                    </Flex>

                    <Flex>
                        <Button
                            className={cnb('connect-btn')}
                            disabled={isAddingAddress || isAuthorizing}
                            onClick={showOtherWalletsModal}
                        >
                            <Icon icon="other-wallets" /> Other wallets
                        </Button>
                    </Flex>
                    <Flex>
                        <Button
                            className={cnb('connect-btn')}
                            disabled={isAddingAddress || isAuthorizing}
                            onClick={addAddressViaOKX}
                        >
                            <Icon icon="okx" /> OKX exchange
                        </Button>
                    </Flex>

                    <Flex className={cnb('input-wrapper')}>
                        <InputGroup>
                            <Form.Control
                                ref={addressRef}
                                className={cnb('input-component')}
                                type="text"
                                placeholder="Any wallet address"
                                onKeyUp={onKeyUp}
                                disabled={isAddingAddress || isAuthorizing}
                            />
                            <Button
                                iconLeft={inputIconLeft}
                                onClick={addAddressManuallyFn}
                                disabled={isAddingAddress || isAuthorizing}
                            />
                        </InputGroup>
                    </Flex>

                    <UnauthorizedModalFooter />
                </FlexContainer>
            </Modal>
        </>
    );
});
