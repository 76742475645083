import classNames from 'classnames/bind';
import type { Config } from 'react-popper-tooltip';
import { cloneElement, memo, useCallback, useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import styles from './Tooltip.module.scss';

const cnb = classNames.bind(styles);

type TooltipProps = {
    content: React.ReactNode;
    children: React.ReactElement;
    config?: Partial<Config>;
};

export const Tooltip = memo(function Tooltip({ children, content, config }: TooltipProps) {
    const [visibility, setVisibility] = useState(false);
    const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
        ...config,
        visible: visibility,
        onVisibleChange: setVisibility,
    });

    // Hide tooltip when clicking
    const handleClickOutside = useCallback((_event: MouseEvent) => {
        setVisibility(false);
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <>
            {cloneElement(children, { ref: setTriggerRef })}
            {visible && content && (
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className: cnb('custom-tooltip'),
                    })}
                >
                    {content}
                </div>
            )}
        </>
    );
});
