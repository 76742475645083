import BigNumber from 'bignumber.js';

import type { Price } from '../types';

/**
 * Function to find out either the price is low or unknown.
 * @param price - a given price instance.
 * @returns the result of the check.
 */
export function isPriceLow(price: Price): boolean {
    // Check if the price is unknown
    const { value } = price;
    if (value == null) {
        return true;
    }

    // Check each known currency for the low value
    const { currency } = price;
    if (currency === 'USD') {
        // Price is considered low if less then 1 USD
        return value.lt(new BigNumber(1));
    }

    if (currency === 'EUR') {
        // Price is considered low if less then 1 EUR
        return value.lt(new BigNumber(1));
    }

    if (currency === 'CNY') {
        // Price is considered low if less then 10 CNY
        return value.lt(new BigNumber(10));
    }

    if (currency === 'GBP') {
        // Price is considered low if less then 10 GBP
        return value.lt(new BigNumber(1));
    }

    if (currency === 'JPY') {
        // Price is considered low if less then 150 JPY
        return value.lt(new BigNumber(150));
    }

    if (currency === 'AED') {
        // Price is considered low if less then 5 AED
        return value.lt(new BigNumber(5));
    }

    if (currency === 'BRL') {
        // Price is considered low if less then 5 BRL
        return value.lt(new BigNumber(5));
    }

    throw new Error(`"${currency}" currency is not supported by "isPriceLow" utility`);
}
