import { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { BoardTemplates } from '@smartfolly/sdk';

import { assetsService, authService, boardsService } from '../services';

import { NothingToDisplay } from '../components/Board';

const spinnerStyle = { top: '50%', left: '50%' };

export const Home = observer(function Home() {
    const { session, isAuthorizing } = authService;
    const { isAddingAddress } = assetsService;
    const { featuredBoards } = boardsService;

    // Getters

    // Find out if if there is a total board to navigate to
    const loadingTotalBoard = useMemo(
        () =>
            featuredBoards.findIndex(board => board.template?.kind === BoardTemplates.Total) === -1,
        [featuredBoards],
    );

    // Render

    // Do not navigate to the total board if the user it not authorized or session is unknown.
    if (session == null || isAuthorizing) {
        return null;
    }

    // Show the spinner while waiting for the total board to be loaded
    if (loadingTotalBoard) {
        if (isAddingAddress) {
            return (
                <Spinner animation="border" className="position-absolute" style={spinnerStyle} />
            );
        }

        // Show not found message if the total board is not found - this may change in the future
        return (
            <div className="p-t-5">
                <NothingToDisplay />
            </div>
        );
    }

    // Navigate to the total board
    return <Navigate to="/boards/total" replace />;
});
