import { memo, useCallback } from 'react';
import { ItemContent, TableVirtuoso } from 'react-virtuoso';
import classNames from 'classnames/bind';

import type { TokenGroup } from '@smartfolly/frontend.assets-service';
import { Flex, FlexContainer, Percentage } from '@smartfolly/frontend.web-ui';

import { usePercentClassName } from '../../hooks';

import styles from './Trending.module.scss';

const cnb = classNames.bind(styles);

const AssetRow = memo(function AssetRow({
    group,
    onItemClicked,
}: {
    group: TokenGroup;
    onItemClicked: (group: TokenGroup) => void;
}) {
    // Getters
    const percentClassName = usePercentClassName(group.totalPrice.percentChange24h.value);

    const itemClicked = useCallback(() => {
        onItemClicked(group);
    }, [group, onItemClicked]);

    return (
        <>
            <td role="presentation" onClick={itemClicked} className="w-60">
                <FlexContainer
                    className="p-t-0.75 p-b-0.75 grid-bordered__item"
                    direction="row"
                    align="center"
                    justify="space-between"
                    onClick={itemClicked}
                >
                    <Flex>
                        <div className="icon small">
                            <img src={group.token.icon} alt={group.token.name} />
                        </div>
                    </Flex>
                    <Flex grow={1} className="me-1 text-nowrap text-truncate">
                        <div className="action action-normal">{group.token.symbol}</div>
                    </Flex>
                </FlexContainer>
            </td>
            <td className="text-right w-20" role="presentation" onClick={itemClicked}>
                <div className="me-3 color-text-secondary text-nowrap mono mono-normal">
                    {group.totalPrice.change24h.string}
                </div>
            </td>
            <td className="text-right cut" role="presentation" onClick={itemClicked}>
                <div
                    className={`text-nowrap mono mono-normal ${percentClassName}`}
                    title={group.totalPrice.percentChange24h.string}
                >
                    <Percentage
                        value={group.totalPrice.percentChange24h.string}
                        className={percentClassName}
                    />
                </div>
            </td>
        </>
    );
});

export const Trending = memo(function Trending({
    trending,
    onItemClicked,
    header,
}: {
    trending: TokenGroup[];
    onItemClicked: (group: TokenGroup) => void;
    header?: React.ReactNode;
}) {
    const renderAsset: ItemContent<TokenGroup, unknown> = useCallback(
        (_index, group) => <AssetRow group={group} onItemClicked={onItemClicked} />,
        [onItemClicked],
    );

    return (
        <FlexContainer direction="column" align="stretch" justify="center">
            <Flex>
                {header ?? (
                    <FlexContainer direction="row" align="center" justify="space-between">
                        <Flex grow={1}>
                            <div className="action action-large">Trending</div>
                            <div className="paragraph paragraph-small color-text-secondary">
                                Top gainers and losers today
                            </div>
                        </Flex>
                    </FlexContainer>
                )}
            </Flex>
            <Flex className="m-t-1">
                <TableVirtuoso
                    className={`${cnb('grid-wrapper', 'table-wrapper')} scrollable`}
                    useWindowScroll
                    data={trending}
                    fixedItemHeight={48}
                    itemContent={renderAsset}
                />
            </Flex>
        </FlexContainer>
    );
});
