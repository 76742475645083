import type { Tokens } from '@smartfolly/frontend.currencies-service';

import type { Asset } from '@smartfolly/sdk';

/**
 * Make a list of all available tokens from the given assets.
 * @param assets - a list of the assets to get the available tokens for.
 * @param includeTokens - optional tokens to include in the available tokens list.
 * @returns the list of available tokens.
 */
export function getAvailableTokens(assets: Asset[], includeTokens?: Tokens[]): Tokens[] {
    // Use Set to get the tokens without duplicates fast
    return Array.from(
        assets.reduce((acc, asset) => {
            acc.add(asset.token);
            return acc;
        }, new Set<Tokens>(includeTokens)),
    );
}
