import { useMemo } from 'react';

import { Asset, getExplorerLinkToAsset } from '@smartfolly/frontend.assets-service';

/**
 * Hook to get the explorer link for the given asset.
 * @param asset - a asset to get the explorer link for.
 * @returns the explorer link.
 */
export function useExplorerLink(asset: Asset): string | undefined {
    return useMemo(() => getExplorerLinkToAsset(asset), [asset]);
}
