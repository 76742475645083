import { useState, useEffect } from 'react';

import { Log } from '@smartfolly/common.utilities';

import { WalletProvider, loadWalletProviders } from './loadWalletProviders';

export type { WalletProvider };

const log = new Log('useWalletProviders');

/**
 * Hook to present the wallet providers list.
 */
export function useWalletProviders(): WalletProvider[] {
    const [walletProviders, setWalletProviders] = useState<WalletProvider[]>([]);

    useEffect(() => {
        (async () => {
            const providers = await loadWalletProviders().catch(error => {
                log.error('Failed to load the wallet providers:', error);
                return [] as WalletProvider[];
            });

            setWalletProviders(providers);
        })();
    }, []);

    return walletProviders;
}
