import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';

import type {
    ProvidedAddressWithWallets,
    ProvidedExchangeWithWallets,
} from '@smartfolly/frontend.assets-service';

import { Flex, FlexContainer, Icon } from '@smartfolly/frontend.web-ui';

import { useFormattedWallet, useProvidedWalletTotalPriceString } from '../../../hooks';

import { assetsService } from '../../../services';

import { useEditingWallets } from '../contexts';

import { CryptoIcon } from '../../CryptoIcon';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

export const EditWalletCell = observer(function EditWalletCell({
    item,
}: {
    item: ProvidedAddressWithWallets | ProvidedExchangeWithWallets;
}) {
    // Stores

    const { selectedExchangeCurrency } = assetsService;

    // States

    const { selectedWallets, setSelectedWallets } = useEditingWallets();

    const totalPriceString = useProvidedWalletTotalPriceString(item, selectedExchangeCurrency);

    // Getters

    const itemId = useMemo(() => {
        return 'address' in item ? item.address : item.sourceId;
    }, [item]);

    const isChecked = useMemo(() => selectedWallets.includes(itemId), [itemId, selectedWallets]);

    const custodianWallet = 'sourceId' in item ? item.wallets[0]?.wallet ?? null : null;

    const custodianWalletName = useFormattedWallet(custodianWallet);

    // Actions

    const onPress = useCallback(() => {
        if (selectedWallets.includes(itemId)) {
            setSelectedWallets(selectedWallets.filter(wallet => wallet !== itemId));
        } else {
            setSelectedWallets([...selectedWallets, itemId]);
        }
    }, [itemId, selectedWallets, setSelectedWallets]);

    // Render

    return (
        <FlexContainer
            key={itemId}
            direction="row"
            justify="stretch"
            align="center"
            className={`p-t-0.5 p-b-0.5 ${cnb('toggleable-wallet', isChecked ? 'checked' : '')}`}
            role="button"
            onClick={onPress}
        >
            {'address' in item ? (
                <>
                    <Flex>
                        <div className="icon">
                            <Icon icon="multi-wallet" />
                        </div>
                    </Flex>
                    <Flex grow={1}>
                        <div className="paragraph paragraph-small color-text-secondary">
                            {`${item.wallets.length} ${
                                item.wallets.length === 1 ? 'wallet' : 'wallets'
                            } · ${totalPriceString}`}
                        </div>
                        <div className="action action-default text-break">{item.address}</div>
                    </Flex>
                </>
            ) : (
                <>
                    <Flex>
                        <CryptoIcon
                            className="icon"
                            icon={`${custodianWallet?.exchange.id ?? 'default'}-wallet`}
                        />
                    </Flex>
                    <Flex grow={1}>
                        <div className="paragraph paragraph-small color-text-secondary">
                            Exchange · {totalPriceString}
                        </div>
                        <div className="action action-default text-break">
                            {custodianWalletName}
                        </div>
                    </Flex>
                </>
            )}
            <Flex>
                <Button variant="link" className={cnb('btn-check')}>
                    {isChecked ? (
                        <Icon icon="checkcircle-on-fill" />
                    ) : (
                        <Icon icon="checkcircle-off" />
                    )}
                </Button>
            </Flex>
        </FlexContainer>
    );
});
