import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useState, useCallback, useMemo } from 'react';

import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { FiltersModal } from './FiltersModal';

import styles from './Filters.module.scss';
import { assetsService } from '../../services';

const cnb = classNames.bind(styles);

const iconLeft: IButtonIcon = {
    icon: <Icon icon="filter-alt" />,
    animation: 'none',
};
export const FiltersButton = observer(function FiltersButton() {
    // Stores

    const {
        appliedFilters: { pickWallets, pickBlockchains, pickExchanges, pickTokens },
    } = assetsService;

    // State

    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

    // Getters

    const hasBadge = useMemo(
        () => !!pickWallets || !!pickBlockchains || !!pickExchanges || !!pickTokens,
        [pickBlockchains, pickExchanges, pickTokens, pickWallets],
    );

    // Actions

    const showFiltersModalAction = useCallback(() => setShowFiltersModal(true), []);
    const hideFiltersModalAction = useCallback(() => setShowFiltersModal(false), []);

    // Render

    return (
        <>
            <Button
                iconLeft={iconLeft}
                className={`back-transparent ${cnb('filters-button', hasBadge ? 'badge' : '')}`}
                onClick={showFiltersModalAction}
            />
            {showFiltersModal && (
                <FiltersModal show={showFiltersModal} onHide={hideFiltersModalAction} />
            )}
        </>
    );
});
