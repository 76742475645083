import { browserName } from 'react-device-detect';

import { SurfKeeperExtensions } from '../../constants';

/**
 * Method to open SurfKeeper extension.
 */
export const openSurfKeeper = () => {
    if (browserName in SurfKeeperExtensions) {
        window
            .open(
                SurfKeeperExtensions[browserName as keyof typeof SurfKeeperExtensions],
                '_blank',
                'noreferrer',
            )
            ?.focus();
    } else {
        window.open(SurfKeeperExtensions.Chrome, '_blank', 'noreferrer')?.focus();
    }
};
