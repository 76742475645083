import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';

import type { WalletGroup } from '@smartfolly/frontend.assets-service';
import { Button, Flex, FlexContainer, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { useFormattedWallet } from '../../../hooks';

import { CryptoIcon } from '../../CryptoIcon';

import styles from '../Board.module.scss';

const cnb = classnames.bind(styles);

export const WalletCell = observer(function WalletCell({
    group,
    groupToggle,
    isChecked,
}: {
    group: WalletGroup;
    groupToggle: (tokenGroup: WalletGroup) => void;
    isChecked: boolean;
}) {
    // Hooks

    const formattedWallet = useFormattedWallet(group.wallet);

    // Actions

    const toggle = useCallback(() => groupToggle(group), [groupToggle, group]);

    // Render

    const iconLeft = useMemo<IButtonIcon>(
        () => ({
            icon: isChecked ? <Icon icon="checkcircle-on-fill" /> : <Icon icon="checkcircle-off" />,
        }),
        [isChecked],
    );

    return (
        <FlexContainer
            key={'address' in group.wallet ? group.wallet.address : group.wallet.sourceId}
            className={`${cnb('toggleable-asset')} ${
                isChecked ? cnb('checked') : ''
            } grid-bordered__item p-t-0.25 p-b-0.25`}
            role="button"
            justify="stretch"
            align="center"
            onClick={toggle}
        >
            <Flex>
                <div className="icon small">
                    {'blockchain' in group.wallet ? (
                        <CryptoIcon
                            icon={`${group.wallet.blockchain.id}-wallet`}
                            defaultIcon="default-wallet"
                        />
                    ) : (
                        <CryptoIcon
                            icon={`${group.wallet.exchange.id}-wallet`}
                            defaultIcon="default-wallet"
                        />
                    )}
                </div>
            </Flex>
            <Flex grow={1}>
                <span className="action action-normal m-r-0.5">{formattedWallet}</span>
                <span className="paragraph paragraph-normal color-text-secondary" />
            </Flex>
            <Flex>
                <div className="paragraph paragraph-normal">
                    <Button iconLeft={iconLeft} className={cnb('btn-check')} />
                </div>
            </Flex>
        </FlexContainer>
    );
});
