import { Flex } from '@smartfolly/frontend.web-ui';
import { memo } from 'react';
import { isMobile } from 'react-device-detect';

export const UnauthorizedModalFooter = memo(function UnauthorizedModalFooter() {
    return (
        isMobile && (
            <Flex className="paragraph paragraph-small color-text-secondary m-b-1.5">
                We only request your public wallet address to read
                <br /> an open data and show an information about your portfolio
                <br /> and analytics.
            </Flex>
        )
    );
});
