// Internal constants

export enum UsersRequestPath {
    EditAddress = 'users/editAddress',
    EditExchange = 'users/editExchange',
}

export enum ServicesRequestPath {
    Whales = 'services/whales',
}
