import { useEffect, useRef } from 'react';

/**
 * Hook to get the previous prop value.
 * @param value - a prop value (current) to get the previous value for.
 * @returns the previous value of the prop.
 */
export function usePreviousPropValue<T>(value: T): T | undefined {
    const ref = useRef<T | undefined>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
