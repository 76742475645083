import { Octokit } from '@octokit/core';
import { computed, makeObservable, observable } from 'mobx';

import { CommonStore, appStorage } from '@smartfolly/common.utilities';

import type { IAuthService } from '@smartfolly/frontend.auth-service';

import type { IReleasesService, Markdown } from '../types';

import { owner, repo } from './constants';

export type ReleasesServiceOptions = {
    /**
     * An instance of the AuthService to work with when dealing with the app releases data.
     * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
     */
    authService: IAuthService;

    /**
     * A current app version.
     */
    appVersion: string;

    /**
     * A flag either this is a first app launch or not.
     * Note: might be used to avoid showing the releases notes on for a just install app version.
     */
    isFirstAppLaunch: boolean;
};

type ReleaseData = {
    /**
     * A markdown string for the release, a.k.a. release notes.
     */
    body: string;

    /**
     * A tag name of the release.
     */
    tag_name: string;

    // TODO: add more properties from the response if needed
};

export class ReleasesService extends CommonStore implements IReleasesService {
    // Properties

    /**
     * An instance of the AuthService to work with when dealing with the app releases data.
     */
    private authService: IAuthService;

    /**
     * A current app version to work with when dealing with this app version release data.
     */
    private appVersion: string;

    /**
     * A flag either this is a first app launch or not.
     */
    private isFirstAppLaunch: boolean;

    /**
     * A MobX-observable release notes markdown string for the current app version.
     */
    private releaseNotes: Markdown | undefined = undefined;

    /**
     * A MobX-observable flag that the release notes were shown for this app version.
     */
    private releaseNotesWereShownFlag: boolean | undefined = undefined;

    // Constructor

    public constructor(options: ReleasesServiceOptions) {
        super();

        this.authService = options.authService;
        this.appVersion = options.appVersion;
        this.isFirstAppLaunch = options.isFirstAppLaunch;

        makeObservable<
            ReleasesService,
            'releaseNotes' | 'releaseNotesWereShownFlag' | 'releaseNotesWereShown'
        >(this, {
            releaseNotes: observable,
            readyToShowReleaseNotes: computed,
            releaseNotesWereShownFlag: observable,
            releaseNotesWereShown: computed,
        });
    }

    // Interface

    protected onLoad = async () => {
        // Ensure `authService` is initialized to work with
        if (!this.authService.initialized) {
            throw new Error('AuthService instance is not initialized to load ReleasesService');
        }

        // Load the local flag value awaiting
        await this.loadReleaseNotesWereShownFlag();

        // Load the release notes for the current app version if release notes weren't shown
        // Note: there is no need to await for the release notes to be loaded
        if (!this.releaseNotesWereShown) {
            this.loadReleaseNotes().catch(error => {
                this.log.error(
                    `Failed to load the release notes for the current app version with error:`,
                    error,
                    this.appVersion,
                );
            });
        }
    };

    protected onUnload = async () => {
        // Reset the "releaseNotesWereShown" flag once the service is unloading
        // since its value depends on the current authorized user
        this.releaseNotesWereShown = undefined;
    };

    public get readyToShowReleaseNotes(): Markdown | false | undefined {
        // Check if we know the state that the release notes of the current app version were shown
        if (this.releaseNotesWereShown === undefined) {
            // Not ready to show while the flag value is unknown
            return undefined;
        }

        // Check if the release notes for the current app version have been shown already
        if (this.releaseNotesWereShown === true) {
            // No need to show the release notes
            return false;
        }

        // Return the release notes if they haven't been shown
        return this.releaseNotes;
    }

    public markReleaseNotesAsShown = async (): Promise<void> => {
        // Mark the release notes were shown
        this.releaseNotesWereShown = true;

        // Save the "releaseNotesWereShown" value equal to the current app version
        return this.saveReleaseNotesWereShownValue();
    };

    // Internals

    /**
     * Mob-X computed getter to learn if the release notes were shown for the current app version.
     */
    private get releaseNotesWereShown(): boolean | undefined {
        return this.releaseNotesWereShownFlag;
    }

    /**
     * Mob-X actioned setter to mark if the release notes were shown for the current app version.
     */
    private set releaseNotesWereShown(releaseNotesWereShown: boolean | undefined) {
        this.releaseNotesWereShownFlag = releaseNotesWereShown;
    }

    /**
     * Getter for the storage key where the "releaseNotesWereShown" flag value is kept.
     */
    private get releaseNotesWereShownKey(): string {
        // Note: the key depends on the current user
        return this.authService.getStorageKey('releaseNotesWereShown');
    }

    /**
     * Method to load the "releaseNotesWereShown" flag value from the local storage.
     */
    private async loadReleaseNotesWereShownFlag() {
        // Load the release notes were shown value from the storage
        const releaseNotesWereShown = await appStorage.getItem(this.releaseNotesWereShownKey);

        // Check if it's present
        if (releaseNotesWereShown) {
            // Compare the stored shown release notes app version with the current one
            this.releaseNotesWereShown = releaseNotesWereShown === this.appVersion;
        }
        // The value isn't present, i.e. the releases notes were not shown
        else if (this.isFirstAppLaunch) {
            // Do not show the release notes for the current app version if it was just installed
            // Note: the following should also save the corresponding value in the storage
            await this.markReleaseNotesAsShown();
        } else {
            // Since the value isn't present the release notes were not shown
            this.releaseNotesWereShown = false;
        }
    }

    /**
     * Method to save the "releaseNotesWereShown" value in the local storage.
     * Note: The storing value should equal to the current app version.
     */
    private async saveReleaseNotesWereShownValue(): Promise<void> {
        await appStorage.setItem(this.releaseNotesWereShownKey, this.appVersion);
    }

    /**
     * Method to load the release notes for the current app version.
     */
    private async loadReleaseNotes() {
        try {
            // Create an Octokit instance to communicate with GitHub API
            const octokit = new Octokit();

            // Make a request to get the release data for the current app version
            const response = await octokit.request<ReleaseData>({
                method: 'GET',
                url: `/repos/${owner}/${repo}/releases/tags/v${this.appVersion}`,
            });

            // Check if the data is present in the response
            if (!('data' in response)) {
                throw new Error('No data is present in the request response');
            }

            // Get the release data
            const { data } = response;

            // Check if the release notes body is present in the data
            if (!('body' in data)) {
                throw new Error('No body is present in the request response data');
            }

            this.releaseNotes = data.body;
        } catch (error) {
            // It's OK in case the app version is no yet released
            this.log.debug('Failed to load the release notes with error:', error);
        }
    }
}
