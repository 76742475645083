import type { IAuthService } from '@smartfolly/frontend.auth-service';

import type { IAssetsService } from '../types';

import { AssetsService } from './AssetsService';

/**
 * Create the AssetsService instance which should work with the provided "AuthService" instance.
 * Note: such service should be able to deal with assets in "Write" mode (i.e. add / edit / delete).
 * @returns an instance of the AssetsService.
 */
export function createWithAuthService(authService: IAuthService): IAssetsService {
    return new AssetsService({ authService });
}

/**
 * Create the AssetsService instance which should work with the provided "userId".
 * Note: such service should be able to deal with assets in "Read" mode (i.e. only view data).
 * @returns an instance of the AssetsService.
 */
export function createWithUserId(userId: string): IAssetsService {
    return new AssetsService({ userId });
}
