import { useMemo } from 'react';

/**
 * Hook to calculate the text width.
 * @param text - a text to calculate.
 * @param fontSize - a font size.
 * @param fontFamily - a font family.
 * @returns the text width.
 * @example
 * const textWidth = useCalculateTextWidth('Hello world!', 16, 'Arial');
 * console.log(textWidth); // 72.5
 */

export function useCalculateTextWidth(text: string, fontSize: number, fontFamily: string) {
    return useMemo(() => {
        const canvas = document.createElement('canvas');
        canvas.classList.add('recharts-cartesian-axis-tick-value'); // to apply the same styles
        const context = canvas.getContext('2d');
        if (context) {
            context.font = `${fontSize}px ${fontFamily}`;
            return context.measureText(text).width;
        }
        return 0;
    }, [text, fontSize, fontFamily]);
}
