import type { ComponentType } from 'react';
import { memo } from 'react';
import type { TopItemListProps } from 'react-virtuoso';

/* Virtooso TopItemList component */
export const GroupHeader: ComponentType<TopItemListProps> = memo(function GroupHeaderContainer({
    children,
    ...rest
}: TopItemListProps) {
    return (
        <div {...rest} style={{ position: 'static' }}>
            {children}
        </div>
    );
});
