import { makeAutoObservable } from 'mobx';

export interface IWarningModalStore {
    open: boolean;
    icon: string | null;
    body: string | null;
}

/**
 * Store to handle the warning modal.
 */
export default class WarningModalStore {
    protected modal: IWarningModalStore = {
        open: false,
        body: null,
        icon: null,
    };

    public constructor() {
        makeAutoObservable(this);
    }

    public openModal = ({ content, icon }: { content: string; icon: string }) => {
        this.modal.open = true;
        this.modal.body = content;
        this.modal.icon = icon;
    };

    public closeModal = () => {
        this.modal.open = false;
        this.modal.body = null;
        this.modal.icon = null;
    };

    public get isOpen() {
        return this.modal.open;
    }

    public get body() {
        return this.modal.body;
    }

    public get icon() {
        return this.modal.icon;
    }
}
