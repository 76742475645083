import { memo, useCallback, useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, SectorProps } from 'recharts';
import type { Props } from 'recharts/types/component/DefaultLegendContent';

import { Flex, FlexContainer, Percentage } from '@smartfolly/frontend.web-ui';

import type { Price, TokenGroup } from '@smartfolly/frontend.assets-service';

import type { PieChartSlice } from '../../../helpers';

import { useHover, usePercentClassName, usePieChartData } from '../../../hooks';

const COLORS = ['#DF6F66', '#C5638B', '#8B76CA', '#5480D3', '#4BA0BA', '#3B8196'];

const ActiveShape = memo(function ActiveShape(
    props: SectorProps & { payload?: PieChartSlice | undefined },
) {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    return (
        <g>
            <text x={cx} y={cy} dy={4} textAnchor="middle" fill="#ffffff">
                {payload?.portion} {payload?.name}
            </text>
            <text x={cx} y={cy} dy={24} textAnchor="middle" fill="#ffffff" fontSize={12}>
                {payload?.price}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius ? outerRadius - 25 : 0}
                outerRadius={outerRadius ? outerRadius - 9 : 0}
                fill={fill}
            />
        </g>
    );
});

export const PieGraph = memo(function PieGraph({
    allTokenGroups,
    totalPrice,
    header,
    showLegend = true,
    containerHeight = 368,
    innerRadius = 118,
    outerRadius = 128,
}: {
    allTokenGroups: TokenGroup[];
    totalPrice: Price;
    header?: React.ReactNode;
    showLegend?: boolean;
    containerHeight?: number;
    innerRadius?: number;
    outerRadius?: number;
}) {
    // States

    const [activeIndex, setActiveIndex] = useState<number>(0);

    // Lifecycle

    useEffect(() => {
        // Set active index to zero when the groups are changed,
        // e.g. when switching from one board to another
        setActiveIndex(0);
    }, [allTokenGroups]);

    // Getters

    // Build the pie chart data by providing a sorted list of token groups using the proper hook
    // Note: we build the chart data with all available groups, including hidden!
    const chartData = usePieChartData(allTokenGroups, 'Others'); // TODO: localize 'Others'

    // Events

    const onPieEnter = useCallback(
        (_: unknown, index: number) => {
            setActiveIndex(index);
        },
        [setActiveIndex],
    );

    // Render

    const renderLegend = useCallback(
        (props: Props): React.ReactNode => {
            const { payload } = props;

            return (
                <div className="row legend m-l-1.25 m-r-1.25 m-t-2.5">
                    {(payload ?? []).map((group, index: number) => (
                        <LegendLabel
                            key={`legend-${(group.payload as unknown as PieChartSlice).name}`}
                            group={group.payload as unknown as PieChartSlice}
                            index={index}
                            onPieEnter={onPieEnter}
                        />
                    ))}
                </div>
            );
        },
        [onPieEnter],
    );

    const changed24ClassName = usePercentClassName(totalPrice.percentChange24h.value);

    return (
        <FlexContainer direction="column" align="stretch" justify="center">
            <Flex>
                {header ?? (
                    <FlexContainer
                        direction="row"
                        align="center"
                        justify="space-between"
                        className="m-b-0.25"
                    >
                        <Flex grow={1}>
                            <div className="action action-large">{totalPrice.string}</div>
                            <div className={`paragraph paragraph-small ${changed24ClassName}`}>
                                {totalPrice.change24h.string} ·{' '}
                                <Percentage
                                    value={totalPrice.percentChange24h.string}
                                    className={changed24ClassName}
                                />{' '}
                                <span className="color-text-secondary">Today</span>
                            </div>
                        </Flex>
                    </FlexContainer>
                )}
            </Flex>
            <Flex className="m-t-0.75">
                <ResponsiveContainer width="100%" height={containerHeight} className="mt-3">
                    <PieChart>
                        <Pie
                            className="no-ouline"
                            activeIndex={activeIndex}
                            activeShape={<ActiveShape />}
                            data={chartData}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            paddingAngle={1}
                            blendStroke
                            stroke="transparent"
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                        >
                            {chartData.map((item: PieChartSlice, index: number) => (
                                <Cell
                                    key={`cell-${item.name}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                        {showLegend && <Legend content={renderLegend} />}
                    </PieChart>
                </ResponsiveContainer>
            </Flex>
        </FlexContainer>
    );
});

const LegendLabel = memo(function LegendData({
    group,
    index,
    onPieEnter,
}: {
    group: PieChartSlice;
    index: number;
    onPieEnter: (event: unknown, index: number) => void;
}) {
    const hover = useHover({ color: COLORS[index % COLORS.length] });

    const onClick = useCallback(() => {
        onPieEnter(null, index);
    }, [index, onPieEnter]);

    return (
        <FlexContainer
            key={`cell-${group.name}`}
            align="center"
            justify="space-between"
            className="col-6 p-t-0.25 p-b-0.25"
            role="button"
            onClick={onClick}
            {...hover}
        >
            <Flex>
                <div
                    className="legend-color m-r-0.5"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                />
            </Flex>
            <Flex grow={1} className="action action-small text-nowrap">
                {group.portion} {group.name}
            </Flex>
        </FlexContainer>
    );
});
