import * as amplitude from '@amplitude/analytics-browser';
import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Log } from '@smartfolly/common.utilities';
import { BoardTemplates } from '@smartfolly/sdk';

import { Button, Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { boardsService, pirschClient, templatesService } from '../../services';

import { showToast } from '../../utils';

import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

const log = new Log('CustomBoardTemplateButtons');

export const CustomBoardTemplateButtons = observer(function CustomBoardTemplateButtons({
    hideModal,
}: {
    hideModal: () => void;
}) {
    // Stores

    const { isAddingBoard, isEditingBoard } = boardsService;
    const { addBoardWithTemplate } = templatesService;

    // States

    const navigate = useNavigate();

    // Actions

    const saveBoardAsTemplate = useCallback(
        async (template: BoardTemplates) => {
            try {
                const result = await addBoardWithTemplate(template);
                if (result) {
                    pirschClient.event('Created template board');
                    amplitude.logEvent('Created template board', { template });
                    showToast('Board saved');
                    hideModal();
                    navigate(`/boards/${result.boardId}#rename`);
                } else {
                    showToast('Something went wrong');
                }
            } catch (error) {
                log.error('Failed to add/edit the board with error:', error);

                showToast('Something went wrong');
            }
        },
        [addBoardWithTemplate, hideModal, navigate],
    );

    const createCoreCryptoBoard = useCallback(() => {
        saveBoardAsTemplate(BoardTemplates.CoreCrypto);
    }, [saveBoardAsTemplate]);

    const createStablecoinsBoard = useCallback(() => {
        saveBoardAsTemplate(BoardTemplates.Stablecoins);
    }, [saveBoardAsTemplate]);

    const createStakedTokensBoard = useCallback(() => {
        saveBoardAsTemplate(BoardTemplates.StakedTokens);
    }, [saveBoardAsTemplate]);

    // Render

    return (
        <FlexContainer className="b-r-4" direction="column" justify="stretch" align="stretch">
            <Flex className="m-b-1">
                <div className="title title-small">Templates</div>
            </Flex>
            <Flex>
                <Button
                    disabled={isAddingBoard || isEditingBoard}
                    onClick={createCoreCryptoBoard}
                    className={`me-2 mb-2 d-sm-inline ${cnb('template-button')}`}
                >
                    Core Crypto
                </Button>
                <Button
                    disabled={isAddingBoard || isEditingBoard}
                    onClick={createStablecoinsBoard}
                    className={`me-2 mb-2 d-sm-inline ${cnb('template-button')}`}
                >
                    Stablecoins
                </Button>
                <Button
                    disabled={isAddingBoard || isEditingBoard}
                    onClick={createStakedTokensBoard}
                    className={`mb-2 d-sm-inline ${cnb('template-button')}`}
                >
                    Staked Tokens
                </Button>
            </Flex>
        </FlexContainer>
    );
});
