import classnames from 'classnames/bind';
import { memo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Flex, FlexContainer, Icon } from '@smartfolly/frontend.web-ui';

import { useTruncateAddress } from '../../../hooks';

import { showToast } from '../../../utils';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

function addressCopied(address: string) {
    if (address) {
        showToast('Address copied to clipboard');
    }
}

export const UserAddress = memo(function UserAddress({ address }: { address: string }) {
    // Getters

    const truncatedAddress = useTruncateAddress(address);

    // Render

    return (
        <FlexContainer
            key={JSON.stringify(address)}
            direction="column"
            justify="stretch"
            align="stretch"
        >
            <CopyToClipboard text={address} onCopy={addressCopied}>
                <Flex
                    role="button"
                    className={`${cnb('main-address')} title title-small color-text-secondary`}
                >
                    <FlexContainer direction="row" justify="stretch" align="center">
                        <Flex>{truncatedAddress}</Flex>
                        <Flex>
                            <Icon icon="copy-tiny" />
                        </Flex>
                    </FlexContainer>
                </Flex>
            </CopyToClipboard>
        </FlexContainer>
    );
});
