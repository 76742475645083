import type { IUserAuth } from '@smartfolly/sdk';

import type { IAuthService } from '../types';

import { AuthService } from './AuthService';

/**
 * Create the AuthService instance which should work with the provided "UserAuth" instance.
 * @returns an instance of the AuthService.
 */
export function createWithUserAuth(userAuth: IUserAuth): IAuthService {
    return new AuthService({ userAuth });
}
