import type { Asset } from '../types';

import { isPriceLow } from './isPriceLow';

/**
 * A function to detect either the list of assets has ONLY assets with the low price.
 * @param assets - a list of assets to check for the low price.
 * @returns the result of the detection either all assets have the low price.
 */
export function areAllAssetsWithLowPrice(assets: Asset[]): boolean {
    return assets.every(asset => isPriceLow(asset.price));
}
