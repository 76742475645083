// Internal constants

export enum AddressesRequestsPath {
    Provide = 'addresses/provide',
    Remove = 'addresses/remove',
}

export enum ExchangesRequestsPath {
    Provide = 'exchanges/provide',
    Remove = 'exchanges/remove',
}

export enum AssetsRequestPath {
    Rescan = 'assets/rescan',
    GetAll = 'assets/getAll',
    Get = 'assets/get',
}
