import type { StablecoinsMap } from '@smartfolly/frontend.currencies-service';

import type { Asset, FilteringOptions } from '../types';

import { detectTokenTypesSuitableForAsset } from './detectTokenTypesSuitableForAsset';

/**
 * Function to check either the asset corresponds to the given filtering options.
 * @param asset - an asset to check.
 * @param options - the filtering options to check the asset with.
 * @param stablecoins - map with all supported stablecoins.
 * @returns the result of the check.
 */
export function doesAssetCorrespondsToFilteringOptions(
    asset: Asset,
    {
        pickTokens,
        pickBlockchains,
        pickExchanges,
        pickWallets,
        pickContractTypes,
        pickTokenTypes,
    }: FilteringOptions,
    stablecoins: StablecoinsMap,
): boolean {
    // Check for `pickTokens` filter presence
    if (pickTokens) {
        // Check if `pickTokens` doesn't include the asset token
        if (!pickTokens.includes(asset.token.id)) {
            // Should not include
            return false;
        }
    }

    // Check for `pickBlockchains` filter
    if (pickBlockchains) {
        // Get the asset wallet
        const { wallet } = asset;

        // Check if `pickBlockchains` doesn't include the asset blockchain
        const shouldInclude =
            'blockchain' in wallet && pickBlockchains.includes(wallet.blockchain.id);
        if (!shouldInclude) {
            // Should not include
            return false;
        }
    }

    // Check for `pickExchanges` filter
    if (pickExchanges) {
        // Get the asset wallet
        const { wallet } = asset;

        // Check if `pickExchanges` doesn't include the asset exchange
        const shouldInclude = 'exchange' in wallet && pickExchanges.includes(wallet.exchange.id);
        if (!shouldInclude) {
            // Should not include
            return false;
        }
    }

    // Check for `pickWallets` filter
    if (pickWallets) {
        // Check if `pickWallets` doesn't include the asset corresponding properties
        const shouldInclude = pickWallets.find(pickWallet => {
            // Get the asset wallet
            const { wallet } = asset;

            if ('address' in pickWallet) {
                return (
                    'address' in wallet &&
                    wallet.address === pickWallet.address &&
                    wallet.blockchain.id === pickWallet.blockchain
                );
            }

            return (
                'sourceId' in wallet &&
                wallet.sourceId === pickWallet.sourceId &&
                wallet.exchange.id === pickWallet.exchange
            );
        });
        if (!shouldInclude) {
            // Should not include
            return false;
        }
    }

    // Check for `pickContractTypes` filter
    if (pickContractTypes) {
        // Check if `contractType' isn't present in the asset
        if (!('contractType' in asset)) {
            // Should not include if `pickContractTypes` doesn't include "null"
            if (!pickContractTypes.includes(null)) {
                return false;
            }
        }
        // Check if `pickContractTypes` doesn't include the asset contractType
        else if (!pickContractTypes.includes(asset.contractType)) {
            // Should not include
            return false;
        }
    }

    // Check for `pickTokenTypes` filter
    if (pickTokenTypes) {
        // Detect the token types suitable for the asset
        const tokenTypes = detectTokenTypesSuitableForAsset(asset, stablecoins);
        // Check if `pickTokenTypes` doesn't include the asset token type
        if (pickTokenTypes.every(tokenType => !tokenTypes.has(tokenType))) {
            // Should not include
            return false;
        }
    }

    // TODO: Check for `prices` filter if needed

    // All checks have been passed. Let's include the asset
    return true;
}
