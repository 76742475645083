// Public constants

// Errors

export const AuthServiceError = {
    Session: {
        NotReadyToAuthorize: new Error('Not ready to authorize'), // i.e. the session is `unknown`
        AlreadyAuthorized: new Error('User is already authorized'), // i.e. the session is present
        NotAuthorized: new Error('User is no authorized'), // i.e. the session is `null` (missing)
    },
};
