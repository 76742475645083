import { useState, useMemo, CSSProperties } from 'react';

/**
 * Hook to use hover inline styles.
 * @param styleOnHover - style on hover.
 * @param styleOnNotHover - default style.
 * @returns style with mouse enter/leave events.
 */
export function useHover(styleOnHover: CSSProperties, styleOnNotHover: CSSProperties = {}) {
    const [style, setStyle] = useState(styleOnNotHover);
    return useMemo(() => {
        const onMouseEnter = () => setStyle(styleOnHover);
        const onMouseLeave = () => setStyle(styleOnNotHover);

        return { style, onMouseEnter, onMouseLeave };
    }, [style, styleOnHover, styleOnNotHover]);
}
