import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useMemo, useCallback, useState, useRef } from 'react';
import { Spinner } from 'react-bootstrap';

import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { exchangesData, type Exchanges } from '@smartfolly/common.exchanges';

import { assetsService, authService } from '../../../services';

import { showToast } from '../../../utils';

import { Tooltip } from '../../Common';

import { AddingWalletProvider } from '../contexts';

import { AddWalletModal } from '../AddWalletModal';
import { EditWalletsModal } from '../EditWalletsModal';
import { UserWalletsModal } from '../UserWalletsModal';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

const WalletsTotalPrice = observer(function WalletsTotalPrice({
    onClick,
}: {
    onClick: () => void;
}) {
    const { allTotalPrice, isRescanningAssets, isAddingAddress } = assetsService;
    const { isLoggingOut } = authService;

    // Getters

    const iconLeft = useMemo<IButtonIcon>(() => {
        if (isRescanningAssets || isAddingAddress) {
            return {
                icon: (
                    <div className={`${cnb('icon')} m-r-0`}>
                        <Spinner animation="border" className="spinner-icon" />
                    </div>
                ),
                animation: 'none',
            };
        }
        return {
            icon: <Icon icon="wallet-mini" className="m-r-0" />,
            animation: 'none',
        };
    }, [isRescanningAssets, isAddingAddress]);

    // Render

    return (
        <Tooltip content="Show wallets">
            <Button
                className="p-r-0.5 p-l-0.5 b-r-3"
                disabled={isLoggingOut}
                iconLeft={iconLeft}
                onClick={onClick}
                aria-label="Show wallets"
            >
                <span className="d-none d-sm-block m-l-0.25 m-r-0.25">{allTotalPrice.string}</span>
            </Button>
        </Tooltip>
    );
});

export function WalletsButton() {
    // Refs

    const addingAddress = useRef<string>();
    const addingExchange = useRef<string>();

    // States

    const [showWalletsModal, setShowWalletsModal] = useState<boolean>(false);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    // Actions

    const showWalletsModalFn = useCallback(() => {
        setShowWalletsModal(true);
    }, []);

    const hideWalletsModalFn = useCallback(() => {
        setShowWalletsModal(false);
    }, []);

    const showAddModalFn = useCallback(() => {
        setShowAddModal(true);
        setShowWalletsModal(false);
    }, []);

    const hideAddModalFn = useCallback(() => {
        setShowAddModal(false);
        setShowWalletsModal(true);
    }, []);

    const showEditModalFn = useCallback(() => {
        setShowEditModal(true);
        setShowWalletsModal(false);
    }, []);

    const hideEditModalFn = useCallback(() => {
        setShowEditModal(false);
        setShowWalletsModal(true);
    }, []);

    const onAddressWillAddManually = useCallback((address: string) => {
        addingAddress.current = address;
    }, []);

    const onExchangeWillAdd = useCallback((exchange: Exchanges, apiKey: string) => {
        addingExchange.current = `${exchangesData[exchange].name} ···· ${apiKey.slice(-4)}`;
    }, []);

    const onAddressAdded = useCallback(
        (result: boolean, error?: Error) => {
            if (!error) {
                hideAddModalFn();

                if (result) {
                    showToast('Address was added');
                } else {
                    showToast('No assets found');
                }
            } else {
                // Let user retry adding an address in case of an error,
                // hereby don't close the add wallet modal
            }
        },
        [hideAddModalFn],
    );

    const onExchangeAdded = useCallback(
        (_exchange: Exchanges, result: boolean, error?: Error) => {
            if (!error) {
                hideAddModalFn();

                if (result) {
                    showToast('Exchange was added');
                } else {
                    showToast('No assets found');
                }
            } else {
                // Let user retry adding an exchange in case of an error,
                // hereby don't close the add wallet modal
            }
        },
        [hideAddModalFn],
    );

    // Render

    return (
        <>
            <WalletsTotalPrice onClick={showWalletsModalFn} />
            <AddingWalletProvider
                addingAddress={addingAddress.current}
                addingExchange={addingExchange.current}
            >
                <UserWalletsModal
                    showModal={showWalletsModal}
                    onHide={hideWalletsModalFn}
                    showAddModalFn={showAddModalFn}
                    showEditModalFn={showEditModalFn}
                />
            </AddingWalletProvider>
            <AddWalletModal
                confirmed={showAddModal}
                onHide={hideAddModalFn}
                onAddressAddedViaMetaMaskExt={onAddressAdded}
                onAddressAddedViaSurfKeeper={onAddressAdded}
                onAddressAddedViaWalletConnect={onAddressAdded}
                onAddressAddedByOthers={onAddressAdded}
                onAddressAddedManually={onAddressAdded}
                onAddressWillAddManually={onAddressWillAddManually}
                onExchangeWillAdd={onExchangeWillAdd}
                onExchangeAdded={onExchangeAdded}
            />
            <EditWalletsModal showModal={showEditModal} onHide={hideEditModalFn} />
        </>
    );
}
