import { useMemo } from 'react';

import type { ExtendedBigNumber } from '@smartfolly/common.utilities';

import type { HistoricalPrice } from '@smartfolly/frontend.assets-service';
import type { Currency } from '@smartfolly/frontend.currencies-service';

export type WorthChartPoint = {
    /**
     * A date of the chart point.
     */
    date: string;
    /**
     * The calculated worth of the chart point.
     */
    worth: ExtendedBigNumber & { asNumber: number };
    /**
     * The worth currency.
     */
    currency: Currency;
};

/**
 * Max number of charts points to work with by design
 */
const pointsAmount = 7;

/**
 * Hook to get the worth chart data from the sorted list of historical total prices.
 * @param prices - a sorted list of historical total prices.
 * @returns the built worth chart data.
 */
export function useWorthChartData(prices: HistoricalPrice[]): WorthChartPoint[] {
    return useMemo(() => {
        // Note we show only the desired amount of chart points by the design
        const pricesSlice = prices.slice(-pointsAmount);

        // Map the prices into the worth chart points to show
        return pricesSlice.map(({ date, price }) => {
            return {
                date,
                worth: {
                    string: price.string,
                    value: price.value,
                    asNumber: price.value?.toNumber() ?? 0,
                },
                currency: price.currency,
            };
        });
    }, [prices]);
}
