import classnames from 'classnames/bind';
import { memo, useCallback, useMemo } from 'react';

import type { Asset } from '@smartfolly/frontend.assets-service';
import { Button, Flex, FlexContainer, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { useAssetBalance, useFormattedWallet } from '../../hooks';

import { CryptoIcon } from '../CryptoIcon';

import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

export const AssetCell = memo(function AssetCell({
    asset,
    itemToggle,
    isChecked,
}: {
    asset: Asset;
    itemToggle: (asset: string) => void;
    isChecked: boolean;
}) {
    const { assetId, wallet } = asset;

    // Getters

    const formattedWallet = useFormattedWallet(wallet);

    const balance = useAssetBalance(asset, 4);

    // Actions

    const onClick = useCallback(() => itemToggle(assetId), [assetId, itemToggle]);

    // Render

    const iconLeft = useMemo<IButtonIcon>(
        () => ({
            icon: isChecked ? <Icon icon="checkcircle-on-fill" /> : <Icon icon="checkcircle-off" />,
        }),
        [isChecked],
    );

    return (
        <FlexContainer
            key={asset.assetId}
            className={`${cnb('toggleable-asset')} ${
                isChecked ? cnb('checked') : ''
            } grid-bordered__item p-t-0.75 p-b-0.75`}
            role="button"
            direction="row"
            justify="stretch"
            align="center"
            onClick={onClick}
        >
            <Flex>
                <div className="icon">
                    <img src={asset.token.icon} alt={asset.token.name} />
                </div>
            </Flex>
            <Flex grow={1}>
                <div className="action action-normal">{balance}</div>
                <div className="paragraph paragraph-normal d-block d-sm-none">
                    {asset.price.string}
                </div>

                <div className="paragraph paragraph-small color-text-secondary">
                    <FlexContainer direction="row" justify="start" align="center">
                        <Flex>
                            <div className="icon micro">
                                <CryptoIcon
                                    icon={`${
                                        'blockchain' in wallet
                                            ? wallet.blockchain.id
                                            : wallet.exchange.id
                                    }-wallet`}
                                    defaultIcon="default-wallet"
                                />
                            </div>
                        </Flex>
                        <Flex>{formattedWallet}</Flex>
                    </FlexContainer>
                </div>
            </Flex>
            <Flex>
                <div className="paragraph paragraph-normal d-none d-sm-block">
                    {asset.price.string}
                </div>
            </Flex>
            <Flex>
                <div className="paragraph paragraph-normal">
                    <Button iconLeft={iconLeft} className={cnb('btn-check')} />
                </div>
            </Flex>
        </FlexContainer>
    );
});
