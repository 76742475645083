import { logEvent } from '@amplitude/analytics-browser';
import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, InputGroup, Spinner } from 'react-bootstrap';

import type { Exchanges } from '@smartfolly/common.exchanges';
import { Log } from '@smartfolly/common.utilities';

import { AssetsManagerError } from '@smartfolly/sdk';

import { detectCrypto } from '@smartfolly/frontend.currencies-service';

import { Button, Flex, FlexContainer, IButtonIcon, Icon, Modal } from '@smartfolly/frontend.web-ui';

import { openMetaMask } from '../../../helpers';

import type { WalletProvider } from '../../../hooks';

import { assetsService, pirschClient } from '../../../services';

import { showToast } from '../../../utils';

import { OtherWalletsModal } from '../OtherWalletsModal';
import { AddExchangeModal } from '../AddExchangeModal';

import styles from '../Connect.module.scss';

import type { AddressAddedWithResultCallback, ExchangeAddedWithResultCallback } from './types';

const cnb = classnames.bind(styles);

const log = new Log('AddWalletModal');

export const AddWalletModal = observer(function AddWalletModal({
    confirmed,
    onHide,
    onAddressAddedViaMetaMaskExt,
    onAddressAddedViaWalletConnect,
    onAddressAddedByOthers,
    onAddressAddedManually,
    onAddressWillAddManually,
    onExchangeWillAdd: onExchangeWillAddProp,
    onExchangeAdded: onExchangeAddedProp,
}: {
    confirmed: boolean;
    onHide: () => void;
    onAddressAddedViaMetaMaskExt: AddressAddedWithResultCallback;
    onAddressAddedViaSurfKeeper: AddressAddedWithResultCallback;
    onAddressAddedViaWalletConnect: AddressAddedWithResultCallback;
    onAddressAddedByOthers: AddressAddedWithResultCallback;
    onAddressAddedManually: AddressAddedWithResultCallback;
    onAddressWillAddManually?: (address: string) => void;
    onExchangeWillAdd?: (exchange: Exchanges, apiKey: string) => void;
    onExchangeAdded: ExchangeAddedWithResultCallback;
}) {
    const {
        addAddressManually,
        addAddressViaMetamask,
        addAddressViaWalletConnect,
        isAddingAddress,
    } = assetsService;

    // States

    const [thisModalConfirmed, setThisModalConfirmed] = useState<boolean>(confirmed);

    const [showOtherWallets, setShowOtherWallets] = useState<boolean>(false);

    const [showAddExchangeModal, setShowAddExchangeModal] = useState<boolean>(false);

    // Life-cycle

    useEffect(() => {
        setThisModalConfirmed(confirmed);

        // Reset the state of all modals if the "confirmed" prop changes
        setShowOtherWallets(false);
        setShowAddExchangeModal(false);
    }, [confirmed]);

    // Refs

    const addressRef = useRef<HTMLInputElement>(null);

    // Getters

    const inputIconLeft = useMemo<IButtonIcon>(
        () => ({
            icon: isAddingAddress ? (
                <Spinner animation="border" className="spinner-icon" />
            ) : (
                <Icon icon="arrow-right" />
            ),
            animation: 'none',
        }),
        [isAddingAddress],
    );

    // Actions

    const addAddressViaMetamaskFn = useCallback(async () => {
        logEvent('Add wallet with MetaMask Ext clicked');

        // Add address via MetaMask Ext
        try {
            logEvent('Add wallet with MetaMask Ext started');
            const result = await addAddressViaMetamask();
            logEvent('Add wallet with MetaMask Ext finished', { result: true });

            // Trigger the ending callback
            onAddressAddedViaMetaMaskExt(result);
            pirschClient.event('Adding another wallet (Metamask)');
        } catch (error) {
            logEvent('Add wallet with MetaMask Ext finished', { result: false });

            if (error === AssetsManagerError.AddressIsAlreadyPresent) {
                // Show the corresponding toast
                showToast('An address is already present');
            } else if (error === AssetsManagerError.Providers.MetaMaskExt.NoProviderFound) {
                showToast('MetaMask is not available');
                openMetaMask();
            } else {
                log.error('Failed to add an address via MetaMask Ext with error:', error);
                // Show the toast
                showToast('Something went wrong');
            }

            // Trigger the ending callback
            onAddressAddedViaMetaMaskExt(false, error);
        }
    }, [addAddressViaMetamask, onAddressAddedViaMetaMaskExt]);

    const addAddressViaWalletConnectFn = useCallback(async () => {
        logEvent('Add wallet with WalletConnect clicked');

        // Add address via WalletConnect
        try {
            logEvent('Add wallet with WalletConnect started');
            const result = await addAddressViaWalletConnect();
            logEvent('Add wallet with WalletConnect finished', { result });

            // Trigger the ending callback
            onAddressAddedViaWalletConnect(result);
            pirschClient.event('Adding another wallet (WalletConnect)');
        } catch (error) {
            logEvent('Add wallet with WalletConnect finished', { result: false });

            if (error === AssetsManagerError.AddressIsAlreadyPresent) {
                // Show the corresponding toast
                showToast('An address is already present');
            } else if (error === AssetsManagerError.Providers.WalletConnect.QRCodeClosed) {
                // User has closed a modal with the QR Code, do nothing
            } else {
                log.error('Failed to add an address via WalletConnect with error:', error);
                // Show the toast
                showToast('Something went wrong');
            }

            // Trigger the ending callback
            onAddressAddedViaWalletConnect(false, error);
        }
    }, [addAddressViaWalletConnect, onAddressAddedViaWalletConnect]);

    const addAddressManuallyFn = useCallback(async () => {
        logEvent('Add wallet manually with address clicked');

        if (isAddingAddress) {
            // Do nothing if already adding an address
            return;
        }

        if (!addressRef.current) {
            // Do nothing
            return;
        }

        const address = addressRef.current.value.trim();
        try {
            logEvent('Add wallet manually with address started', { address });

            const blockchains = detectCrypto(address);
            if (blockchains.length) {
                // Trigger the starting callback
                onAddressWillAddManually?.(address);

                const result = await addAddressManually(address);

                // Trigger the ending callback
                onAddressAddedManually(result);

                pirschClient.event('Adding another wallet');

                logEvent('Add wallet manually with address finished', { address, result });
            } else {
                logEvent('Add wallet manually with address finished', { address, result: false });

                // Show the toast
                showToast('Invalid address or blockchain is not supported');

                // Trigger the ending callback
                onAddressAddedManually(false);
            }
        } catch (error) {
            logEvent('Add wallet manually with address finished', { address, result: false });

            if (error === AssetsManagerError.AddressIsAlreadyPresent) {
                // Show the corresponding toast
                showToast('An address is already present');
            } else {
                log.error('Failed to an add address manually with error:', error);
                // Show the toast
                showToast('Something went wrong');
            }

            // Trigger the ending callback
            onAddressAddedManually(false, error);
        }
    }, [addAddressManually, isAddingAddress, onAddressAddedManually, onAddressWillAddManually]);

    const showOtherWalletsModal = useCallback(() => {
        logEvent('Add wallet with other wallet clicked');

        setThisModalConfirmed(false);
        setShowOtherWallets(true);
    }, []);

    const hideOtherWalletsModal = useCallback(() => {
        setThisModalConfirmed(true);
        setShowOtherWallets(false);
    }, []);

    const addAddressViaOKX = useCallback(async () => {
        logEvent('Add wallet with exchange clicked');

        setThisModalConfirmed(false);
        setShowAddExchangeModal(true);
    }, []);

    const hideAddExchangeModal = useCallback(() => {
        setThisModalConfirmed(true);
        setShowAddExchangeModal(false);
    }, []);

    // Events

    const onKeyUp = useCallback(
        async (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                await addAddressManuallyFn(); // Note: it shouldn't throw!
            }
            event.stopPropagation();
        },
        [addAddressManuallyFn],
    );

    const onExchangeWillAdd = useCallback(
        (exchange: Exchanges, apiKey: string) => {
            logEvent('Add wallet with exchange started', { exchange });

            onExchangeWillAddProp?.(exchange, apiKey);
        },
        [onExchangeWillAddProp],
    );

    const onExchangeAdded = useCallback(
        (exchange: Exchanges, result: boolean, error?: Error) => {
            logEvent('Add wallet with exchange finished', { exchange, result });

            if (result) {
                hideAddExchangeModal();
            }

            onExchangeAddedProp(exchange, result, error);
        },
        [hideAddExchangeModal, onExchangeAddedProp],
    );

    const onOtherWalletWillProvide = useCallback((wallet: WalletProvider) => {
        logEvent('Add wallet with other wallet started', { wallet: wallet.name });
    }, []);

    const onOtherWalletProvided = useCallback(
        (wallet: WalletProvider, result: boolean, error?: Error) => {
            logEvent('Add wallet with other wallet finished', { wallet: wallet.name, result });

            if (result) {
                hideOtherWalletsModal();
            }

            onAddressAddedByOthers(result, error);
        },
        [hideOtherWalletsModal, onAddressAddedByOthers],
    );

    // Render

    return (
        <>
            <Modal
                className={cnb('connect-modal')}
                show={thisModalConfirmed}
                onHide={onHide}
                header={
                    <FlexContainer justify="space-between" align="center">
                        <Flex className="title title-large widget-text-primary">Connect</Flex>
                    </FlexContainer>
                }
            >
                <FlexContainer direction="column" justify="stretch" align="stretch">
                    <Flex className="title title-normal m-b-1.5 m-t-0.75">Add manually</Flex>
                    <Flex className={cnb('input-wrapper')}>
                        <InputGroup>
                            <Form.Control
                                ref={addressRef}
                                className={cnb('input-component')}
                                type="text"
                                placeholder="Paste wallet address"
                                onKeyUp={onKeyUp}
                                disabled={isAddingAddress}
                            />
                            <Button
                                iconLeft={inputIconLeft}
                                onClick={addAddressManuallyFn}
                                disabled={isAddingAddress}
                            />
                        </InputGroup>
                    </Flex>
                    <Flex className="m-b-1.5 m-t-1.5 m-l-0.25 m-r-0.25 title title-normal">
                        Connect wallet
                    </Flex>
                    <Flex>
                        <Button
                            className={cnb('connect-btn')}
                            disabled={isAddingAddress}
                            onClick={addAddressViaMetamaskFn}
                        >
                            <Icon icon="metamask" /> MetaMask
                        </Button>
                    </Flex>
                    <Flex>
                        <Button
                            className={cnb('connect-btn')}
                            disabled={isAddingAddress}
                            onClick={addAddressViaWalletConnectFn}
                        >
                            <Icon icon="walletconnect" /> WalletConnect
                        </Button>
                    </Flex>
                    <Flex>
                        <Button
                            className={cnb('connect-btn')}
                            disabled={isAddingAddress}
                            onClick={showOtherWalletsModal}
                        >
                            <Icon icon="other-wallets" /> Other wallets
                        </Button>
                    </Flex>
                    <Flex className="m-b-1.5 m-t-0.75 m-l-0.25 m-r-0.25 title title-normal">
                        Connect exchange
                    </Flex>
                    <Flex>
                        <Button
                            className={cnb('connect-btn')}
                            disabled={isAddingAddress}
                            onClick={addAddressViaOKX}
                        >
                            <Icon icon="okx" />
                            OKX
                        </Button>
                    </Flex>
                    <Flex className="paragraph paragraph-small color-text-secondary m-b-1.5">
                        We only request your public wallet address to read
                        <br /> an open data and show an information about your portfolio
                        <br /> and analytics.
                    </Flex>
                </FlexContainer>
            </Modal>
            <AddExchangeModal
                show={showAddExchangeModal}
                onHide={hideAddExchangeModal}
                onExchangeWillAdd={onExchangeWillAdd}
                onExchangeAdded={onExchangeAdded}
            />
            <OtherWalletsModal
                show={showOtherWallets}
                isAddingAddress={isAddingAddress}
                onAddressWillAdd={onOtherWalletWillProvide}
                onAddressAdded={onOtherWalletProvided}
                onHide={hideOtherWalletsModal}
            />
        </>
    );
});
