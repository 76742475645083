import { memo } from 'react';

export const Percentage = memo(function Percentage({
    value,
    className,
}: {
    value: string | undefined;
    className: string;
}) {
    if (!value) {
        return null;
    }
    return (
        <span className={className}>
            <span>{value}</span>
        </span>
    );
});
