import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';

import { Button, Icon } from '@smartfolly/frontend.web-ui';

import { authService } from '../../../services';

import { useStore } from '../../../stores';

import { Tooltip } from '../../Common';

import { ProfileModal } from '../ProfileModal';
import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

export const ProfileButton = observer(function ProfileButton() {
    const { isLoggingOut } = authService;

    // States

    const [showProfileModal, setShowProfileModal] = useState<boolean>(false);

    const {
        getProStore: { modalWasShown },
    } = useStore();

    // Actions

    const onShowProfile = useCallback(() => setShowProfileModal(true), []);

    // Render

    return (
        <>
            <Tooltip content="View profile">
                <Button
                    onClick={onShowProfile}
                    disabled={isLoggingOut}
                    className={cnb('profile-btn', modalWasShown ? '' : 'badge')}
                    aria-label="View profile"
                >
                    <Icon icon="user-mini" />
                </Button>
            </Tooltip>
            <ProfileModal show={showProfileModal} setShow={setShowProfileModal} />
        </>
    );
});
