import { computed, makeObservable, observable } from 'mobx';

export class ObservableFlags {
    // Properties

    /**
     * A private observable instance of the `isSubscribingToWhales` flag.
     */
    private isSubscribingToWhalesFlag: boolean = false;

    /**
     * A private observable instance of the `isUnsubscribingFromWhales` flag.
     */
    private isUnsubscribingFromWhalesFlag: boolean = false;

    // Constructor

    public constructor() {
        makeObservable<
            ObservableFlags,
            'isSubscribingToWhalesFlag' | 'isUnsubscribingFromWhalesFlag'
        >(this, {
            isSubscribingToWhales: computed,
            isSubscribingToWhalesFlag: observable,
            isUnsubscribingFromWhales: computed,
            isUnsubscribingFromWhalesFlag: observable,
        });
    }

    public get isSubscribingToWhales(): boolean {
        return this.isSubscribingToWhalesFlag;
    }

    public set isSubscribingToWhales(isSubscribingToWhales: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isSubscribingToWhalesFlag = isSubscribingToWhales;
    }

    public get isUnsubscribingFromWhales(): boolean {
        return this.isUnsubscribingFromWhalesFlag;
    }

    public set isUnsubscribingFromWhales(isUnsubscribingFromWhales: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isUnsubscribingFromWhalesFlag = isUnsubscribingFromWhales;
    }
}
