import { Log } from '@smartfolly/common.utilities';
import { loadBoard, LoaderBoardResponse } from '@smartfolly/frontend.boards-service';
import { Button, Flex, FlexContainer } from '@smartfolly/frontend.web-ui';
import { observer } from 'mobx-react';
import { useState, useEffect, useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { showToast } from '../utils';

import { BoardComponent } from '../components/Board';
import { Feedback } from '../components/Feedback';

const log = new Log('SharedBoard');

const navigateToDiscord = () => {
    window.open('https://discord.gg/dRJczHKmVB', '_blank', 'noreferrer')?.focus();
};

const SomethingGoneWrong = function SomethingGoneWrong() {
    return (
        <FlexContainer direction="column" align="start" justify="center" className="m-t-2">
            <Flex className="title title-small">Oops!</Flex>
            <Flex className="action action-huge">Something&apos;s gone wrong</Flex>
            <Flex className="paragraph paragraph-small color-text-secondary">
                Refresh the page or try again later
            </Flex>
        </FlexContainer>
    );
};

export const SharedBoard = observer(function SharedBoard() {
    const { boardId } = useParams();
    const navigate = useNavigate();

    // States

    const [loading, setLoading] = useState(true);

    // Create a state for the response of the `loadBoard` function.
    // Note: it is MobX observable object!
    const [loadedBoard, setLoadedBoard] = useState<LoaderBoardResponse | undefined>(undefined);

    // Life-cycle

    // Remove the sidebar-open class from the body
    useEffect(() => {
        document.body.classList.remove('sidebar-open');
    }, []);

    // Load the board for the given ID
    useEffect(() => {
        // Check if the board name is provided by the parameters
        if (!boardId) {
            return;
        }

        // Try to find the board in all available boards of the service knowing its name
        (async () => {
            setLoading(true);
            try {
                const observableObject = await loadBoard({ boardId });
                setLoadedBoard(observableObject);
            } catch (error) {
                log.error('Failed to load the board with error:', error);

                showToast('Something went wrong');
            } finally {
                setLoading(false);
            }
        })();
    }, [boardId]);

    // Unload the board loader
    useEffect(() => {
        if (!loadedBoard) {
            return undefined;
        }

        return () => {
            loadedBoard
                .unload()
                .catch(error => log.error('Failed to unload loaded board with error:', error));
        };
    }, [loadedBoard]);

    // Actions

    const navigateHome = useCallback(() => {
        navigate('/');
    }, [navigate]);

    // Render

    return (
        <div className="app-layout">
            <div className="app-content">
                <div className="app-content-header">
                    <div className="float-end pt-3">
                        <Button className="action action-special m-r-1" onClick={navigateHome}>
                            Start Tracking
                        </Button>
                        <Button
                            className="action action-special back-special-accent-dark"
                            onClick={navigateToDiscord}
                        >
                            Join our Discord
                        </Button>
                    </div>
                </div>
                <div className="main-content">
                    {loadedBoard?.board ? (
                        <BoardComponent
                            board={loadedBoard.board}
                            boardsService={loadedBoard.boardsService}
                        />
                    ) : (
                        <div>
                            {
                                // Display the spinner why the loaded board object is loading
                                loading ||
                                // or the board itself is loading inside the observable object
                                (loadedBoard && !loadedBoard.board) ? (
                                    <div className="text-center  mt-4">
                                        <Spinner animation="border" />
                                    </div>
                                ) : (
                                    <SomethingGoneWrong />
                                )
                            }
                        </div>
                    )}
                </div>
            </div>
            <Feedback />
        </div>
    );
});
