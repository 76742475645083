import type { Group } from '../types';

import { isPriceLow } from './isPriceLow';

type FilterGroupsResponse<T extends Group> = {
    /**
     * Filtered groups list.
     */
    filteredGroups: T[];

    /**
     * Excluded by the filter groups.
     */
    excludedGroups?: T[];
};

/**
 * Function to filter groups excluding assets with the low price from them.
 */
export function filterGroupsExcludingAssetsWithLowPrice<T extends Group>(
    groups: T[],
): FilterGroupsResponse<T> {
    return groups.reduce<FilterGroupsResponse<T>>(
        (response, group) => {
            // Filter assets in groups excluding those with the low price
            // and putting such in the `hiddenAssets` property
            const { assets, hiddenAssets } = group.assets.reduce<
                Pick<Group, 'assets' | 'hiddenAssets'>
            >(
                (acc, asset) => {
                    // Check if the current asset price low
                    if (isPriceLow(asset.price)) {
                        // Create the `hiddenAssets` property if missing
                        if (!acc.hiddenAssets) {
                            acc.hiddenAssets = [];
                        }

                        // Put the asset into it
                        acc.hiddenAssets.push(asset);
                    } else {
                        // Put the asset into the `assets` property
                        acc.assets.push(asset);
                    }

                    return acc;
                },
                { assets: [] },
            );

            // Build-up a group with processed assets
            const processedGroup: T = {
                ...group,
                assets,
                ...(hiddenAssets ? { hiddenAssets } : {}),
            };

            // Leave the group only if it has some assets in it after hiding those with low price
            if (assets.length > 0) {
                // Put the processed group into the `filteredGroups` property
                response.filteredGroups.push(processedGroup);
            } else {
                // Create the `excludedGroups` property if missing
                if (!response.excludedGroups) {
                    response.excludedGroups = [];
                }

                // Put the processed group into it
                response.excludedGroups.push(processedGroup);
            }

            return response;
        },
        { filteredGroups: [] },
    );
}
