import { browserName } from 'react-device-detect';

import { SolflareExtensions } from '../../constants';

/**
 * Method to open Solflare extension.
 */
export const openSolflare = () => {
    if (browserName in SolflareExtensions) {
        window
            .open(
                SolflareExtensions[browserName as keyof typeof SolflareExtensions],
                '_blank',
                'noreferrer',
            )
            ?.focus();
    } else {
        window.open(SolflareExtensions.Chrome, '_blank', 'noreferrer')?.focus();
    }
};
