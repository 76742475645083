import type { AddBoardOptions } from '@smartfolly/frontend.boards-service';

import { currenciesService } from '@smartfolly/frontend.currencies-service';

import { BoardTemplates } from '@smartfolly/sdk';

import { templateBoardNames } from '../../constants';

const { tokens } = currenciesService;

export const templateBoardOptions: Record<BoardTemplates, AddBoardOptions> = {
    [BoardTemplates.Total]: {
        name: templateBoardNames[BoardTemplates.Total],
        filters: {}, // Include everything
        template: {
            kind: BoardTemplates.Total,
        },
    },
    [BoardTemplates.CoreCrypto]: {
        name: templateBoardNames[BoardTemplates.CoreCrypto],
        filters: {
            // Note: we consider BTC, ETH, USDT and USDC to be the core cryptocurrencies
            pickTokens: [
                tokens.bitcoin, // BTC
                tokens.ethereum, // ETH
                tokens.tether, // USDT
                tokens['usd-coin'], // USDC
            ],
        },
        template: {
            kind: BoardTemplates.CoreCrypto,
        },
    },
    [BoardTemplates.Stablecoins]: {
        name: templateBoardNames[BoardTemplates.Stablecoins],
        filters: {
            pickTokenTypes: ['Stablecoin'],
        },
        template: {
            kind: BoardTemplates.Stablecoins,
        },
    },
    [BoardTemplates.StakedTokens]: {
        name: templateBoardNames[BoardTemplates.StakedTokens],
        filters: {
            pickContractTypes: ['StakingPool'],
        },
        template: {
            kind: BoardTemplates.StakedTokens,
        },
    },
};
