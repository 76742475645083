import { computed, makeObservable, observable, reaction } from 'mobx';

import { appStorage, buildStorageKeyForUser, CommonStore, Log } from '@smartfolly/common.utilities';

import { AuthProvider, AuthUserError } from '@smartfolly/sdk';
import type {
    IUserAuth,
    SessionListenerDisposer,
    UserAuthOptions,
    UserAuthTelegramBotOptions,
} from '@smartfolly/sdk';

import { AuthServiceError } from '../constants';

import type {
    IAuthService,
    AuthSessionInfo,
    AuthSessionListener,
    AuthSessionListenerDisposer,
} from '../types';

const log = new Log('AuthService');

type AuthServiceOptions = {
    /**
     * An instance of UserAuth to work with when dealing with the authorization.
     * Note: the module will be re-configured if it was configured already.
     */
    userAuth: IUserAuth;

    /**
     * An optional extra key to deal with several sessions.
     */
    key?: string;
};

export class AuthService extends CommonStore implements IAuthService {
    // Properties

    /**
     * A private instance of {@link IUserAuth} module from SDK to deal with the authorization.
     */
    private userAuthModule: IUserAuth;

    /**
     * An observable private flag used when the service is authorizing.
     */
    private authorizing: boolean = false;

    /**
     * An observable private flag used when the service is logging out.
     */
    private loggingOut: boolean = false;

    /**
     * An observable private variable with auth session info.
     * Note: `undefined` means yet `unknown`, `null` means `unauthorized`.
     */
    private currentSession: AuthSessionInfo | null | undefined = undefined;

    /**
     * A private disposer to stop listen to the session info.
     */
    private sessionListenerDisposer?: SessionListenerDisposer;

    // Constructor

    public constructor({ userAuth, key }: AuthServiceOptions) {
        super();

        this.userAuthModule = userAuth;

        this.configureUserAuth(key);

        makeObservable<AuthService, 'authorizing' | 'loggingOut' | 'currentSession'>(this, {
            authorizing: observable,
            loggingOut: observable,
            isAuthorizing: computed,
            isLoggingOut: computed,
            currentSession: observable,
            session: computed,
        });
    }

    // Interface

    protected onLoad = async () => {
        // Get the current session info in case there is any
        this.session = this.userAuth.sessionInfo;

        // Listen to the auth session
        this.sessionListenerDisposer = this.userAuth.listenToSession(authSession => {
            // Set the current session
            this.session = authSession;
        });
    };

    protected onUnload = async () => {
        // Stop listening to the auth session
        if (this.sessionListenerDisposer) {
            this.sessionListenerDisposer();

            // And delete the disposer itself
            delete this.sessionListenerDisposer;
        }
    };

    public get userAuth(): IUserAuth {
        return this.userAuthModule;
    }

    public get isAuthorizing(): boolean {
        return this.authorizing;
    }

    /**
     * Private setter to mark the service is authorizing or not.
     * @param authorizing - an authorizing flag value.
     */
    private set isAuthorizing(authorizing: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.authorizing = authorizing;
    }

    public get isLoggingOut(): boolean {
        return this.loggingOut;
    }

    /**
     * Private setter to mark the service is logging out or not.
     * @param loggingOut - a logging out flag value.
     */
    private set isLoggingOut(loggingOut: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.loggingOut = loggingOut;
    }

    public get session(): AuthSessionInfo | null | undefined {
        return this.currentSession;
    }

    /**
     * Private setter to change the current auth session info.
     * Note: `undefined` means yet `unknown`, `null` means `unauthorized`.
     * @param currentSession - the current auth session info.
     */
    private set session(currentSession: AuthSessionInfo | null | undefined) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.currentSession = currentSession;
    }

    public authWithMetaMaskExt = async (): Promise<void> => {
        return this.authWithProvider({ provider: AuthProvider.MetaMaskExt });
    };

    public authWithWalletConnect = async (): Promise<void> => {
        return this.authWithProvider({ provider: AuthProvider.WalletConnect });
    };

    public authWithTelegramBot = async (options: UserAuthTelegramBotOptions): Promise<void> => {
        return this.authWithProvider({ provider: AuthProvider.TelegramBot, ...options });
    };

    public authAnonymously = async (): Promise<void> => {
        return this.authWithProvider({ provider: AuthProvider.SimpleAuth });
    };

    public addTelegramBotAuth = async (options: UserAuthTelegramBotOptions): Promise<void> => {
        // Mark we are authorizing
        this.isAuthorizing = true;
        try {
            // Check if the user is authorized to add a new auth provider
            if (this.userAuth.sessionInfo === undefined) {
                throw AuthServiceError.Session.NotReadyToAuthorize;
            } else if (this.userAuth.sessionInfo === null) {
                throw AuthServiceError.Session.NotAuthorized;
            }

            // Authorize with the given provider
            await this.userAuth.addProvider({ provider: AuthProvider.TelegramBot, ...options });
            // Note: no need to set the `.auth` response to `this.session` info,
            // since it will happen automatically thanks to the session listener
        } catch (error) {
            if (
                error !== AuthServiceError.Session.NotReadyToAuthorize &&
                error !== AuthServiceError.Session.NotAuthorized &&
                error !== AuthUserError.Providers.TelegramBot.Stopped &&
                error !== AuthUserError.Providers.TelegramBot.UserIsTakenAlready
            ) {
                log.error('Failed to add an auth provider:', AuthProvider.TelegramBot, error);
            }

            throw error;
        } finally {
            // Authorization has stopped
            this.isAuthorizing = false;
        }
    };

    public logout = async (): Promise<void> => {
        // Mark we are logging out
        this.isLoggingOut = true;
        try {
            // Logout
            await this.userAuth.logout();
        } catch (error) {
            log.error('Failed to logout with error:', error);
        } finally {
            // Logging out has stopped
            this.isLoggingOut = false;
        }
    };

    // eslint-disable-next-line class-methods-use-this
    public exportSession = async (): Promise<string> => {
        return this.userAuth.exportSession();
    };

    public importSession = async (stringifiedSession: string): Promise<void> => {
        // Mark we are authorizing
        this.isAuthorizing = true;
        try {
            // Check if session state is unknown or present.
            if (this.session === undefined) {
                throw AuthServiceError.Session.NotReadyToAuthorize;
            } else if (this.session !== null) {
                throw AuthServiceError.Session.AlreadyAuthorized;
            }

            // Authorize without any wallet by importing the session key
            await this.userAuth.importSession(stringifiedSession);
            // Note: no need to set the `.auth` response to `this.session` info,
            // since it will happen automatically thanks to the session listener
        } catch (error) {
            if (
                error !== AuthServiceError.Session.NotReadyToAuthorize &&
                error !== AuthServiceError.Session.AlreadyAuthorized
            ) {
                log.error('Failed to import the session with error:', error);
            }

            throw error;
        } finally {
            // Authorization has stopped
            this.isAuthorizing = false;
        }
    };

    public subscribe = (listener: AuthSessionListener): AuthSessionListenerDisposer => {
        return reaction(
            () => this.session,
            () => {
                if (this.session) {
                    listener(this.session);
                } else {
                    listener(null);
                }
            },
            { name: 'a reaction to listen to the auth session changes' },
        );
    };

    public getStorageKey = (key: string) => {
        // Check if the user is authorized to build-up a storage key
        if (this.userAuth.sessionInfo === undefined) {
            throw AuthServiceError.Session.NotReadyToAuthorize;
        } else if (this.userAuth.sessionInfo === null) {
            throw AuthServiceError.Session.NotAuthorized;
        }

        // Build-up the storage key
        return buildStorageKeyForUser(key, this.userAuth.sessionInfo.userId);
    };

    // Internals

    /**
     * Method to auth with the provider.
     * @param options - contain the name of the provider to be authorized with.
     */
    private authWithProvider = async (options: UserAuthOptions): Promise<void> => {
        // Mark we are authorizing
        this.isAuthorizing = true;
        try {
            // Check if session state is unknown or present.
            if (this.session === undefined) {
                throw AuthServiceError.Session.NotReadyToAuthorize;
            } else if (this.session !== null) {
                throw AuthServiceError.Session.AlreadyAuthorized;
            }

            // Authorize with the given provider
            await this.userAuth.auth(options);
            // Note: no need to set the `.auth` response to `this.session` info,
            // since it will happen automatically thanks to the session listener
        } catch (error) {
            if (
                error !== AuthServiceError.Session.NotReadyToAuthorize &&
                error !== AuthServiceError.Session.AlreadyAuthorized &&
                (options.provider !== AuthProvider.WalletConnect ||
                    error !== AuthUserError.Providers.WalletConnect.QRCodeClosed) &&
                (options.provider !== AuthProvider.TelegramBot ||
                    error !== AuthUserError.Providers.TelegramBot.Stopped)
            ) {
                log.error('Failed to auth with with provider:', options.provider, error);
            }

            throw error;
        } finally {
            // Authorization has stopped
            this.isAuthorizing = false;
        }
    };

    /**
     * Method to configure the "UserAuth" module
     * @param key - an optional key to deal with several sessions.
     */
    private configureUserAuth = (key: string | undefined): void => {
        // Configure the userAuth module from SDK before using the service
        this.userAuth.configure({
            storage: appStorage,
            ...(key ? { key } : {}), // pass the session extra key if present
        });
    };
}
