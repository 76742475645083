import type { TokenType } from '@smartfolly/sdk';

import type { Tokens, StablecoinsMap } from '@smartfolly/frontend.currencies-service';

import type { Asset } from '../types';

/**
 * Function to detect a set of suitable token types for the asset.
 * @param asset - an asset to detect a set of suitable token types for.
 * @param stablecoins - map with all supported stablecoins.
 * @returns a set of suitable token types for the asset.
 */
export function detectTokenTypesSuitableForAsset(
    asset: Asset,
    stablecoins: StablecoinsMap,
): Set<TokenType> {
    // Create an empty suitable token types set to fill
    const suitableTokenTypes = new Set<TokenType>();

    if ('isNative' in asset && asset.isNative) {
        suitableTokenTypes.add('Native');
    }

    if (new Set(Object.values(stablecoins) as string[] as Tokens[]).has(asset.token.id)) {
        suitableTokenTypes.add('Stablecoin');
    }

    // Return the set of suitable token types
    return suitableTokenTypes;
}
