import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Button as BootstrapButton, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
    Components,
    GroupContent,
    GroupedVirtuoso,
    GroupItemContent,
    VirtuosoHandle,
} from 'react-virtuoso';

import { Log } from '@smartfolly/common.utilities';

import type { FilteringOptions, Asset } from '@smartfolly/frontend.assets-service';
import { boardFiltersToFilteringOptions, type Board } from '@smartfolly/frontend.boards-service';

import { Button, Flex, FlexContainer, Modal } from '@smartfolly/frontend.web-ui';

import { useNumberToWords, usePluralize } from '../../hooks';

import { assetsService, boardsService, pirschClient } from '../../services';

import { showToast } from '../../utils';

import { GroupHeader } from '../Common';

import { AssetCell } from './CustomBoardAssetCell';
import { CustomBoardFiltersButtons } from './CustomBoardFilters';
import { CustomBoardTemplateButtons } from './CustomBoardTemplateButtons';
import { FilterModal } from './FilterModal';

import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

const log = new Log('CustomBoardModal');

const listComponents: Components<Asset> = { TopItemList: GroupHeader };

export const CustomBoardModal = observer(function CustomBoardModal({
    board,
    hideModal,
}: {
    board?: Board;
    hideModal: () => void;
}) {
    // Stores

    const {
        groupedTokens: { groups, hiddenGroups },
    } = assetsService;
    const { addBoard, editBoard, isAddingBoard, isEditingBoard, boards } = boardsService;

    // Refs

    const virtuoso = useRef<VirtuosoHandle>(null);

    // States

    const navigate = useNavigate();

    const [showFilter, setShowFilter] = useState<
        'tokens' | 'networks' | 'exchanges' | 'wallets' | null
    >(null);

    const [totalHeight, setTotalHeight] = useState<number>(0);

    // Getters

    const newBoardName = `New board ${useNumberToWords(boards.length + 1)}`;

    const allGroups = useMemo(() => groups.concat(hiddenGroups ?? []), [groups, hiddenGroups]);

    const { groupCounts, flatAssets } = useMemo(
        () =>
            allGroups.reduce<{
                groupCounts: number[];
                flatAssets: Asset[];
            }>(
                (acc, { assets, hiddenAssets }) => {
                    const allAssets = assets.concat(hiddenAssets ?? []);
                    acc.groupCounts.push(allAssets.length);
                    acc.flatAssets.push(...allAssets);
                    return acc;
                },
                { groupCounts: [], flatAssets: [] },
            ),
        [allGroups],
    );

    const boardFilters = useRef<FilteringOptions | null>(
        board?.filters ? boardFiltersToFilteringOptions(board.filters) : null,
    );

    const [selectedAssets, setSelectedAssets] = useState<string[]>(
        flatAssets
            .filter(
                asset => board?.selectedAssets.map(asst => asst.assetId).includes(asset.assetId),
            )
            .map(asst => asst.assetId),
    );

    const pluralizedAssets = usePluralize('asset', selectedAssets.length);

    const listStyle = useMemo(
        () => ({
            height: totalHeight,
            maxHeight: 'calc(100vh - 460px)',
            minHeight: '68px',
        }),
        [totalHeight],
    );

    // Actions

    const toggleAsset = useCallback(
        (assetId: string) => {
            const newSelectedAssets = selectedAssets.slice();
            const index = newSelectedAssets.indexOf(assetId);
            if (index >= 0) {
                newSelectedAssets.splice(index, 1);
            } else {
                newSelectedAssets.push(assetId);
            }

            // When selected assets toggled, let's remove the filters
            boardFilters.current = null;
            setSelectedAssets(newSelectedAssets);
        },
        [selectedAssets],
    );

    const selectAll = useCallback(() => {
        // When selected assets toggled, let's remove the filters
        boardFilters.current = null;

        if (selectedAssets.length === 0) {
            // Select all
            setSelectedAssets(flatAssets.map(asset => asset.assetId));
        } else {
            // Deselect all
            setSelectedAssets([]);
        }
    }, [flatAssets, selectedAssets]);

    const hideModalFn = useCallback(() => {
        boardFilters.current = null;
        setSelectedAssets([]);
        hideModal();
    }, [hideModal]);

    const showTokensFilterModal = useCallback(() => {
        setShowFilter('tokens');
    }, []);

    const showNetworksFilterModal = useCallback(() => {
        setShowFilter('networks');
    }, []);

    const showExchangesFilterModal = useCallback(() => {
        setShowFilter('exchanges');
    }, []);

    const showWalletsFilterModal = useCallback(() => {
        setShowFilter('wallets');
    }, []);

    const hideFilterModal = useCallback(() => {
        setShowFilter(null);
    }, []);

    const saveBoard = useCallback(async () => {
        if (selectedAssets.length === 0) {
            showToast('Please select at least one token');
            return;
        }

        try {
            const result = board
                ? await editBoard({
                      boardId: board.boardId,
                      ...(boardFilters.current != null
                          ? { filters: boardFilters.current }
                          : { selectedAssets }),
                  })
                : await addBoard({
                      name: newBoardName,
                      ...(boardFilters.current != null
                          ? { filters: boardFilters.current }
                          : { selectedAssets }),
                  });
            if (result) {
                if (!board) {
                    pirschClient.event('Created custom board');
                }
                showToast('Board saved');
                hideModal();
                if (!board) {
                    navigate(`/boards/${result.boardId}#rename`);
                }
            } else {
                showToast('Something went wrong');
            }
        } catch (error) {
            log.error('Failed to add/edit the board with error:', error);

            showToast('Something went wrong');
        }
    }, [addBoard, board, editBoard, hideModal, navigate, newBoardName, selectedAssets]);

    const handleTotalListHeightChanged = useCallback((h: number) => {
        setTotalHeight(h);
    }, []);

    const applyFilters = useCallback(
        (filters: FilteringOptions, assets: Asset[]) => {
            // When filters are applied, let's save them
            boardFilters.current = filters;
            setSelectedAssets(assets.map(el => el.assetId));
            hideFilterModal();
        },
        [hideFilterModal],
    );

    // Render

    const renderGroup: GroupContent = useCallback(
        (index: number) => {
            const tokenGroup = allGroups[index]!;
            return (
                <FlexContainer
                    direction="row"
                    justify="start"
                    align="center"
                    className="p-0.25 p-t-0.5 p-b-0.5"
                >
                    <Flex grow={1} className="title title-small">
                        {tokenGroup.token.name}
                    </Flex>
                </FlexContainer>
            );
        },
        [allGroups],
    );

    const renderAsset: GroupItemContent<Asset, unknown> = useCallback(
        index => {
            const asset = flatAssets[index]!;
            const isChecked = selectedAssets.includes(asset.assetId);
            return <AssetCell asset={asset} itemToggle={toggleAsset} isChecked={isChecked} />;
        },
        [flatAssets, toggleAsset, selectedAssets],
    );

    return (
        <>
            <Modal
                className={cnb('info-modal', showFilter ? 'with-over-modal' : '')}
                show
                onHide={hideModalFn}
                header={
                    <div className="title title-normal">
                        {board ? 'Edit Board' : 'Create Board'}
                        {(isAddingBoard || isEditingBoard) && (
                            <Spinner animation="border" className="spinner-icon m-l-0.75" />
                        )}
                    </div>
                }
            >
                {/* Show templates board buttons when creating a board */}
                {!board && <CustomBoardTemplateButtons hideModal={hideModalFn} />}

                <CustomBoardFiltersButtons
                    showTokensFilterModal={showTokensFilterModal}
                    showNetworksFilterModal={showNetworksFilterModal}
                    showExchangesFilterModal={showExchangesFilterModal}
                    showWalletsFilterModal={showWalletsFilterModal}
                />

                <FlexContainer direction="column" justify="stretch" align="stretch">
                    <Flex className="p-b-0.5 p-t-1 border-bottom border-secondary">
                        <FlexContainer direction="row" justify="start" align="center">
                            <Flex grow={1}>
                                <div className="title title-small">Assets</div>
                            </Flex>
                            <Flex>
                                <Button className="btn-link p-0.25 h-auto" onClick={selectAll}>
                                    {!selectedAssets || selectedAssets.length === 0
                                        ? 'Select all'
                                        : 'Deselect all'}
                                </Button>
                            </Flex>
                        </FlexContainer>
                    </Flex>
                    <GroupedVirtuoso<Asset>
                        className="grid-bordered scrollable"
                        ref={virtuoso}
                        style={listStyle}
                        groupCounts={groupCounts}
                        groupContent={renderGroup}
                        itemContent={renderAsset}
                        totalListHeightChanged={handleTotalListHeightChanged}
                        components={listComponents}
                    />
                    <Flex className="m-t-1">
                        <BootstrapButton
                            color="primary"
                            className="d-block w-100 p-t-0.5 p-b-0.5 rounded-3 action action-normal"
                            onClick={saveBoard}
                            disabled={isAddingBoard || isEditingBoard}
                        >
                            <div className="action action-normal">Save board</div>
                            <div className="paragraph paragraph-tiny">{pluralizedAssets}</div>
                        </BootstrapButton>
                    </Flex>
                </FlexContainer>
            </Modal>
            {showFilter && (
                <FilterModal
                    applyFilters={applyFilters}
                    type={showFilter}
                    onHide={hideFilterModal}
                />
            )}
        </>
    );
});
