import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams, useNavigate } from 'react-router-dom';

import { Log } from '@smartfolly/common.utilities';

import { AuthServiceError } from '@smartfolly/frontend.auth-service';

import { authService } from '../services';

import { showToast } from '../utils';

const log = new Log('UserWithSession');

export const UserWithSession = observer(function UserWithSession() {
    const { importSession } = authService;

    const navigate = useNavigate();

    const { session: sessionString } = useParams();

    useEffect(() => {
        (async () => {
            if (sessionString) {
                try {
                    await importSession(sessionString);
                    navigate('/');
                } catch (error) {
                    if (error === AuthServiceError.Session.AlreadyAuthorized) {
                        showToast(
                            "The session link can't be used as you have already been authorized. Please log out and try again",
                        );
                    } else if (error === AuthServiceError.Session.NotReadyToAuthorize) {
                        showToast('Cannot open the session link, please try again');
                    } else {
                        log.error('Failed to import the session with error:', error);

                        showToast('Cannot open the session link, please try again');
                    }
                }
            }
        })();
    }, [importSession, sessionString, navigate]);

    return null;
});
