import { createContext, useContext } from 'react';

type AddingWalletContextType = {
    /**
     * A currently adding address if present.
     */
    addingAddress: string | undefined;

    /**
     * A currently adding exchange if present.
     */
    addingExchange: string | undefined;
};

const AddingWalletContext = createContext<AddingWalletContextType | undefined>(undefined);

/**
 * Hook to work with the adding address.
 */
export function useAddingWallet(): AddingWalletContextType {
    const value = useContext(AddingWalletContext);

    if (!value) {
        throw new Error('Have you forgot to wrap component with `AddingWalletProvider`?');
    }

    return value;
}

export function AddingWalletProvider({
    children,
    ...rest
}: React.PropsWithChildren & {
    /**
     * A currently adding address if present.
     */
    addingAddress: string | undefined;

    /**
     * A currently adding exchange if present.
     */
    addingExchange: string | undefined;
}) {
    return <AddingWalletContext.Provider value={rest}>{children}</AddingWalletContext.Provider>;
}
