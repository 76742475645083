import { findIndexToInsert } from '@smartfolly/common.utilities';
import Fuse from 'fuse.js';

import type { Asset, Group } from '../types';

/**
 * Function to lookup the assets in the list of groups by filtering them with a string pattern.
 * @param pattern - a pattern string to lookup in the properties of the assets in groups
 * to include those assets in the resulted list of groups with hereby filtered assets.
 * @param groups - a list of groups to lookup the assets in.
 * @returns the filtered list of groups with the filtered assets suitable for the given pattern.
 */
export function lookupAssetsInGroups<T extends Group>(
    pattern: string,
    groups: T[],
    includeHiddenAssets = false,
): T[] {
    // Create a list of keys to lookup the assets with
    const keys: Fuse.FuseOptionKey<Asset> = [
        'contract',
        'token.id',
        'token.name',
        'token.symbol',
        'wallet.address',
        'wallet.addressData.info.name',
        'wallet.blockchain.id',
        'wallet.blockchain.name',
        'wallet.exchange.id',
        'wallet.exchange.name',
        'wallet.exchangeData.info.name',
        // TODO: add more if needed
    ];

    // Create the options to lookup the assets with
    const options: Fuse.IFuseOptions<Asset> = {
        keys, // to filter the assets with the specified keys
        ignoreLocation: true, // to be able searching knowing the substring in the middle
        includeScore: true, // to known the sort score of the assets and groups (lower is better)
        isCaseSensitive: false, // to disable the case check when searching
        findAllMatches: true, // to find all possible matches even if the perfect one is found
        minMatchCharLength: 1, // to start searching from 1st character in a pattern
        shouldSort: true, // to sort the assets and groups putting above those with a higher match
        threshold: 0.2, // to include almost perfect matches in the resulted list
    };

    // Create an array of scores which will be used to find the index to insert the group
    // when reducing them in a descending order as per the "match" score determined by Fuse
    const matchScores: number[] = [];

    // Lookup the assets in all groups and filter those which has assets left
    // Also note, that the groups and assets in them should be sorted as per Fuse score
    return groups.reduce<T[]>((acc, group) => {
        // Create the Fuse index to speed-up the search
        const assets = includeHiddenAssets
            ? group.assets.concat(group.hiddenAssets ?? [])
            : group.assets;

        const searchIndex = Fuse.createIndex<Asset>(keys, assets);

        // Initialize the Fuse instance
        const fuse = new Fuse<Asset>(assets, options, searchIndex);

        // Search the group assets and return the resulted items
        const results = fuse.search<T>(pattern);

        // Push the group to the accumulator if we found any assets
        if (results.length > 0) {
            // Find the "match" score of the group as the best score of the searched assets
            // which can be taken from the first found result as they are sorted by the score
            const groupMatchScore = results[0]?.score ?? 1; // set `1` as a complete mismatch

            // Find the proper placement of the group to insert it in the descending order
            const indexToInsert = findIndexToInsert(
                matchScores,
                groupMatchScore,
                (a, b) => a - b, // sort the groups in the descending order of `score`
            );

            // Insert the match score for further calculations
            matchScores.splice(indexToInsert, 0, groupMatchScore);

            // Prepare the group data to insert
            const groupToInsert: T = {
                ...group,
                assets: results.map(({ item }) => item), // get the filtered group assets
            };
            // Insert the processed group data
            acc.splice(indexToInsert, 0, groupToInsert);
        } else {
            // Leave the accumulator unchanged
        }

        // Return the accumulator
        return acc;
    }, []);
}
