/**
 * Function to download any string as a file in web browser.
 * @param string - a string to download as a file.
 * @param filename - a name for the resulted file.
 * @example
 * ```
 * import { exportAssetsToCSV } from '@smartfolly/frontend.assets-service';
 * ...
 * const csvString = exportAssetsToCSV(board.selectedAssets);
 * const filename = `Portfolio-${new Date().toISOString()}.csv`;
   downloadStringAsFile(csvString, filename);
 * ``` 
 */
export function downloadStringAsFile(string: string, filename: string): void {
    const a = document.createElement('a');
    const blob = new Blob([string], { type: 'application/octet-stream' });
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
}
