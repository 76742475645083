import BigNumber from 'bignumber.js';

import { CurrencySymbols, priceToString } from '@smartfolly/frontend.currencies-service';

import type { Asset } from '../types';

/**
 * Function to format the asset balance with the specified precision and appended token symbol.
 * @param asset - an asset to format its balance for.
 * @param precision - an optional precision used when formatting the asset balance.
 * Note: when the precision is not provided it displays the balance fully.
 * @returns the formatted asset balance string.
 */
export function formatAssetBalance(asset: Asset, precision?: number): string {
    // Preset an asset balance as the BigNumber
    const balance = new BigNumber(asset.balance);

    // Specify the amount of decimals to display knowing the provided parameter
    // or display the balance fully if the parameter wasn't provided
    const decimalsToDisplay = precision ?? balance.decimalPlaces() ?? 0;

    // Format the balance as it would be a price considering the user locale
    const formattedBalance = priceToString(
        balance,
        // Select the "EUR" currency as its symbol has only one representation (€)
        // and cannot be different when the user's locale is changed, as if it were a US dollar,
        // e.g. $, US$ or U$
        'EUR',
        decimalsToDisplay,
    ).replace(CurrencySymbols.EUR, '');

    // Append the token symbol separated by a no-break space
    return `${formattedBalance}\u00A0${asset.token.symbol}`;
}
