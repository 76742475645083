import type { Board } from '@smartfolly/frontend.boards-service';
import classnames from 'classnames/bind';
import { memo, useMemo, useCallback } from 'react';
import BigNumber from 'bignumber.js';

import { Flex, FlexContainer, Icon, Percentage, Skeleton } from '@smartfolly/frontend.web-ui';

import type { TokenGroup } from '@smartfolly/frontend.assets-service';
import { priceToString } from '@smartfolly/frontend.currencies-service';

import { localePercentage } from '@smartfolly/common.utilities';

import { BoardTemplates } from '@smartfolly/sdk';
import { usePercentClassName } from '../../hooks';

import styles from './Sidebar.module.scss';

const cnb = classnames.bind(styles);

const AssetCell = memo(function AssetCell({ asset }: { asset: TokenGroup }) {
    // Getters

    const { totalPrice, totalBalance, token } = asset;

    // Render

    return (
        <FlexContainer
            className={cnb('board-asset-cell')}
            direction="row"
            justify="space-between"
            align="stretch"
        >
            <Flex className="paragraph paragraph-small color-text-secondary">
                <div className="icon">
                    <img src={token.icon} alt={token.name} />
                </div>
            </Flex>
            <Flex>
                <div className="action action-normal">
                    {totalBalance.string}&nbsp;{token.symbol}
                </div>
                <div className="paragraph paragraph-small color-text-secondary">
                    {totalPrice.string}
                </div>
            </Flex>
        </FlexContainer>
    );
});

export function BoardCard({
    board,
    clickCallback,
    isActive,
    showTokens = false,
}: {
    board: Board;
    clickCallback: (board: Board) => void;
    isActive: boolean;
    showTokens?: boolean;
}) {
    // Getters
    const percentClassName = usePercentClassName(board.totalPrice.percentChange24h.value);
    const fiveAssets = useMemo(
        () => board.tokenGroups.concat(board.hiddenTokenGroups ?? []).slice(0, 5),
        [board.tokenGroups, board.hiddenTokenGroups],
    );

    const isTemplateBoard = useMemo(
        // Note: we don't consider .Total board as a "template" by design
        () => !!(board.template && board.template.kind !== BoardTemplates.Total),
        [board.template],
    );

    const isDynamicBoard = useMemo(
        // Note: Dynamic boards should have filters and not be a "template" (including .Total)
        () => Object.keys(board.filters ?? {}).length > 0 && !board.template,
        [board.filters, board.template],
    );

    const clickCallbackFn = useCallback(() => clickCallback(board), [board, clickCallback]);

    // Actions

    // Render

    return (
        <FlexContainer
            onClick={clickCallbackFn}
            className={cnb('board-card', isActive ? 'active' : '')}
            direction="column"
            justify="space-between"
            align="stretch"
        >
            <Flex className="title title-normal m-b-0.75 text-truncate">
                {board.name}
                {isTemplateBoard && <Icon icon="crop" className={cnb('template-board-icon')} />}
                {isDynamicBoard && (
                    <Icon icon="funnel-filter" className={cnb('dynamic-board-icon')} />
                )}
            </Flex>
            <Flex>
                <FlexContainer
                    direction="row"
                    justify="start"
                    align="center"
                    style={{ flexWrap: 'wrap' }}
                >
                    <Flex className="paragraph paragraph-normal m-r-0.5 m-b-0.5">
                        {!board.totalPrice.string && <Skeleton />}
                        {board.totalPrice.string}
                    </Flex>
                    <Flex
                        className={`${percentClassName} paragraph paragraph-small m-b-0.5 ${cnb(
                            'change24',
                        )}`}
                    >
                        {!board.totalPrice.string && <Skeleton wide="half" />}
                        {board.totalPrice.change24h.string ??
                            priceToString(new BigNumber(0), 'USD')}{' '}
                        ·{' '}
                        <Percentage
                            value={
                                board.totalPrice.percentChange24h.string ??
                                localePercentage(new BigNumber(0))
                            }
                            className={percentClassName}
                        />
                    </Flex>
                </FlexContainer>
            </Flex>
            <Flex className={cnb('board-card-tokens-wrap')}>
                {showTokens && fiveAssets.length > 0 && (
                    <FlexContainer
                        direction="row"
                        justify="space-between"
                        align="stretch"
                        className={cnb('board-card-tokens')}
                    >
                        {fiveAssets.map(asset => (
                            <AssetCell key={asset.token.id} asset={asset} />
                        ))}
                    </FlexContainer>
                )}
            </Flex>
        </FlexContainer>
    );
}
