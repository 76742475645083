import { observer } from 'mobx-react';
import { useCallback, useMemo, useState } from 'react';
import { Components, GroupContent, GroupedVirtuoso, GroupItemContent } from 'react-virtuoso';

import { Log } from '@smartfolly/common.utilities';

import type {
    CustodianWallet,
    NonCustodianWallet,
    ProvidedAddressWithWallets,
    ProvidedExchangeWithWallets,
    WalletGroup,
} from '@smartfolly/frontend.assets-service';

import { assetsService } from '../../../services';

import { GroupHeader } from '../../Common';

import { UserAddress } from './UserAddress';
import { UserWalletCell } from './UserWalletCell';
import { UserExchanges } from './UserExchanges';
import { AddingAddress } from './AddingAddress';
import { AddingExchange } from './AddingExchange';

const log = new Log('UserWallets');

type ProvidedWallet = ProvidedAddressWithWallets | ProvidedExchangeWithWallets;

const listComponents: Components<ProvidedWallet> = {
    TopItemList: GroupHeader,
};

export const UserWallets = observer(function UserWallets() {
    // Stores

    const {
        providedAddressesWithWallets: addresses,
        isAddingAddress,
        providedExchangesWithWallets: exchanges,
        isAddingExchange,
    } = assetsService;

    // States

    const [totalHeight, setTotalHeight] = useState<number>(0);

    // Getters

    // Calculate the provided wallets list
    type ProvidedWallets =
        | ProvidedWallet
        | {
              isAddingAddress: true;
              wallets: WalletGroup<NonCustodianWallet>[];
          }
        | {
              isAddingExchange: true;
              wallets: WalletGroup<CustodianWallet>[];
          };
    const providedWallets = useMemo<ProvidedWallets[]>(() => {
        // Form the list of all provided wallets to display

        // Start with addresses
        const wallets: ProvidedWallets[] = addresses.slice();

        // Add a "dummy wallet" to display the adding address group
        if (isAddingAddress) {
            wallets.push({ wallets: [], isAddingAddress: true });
        }

        // Add exchanges
        wallets.push(...exchanges);

        // Add a "dummy wallet" to display the adding exchange group
        if (isAddingExchange) {
            wallets.push({ wallets: [], isAddingExchange: true });
        }

        return wallets;
    }, [addresses, exchanges, isAddingAddress, isAddingExchange]);

    // Need to prepare the groupCounts property for the GroupedVirtuoso and
    // to flat the grouped wallets due to the GroupedVirtuoso API convention
    // Note: that we'd like to do it in a single run for better performance
    const { groupCounts, flatWallets } = useMemo(() => {
        return providedWallets.reduce(
            (acc, { wallets }) => {
                acc.groupCounts.push(wallets.length);
                acc.flatWallets.push(...wallets);
                return acc;
            },
            { groupCounts: [], flatWallets: [] } as {
                groupCounts: number[];
                flatWallets: WalletGroup[];
            },
        );
    }, [providedWallets]);

    // Actions

    const handleTotalListHeightChanged = useCallback((h: number) => setTotalHeight(h), []);

    // Render

    const renderSectionHeader: GroupContent = useCallback(
        index => {
            const wallet = providedWallets[index]!;

            // Display a wallet address for each address group
            if ('address' in wallet) {
                return <UserAddress address={wallet.address} />;
            }

            // Display a dummy wallet for adding address group
            if ('isAddingAddress' in wallet) {
                return <AddingAddress />;
            }

            // Display a wallet address for each address group
            if ('sourceId' in wallet) {
                // Note: should be displayed only for the first exchange by design
                if (wallet === exchanges[0]) {
                    return <UserExchanges />;
                }

                return null;
            }

            // Display a dummy wallet for adding exchange group
            if ('isAddingExchange' in wallet) {
                return <AddingExchange />;
            }

            // Display nothing otherwise
            log.warning('The wallet section header is not rendered:', wallet);
            return null;
        },
        [exchanges, providedWallets],
    );

    const renderUserWallet: GroupItemContent<ProvidedWallet, unknown> = useCallback(
        index => <UserWalletCell group={flatWallets[index]!} />,
        [flatWallets],
    );

    const listStyle = useMemo(
        () => ({
            height: totalHeight,
            maxHeight: 'calc(100vh - 244px)',
            minHeight: '64px',
        }),
        [totalHeight],
    );

    return (
        <GroupedVirtuoso
            className="scrollable"
            style={listStyle}
            groupCounts={groupCounts}
            groupContent={renderSectionHeader}
            itemContent={renderUserWallet}
            components={listComponents}
            totalListHeightChanged={handleTotalListHeightChanged}
        />
    );
});
