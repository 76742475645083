import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { forwardRef, useCallback } from 'react';

import { Button, Icon } from '@smartfolly/frontend.web-ui';

import { assetsService } from '../../services';

import styles from './Assets.module.scss';
import { AddDynamicBoard } from '../Board/AddDynamicBoard';

const cnb = classnames.bind(styles);

export const Footer = observer(
    forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
        function Footer() {
            const { toggleHidingLowPrices, areLowPricesHidden, couldToggleHidingLowPrices } =
                assetsService;

            // Actions

            const toggleHidingLowPricesFn = useCallback(() => {
                toggleHidingLowPrices(!areLowPricesHidden);
            }, [toggleHidingLowPrices, areLowPricesHidden]);

            // Render

            return (
                <tfoot>
                    <tr>
                        <td colSpan={3} className="border-0">
                            <div className="p-t-0 p-b-0.75 p-b-4">
                                {couldToggleHidingLowPrices && (
                                    <Button
                                        color="transparent"
                                        className={`color-text-secondary hover:color-text-bw p-x-0 action action-normal ${cnb(
                                            'hide-low-balances',
                                        )}`}
                                        onClick={toggleHidingLowPricesFn}
                                    >
                                        {areLowPricesHidden ? (
                                            <>
                                                <Icon icon="eye-open-mini" /> Show small value
                                            </>
                                        ) : (
                                            <>
                                                <Icon icon="eye-close-mini" /> Hide small value
                                            </>
                                        )}
                                    </Button>
                                )}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="border-0">
                            <AddDynamicBoard />
                        </td>
                    </tr>
                </tfoot>
            );
        },
    ),
);
