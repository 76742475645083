import classnames from 'classnames/bind';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { BoardTemplatesModal } from '../BoardTemplatesModal';
import { ReleaseModal } from '../ReleaseModal';

import styles from '../Connect.module.scss';

import { AddWalletButton } from './AddWalletButton';
import { CurrencyButton } from './CurrencyButton';
import { WalletsButton } from './WalletsButton';
import { RescanButton } from './RescanButton';
import { ProfileButton } from './ProfileButton';

const cnb = classnames.bind(styles);

export function AuthorizedUser() {
    return (
        <div className={cnb('logged-box')}>
            <FlexContainer
                direction="row"
                justify="end"
                align="baseline"
                className={cnb('session')}
            >
                <Flex>
                    <AddWalletButton />
                </Flex>
                <Flex>
                    <WalletsButton />
                </Flex>
                <Flex>
                    <CurrencyButton />
                </Flex>
                <Flex>
                    <RescanButton />
                </Flex>
                <Flex>
                    <ProfileButton />
                </Flex>
            </FlexContainer>
            <BoardTemplatesModal />
            <ReleaseModal />
        </div>
    );
}
