import { useMemo } from 'react';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';
import type { NameType } from 'recharts/types/component/DefaultTooltipContent';
import type { PerformanceChartPoint } from '../../../hooks';
import {
    ChartsTooltip,
    contentStyle,
    dateFormatter,
    percentageFormatter,
    wrapperStyle,
} from './ChartsTooltip';

const tickStyle = {
    fontSize: '10px',
    fontWeight: '500',
};
const tickStroke = '#A4A6A8';
const gridStroke = '#21282C';
const positiveColor = '#26DA72';
const negativeColor = '#FF4747';
const btcColor = '#E9983D';
const ethColor = '#687DE3';

const xAxisTickFormatter = (date: string) => new Date(date).getDate().toString();
const yAxisTickFormatter = (value: number) =>
    `${value > 0 ? `+${value}` : value.toString().replace('-', '−')}%`;

const tooltipNameFormatter = (name: NameType | undefined) => {
    if (name === 'performance') {
        return 'Your';
    }
    if (name === 'btcPerformance') {
        return 'BTC';
    }
    if (name === 'ethPerformance') {
        return 'ETH';
    }
    return name;
};

export const PerformanceGraph = function PerformanceGraph({
    data,
}: {
    data: PerformanceChartPoint[];
}) {
    const lineColor = useMemo(() => {
        const lastElement = data.at(-1);
        if (lastElement) {
            return lastElement.performance > 0 ? positiveColor : negativeColor;
        }
        return positiveColor;
    }, [data]);
    return (
        <FlexContainer direction="column" align="stretch" justify="center">
            <Flex>
                <FlexContainer direction="row" align="center" justify="space-between">
                    <Flex grow={1}>
                        <div className="title title-normal">Performance</div>
                    </Flex>
                </FlexContainer>
            </Flex>
            <Flex className="m-t-1">
                <ResponsiveContainer width="100%" height={180}>
                    <LineChart data={data}>
                        <XAxis
                            dataKey="date"
                            tickFormatter={xAxisTickFormatter}
                            stroke={tickStroke}
                            style={tickStyle}
                            axisLine={false}
                            tickLine={false}
                        />
                        <YAxis
                            tickFormatter={yAxisTickFormatter}
                            stroke={tickStroke}
                            style={tickStyle}
                            axisLine={false}
                            tickLine={false}
                            orientation="right"
                            tickMargin={8}
                        />
                        <CartesianGrid vertical={false} stroke={gridStroke} />
                        <Line
                            type="monotone"
                            dot={false}
                            dataKey="performance"
                            stroke={lineColor}
                            strokeWidth={2}
                        />
                        <Line
                            type="monotone"
                            dot={false}
                            dataKey="btcPerformance"
                            stroke={btcColor}
                            strokeDasharray="3 3"
                            strokeWidth={2}
                        />
                        <Line
                            type="monotone"
                            dot={false}
                            dataKey="ethPerformance"
                            stroke={ethColor}
                            strokeDasharray="3 3"
                            strokeWidth={2}
                        />
                        <Tooltip
                            content={
                                <ChartsTooltip
                                    customLabelFormatter={dateFormatter}
                                    customValueFormatter={percentageFormatter}
                                    customNameFormatter={tooltipNameFormatter}
                                />
                            }
                            contentStyle={contentStyle}
                            wrapperStyle={wrapperStyle}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Flex>
        </FlexContainer>
    );
};
