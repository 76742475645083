import { when } from 'mobx';
import type { NavigateFunction } from 'react-router-dom';

import { Log } from '@smartfolly/common.utilities';

import { detectCrypto } from '@smartfolly/frontend.currencies-service';

import { AssetsManagerError } from '@smartfolly/sdk';

import { assetsService, authService } from '../services';

import { showToast } from '../utils';

const log = new Log('joinWithAddress');

const goHome = (navigate: NavigateFunction) => navigate('/');

const overviewAddress = (address: string, navigate: NavigateFunction) =>
    navigate(`/overview/${address}`);

const showFailureToast = () => showToast('No assets found for the address');

/**
 * Function to join an address to the portfolio.
 * @param options - to join including an address to join and the "navigate" function
 */
export async function joinAddress({
    address,
    navigate,
}: {
    address: string | undefined;
    navigate: NavigateFunction;
}): Promise<void> {
    // Wait until the authorization state is known
    await when(() => authService.session !== undefined, {
        name: 'wait for auth session state is known',
    });

    // Check if the session state is present (since it was awaited above)
    if (authService.session === undefined) {
        log.error('Auth session state is unknown');
        throw new Error('Auth session state is unknown');
    }

    // Check if the user is unauthorized
    if (authService.session === null) {
        // Need to authorize if it's not
        try {
            // Authorize the user anonymously
            await authService.authAnonymously();

            // Wait until the user is authorized
            await when(() => authService.session != null, {
                name: 'wait for auth session is established',
            });
        } catch (error) {
            log.error('Failed to auth anonymously when joining an address with error:', error);
            throw error;
        }
    }

    // User is authorized. Try to add an address

    // Check if the address is actually valid
    if (!address || !detectCrypto(address).length) {
        // Address is invalid or not supported. Go Home
        // Note: it will display a "Zero" state for newly authorized profiles
        goHome(navigate);

        // Show the toast about the failure
        showFailureToast();
        return;
    }

    // "Join" the given address to the user portfolio.
    try {
        // Add the address as we would add it "manually"
        const result = await assetsService.addAddressManually(address);

        // Check if assets for the given address were found
        if (result) {
            // Navigate to the overview page if found
            overviewAddress(address, navigate);
        } else {
            // Go Home if failed to find any asset
            // Note: it will display a "Zero" state for newly authorized profiles
            goHome(navigate);

            // Show the toast about the failure
            showFailureToast();
        }
    } catch (error) {
        if (error === AssetsManagerError.AddressIsAlreadyPresent) {
            // Address is already present
            // Just go to the monitor page
            overviewAddress(address, navigate);
            return;
        }

        log.error('Failed to add an address when joining an address with error:', error);
        throw error;
    }
}
