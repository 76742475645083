import classnames from 'classnames/bind';
import { memo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import type { WalletGroup } from '@smartfolly/frontend.assets-service';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { useFormattedWallet, useTruncateAddress } from '../../../hooks';

import { showToast } from '../../../utils';

import { CryptoIcon } from '../../CryptoIcon';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

function addressCopied(address: string) {
    if (address) {
        showToast('Address copied to clipboard');
    }
}

function apiKeyCopied(apiKey: string) {
    if (apiKey) {
        showToast('Api key copied to clipboard');
    }
}

export const UserWalletCell = memo(function UserWalletCell({ group }: { group: WalletGroup }) {
    const { wallet, totalPrice } = group;

    // Getters

    const formattedWallet = useFormattedWallet(wallet);
    const truncatedAddress = useTruncateAddress('blockchain' in wallet ? wallet.address : '');

    // Render

    return (
        <CopyToClipboard
            key={JSON.stringify(
                'address' in wallet
                    ? wallet.address + wallet.blockchain.id
                    : wallet.sourceId + wallet.exchange.id,
            )}
            text={'address' in wallet ? wallet.address : wallet.exchangeData?.keys.apiKey ?? ''}
            onCopy={'address' in wallet ? addressCopied : apiKeyCopied}
        >
            <FlexContainer
                direction="row"
                justify="stretch"
                align="stretch"
                className={cnb('wallets-management-item')}
            >
                <Flex className={cnb('icon')}>
                    <CryptoIcon
                        icon={`${
                            'blockchain' in wallet ? wallet.blockchain.id : wallet.exchange.id
                        }-wallet`}
                        defaultIcon="default-wallet"
                    />
                </Flex>
                <Flex grow={1} className={cnb('info')}>
                    <FlexContainer direction="column" justify="space-between" align="stretch">
                        <Flex className="action action-normal">{formattedWallet}</Flex>
                        {truncatedAddress && (
                            <Flex className="paragraph paragraph-small color-text-secondary">
                                {truncatedAddress}
                            </Flex>
                        )}
                    </FlexContainer>
                </Flex>
                <Flex>{totalPrice.string}</Flex>
            </FlexContainer>
        </CopyToClipboard>
    );
});
