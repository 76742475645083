import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback } from 'react';

import { Exchanges } from '@smartfolly/common.exchanges';
import { Log } from '@smartfolly/common.utilities';

import { AssetsManagerError } from '@smartfolly/sdk';

import { Flex, FlexContainer, Icon, Modal } from '@smartfolly/frontend.web-ui';

import { assetsService } from '../../services';

import { showToast } from '../../utils';

import styles from './Connect.module.scss';

import { ExchangeKeys } from './ExchangeKeys';

const cnb = classNames.bind(styles);

const log = new Log('AddExchangeModal');

export const AddExchangeModal = observer(function AddExchangeModal({
    show,
    onHide,
    onExchangeWillAdd,
    onExchangeAdded,
    prepareToAddExchange,
}: {
    show: boolean;
    onHide: () => void;
    onExchangeWillAdd?: (exchange: Exchanges, apiKey: string) => void;
    onExchangeAdded: <R extends boolean>(
        exchange: Exchanges,
        result: R,
        error?: R extends false ? Error : never,
    ) => void;
    prepareToAddExchange?: () => Promise<void>;
}) {
    const { addExchange } = assetsService;

    // States

    // Lifecycle

    // Getters

    // Actions

    const okxSubmit = useCallback(
        async ({
            exchange,
            ...keys
        }: {
            exchange: Exchanges;
            apiKey: string;
            secretKey: string;
            passphrase: string;
        }) => {
            try {
                onExchangeWillAdd?.(exchange, keys.apiKey);

                const result = await addExchange({
                    exchange,
                    parameters: {
                        keys,
                    },
                    ...(prepareToAddExchange ? { prepareToAddExchange } : {}),
                });

                onExchangeAdded(exchange, result);
            } catch (error) {
                onExchangeAdded(exchange, false, error);

                if (error === AssetsManagerError.ExchangeSourceIsAlreadyPresent) {
                    // Show the corresponding toast
                    showToast('An exchange is already present');
                } else {
                    log.error('Failed to add an exchange with error:', error);
                    // Show the toast
                    showToast('Something went wrong');
                }
            }
        },
        [addExchange, onExchangeAdded, onExchangeWillAdd, prepareToAddExchange],
    );

    // Render

    return (
        <Modal
            className={cnb('add-exchange-modal')}
            show={show}
            onHide={onHide}
            header={
                <FlexContainer justify="start" align="center" onClick={onHide} role="button">
                    <Flex className="m-r-0.75">
                        <Icon icon="arrow-left" />
                    </Flex>
                    <Flex className="title title-normal color-text-secondary p-t-0.25">Back</Flex>
                </FlexContainer>
            }
        >
            <FlexContainer direction="column" justify="stretch" align="stretch">
                <Flex>
                    {/* Other exchanges can be added here */}
                    <ExchangeKeys exchange={Exchanges.OKX} onSubmit={okxSubmit} />
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
