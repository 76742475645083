import { browserName } from 'react-device-detect';

import { PhantomExtensions } from '../../constants';

/**
 * Method to open Phantom extension.
 */
export const openPhantom = () => {
    if (browserName in PhantomExtensions) {
        window
            .open(
                PhantomExtensions[browserName as keyof typeof PhantomExtensions],
                '_blank',
                'noreferrer',
            )
            ?.focus();
    } else {
        window.open(PhantomExtensions.Chrome, '_blank', 'noreferrer')?.focus();
    }
};
