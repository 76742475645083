import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import { Feedback } from '../Feedback';
import { Onboarding } from '../Onboarding';
import { WarningModal } from '../WarningModal';

export function Layout() {
    return (
        <>
            <div className="app-layout">
                <Sidebar />
                <div className="app-content">
                    <Header />
                    <Outlet />
                </div>
            </div>
            <ToastContainer />
            <Feedback />
            <Onboarding />
            <WarningModal />
        </>
    );
}
