import { observer } from 'mobx-react';
import { useState, useCallback } from 'react';

import { IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { assetsService } from '../../../services';

import { AddWalletModal } from '../../Connect';

import type { ActiveGroup } from '../types';

import { EmptyPlaceholder } from './EmptyPlaceholder';

const cancelIcon: IButtonIcon = {
    icon: <Icon icon="close-mini" />,
    animation: 'none',
};

const addIcon: IButtonIcon = {
    icon: <Icon icon="add-plus" />,
    animation: 'none',
};

export const ActiveEmptyPlaceholder = observer(function ActiveEmptyPlaceholder({
    active,
}: {
    active: ActiveGroup;
}) {
    const { filter } = assetsService;

    // States

    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    // Actions

    const showAddWalletModal = useCallback(() => setShowAddModal(true), []);
    const hideAddWalletModal = useCallback(async () => setShowAddModal(false), []);

    const clearFilters = useCallback(() => filter({}), [filter]);

    return (
        <>
            <EmptyPlaceholder
                active={active}
                cancelIcon={cancelIcon}
                addIcon={addIcon}
                onClearFilters={clearFilters}
                onAddWallet={showAddWalletModal}
            />
            <AddWalletModal
                confirmed={showAddModal}
                onHide={hideAddWalletModal}
                onAddressAddedViaMetaMaskExt={hideAddWalletModal}
                onAddressAddedViaSurfKeeper={hideAddWalletModal}
                onAddressAddedViaWalletConnect={hideAddWalletModal}
                onAddressAddedByOthers={hideAddWalletModal}
                onAddressAddedManually={hideAddWalletModal}
                onExchangeAdded={hideAddWalletModal}
            />
        </>
    );
});
