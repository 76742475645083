import { memo } from 'react';
import classnames from 'classnames/bind';

import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { Tooltip } from '../../Common';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

const getProButtonIconLeft: IButtonIcon = {
    icon: <Icon icon="rocket-mini" className="m-r-0" />,
    animation: 'none',
};

export const GetProButton = memo(function GetProButton({
    onShowGetProModal: showGetProModal,
}: {
    onShowGetProModal: () => void;
}) {
    // Render

    return (
        <Tooltip content="Unleash the full potential">
            <div className={`m-b-1.5 ${cnb('get-pro-btn-wrapper')}`}>
                <Button
                    onClick={showGetProModal}
                    className={cnb('get-pro-btn')}
                    iconLeft={getProButtonIconLeft}
                    aria-label="Get PRO version"
                >
                    <span className="action action-special m-l-0.25 m-r-0.25">Get PRO version</span>
                </Button>
            </div>
        </Tooltip>
    );
});
