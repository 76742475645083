import classnames from 'classnames/bind';
import { observer } from 'mobx-react';

import { Flex, FlexContainer, Icon, Modal } from '@smartfolly/frontend.web-ui';

import styles from './WarningModal.module.scss';

import { useStore } from '../../stores';

const cnb = classnames.bind(styles);

export const WarningModal = observer(function WarningModal() {
    const {
        warningModalStore: { isOpen, closeModal, body, icon },
    } = useStore();

    return (
        <Modal className={cnb('warning-modal')} show={isOpen} onHide={closeModal} header={<div />}>
            <FlexContainer
                direction="row"
                justify="stretch"
                align="start"
                className={cnb('warning-modal-main-info')}
            >
                <Flex className={cnb('warning-modal-icon')}>{!!icon && <Icon icon={icon} />}</Flex>
                <Flex className="paragraph paragraph-special color-text-primary lh-1.75">
                    <div>{body}</div>
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
