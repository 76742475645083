import { blockchainsData } from '@smartfolly/frontend.currencies-service';

import { exchangesData } from '@smartfolly/common.exchanges';

import type { Wallet } from '../types';

import type {
    BlockchainAssetWithAddressData,
    ExchangeAssetWithExchangeData,
} from '../module/types';

/**
 * Make a list of all available wallets (`address` + `blockchain` objects) from the given assets.
 * @param assets - a list of the assets to get the available wallets for.
 * @returns the list of available wallets.
 */
export function getAvailableWallets(
    assets: (BlockchainAssetWithAddressData | ExchangeAssetWithExchangeData)[],
): Wallet[] {
    // Use Object Map to get the wallets without duplicates fast
    return Object.values(
        assets.reduce<{ [key: string]: Wallet }>((acc, asset) => {
            // Create a unique wallet key to deal with duplicates
            let walletKey: string;

            // Check if the asset has an address to be grouped with
            if ('address' in asset) {
                walletKey = `${asset.address}:${asset.blockchain}`;

                // Add the wallet properties if no yet present
                if (!acc[walletKey]) {
                    acc[walletKey] = {
                        address: asset.address,
                        ...(asset.addressData ? { addressData: asset.addressData } : {}),
                        blockchain: {
                            id: asset.blockchain,
                            name: blockchainsData[asset.blockchain].name,
                            nativeToken: blockchainsData[asset.blockchain].nativeToken,
                        },
                    };
                }
            } else {
                // If no, group by custodian wallets, a.k.a. exchanges
                walletKey = `${asset.sourceId}:${asset.exchange}`;

                // Add the wallet properties if no yet present
                if (!acc[walletKey]) {
                    acc[walletKey] = {
                        sourceId: asset.sourceId,
                        ...(asset.exchangeData ? { exchangeData: asset.exchangeData } : {}),
                        exchange: {
                            id: asset.exchange,
                            name: exchangesData[asset.exchange].name,
                        },
                    };
                }
            }

            return acc;
        }, {}),
    );
}
