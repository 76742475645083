import BigNumber from 'bignumber.js';

import { findIndexToInsert } from '@smartfolly/common.utilities';

import type { Blockchains } from '@smartfolly/frontend.currencies-service';

import type { Asset, BlockchainGroup } from '../types';

import { appendTotalPriceAndPortionToGroup } from './appendTotalPriceAndPortionToGroup';

/**
 * Function to group the assets by blockchains.
 * @param assets - a list of assets to group.
 * @returns a blockchain group list.
 */
export function groupAssetsByBlockchains(assets: Asset[]): BlockchainGroup[] {
    // Calculate the total price of given assets
    let totalPrice: BigNumber | undefined;

    // Group assets by blockchains
    const filteredBlockchains = Object.values(
        assets.reduce<{ [blockchain in Blockchains]?: Omit<BlockchainGroup, 'totalPrice'> }>(
            (acc, asset) => {
                // Get the asset wallet
                const { wallet } = asset;

                // Check if the blockchain data is present
                if (!('blockchain' in wallet)) {
                    return acc;
                }

                // Create a blockchain group if not yet present
                if (!acc[wallet.blockchain.id]) {
                    acc[wallet.blockchain.id] = {
                        blockchain: wallet.blockchain, // same for any asset in the group
                        assets: [],
                    };
                }

                // Find the proper placement of the asset to insert it in the descending order
                const indexToInsert = findIndexToInsert(
                    acc[wallet.blockchain.id]!.assets,
                    asset,
                    (a, b) => {
                        // Consider the price of the asset with an unknown value as zero
                        const aAssetPrice = a.price.value ?? new BigNumber(0);
                        const bAssetPrice = b.price.value ?? new BigNumber(0);

                        // Sort the assets by the price in the descending order
                        return bAssetPrice.comparedTo(aAssetPrice);
                    },
                );

                // Add the asset to the blockchain group
                acc[wallet.blockchain.id]!.assets.splice(indexToInsert, 0, asset);

                // Increase the total price of the group as well
                if (asset.price.value) {
                    totalPrice = asset.price.value.plus(totalPrice ?? new BigNumber(0));
                }

                return acc;
            },
            {},
        ),
    );

    // Return an empty array if no blockchain groups are filtered
    if (!filteredBlockchains.length) {
        return [];
    }

    // Append the total price and the portion to each blockchain group
    return filteredBlockchains.map<BlockchainGroup>(
        group => appendTotalPriceAndPortionToGroup(group, totalPrice) as BlockchainGroup,
    );
}
