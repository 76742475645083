import type { SessionStorage } from '../../../../types';

import { SESSION_INFO_KEY } from '../constants';

import type { StorageSessionInfo } from '../types';

type StoreSessionInfoOptions = {
    /**
     * A storage to store the session info to.
     */
    storage: SessionStorage;

    /**
     * A session info to store.
     */
    sessionInfo: StorageSessionInfo;

    /**
     * An extra key to deal with several sessions.
     * Note: the key is appended as a suffix to the {@link SESSION_INFO_KEY} if defined.
     */
    key: string | undefined;
};

type StoreSessionInfoResponse = {
    /**
     * A successful result of the store operation.
     */
    result: true;
};

/**
 * A function to store the session info in the storage.
 * @param options - contain the parameters to store the session info.
 * @returns an object with the result of the store operation.
 * The `result` should always be `true`, otherwise the function @throws an error.
 */
export async function storeSessionInfoInStorage({
    storage,
    sessionInfo,
    key,
}: StoreSessionInfoOptions): Promise<StoreSessionInfoResponse> {
    let sessionInfoKey = SESSION_INFO_KEY;
    if (key) {
        sessionInfoKey += `:${key}`;
    }

    const sessionStorageString = JSON.stringify(sessionInfo);

    await storage.setItem(sessionInfoKey, sessionStorageString);
    return { result: true };
}
