import { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';

import type { Blockchain, Exchange, Token, Wallet } from '@smartfolly/frontend.assets-service';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { assetsService } from '../../services';

import { CryptoIcon } from '../CryptoIcon';
import { Dropdown } from '../Dropdown';

export const Filters = observer(function Filters() {
    const {
        appliedFilters,
        availableWallets,
        availableBlockchains,
        availableExchanges,
        availableTokens,
        filter,
    } = assetsService;

    const { pickWallets, pickBlockchains, pickExchanges, pickTokens } = appliedFilters;

    // Getters

    const selectedTokens = useMemo(() => {
        if (!pickTokens) {
            return undefined;
        }

        // Find the available tokens among filtered
        const tokens = availableTokens.filter(({ id }) => pickTokens.includes(id));

        // Select them if found
        return tokens.length > 0 ? tokens : undefined;
    }, [availableTokens, pickTokens]);

    const selectedBlockchains = useMemo(() => {
        if (!pickBlockchains) {
            return undefined;
        }

        // Find the available blockchains among filtered
        const blockchains = availableBlockchains.filter(({ id }) => pickBlockchains.includes(id));

        // Select them if found
        return blockchains.length > 0 ? blockchains : undefined;
    }, [availableBlockchains, pickBlockchains]);

    const selectedExchanges = useMemo(() => {
        if (!pickExchanges) {
            return undefined;
        }

        // Find the available exchange among filtered
        const exchanges = availableExchanges.filter(({ id }) => pickExchanges.includes(id));

        // Select them if found
        return exchanges.length > 0 ? exchanges : undefined;
    }, [availableExchanges, pickExchanges]);

    const selectedWallets = useMemo(() => {
        if (!pickWallets) {
            return undefined;
        }

        // Find the available wallets among filtered
        const wallets = availableWallets.filter(availableWallet => {
            if ('address' in availableWallet) {
                return pickWallets.find(
                    wallet =>
                        'address' in wallet &&
                        wallet.address === availableWallet.address &&
                        wallet.blockchain === availableWallet.blockchain.id,
                );
            }

            return pickWallets.find(
                wallet =>
                    'sourceId' in wallet &&
                    wallet.sourceId === availableWallet.sourceId &&
                    wallet.exchange === availableWallet.exchange.id,
            );
        });

        // Select them if found
        return wallets.length ? wallets : undefined;
    }, [availableWallets, pickWallets]);

    const blockchainIcon = useCallback(
        (blockchain: Blockchain) => (
            <CryptoIcon icon={`${blockchain.id}-network`} defaultIcon="default-network" />
        ),
        [],
    );

    const exchangeIcon = useCallback(
        (exchange: Exchange) => (
            <CryptoIcon icon={`${exchange.id}-exchange`} defaultIcon="default-exchange" />
        ),
        [],
    );

    const walletIcon = useCallback((wallet: Wallet) => {
        if ('blockchain' in wallet) {
            return (
                <CryptoIcon icon={`${wallet.blockchain.id}-wallet`} defaultIcon="default-wallet" />
            );
        }

        return <CryptoIcon icon={`${wallet.exchange.id}-wallet`} defaultIcon="default-wallet" />;
    }, []);

    // Actions

    const selectTokens = useCallback(
        (tokens: Token[] | undefined) =>
            filter({
                ...appliedFilters, // keep previously selected filters
                pickTokens: tokens ? tokens.map(({ id }) => id) : undefined,
            }),
        [appliedFilters, filter],
    );

    const selectBlockchains = useCallback(
        (blockchains: Blockchain[] | undefined) =>
            filter({
                ...appliedFilters, // keep previously selected filters
                pickBlockchains: blockchains ? blockchains.map(({ id }) => id) : undefined,
            }),
        [appliedFilters, filter],
    );

    const selectExchanges = useCallback(
        (exchanges: Exchange[] | undefined) =>
            filter({
                ...appliedFilters, // keep previously selected filters
                pickExchanges: exchanges ? exchanges.map(({ id }) => id) : undefined,
            }),
        [appliedFilters, filter],
    );

    const selectWallets = useCallback(
        (wallets: Wallet[] | undefined) =>
            filter({
                ...appliedFilters, // keep previously selected filters
                pickWallets: wallets
                    ? wallets.map(wallet => {
                          if ('address' in wallet) {
                              const {
                                  address,
                                  blockchain: { id: blockchain },
                              } = wallet;

                              return {
                                  address,
                                  blockchain,
                              };
                          }

                          const {
                              sourceId,
                              exchange: { id: exchange },
                          } = wallet;
                          return {
                              sourceId,
                              exchange,
                          };
                      })
                    : undefined,
            }),
        [appliedFilters, filter],
    );

    // Render

    // Note: in case we decide to display the Filters buttons show them differently for more than 3
    return (
        <FlexContainer
            direction={
                availableBlockchains.length > 0 && availableExchanges.length > 0 ? 'column' : 'row'
            }
        >
            <FlexContainer direction="row" justify="space-between" align="center">
                {
                    // Note: some tokens must be always available to filter
                    <Flex>
                        <Dropdown
                            title="tokens"
                            data={availableTokens}
                            selected={selectedTokens}
                            onChange={selectTokens}
                            defaultIcon="default-token"
                            showSymbol
                        />
                    </Flex>
                }
                {
                    // Note: blockchains might be not available to filter
                    availableBlockchains.length > 0 ? (
                        <Flex>
                            <Dropdown
                                title="networks"
                                data={availableBlockchains}
                                selected={selectedBlockchains}
                                onChange={selectBlockchains}
                                defaultIcon="default-network"
                                drawIcon={blockchainIcon}
                            />
                        </Flex>
                    ) : undefined
                }
            </FlexContainer>
            <FlexContainer direction="row" justify="space-between" align="center">
                {
                    // Note: exchange might be not available to filter
                    availableExchanges.length > 0 ? (
                        <Flex>
                            <Dropdown
                                title="exchanges"
                                data={availableExchanges}
                                selected={selectedExchanges}
                                onChange={selectExchanges}
                                defaultIcon="default-exchange"
                                drawIcon={exchangeIcon}
                            />
                        </Flex>
                    ) : undefined
                }
                {
                    // Note: some wallets must be always available to filter
                    <Flex>
                        <Dropdown
                            title="wallets"
                            data={availableWallets}
                            selected={selectedWallets}
                            onChange={selectWallets}
                            defaultIcon="default-wallet"
                            drawIcon={walletIcon}
                        />
                    </Flex>
                }
            </FlexContainer>
        </FlexContainer>
    );
});
