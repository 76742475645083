// Inspired by https://github.com/christsim/multicoin-address-validator

import base58 from 'bs58';

/**
 * Check if the given address is valid for SOL blockchain.
 * @param address - an address to check.
 * @returns a result of the check.
 */
export function isValidSolAddress(address: string) {
    // Solana address could be any "base58" string, and it's no necessarily a ed25519 curve,
    // hence detecting it using `PublicKey.isOnCurve` from `@solana/web3` is not a solution.
    // See: https://github.com/coral-xyz/backpack/issues/936#issue-1391984384
    // and https://stackoverflow.com/a/73152936/1264445
    try {
        // Note: the following may crash...
        return base58.decode(address).length === 32; // should be 32 bytes
    } catch (error) {
        return false;
    }

    // Legacy regex which was compatible with any TRX address but actually shouldn't be >>>
    // return /(?:^[a-km-zA-HJ-NP-Z1-9]{32,44}$)/.test(address);
}
