import type { Exchanges } from '@smartfolly/common.exchanges';

import type { Asset } from '@smartfolly/sdk';

/**
 * Make a list of all available exchanges from the given assets.
 * @param assets - a list of the assets to get the available exchanges for.
 * @returns the list of available exchanges.
 */
export function getAvailableExchanges(assets: Asset[]): Exchanges[] {
    // Use Set to get the exchanges without duplicates fast
    return Array.from(
        assets.reduce((acc, asset) => {
            // Check if the asset has an exchange data
            if ('exchange' in asset) {
                // Put the exchange into the Set
                acc.add(asset.exchange);
            }
            return acc;
        }, new Set<Exchanges>()),
    );
}
