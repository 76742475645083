import { Log } from '@smartfolly/common.utilities';

import { Protocols, serverConnector } from '@smartfolly/sdk';

import { currenciesService } from '@smartfolly/frontend.currencies-service';

import { assetsRescanner } from '../AssetsRescanner';
import { authService } from '../AuthService';
import { assetsService } from '../AssetsService';
import { boardsService } from '../BoardsService';
import { releasesService } from '../ReleasesService';
import { templatesService } from '../TemplatesService';
import { userService } from '../UserService';

import { setupLogs } from './helpers';

const log = new Log('ServicesLauncher');

class ServicesLauncher {
    /**
     * A single method to start all services once at app launch.
     */
    // eslint-disable-next-line class-methods-use-this
    public async start() {
        // Setup the logs behaviour
        setupLogs();

        // Configure the server connector singleton
        serverConnector.configure({
            host: 'mooli.app:30001', // or '0.0.0.0:30000',
            queryProtocol: Protocols.Https, // or Protocols.Http,
            subscriptionProtocol: Protocols.Wss, // or Protocols.Ws,
            // Note: to work with 'http://0.0.0.0:30000' (local version of the server)
            // just run `yarn server:build` once (in order to setup MongoDB instance)
            // and then run `yarn server:launch && yarn server:local` in the repo root
        });

        // Load low-level services which don't depend on the auth
        (async () => {
            try {
                await currenciesService.load();
            } catch (error) {
                log.error('Failed to load currencies service with error:', error);
            }
        })();

        // Now subscribe on auth session changes (with no need to unsubscribe)
        authService.subscribe(authSession => {
            (async () => {
                // Load / unload main services once `authSession` is known
                if (authSession) {
                    // Load `releasesService` if not already loading or initialized
                    if (!releasesService.isLoading && !releasesService.initialized) {
                        try {
                            await releasesService.load();
                        } catch (error) {
                            log.error('Failed to load releases service with error:', error);
                        }
                    }

                    // Load `userService` if not already loading or initialized
                    if (!userService.isLoading && !userService.initialized) {
                        try {
                            await userService.load();
                        } catch (error) {
                            log.error('Failed to load user service with error:', error);
                        }
                    }

                    // Load `assetsService` if not already loading or initialized
                    if (!assetsService.isLoading && !assetsService.initialized) {
                        try {
                            await assetsService.load();
                        } catch (error) {
                            log.error('Failed to load assets service with error:', error);
                        }
                    } else {
                        log.debug(
                            'Attempt to load the assets service which is already loading or initialized',
                        );
                    }

                    // Load `assetsRescanner` if not already loading or initialized
                    // once `assetsService` is successfully initialized
                    if (
                        assetsService.initialized &&
                        !assetsRescanner.isLoading &&
                        !assetsRescanner.initialized
                    ) {
                        try {
                            await assetsRescanner.load();
                        } catch (error) {
                            log.error('Failed to load assets rescanner with error:', error);
                        }
                    } else if (!assetsService.initialized) {
                        log.debug(
                            'Attempt to load the assets rescanner while the assets service is not yet initialized',
                        );
                    } else {
                        log.debug(
                            'Attempt to load the assets rescanner which is already loading or initialized',
                        );
                    }

                    // Load `boardsService` if not already loading or initialized
                    // once `assetsService` is successfully initialized
                    if (
                        assetsService.initialized &&
                        !boardsService.isLoading &&
                        !boardsService.initialized
                    ) {
                        try {
                            await boardsService.load();
                        } catch (error) {
                            log.error('Failed to load boards service with error:', error);
                        }
                    } else if (!assetsService.initialized) {
                        log.debug(
                            'Attempt to load the boards service while the assets service is not yet initialized',
                        );
                    } else {
                        log.debug(
                            'Attempt to load the boards service which is already loading or initialized',
                        );
                    }

                    // Load `templatesService` if not already loading or initialized
                    // once `boardsService` is successfully initialized
                    if (
                        boardsService.initialized &&
                        !templatesService.isLoading &&
                        !templatesService.initialized
                    ) {
                        try {
                            await templatesService.load();
                        } catch (error) {
                            log.error('Failed to load templates service with error:', error);
                        }
                    } else if (!boardsService.initialized) {
                        log.debug(
                            'Attempt to load the templates service while the boards service is not yet initialized',
                        );
                    } else {
                        log.debug(
                            'Attempt to load the templates service which is already loading or initialized',
                        );
                    }
                } else {
                    // Unload the templates service
                    try {
                        await templatesService.unload();
                    } catch (error) {
                        log.error('Failed to unload templates service with error:', error);
                    }

                    // Unload the boards service
                    try {
                        await boardsService.unload();
                    } catch (error) {
                        log.error('Failed to unload boards service with error:', error);
                    }

                    // Unload thr assets rescanner
                    try {
                        await assetsRescanner.unload();
                    } catch (error) {
                        log.error('Failed to unload assets rescanner with error:', error);
                    }

                    // Unload the assets service
                    try {
                        await assetsService.unload();
                    } catch (error) {
                        log.error('Failed to unload assets service with error:', error);
                    }

                    // Unload the user service
                    try {
                        await userService.unload();
                    } catch (error) {
                        log.error('Failed to unload user service with error:', error);
                    }

                    // Unload the releases service
                    try {
                        await releasesService.unload();
                    } catch (error) {
                        log.error('Failed to unload releases service with error:', error);
                    }
                }
            })();
        });

        // Then load the auth service itself (once the subscription is created)
        try {
            await authService.load();
        } catch (error) {
            log.error('Failed to load authService with error:', error);
        }
    }
}

export const servicesLauncher = new ServicesLauncher();
