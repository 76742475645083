import { computed, makeObservable, observable, when } from 'mobx';

import {
    appStorage,
    buildStorageKeyForUser,
    CommonStore,
    DocumentLoaderListener,
    findIndexToInsert,
    Log,
    MemoDocumentLoader,
} from '@smartfolly/common.utilities';

import {
    Board,
    Boards,
    BoardsManager,
    BoardsManagerOptions,
    IBoardsManager,
} from '@smartfolly/sdk';

import type { IAssetsService } from '@smartfolly/frontend.assets-service';

import { filteringOptionsToBoardFilters } from '../../../utils';

import type { AddBoardOptions, DeleteBoardOptions, EditBoardOptions } from '../../../types';

import type { IBoardsStore } from '../../types';

import { ObservableFlags } from './ObservableFlags';

const log = new Log('BoardsStore');

const ALL_BOARDS_KEY = 'BoardsStore:AllBoards';

type BoardsStoreOptions = {
    /**
     * An instance of the AssetsService to work with when dealing with the boards.
     * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
     */
    assetsService: IAssetsService;
};

export class BoardsStore extends CommonStore implements IBoardsStore {
    // Properties

    /**
     * A private instance of MobX observable flags used by the store.
     */
    private flags = new ObservableFlags();

    /**
     * An instance of the AssetsService to work with when dealing with the boards.
     */
    private assetsService: IAssetsService;

    /**
     * An instance of the BoardsManager to work with when dealing with the boards.
     */
    private boardsManager: IBoardsManager;

    /**
     * A private observable list of all boards provided by {@link boardsManager}.
     */
    private allBoardsList: Boards = [];

    /**
     * A listener to update all user boards.
     */
    private boardsLoaderListener?: DocumentLoaderListener<Boards>;

    /**
     * A memo loader of all user boards.
     */
    private memoBoardsLoader?: MemoDocumentLoader<Boards>;

    // Constructor

    public constructor({ assetsService }: BoardsStoreOptions) {
        super();

        this.assetsService = assetsService;

        let boardsManagerOptions: BoardsManagerOptions;
        if (assetsService.userAuth) {
            // Prepare the BoardsManager instance to work in "Write" mode
            boardsManagerOptions = { userAuth: assetsService.userAuth };
        } else if (assetsService.userId) {
            // Prepare the BoardsManager instance to work in "Read" mode
            boardsManagerOptions = { userId: assetsService.userId };
        } else {
            throw new Error('Failed to create a boards manager with unknown user data');
        }

        this.boardsManager = new BoardsManager(boardsManagerOptions);

        makeObservable<BoardsStore, 'allBoards' | 'allBoardsList'>(this, {
            allBoards: computed,
            allBoardsList: observable,
            boards: computed,
        });
    }

    // Getters & Setters

    /**
     * A computed list of all boards.
     */
    private get allBoards(): Boards {
        return this.allBoardsList;
    }

    /**
     * Set the list of all boards.
     */
    private set allBoards(allBoards: Boards) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.allBoardsList = allBoards;

        // TODO: Update subscriptions on all boards updates once needed.
    }

    // Interface

    protected onLoad = async () => {
        // Load the boards
        // Note, that we do not need to await here to ensure that boards are loaded,
        // since we unsubscribe from boards listener immediately when unloading them
        this.loadBoards();
    };

    protected onUnload = async () => {
        // Unload the boards
        this.unloadBoards();
    };

    public get boards(): Board[] {
        return (
            this.allBoards
                // Slice the array as the upcoming .sort method sorts an array in place,
                // which is not allowed for observable arrays
                .slice()
                // Ensure the order by sorting the boards in descending order
                .sort((a, b) => b.sortingIndex - a.sortingIndex)
        );
    }

    public get isLoadingBoards(): boolean {
        return this.flags.isLoadingBoards;
    }

    public get isRefreshingBoards(): boolean {
        return this.flags.isRefreshingBoards;
    }

    public get isAddingBoard(): boolean {
        return this.flags.isAddingBoard;
    }

    public get isEditingBoard(): boolean {
        return this.flags.isEditingBoard;
    }

    public get isDeletingBoard(): boolean {
        return this.flags.isDeletingBoard;
    }

    public refreshBoards = async (): Promise<boolean> => {
        // Mark the boards are being refreshed
        this.flags.isRefreshingBoards = true;
        try {
            // Wait the boards are loaded first
            await this.waitUntilBoardsLoaded();

            // Refetch all boards
            const allBoards = await this.boardsManager.getAllBoards();

            // Check if the boards are still listened to by the loader
            if (!this.boardsLoaderListener) {
                // Return a positive result of the operation, but do not update the boards
                return true;
            }

            // Update all boards via the listener
            this.boardsLoaderListener(undefined, allBoards);

            // Return a positive result of the operation
            return true;
        } catch (error) {
            log.error('Failed to refresh the boards with error:', error);

            // Return a negative result of the operation
            return false;
        } finally {
            // Mark the boards are not being refreshed anymore
            this.flags.isRefreshingBoards = false;
        }
    };

    public addBoard = async (options: AddBoardOptions): Promise<Board | false> => {
        // Mark a board is being added
        this.flags.isAddingBoard = true;
        try {
            // Wait the boards are loaded first
            await this.waitUntilBoardsLoaded();

            // Add the board depending on its type (either with mandatory Filters or SelectedAssets)
            let addedBoard: Board | undefined;
            if ('selectedAssets' in options) {
                // Destruct the given options
                const { filters, ...rest } = options;

                // Make the options.filters suitable for SDK
                const addFilters = filteringOptionsToBoardFilters(filters);

                // Add a board by replacing the filters if they are defined
                addedBoard = await this.boardsManager.addBoard({
                    ...rest,
                    ...(addFilters !== undefined ? { filters: addFilters } : {}),
                });
            } else {
                // Destruct the given options
                const { filters, ...rest } = options;

                // Make the options.filters suitable for SDK
                const addFilters = filteringOptionsToBoardFilters(filters);

                // Add a board by replacing the filters if they are defined
                addedBoard = await this.boardsManager.addBoard({ ...rest, filters: addFilters });
            }
            log.debug('Added board:', addedBoard);

            // Check if the board has been added
            if (!addedBoard) {
                // Return a negative result of the operation
                return false;
            }

            // Check if the boards are still listened to by the loader
            if (!this.boardsLoaderListener) {
                // Return a positive result of the operation, but do not update the boards
                return addedBoard;
            }

            // Update all boards via the listener

            // Get all boards
            const { allBoards } = this;

            // Insert the new board in the list right before the board
            // with the `sortingIndex` lower than the added one, i.e.
            // in descending order
            const indexToInsert = findIndexToInsert(
                allBoards,
                addedBoard,
                (a, b) => b.sortingIndex - a.sortingIndex,
            );

            // Update the boards and trigger the boards loader listener
            const updatedBoards = allBoards.slice(0); // copy
            updatedBoards.splice(indexToInsert, 0, addedBoard); // insert the board
            this.boardsLoaderListener(undefined, updatedBoards);

            // Return a positive result of the operation
            return addedBoard;
        } catch (error) {
            log.error('Failed to add a board with error:', error);

            // Return a negative result of the operation
            return false;
        } finally {
            // Mark a board is not being added anymore
            this.flags.isAddingBoard = false;
        }
    };

    public editBoard = async (options: EditBoardOptions): Promise<Board | false> => {
        // Mark a board is being edited
        this.flags.isEditingBoard = true;
        try {
            // Wait the boards are loaded first
            await this.waitUntilBoardsLoaded();

            // Destruct the given options
            const { filters, ...rest } = options;

            // Make the options.filters suitable for SDK
            const editFilters = filteringOptionsToBoardFilters(filters);

            // Edit a board by replacing the filters if they are defined
            const editedBoard = await this.boardsManager.editBoard({
                ...rest,
                ...(editFilters !== undefined ? { filters: editFilters } : {}),
            });
            log.debug('Edited board:', editedBoard);

            // Check if the board has been edited
            if (!editedBoard) {
                // Return a negative result of the operation
                return false;
            }

            // Check if the boards are still listened to by the loader
            if (!this.boardsLoaderListener) {
                // Return a positive result of the operation, but do not update the boards
                return editedBoard;
            }

            // Update all boards via the listener

            // Get the all boards
            const { allBoards } = this;

            // Find the index of the board to edit
            const indexToEdit = allBoards.findIndex(board => board.boardId === options.boardId);

            // Check if the index of the board to edit is found
            if (indexToEdit < 0) {
                log.debug('Failed to edit a missing board in the list');
                // Return a positive result of the operation, but the boards have failed to update
                return editedBoard;
            }

            // Edit the board and trigger the board loader listener
            const updatedBoards = allBoards.slice(0); // copy
            updatedBoards.splice(indexToEdit, 1, editedBoard); // edit the board
            this.boardsLoaderListener(undefined, updatedBoards);

            // Return a positive result of the operation
            return editedBoard;
        } catch (error) {
            log.error('Failed to edit a board with error:', error);

            // Return a negative result of the operation
            return false;
        } finally {
            // Mark a board is not being added anymore
            this.flags.isEditingBoard = false;
        }
    };

    public deleteBoard = async (options: DeleteBoardOptions): Promise<boolean> => {
        // Mark a board is being deleted
        this.flags.isDeletingBoard = true;
        try {
            // Wait the boards are loaded first
            await this.waitUntilBoardsLoaded();

            // Delete a board
            const deletionResult = await this.boardsManager.deleteBoard(options);
            log.debug('Deletion result:', deletionResult);

            // Check if the board has been deleted
            if (!deletionResult) {
                // Return a negative result of the operation
                return false;
            }

            // Check if the boards are still listened to by the loader
            if (!this.boardsLoaderListener) {
                // Return a positive result of the operation, but do not update the boards
                return true;
            }

            // Update all boards via the listener

            // Get the all boards
            const { allBoards } = this;

            // Find the index of the board to delete
            const indexToDelete = allBoards.findIndex(board => board.boardId === options.boardId);

            // Check if the index of the board to delete is found
            if (indexToDelete < 0) {
                log.debug('Failed to delete a missing board in the list');
                // Return a positive result of the operation, but the boards have failed to update
                return true;
            }

            // Delete the board and trigger the board loader listener
            const updatedBoards = allBoards.slice(0); // copy
            updatedBoards.splice(indexToDelete, 1); // delete the board
            this.boardsLoaderListener(undefined, updatedBoards);

            // Return a positive result of the operation
            return true;
        } catch (error) {
            log.error('Failed to delete a board with error:', error);

            // Return a negative result of the operation
            return false;
        } finally {
            // Mark a board is not being deleted anymore
            this.flags.isDeletingBoard = false;
        }
    };

    // Internals

    /**
     * Method to load boards using memo loader.
     * @throws a variety of errors if the user is not authorized or boards are already loading.
     */
    private loadBoards() {
        // Check if the boards loader already exist to avoid loading the store twice
        if (this.memoBoardsLoader) {
            throw new Error('Boards are already loading');
        }

        if (!this.assetsService.userId) {
            throw new Error('Not ready to load as there is no user to load the boards for');
        }

        // Mark the boards are loading
        this.flags.isLoadingBoards = true;

        // Get a storage key to memo the boards
        // Note: could throw if the user is not authorized
        const storageKey = buildStorageKeyForUser(ALL_BOARDS_KEY, this.assetsService.userId);

        // Get all boards via memo loader
        this.memoBoardsLoader = new MemoDocumentLoader<Boards>({
            key: storageKey,
            storage: appStorage,
            documentFetcher: (listener: DocumentLoaderListener<Boards>) => {
                // Save the listener in order to reflect boards changes made by other methods
                this.boardsLoaderListener = listener;

                // Get all the boards from the backend
                this.boardsManager
                    .getAllBoards()
                    .then(allBoards => listener(undefined, allBoards))
                    .catch(error => listener(error))
                    .finally(() => {
                        // Mark the boards are loaded
                        this.flags.isLoadingBoards = false;
                    });
            },
            stopDocumentFetching: () => {
                // Delete the boards listener
                delete this.boardsLoaderListener;
            },
        });

        // Listen to all boards changes
        this.memoBoardsLoader.subscribe((error: Error | undefined, allBoards?: Boards) => {
            if (error) {
                log.error('Failed to fetch all boards with error:', error);
                return;
            }

            if (!allBoards) {
                log.error('No boards have been loaded');
                return;
            }

            this.allBoards = allBoards;
        });
    }

    /**
     * Methods to unload boards with the memo loader.
     */
    private unloadBoards() {
        // Unsubscribe from the memo boards loader
        if (this.memoBoardsLoader) {
            this.memoBoardsLoader.unsubscribe();

            // And delete the loader itself
            delete this.memoBoardsLoader;
        }

        // Mark the boards are not loading anymore
        this.flags.isLoadingBoards = false;

        // Remove all boards
        // Note: this should also unsubscribe from all the boards updates in the future (once done).
        this.allBoards = [];
    }

    /**
     * Method to wait until the boards are loaded from the remote.
     */
    private async waitUntilBoardsLoaded() {
        // The service should be initialized to wait
        await this.waitUntilInitialized();

        // When until the boards are loaded,
        // i.e. not loading anymore as they start loading on the service load
        return when(() => this.isLoadingBoards === false, { name: 'wait until the boards loaded' });
    }
}
