import { serverConnector } from '@smartfolly/middleware.server-connector';

import type {
    PrepareRequestParameters,
    PrepareRequestResponse,
    ResponseError,
} from '@smartfolly/server';

import { AuthUserRequestPath } from '../constants';

/**
 * A request to prepare to auth.
 */
export async function authPrepare(
    params: PrepareRequestParameters,
): Promise<PrepareRequestResponse> {
    const prepareResponse = await serverConnector.sendPOSTRequest<
        PrepareRequestParameters,
        PrepareRequestResponse
    >({
        params,
        path: AuthUserRequestPath.Prepare,
    });

    // Check for errors in response
    if ('error' in prepareResponse) {
        const { error } = prepareResponse as ResponseError;
        // TODO: process `errorCode` from the response if needed
        throw new Error(error);
    }

    return prepareResponse;
}
