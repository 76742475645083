import * as amplitude from '@amplitude/analytics-browser';
import classnames from 'classnames/bind';
import { useCallback } from 'react';

import { Button, Flex, FlexContainer, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { getQuickActionsLinksForAsset, type Asset } from '@smartfolly/frontend.assets-service';
import { assetsService } from '../../services';
import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

const quickLinksIcons: { [key: string]: IButtonIcon } = {
    buy: {
        icon: <Icon icon="add-plus-mini" className="m-r-0.25" />,
        animation: 'none',
    },
    swap: {
        icon: <Icon icon="h-change-mini" className="m-r-0.25" />,
        animation: 'none',
    },
    bridge: {
        icon: <Icon icon="bridge-mini" className="m-r-0.25" />,
        animation: 'none',
    },
    stake: {
        icon: <Icon icon="percentage-mini" className="m-r-0.25" />,
        animation: 'none',
    },
    sell: {
        icon: <Icon icon="card-mini" className="m-r-0.25" />,
        animation: 'none',
    },
};
export function QuickLinks({ asset }: { asset: Asset }) {
    // Getters

    const quickActions = getQuickActionsLinksForAsset(
        asset,
        assetsService.selectedExchangeCurrency,
    );
    // Actions

    const buy = useCallback(() => {
        amplitude.logEvent('Buy asset clicked', { asset: asset.token.name });
        window.open(quickActions.buy, '_blank');
    }, [quickActions, asset]);
    const swap = useCallback(() => {
        amplitude.logEvent('Swap asset clicked', { asset: asset.token.name });
        window.open(quickActions.swap, '_blank');
    }, [quickActions, asset]);
    const bridge = useCallback(() => {
        amplitude.logEvent('Bridge asset clicked', { asset: asset.token.name });
        window.open(quickActions.bridge, '_blank');
    }, [quickActions, asset]);
    const stake = useCallback(() => {
        amplitude.logEvent('Stake asset clicked', { asset: asset.token.name });
        window.open(quickActions.stake, '_blank');
    }, [quickActions, asset]);
    const sell = useCallback(() => {
        amplitude.logEvent('Sell asset clicked', { asset: asset.token.name });
        window.open(quickActions.sell, '_blank');
    }, [quickActions, asset]);

    return (
        <FlexContainer
            className={`m-b-1 ${cnb('quick-links')}`}
            direction="row"
            justify="stretch"
            align="stretch"
        >
            <Flex className="m-r-0.5">
                {quickActions.buy && (
                    <Button iconLeft={quickLinksIcons.buy} onClick={buy}>
                        <span className="action action-special">Buy</span>
                    </Button>
                )}
            </Flex>
            <Flex className="m-r-0.5">
                {quickActions.swap && (
                    <Button iconLeft={quickLinksIcons.swap} onClick={swap}>
                        <span className="action action-special">Swap</span>
                    </Button>
                )}
            </Flex>
            <Flex className="m-r-0.5">
                {quickActions.bridge && (
                    <Button iconLeft={quickLinksIcons.bridge} onClick={bridge}>
                        <span className="action action-special">Bridge</span>
                    </Button>
                )}
            </Flex>
            <Flex className="m-r-0.5">
                {quickActions.stake && (
                    <Button iconLeft={quickLinksIcons.stake} onClick={stake}>
                        <span className="action action-special">Stake</span>
                    </Button>
                )}
            </Flex>
            <Flex>
                {quickActions.sell && (
                    <Button iconLeft={quickLinksIcons.sell} onClick={sell}>
                        <span className="action action-special">Sell</span>
                    </Button>
                )}
            </Flex>
        </FlexContainer>
    );
}
