import type { Auth } from '@smartfolly/server';

import { serverConnector } from '@smartfolly/middleware.server-connector';

import { generateLinkToTelegramBot } from '@smartfolly/common.providers';

import { AuthProvider, AuthUserError } from '../../constants';

import type { UserAuthTelegramBotOptions } from '../../types';

import { authCollection, authSubscription } from '../constants';

/**
 * Function to subscribe to the auth info nonce which should be updated by Telegram bot to auth.
 */
export async function subscribeToTelegramBotNonce({
    authId,
    shortenId,
    onLinkGenerated,
}: {
    authId: string;
    shortenId: string;
} & UserAuthTelegramBotOptions) {
    return new Promise<string>((resolve, reject) => {
        // First subscribe to the auth object in order to get the updated "nonce"
        // once "telegramUserId" is present
        const disposer = serverConnector.subscribe<{ authId: string }, Auth>(
            {
                collection: authCollection,
                query: authSubscription,
                variables: {
                    authId,
                },
            },
            (error: Error | undefined, auth?: Auth | null) => {
                // Reject with an error if any
                if (error) {
                    reject(error);
                    disposer();
                    return;
                }

                // Check if auth is present
                if (!auth) {
                    // Do nothing
                    return;
                }

                // Ensure the provider is the TelegramBot
                if (auth.provider !== AuthProvider.TelegramBot) {
                    reject(new Error('The auth info does not belong to TelegramBot provider'));
                    return;
                }

                // Check if auth contains "telegramUserId"
                if (auth.telegramUserId) {
                    // Return the updated nonce if so
                    resolve(auth.nonce);
                }

                // TODO: think either need to pass an error message from the bot if any
            },
        );

        // Then create a "stopProcessing" function
        const stopProcessing = () => {
            // Dispose from the subscription first
            disposer();

            // Then reject the promise
            reject(AuthUserError.Providers.TelegramBot.Stopped);
        };

        // Finally generate the link to the Telegram bot
        // Note: the link has an expiration time and is one-time only for security reasons
        const link = generateLinkToTelegramBot(shortenId);

        // And pass the link with an ability to stop processing it into the callback
        onLinkGenerated(link, stopProcessing);
    });
}
