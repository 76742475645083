import { createWithUserId as assetsServiceWithUserId } from '@smartfolly/frontend.assets-service';

import type { IAssetsService } from '@smartfolly/frontend.assets-service';

import type { IBoardsService } from '../types';

import { BoardsService } from './BoardsService';

/**
 * Create the BoardsService instance which should work with the provided "AssetsService" instance.
 * @returns an instance of the BoardsService.
 */
export function createWithAssetsService(assetsService: IAssetsService): IBoardsService {
    return new BoardsService({ assetsService });
}

/**
 * Create the BoardsService instance which should work with the provided "userId".
 * Note: such service should be able to deal with boards in "Read" mode (i.e. only view data).
 * @returns instances of the AssetsService and the BoardsService with a method to unload them.
 */
export async function createWithUserId(userId: string): Promise<{
    assetsService: IAssetsService;
    boardsService: IBoardsService;
    unload: () => Promise<void>;
}> {
    // Create an AssetsService instance first
    const assetsService = assetsServiceWithUserId(userId);

    // Load the AssetsService
    await assetsService.load();

    // Create a BoardsService instance
    const boardsService = createWithAssetsService(assetsService);

    // Load the BoardsService
    await boardsService.load();

    // Prepare a method to unload both services
    const unload = async () => {
        await Promise.all([assetsService.unload(), boardsService.unload()]);
    };

    return {
        assetsService,
        boardsService,
        unload,
    };
}
