import { UserAuth } from '@smartfolly/sdk';

import { createWithUserAuth } from '@smartfolly/frontend.auth-service';

// Create the UserAuth module to work with the current user
// Note: the module will be configured by the AuthService instance
const userAuth = new UserAuth();

// Create the AuthService for my user to work with
export const authService = createWithUserAuth(userAuth);
