import { BoardTemplates } from '@smartfolly/sdk';

/**
 * Predefined template board names.
 * Note: the template boards are created with these names when adding such boards via the service.
 */
export const templateBoardNames: Record<BoardTemplates, string> = {
    [BoardTemplates.Total]: 'Total Board',
    [BoardTemplates.CoreCrypto]: 'Core Crypto',
    [BoardTemplates.Stablecoins]: 'Stablecoins',
    [BoardTemplates.StakedTokens]: 'Staked tokens',
};
