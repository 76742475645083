import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import type { ItemContent, TableComponents } from 'react-virtuoso';
import { TableVirtuoso } from 'react-virtuoso';

import type { ExchangeGroup } from '@smartfolly/frontend.assets-service';

import { assetsService } from '../../../services';

import { ActiveEmptyPlaceholder } from '../ActiveEmptyPlaceholder';
import { FixedFooterContent, FixedHeaderContent } from '../FixedListContent';
import { Footer } from '../Footer';

import styles from '../Assets.module.scss';

import { ExchangeCell } from './ExchangeCell';

const cnb = classnames.bind(styles);

function EmptyExchangesPlaceholder() {
    return <ActiveEmptyPlaceholder active="exchanges" />;
}

const listComponents: TableComponents<ExchangeGroup> = {
    EmptyPlaceholder: EmptyExchangesPlaceholder,
    TableFoot: Footer,
};

export const ActiveExchanges = observer(function ActiveExchanges() {
    const {
        filteredExchanges: { groups },
    } = assetsService;

    const renderExchange: ItemContent<ExchangeGroup, unknown> = useCallback(
        (_index, group) => <ExchangeCell group={group} />,
        [],
    );

    return (
        <TableVirtuoso
            className={cnb('grid-wrapper', 'table-wrapper')}
            data={groups}
            totalCount={groups.length}
            fixedHeaderContent={FixedHeaderContent}
            fixedFooterContent={FixedFooterContent}
            itemContent={renderExchange}
            fixedItemHeight={56}
            components={listComponents}
            useWindowScroll
        />
    );
});
