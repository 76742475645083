import type { BoardFilters } from '@smartfolly/sdk';

import type { FilteringOptions } from '@smartfolly/frontend.assets-service';

/**
 * Function to convert the filtering options from the `AssetsService` into the filters of the board.
 * @param filteringOptions - the given filtering options.
 * @returns an object suitable for the filters of the board.
 * Note: we differ the `null` and `undefined` filters, as `null` tends to remove them from the board,
 * while `undefined` does nothing since such values are not received and processed by the server.
 */
type BoardFiltersToFilteringOptions<T> = T extends undefined
    ? undefined
    : T extends null
    ? null
    : FilteringOptions;
export function boardFiltersToFilteringOptions<T extends BoardFilters | null | undefined>(
    filteringOptions: T,
): BoardFiltersToFilteringOptions<T> {
    if (filteringOptions === undefined) {
        return undefined as BoardFiltersToFilteringOptions<T>;
    }

    if (filteringOptions === null) {
        return null as BoardFiltersToFilteringOptions<T>;
    }

    const filters: FilteringOptions = {};
    if (filteringOptions.tokens) {
        filters.pickTokens = filteringOptions.tokens;
    }
    if (filteringOptions.blockchains) {
        filters.pickBlockchains = filteringOptions.blockchains;
    }
    if (filteringOptions.exchanges) {
        filters.pickExchanges = filteringOptions.exchanges;
    }
    if (filteringOptions.wallets) {
        filters.pickWallets = filteringOptions.wallets;
    }
    if (filteringOptions.contractTypes) {
        filters.pickContractTypes = filteringOptions.contractTypes;
    }
    if (filteringOptions.tokenTypes) {
        filters.pickTokenTypes = filteringOptions.tokenTypes;
    }
    // TODO: deal with the prices once they are supported by the FilteringOptions.

    return filters as BoardFiltersToFilteringOptions<T>;
}
