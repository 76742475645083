import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

import type { Group } from '@smartfolly/frontend.assets-service';

/**
 * Max length of the `trendingGroups` list by design.
 */
const trendingGroupsMaxLength = 8;

/**
 * Hook to get the list of trending groups from the list of groups.
 * @param groups - a list of groups.
 * @returns the list of trending groups.
 */
export function useTrendingGroups<T extends Group>(groups: T[]): T[] {
    return useMemo(() => {
        // Sort groups by the percent change during the 24 hours period
        const groupsSortedBy24PercentChange =
            // Note: .sort() function mutates the provided array! Make a copy of it before sorting!
            groups.slice().sort((a, b) => {
                // Consider the percent change of the price with an unknown value as zero
                const aGroupPercentChange24h =
                    a.totalPrice.percentChange24h.value ?? new BigNumber(0);
                const bGroupPercentChange24h =
                    b.totalPrice.percentChange24h.value ?? new BigNumber(0);

                // Sort the groups by the percent change in the descending order
                return bGroupPercentChange24h.comparedTo(aGroupPercentChange24h);
            });

        // Build the trending groups list taking the first top "highest" and last "lowest" groups
        // in terms of the `percentChange24h` value, where the list length is less or equal to `8`
        const topTrendingGroups: T[] = [];
        const bottomTrendingGroups: T[] = [];

        // Create a function to check if need to break looping the groups
        // once we are ready to build the trending groups list including both "top" and "bottom"
        const needToBreakLooping = () =>
            topTrendingGroups.length + bottomTrendingGroups.length === trendingGroupsMaxLength;

        // Loop the the groups from taking the groups from the both ends,
        // since the list of the groups is sorted by the `percentChange24h` value
        for (let i = 0; i < groupsSortedBy24PercentChange.length / 2; i += 1) {
            // Get the top group and insert it at the first place if its value differs from zero
            const topGroup = groupsSortedBy24PercentChange[i]!;
            if (
                topGroup.totalPrice.percentChange24h.value != null && // ensure the value is present
                !topGroup.totalPrice.percentChange24h.value.eq(new BigNumber(0)) // and not zero
            ) {
                topTrendingGroups.push(topGroup);
                // Check if the trending groups list length equals to the max length
                if (needToBreakLooping()) {
                    // Break the loop if we reached the limit
                    break;
                }
            }

            // Ensure the break if the topGroup was the last to check
            if (i === groupsSortedBy24PercentChange.length - 1 - i) {
                break;
            }

            // Get the bottom group and insert it at the last place if its value differs from zero
            const bottomGroup =
                groupsSortedBy24PercentChange[groupsSortedBy24PercentChange.length - 1 - i]!;
            if (
                bottomGroup.totalPrice.percentChange24h.value && // ensure the value is present
                !bottomGroup.totalPrice.percentChange24h.value.eq(new BigNumber(0)) // and not zero
            ) {
                bottomTrendingGroups.splice(0, 0, bottomGroup);
                // Check if the trending groups list length equals to the max length
                if (needToBreakLooping()) {
                    // Break the loop if we reached the limit
                    break;
                }
            }
        }

        // Return the concatenated trending groups list
        return topTrendingGroups.concat(bottomTrendingGroups);
    }, [groups]);
}
