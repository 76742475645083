import { useMemo } from 'react';

import type { BlockchainGroup, TokenGroup, WalletGroup } from '@smartfolly/frontend.assets-service';

import { PieChartSlice, buildPieChartData } from '../helpers';

/**
 * Hook to get the pie chart data from the provided groups sorted by the .TotalPrice.
 * @param groups - the given sorted groups to build the pie chart data for.
 * @param othersName - the name of the "Others" slice.
 * @returns the built pie chart data.
 */
export function usePieChartData(
    groups: (TokenGroup | BlockchainGroup | WalletGroup)[],
    othersName = 'Others',
): PieChartSlice[] {
    return useMemo(() => buildPieChartData(groups, othersName), [groups, othersName]);
}
