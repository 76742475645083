import { appStorage, buildStorageKeyForUser, CommonStore } from '@smartfolly/common.utilities';

import type { IAssetsService } from '@smartfolly/frontend.assets-service';

import type { IAssetsRescanner } from '../types';

const ONE_HOUR_DURATION = 3600000; // = 60 * 60 * 1000

type AssetsRescannerOptions = {
    /**
     * An instance of the AssetsService to work with when rescanning the assets.
     * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
     */
    assetsService: IAssetsService;
};

export class AssetsRescanner extends CommonStore implements IAssetsRescanner {
    // Properties

    /**
     * A timeout to try to rescan the assets.
     */
    private rescanningTimeout?: ReturnType<typeof setTimeout>;

    /**
     * An interval to rescan the assets periodically.
     */
    private rescanningInterval?: ReturnType<typeof setInterval>;

    /**
     * An instance of the AssetsService to work with when rescanning the assets..
     */
    private assetsService: IAssetsService;

    // Constructor

    public constructor(options: AssetsRescannerOptions) {
        super();

        this.assetsService = options.assetsService;
    }

    // Interface

    protected onLoad = async () => {
        if (!this.assetsService.initialized) {
            throw new Error('Assets service is not initialized when loading Assets rescanner');
        }

        // Try to rescan assets or schedule the rescan when it's possible
        await this.tryToRescanAssets();
    };

    protected onUnload = async () => {
        // Destroy the rescanning timeout if any
        if (this.rescanningTimeout) {
            clearTimeout(this.rescanningTimeout);
            delete this.rescanningTimeout;
        }

        // Destroy the rescanning interval if any
        if (this.rescanningInterval) {
            clearInterval(this.rescanningInterval);
            delete this.rescanningInterval;
        }
    };

    // Internals

    /**
     * Getter for the storage key where the timestamp of the last assets rescan is kept.
     */
    // eslint-disable-next-line class-methods-use-this
    private get lastTimeRescannedKey(): string {
        if (!this.assetsService.userId) {
            throw new Error('Assets service has no user to rescan the assets for');
        }

        return buildStorageKeyForUser('assetsRescannedTimestamp', this.assetsService.userId);
    }

    /**
     * Method to try rescanning the assets or schedule the rescan when it's possible.
     */
    private tryToRescanAssets = async () => {
        // Check the last time assets were scanned
        const lastTimeRescannedValue = await appStorage.getItem(this.lastTimeRescannedKey);
        const lastTimeRescanned = lastTimeRescannedValue ? Number(lastTimeRescannedValue) : 0;

        // Try to rescan assets if it wasn't done for more than 1 hour
        // or schedule the another attempt when appropriate
        const delay = ONE_HOUR_DURATION - (Date.now() - lastTimeRescanned);
        if (delay < 0) {
            // Ready to rescan (as it wasn't scanned for more than 1 hour or even never)
            this.scheduleAssetsRescan();
        } else if (delay > ONE_HOUR_DURATION) {
            // Delay calculation is wrong. Do nothing and log an error message
            this.log.error('The delay required to rescan the assets is wrong');
        } else {
            // Schedule an another rescan attempt
            this.rescanningTimeout = setTimeout(this.tryToRescanAssets, delay);
        }
    };

    /**
     * Method to schedule the assets rescan.
     */
    private scheduleAssetsRescan = () => {
        // Rescan assets
        this.rescanAssets();

        // Schedule the assets rescan every hour
        this.rescanningInterval = setInterval(this.rescanAssets, ONE_HOUR_DURATION);
    };

    /**
     * Methods to rescan the assets.
     */
    private rescanAssets = () => {
        (async () => {
            try {
                // Rescan assets
                await this.assetsService.rescanAssets();

                // Store the last time assets were successfully rescanned
                const lastTimeRescannedValue = Date.now().toString();
                await appStorage.setItem(this.lastTimeRescannedKey, lastTimeRescannedValue);
            } catch (error) {
                this.log.error('Failed to rescan assets with error:', error);
            }
        })();
    };
}
