import type { Asset } from '../../../types';

/**
 * Function to get a link on the Celer bridge.
 */
export function getCelerBridgeLink(): string {
    return 'https://cbridge.celer.network';
}

/**
 * Function to get a link on the Celer bridge for an asset.
 * @param asset - asset to get a link for.
 */
export function getCelerBridgeLinkForAsset(asset: Asset): string | undefined {
    // Check if the asset has a blockchain to be passed through the bridge
    if (!('blockchain' in asset.wallet)) {
        return undefined;
    }

    return 'https://cbridge.celer.network';
}
