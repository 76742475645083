import { useCallback, useState } from 'react';

import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import type { Exchanges } from '@smartfolly/common.exchanges';

import { showToast } from '../../../utils';

import { Tooltip } from '../../Common';

import { AddWalletModal } from '../AddWalletModal';

const addWalletButtonIconLeft: IButtonIcon = {
    icon: <Icon icon="add-plus" className="m-r-0" />,
    animation: 'none',
};

export function AddWalletButton() {
    // States

    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    // Getters

    // Actions

    const showAddModalFn = useCallback(() => {
        setShowAddModal(true);
    }, []);

    const hideAddModalFn = useCallback(() => {
        setShowAddModal(false);
    }, []);

    const onAddressAdded = useCallback(
        (result: boolean, error?: Error) => {
            if (!error) {
                hideAddModalFn();

                if (result) {
                    showToast('Address was added');
                } else {
                    showToast('No assets found');
                }
            } else {
                // Let user retry adding an address in case of an error,
                // hereby don't close the add wallet modal
            }
        },
        [hideAddModalFn],
    );

    const onExchangeAdded = useCallback(
        (_exchange: Exchanges, result: boolean, error?: Error) => {
            if (!error) {
                hideAddModalFn();

                if (result) {
                    showToast('Exchange was added');
                } else {
                    showToast('No assets found');
                }
            } else {
                // Let user retry adding an exchange in case of an error,
                // hereby don't close the add wallet modal
            }
        },
        [hideAddModalFn],
    );

    // Render

    return (
        <>
            <Tooltip content="Wallet or Exchange">
                <Button
                    onClick={showAddModalFn}
                    className="btn-primary back-special-accent-dark m-r-0.5 p-r-0.5 p-l-0.5 b-r-3 onboarding-step-4"
                    iconLeft={addWalletButtonIconLeft}
                    aria-label="Connect"
                >
                    <span className="action action-special d-none d-sm-block m-l-0.25 m-r-0.25">
                        Connect
                    </span>
                </Button>
            </Tooltip>
            <AddWalletModal
                confirmed={showAddModal}
                onHide={hideAddModalFn}
                onAddressAddedViaMetaMaskExt={onAddressAdded}
                onAddressAddedViaSurfKeeper={onAddressAdded}
                onAddressAddedViaWalletConnect={onAddressAdded}
                onAddressAddedByOthers={onAddressAdded}
                onAddressAddedManually={onAddressAdded}
                onExchangeAdded={onExchangeAdded}
            />
        </>
    );
}
