import type BigNumber from 'bignumber.js';
import { useMemo } from 'react';

/**
 * Hook to get CSS classname for positive or negative value.
 * @param value - a value for class determination.
 * @returns a CSS classname.
 */
export function usePercentClassName(value: BigNumber | undefined) {
    return useMemo(() => {
        if (value) {
            if (value.comparedTo(0) === 1) {
                return 'text-positive';
            }
            if (value.comparedTo(0) === -1) {
                return 'text-negative';
            }
        }
        return 'color-text-secondary';
    }, [value]);
}
