import classnames from 'classnames/bind';
import { useMemo, ReactElement, useCallback, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

import { Flex, FlexContainer, Icon } from '@smartfolly/frontend.web-ui';

import type { Blockchain, Exchange, Token, Wallet } from '@smartfolly/frontend.assets-service';

import { useFormattedWallet, useOutsideClick } from '../../hooks';

import styles from './Dropdown.module.scss';

const cnb = classnames.bind(styles);

type Item = Token | Blockchain | Exchange | Wallet;

function DropdownItem<T extends Item>({
    defaultIcon,
    drawIcon,
    isSelected,
    onToggle,
    item,
}: {
    defaultIcon?: string;
    drawIcon?: (item: T) => ReactElement;
    isSelected: boolean;
    onToggle: (item: T) => void;
    item: T;
}) {
    // Getters

    const formattedWallet = useFormattedWallet(
        'address' in item || 'sourceId' in item ? item : null,
    );

    // Action

    const onClick = useCallback(() => onToggle(item), [item, onToggle]);

    // Render

    return (
        <FlexContainer
            onClick={onClick}
            key={JSON.stringify(item)}
            justify="start"
            align="center"
            className={cnb('filter-dropdown-element')}
        >
            <Flex>
                {drawIcon && <div className={cnb('icon')}>{drawIcon(item)}</div>}
                {!drawIcon && (
                    <span>
                        {'icon' in item ? (
                            <>
                                {item.icon && (
                                    <div className={cnb('icon')}>
                                        <img src={item.icon} alt="" />
                                    </div>
                                )}
                                {!item.icon && (
                                    <div className={cnb('icon')}>
                                        {defaultIcon && <Icon icon={defaultIcon} />}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className={cnb('icon')}>
                                {defaultIcon && <Icon icon={defaultIcon} />}
                            </div>
                        )}
                    </span>
                )}
            </Flex>

            <Flex className={cnb('title')} grow={1}>
                {'name' in item ? item.name : formattedWallet}
            </Flex>

            <Flex className={cnb('check-wrapper')}>
                <Button variant="link">{isSelected && <Icon icon="check" />}</Button>
            </Flex>
        </FlexContainer>
    );
}

export function Dropdown<T extends Item>({
    title,
    data,
    selected,
    onChange,
    defaultIcon,
    drawIcon,
    showSymbol,
}: {
    title?: string;
    data: Array<T>;
    selected?: Array<T> | undefined;
    onChange: (items: Array<T> | undefined) => void;
    defaultIcon?: string;
    drawIcon?: (item: T) => ReactElement;
    showSymbol?: boolean;
}) {
    // Refs

    const refDropdown = useRef<HTMLDivElement>(null);

    // States

    const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);

    // Getters
    const firstItem = useMemo(() => selected?.[0], [selected]);

    const formattedWallet = useFormattedWallet(
        firstItem && ('address' in firstItem || 'sourceId' in firstItem) ? firstItem : null,
        { shortenBlockchainName: true },
    );

    const name = useMemo(() => {
        if (showSymbol) {
            return selected && firstItem && 'symbol' in firstItem
                ? firstItem.symbol
                : formattedWallet;
        }

        return firstItem && 'name' in firstItem ? firstItem.name : formattedWallet;
    }, [showSymbol, firstItem, formattedWallet, selected]);

    // Life-cycle

    useOutsideClick(refDropdown, () => {
        setShowDropdownMenu(false);
    });

    // Actions

    const selectAll = useCallback(() => {
        if (!selected || selected.length === 0) {
            // Select all
            onChange(data);
        } else {
            // Deselect all
            onChange(undefined);
        }
    }, [data, onChange, selected]);

    const toggleMenu = useCallback(() => {
        setShowDropdownMenu(!showDropdownMenu);
    }, [showDropdownMenu]);

    const toggleSelected = useCallback(
        (item: T) => {
            if (selected) {
                const index = selected.indexOf(item);
                if (index >= 0) {
                    const excluded = selected.slice(0); // copy an array to avoid its mutation
                    excluded.splice(index, 1); // remove the previously selected item
                    onChange(excluded.length ? excluded : undefined);
                } else {
                    onChange(selected.concat([item]));
                }
            } else {
                onChange([item]);
            }
        },
        [onChange, selected],
    );

    // Render

    return (
        <div className={cnb('filter-wrapper')}>
            <FlexContainer
                key={title}
                className={`${cnb(
                    'filter',
                    selected && selected.length > 0 ? 'selected' : '',
                )} action action-special`}
                direction="row"
                justify="space-between"
                align="center"
                onClick={toggleMenu}
            >
                <Flex>
                    <div className={cnb('filter-choosen')}>
                        {selected && selected.length === 1 && (
                            <span className={cnb('long-and-truncated')}>{name}</span>
                        )}
                        {selected && selected.length > 1 && (
                            <>
                                <span className={cnb('long-and-truncated')}>{name}</span>
                                <span>&nbsp;+&nbsp;{selected.length - 1}</span>
                            </>
                        )}
                        {!selected && <>All {title}</>}
                    </div>
                </Flex>
                <Flex>
                    <Button className={cnb('filter-btn')} variant="link">
                        <Icon icon="dropdown" />
                    </Button>
                </Flex>
            </FlexContainer>
            {showDropdownMenu && (
                <div ref={refDropdown} className={cnb('filter-dropdown')}>
                    <FlexContainer
                        className={cnb('filter-dropdown-header')}
                        direction="row"
                        justify="space-between"
                        align="center"
                    >
                        <Flex className={cnb('filter-dropdown-title')}>{title}</Flex>
                        <Flex>
                            <button
                                type="button"
                                className={`${cnb('select')} action action-special`}
                                onClick={selectAll}
                                disabled={data.length === 0}
                            >
                                {!selected || selected.length === 0 ? 'Select all' : 'Deselect all'}
                            </button>
                        </Flex>
                    </FlexContainer>
                    {data &&
                        Array.isArray(data) &&
                        data.map(item => (
                            <DropdownItem<T>
                                key={JSON.stringify(item)}
                                isSelected={selected?.includes(item) ?? false}
                                item={item as T}
                                onToggle={toggleSelected}
                                {...(drawIcon != null ? { drawIcon } : {})}
                                {...(defaultIcon != null ? { defaultIcon } : {})}
                            />
                        ))}
                </div>
            )}
        </div>
    );
}
