import BigNumber from 'bignumber.js';

import { findIndexToInsert } from '@smartfolly/common.utilities';

import type { Asset, TokenGroup } from '../types';

import { appendTotalPriceAndPortionToGroup } from './appendTotalPriceAndPortionToGroup';

/**
 * Function to group the assets by tokens.
 * @param assets - a list of assets to group.
 * @returns a token group list.
 */
export function groupAssetsByTokens(assets: Asset[]): TokenGroup[] {
    // Calculate the total price of given assets
    let totalPrice: BigNumber | undefined;

    // Group assets by tokens
    const filteredTokens = Object.values(
        assets.reduce<{ [token: string]: Omit<TokenGroup, 'totalPrice'> }>((acc, asset) => {
            // Create a token group if not yet present
            if (!acc[asset.token.id]) {
                acc[asset.token.id] = {
                    token: asset.token, // same for any asset in the group
                    totalBalance: {
                        value: undefined,
                        string: undefined,
                    },
                    assets: [],
                };
            }

            // Find the proper placement of the asset to insert it in the descending order
            const indexToInsert = findIndexToInsert(acc[asset.token.id]!.assets, asset, (a, b) => {
                // Consider the price of the asset with an unknown value as zero
                const aAssetPrice = a.price.value ?? new BigNumber(0);
                const bAssetPrice = b.price.value ?? new BigNumber(0);

                // Sort the assets by the price in the descending order
                return bAssetPrice.comparedTo(aAssetPrice);
            });

            // Add the asset to the token group
            acc[asset.token.id]!.assets.splice(indexToInsert, 0, asset);

            // And increase the total balance of the group by the balance of the added asset
            const totalBalanceValue = new BigNumber(asset.balance).plus(
                acc[asset.token.id]!.totalBalance.value ?? new BigNumber(0),
            );
            acc[asset.token.id]!.totalBalance = {
                value: totalBalanceValue,
                string: totalBalanceValue.toFixed(4), // as per the design
            };

            // Increase the total price of the group as well
            if (asset.price.value) {
                totalPrice = asset.price.value.plus(totalPrice ?? new BigNumber(0));
            }

            return acc;
        }, {}),
    );

    // Return an empty array if no token groups are filtered
    if (!filteredTokens.length) {
        return [];
    }

    // Append the total price and the portion to each token group
    return filteredTokens.map<TokenGroup>(
        group => appendTotalPriceAndPortionToGroup(group, totalPrice) as TokenGroup,
    );
}
