import BigNumber from 'bignumber.js';

import type { Exchanges } from '@smartfolly/common.exchanges';

import { findIndexToInsert } from '@smartfolly/common.utilities';

import type { Asset, ExchangeGroup } from '../types';

import { appendTotalPriceAndPortionToGroup } from './appendTotalPriceAndPortionToGroup';

/**
 * Function to group the assets by exchanges.
 * @param assets - a list of assets to group.
 * @returns an exchanges group list.
 */
export function groupAssetsByExchanges(assets: Asset[]): ExchangeGroup[] {
    // Calculate the total price of given assets
    let totalPrice: BigNumber | undefined;

    // Group assets by exchanges
    const filteredExchanges = Object.values(
        assets.reduce<{ [exchange in Exchanges]?: Omit<ExchangeGroup, 'totalPrice'> }>(
            (acc, asset) => {
                // Get the asset wallet
                const { wallet } = asset;

                // Check if the exchange data is present
                if (!('exchange' in wallet)) {
                    return acc;
                }

                // Create an exchange group if not yet present
                if (!acc[wallet.exchange.id]) {
                    acc[wallet.exchange.id] = {
                        exchange: wallet.exchange, // same for any asset in the group
                        assets: [],
                    };
                }

                // Find the proper placement of the asset to insert it in the descending order
                const indexToInsert = findIndexToInsert(
                    acc[wallet.exchange.id]!.assets,
                    asset,
                    (a, b) => {
                        // Consider the price of the asset with an unknown value as zero
                        const aAssetPrice = a.price.value ?? new BigNumber(0);
                        const bAssetPrice = b.price.value ?? new BigNumber(0);

                        // Sort the assets by the price in the descending order
                        return bAssetPrice.comparedTo(aAssetPrice);
                    },
                );

                // Add the asset to the exchange group
                acc[wallet.exchange.id]!.assets.splice(indexToInsert, 0, asset);

                // Increase the total price of the group as well
                if (asset.price.value) {
                    totalPrice = asset.price.value.plus(totalPrice ?? new BigNumber(0));
                }

                return acc;
            },
            {},
        ),
    );

    // Return an empty array if no exchange groups are filtered
    if (!filteredExchanges.length) {
        return [];
    }

    // Append the total price and the portion to each exchange group
    return filteredExchanges.map<ExchangeGroup>(
        group => appendTotalPriceAndPortionToGroup(group, totalPrice) as ExchangeGroup,
    );
}
