import type { Asset, NonCustodianWallet, ProvidedAddressWithWallets, WalletGroup } from '../types';

import { groupAssetsByWallets } from './groupAssetsByWallets';

/**
 * Function to get the list of provided addresses with their wallets for the given assets.
 * @param assets - the assets to get the list for.
 */
export function getProvidedAddressesWithWallets(assets: Asset[]): ProvidedAddressWithWallets[] {
    // Group assets by wallets
    const walletGroups = groupAssetsByWallets(assets);

    // Group wallets by addresses and return
    return Object.values(
        walletGroups.reduce<{ [address: string]: ProvidedAddressWithWallets }>(
            (acc, walletGroup) => {
                // Do not count the custodian wallets (a.k.a exchanges) here
                if ('exchange' in walletGroup.wallet) {
                    return acc;
                }

                // Create an address group if not yet present
                if (!acc[walletGroup.wallet.address]) {
                    acc[walletGroup.wallet.address] = {
                        address: walletGroup.wallet.address, // same for any wallet in the group
                        wallets: [],
                    };
                }

                // Add the wallet group to the address group
                acc[walletGroup.wallet.address]!.wallets.push(
                    walletGroup as WalletGroup<NonCustodianWallet>,
                );

                return acc;
            },
            {},
        ),
    );
}
