import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useState, useCallback } from 'react';

import { Button, Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { assetsService } from '../../services';

import { ActiveTokens } from './ActiveTokens';
import { ActiveBlockchains } from './ActiveBlockchains';
import { ActiveExchanges } from './ActiveExchanges';
import { ActiveWallets } from './ActiveWallets';

import type { ActiveGroup } from './types';

import styles from './Assets.module.scss';

const cnb = classnames.bind(styles);

export const Assets = observer(function Assets() {
    const { availableBlockchains, availableExchanges } = assetsService;

    // States

    const [active, setActive] = useState<ActiveGroup>('tokens');

    // Actions

    const setTokensActive = useCallback(() => setActive('tokens'), []);
    const setBlockchainsActive = useCallback(() => setActive('blockchains'), []);
    const setExchangesActive = useCallback(() => setActive('exchanges'), []);
    const setWalletsActive = useCallback(() => setActive('wallets'), []);

    // Render

    return (
        <>
            <FlexContainer direction="column" justify="center">
                <FlexContainer
                    className={cnb('chips')}
                    direction="row"
                    justify="space-between"
                    align="center"
                >
                    <Flex>
                        <Button
                            className={cnb('chips-btn', active === 'tokens' ? 'active' : '')}
                            onClick={setTokensActive}
                        >
                            Tokens
                        </Button>
                    </Flex>
                    {availableBlockchains.length > 0 && (
                        <Flex>
                            <Button
                                className={cnb(
                                    'chips-btn',
                                    active === 'blockchains' ? 'active' : '',
                                )}
                                onClick={setBlockchainsActive}
                            >
                                Networks
                            </Button>
                        </Flex>
                    )}
                    {availableExchanges.length > 0 && (
                        <Flex>
                            <Button
                                className={cnb('chips-btn', active === 'exchanges' ? 'active' : '')}
                                onClick={setExchangesActive}
                            >
                                Exchanges
                            </Button>
                        </Flex>
                    )}
                    <Flex>
                        <Button
                            className={cnb('chips-btn', active === 'wallets' ? 'active' : '')}
                            onClick={setWalletsActive}
                        >
                            Wallets
                        </Button>
                    </Flex>
                </FlexContainer>
            </FlexContainer>

            {active === 'tokens' && <ActiveTokens />}
            {active === 'blockchains' && <ActiveBlockchains />}
            {active === 'exchanges' && <ActiveExchanges />}
            {active === 'wallets' && <ActiveWallets />}
        </>
    );
});
