import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';

import type { TokenGroup } from '@smartfolly/frontend.assets-service';
import { Button, Flex, FlexContainer, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import styles from '../Board.module.scss';

const cnb = classnames.bind(styles);

export const TokenCell = observer(function TokenCell({
    group,
    groupToggle,
    isChecked,
}: {
    group: TokenGroup;
    groupToggle: (tokenGroup: TokenGroup) => void;
    isChecked: boolean;
}) {
    // Actions

    const toggle = useCallback(() => groupToggle(group), [groupToggle, group]);

    // Render

    const iconLeft = useMemo<IButtonIcon>(
        () => ({
            icon: isChecked ? <Icon icon="checkcircle-on-fill" /> : <Icon icon="checkcircle-off" />,
        }),
        [isChecked],
    );

    return (
        <FlexContainer
            key={group.token.id}
            className={`${cnb('toggleable-asset')} ${
                isChecked ? cnb('checked') : ''
            } grid-bordered__item p-t-0.25 p-b-0.25`}
            role="button"
            justify="stretch"
            align="center"
            onClick={toggle}
        >
            <Flex>
                <div className="icon small">
                    <img src={group.token.icon} alt={group.token.name} />
                </div>
            </Flex>
            <Flex grow={1}>
                <span className="action action-normal m-r-0.5">{group.token.name}</span>
                <span className="paragraph paragraph-normal color-text-secondary">
                    {group.token.symbol}
                </span>
            </Flex>
            <Flex>
                <div className="paragraph paragraph-normal">
                    <Button iconLeft={iconLeft} className={cnb('btn-check')} />
                </div>
            </Flex>
        </FlexContainer>
    );
});
