import classnames from 'classnames/bind';
import Fuse from 'fuse.js';
import { observer } from 'mobx-react';
import { memo, useCallback, useMemo, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { ItemContent, Virtuoso } from 'react-virtuoso';

import { Log } from '@smartfolly/common.utilities';

import { AssetsManagerError } from '@smartfolly/sdk';

import { Flex, FlexContainer, Icon, Modal } from '@smartfolly/frontend.web-ui';

import { openMetaMask, openPhantom, openSolflare, openSurfKeeper } from '../../helpers';

import { WalletProvider, useWalletProviders } from '../../hooks';

import { authService } from '../../services';

import { showToast } from '../../utils';

import { ClearableInput } from '../Common';

import styles from './Connect.module.scss';

const cnb = classnames.bind(styles);

const log = new Log('OtherWalletsModal');

const onFocus = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.target.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
    });
};

const keys: Fuse.FuseOptionKey<WalletProvider> = ['name'];

const fuseOptions: Fuse.IFuseOptions<WalletProvider> = {
    keys, // to filter the assets with the specified keys
    ignoreLocation: true, // to be able searching knowing the substring in the middle
    includeScore: true, // to known the sort score of the assets and groups (lower is better)
    isCaseSensitive: false, // to disable the case check when searching
    findAllMatches: true, // to find all possible matches even if the perfect one is found
    minMatchCharLength: 1, // to start searching from 1st character in a pattern
    shouldSort: true, // to sort the assets and groups putting above those with a higher match
    threshold: 0.5, // to include non-perfect matches in the resulted list
};

type AddressAddedWithResultCallback = <R extends boolean>(
    wallet: WalletProvider,
    result: R,
    error?: R extends false ? Error : never,
) => void;

export const OtherWalletsModal = observer(function OtherWalletsModal({
    show,
    isAddingAddress,
    onAddressWillAdd,
    onAddressAdded,
    onHide,
}: {
    show: boolean;
    isAddingAddress: boolean;
    onAddressWillAdd?: (wallet: WalletProvider) => void;
    onAddressAdded: AddressAddedWithResultCallback;
    onHide: () => void;
}) {
    const { session } = authService;
    // States

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [totalHeight, setTotalHeight] = useState<number>(0);

    // Refs

    // Getters

    const otherWallets = useWalletProviders();

    const otherWalletsFiltered = useMemo(() => {
        if (searchQuery) {
            const searchIndex = Fuse.createIndex<WalletProvider>(keys, otherWallets);
            const otherWalletsFused = new Fuse<WalletProvider>(
                otherWallets,
                fuseOptions,
                searchIndex,
            );
            return otherWalletsFused.search(searchQuery).map(({ item }) => item);
        }
        return otherWallets;
    }, [otherWallets, searchQuery]);

    const listStyle = useMemo(
        () => ({
            height: totalHeight,
            maxHeight: 'calc(100vh - 432px)',
            minHeight: '72px',
        }),
        [totalHeight],
    );

    // Actions

    const walletClicked = useCallback(
        async (wallet: WalletProvider) => {
            // Provide an address with the wallet

            onAddressWillAdd?.(wallet);

            try {
                const result = await wallet.provide({
                    prepareToAddAddresses: async () => {
                        // Auth if no session is present
                        if (!session) {
                            await authService.authAnonymously();
                        }
                    },
                });

                onAddressAdded(wallet, result);
            } catch (error) {
                if (error === AssetsManagerError.AddressIsAlreadyPresent) {
                    // Show the corresponding toast
                    showToast('An address is already present');
                } else if (error === AssetsManagerError.Providers.SurfKeeperExt.NoProviderFound) {
                    showToast('SurfKeeper is not available');
                    openSurfKeeper();
                } else if (error === AssetsManagerError.Providers.MetaMaskExt.NoProviderFound) {
                    showToast('MetaMask is not available');
                    openMetaMask();
                } else if (error === AssetsManagerError.Providers.Phantom.NoProviderFound) {
                    showToast('Phantom is not available');
                    openPhantom();
                } else if (error === AssetsManagerError.Providers.Solflare.NoProviderFound) {
                    showToast('Solflare is not available');
                    openSolflare();
                } else if (error === AssetsManagerError.Providers.TonConnect.QRCodeClosed) {
                    // User has closed a modal with the QR Code, do nothing
                } else if (error === AssetsManagerError.Providers.WalletConnect.QRCodeClosed) {
                    // User has closed a modal with the QR Code, do nothing
                } else {
                    log.debug('Failed to add an address for wallet:', wallet.name, error);

                    // Show the toast
                    showToast('Something went wrong');
                }

                onAddressAdded(wallet, false, error);
            }
        },
        [onAddressAdded, onAddressWillAdd, session],
    );

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setSearchQuery(event.target.value);
        },
        [setSearchQuery],
    );

    const onKeyUp = useCallback(
        async (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                const target = event.target as HTMLTextAreaElement;
                setSearchQuery(target.value);
            }
            event.stopPropagation();
        },
        [setSearchQuery],
    );

    const handleTotalListHeightChanged = useCallback((h: number) => setTotalHeight(h), []);

    // Render

    const renderWallet: ItemContent<WalletProvider, unknown> = useCallback(
        (_index, wallet) => (
            <WalletCell
                wallet={wallet}
                walletClicked={walletClicked}
                isAddingAddress={isAddingAddress}
            />
        ),
        [walletClicked, isAddingAddress],
    );

    return (
        <Modal
            show={show}
            className={cnb('connect-modal')}
            header={
                <FlexContainer justify="start" align="center" onClick={onHide} role="button">
                    <Flex className="m-r-0.75">
                        <Icon icon="arrow-left" />
                    </Flex>
                    <Flex className="title title-normal color-text-secondary p-t-0.25">Back</Flex>
                </FlexContainer>
            }
            onHide={onHide}
        >
            <FlexContainer direction="row" justify="stretch" align="center" className="p-t-1 p-b-1">
                <Flex className="title title-normal">Choose your wallet</Flex>
            </FlexContainer>
            <FlexContainer
                justify="space-between"
                align="start"
                direction="column"
                className="m-t-0.5 m-b-1 m-r-0.5"
            >
                <ClearableInput
                    className="back-secondary color-text-bw border-secondary p-b-0.75 p-t-0.75 p-l-0.5 p-r-0.5"
                    btnClassName="back-secondary p-l-1.5 p-r-1.5 p-b-0.5 p-t-0.5 h-auto"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    onFocus={onFocus}
                    prepend={
                        <InputGroup.Text className="back-secondary border-secondary p-r-0.25">
                            <Icon icon="search-mini" />
                        </InputGroup.Text>
                    }
                />
            </FlexContainer>
            <Virtuoso
                className="scrollable"
                style={listStyle}
                data={otherWalletsFiltered}
                itemContent={renderWallet}
                fixedItemHeight={72}
                totalListHeightChanged={handleTotalListHeightChanged}
            />
        </Modal>
    );
});

const WalletCell = memo(function WalletCell({
    wallet,
    walletClicked,
    isAddingAddress,
}: {
    wallet: WalletProvider;
    walletClicked: (wallet: WalletProvider) => void;
    isAddingAddress: boolean;
}) {
    const itemClicked = useCallback(() => {
        if (!isAddingAddress) {
            walletClicked(wallet);
        }
    }, [wallet, walletClicked, isAddingAddress]);
    return (
        <div className="p-b-1">
            <FlexContainer
                role="button"
                onClick={itemClicked}
                direction="row"
                justify="start"
                align="center"
                className={`p-t-1 p-b-1 p-l-1 p-r-1 b-r-3 back-secondary  ${
                    isAddingAddress ? 'color-text-secondary' : 'hover:back-tertiary'
                }`}
            >
                <Flex className="icon small">
                    {typeof wallet.image === 'string' ? (
                        <span>
                            {wallet.image ? (
                                <img src={wallet.image} alt={wallet.name} />
                            ) : (
                                <Icon icon="other-wallets-default" />
                            )}
                        </span>
                    ) : (
                        wallet.image
                    )}
                </Flex>
                <Flex className="action action-normal">{wallet.name}</Flex>
            </FlexContainer>
        </div>
    );
});
