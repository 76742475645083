import { ChangeEventHandler, memo, useCallback, useRef, useState } from 'react';

import { Button } from '@smartfolly/frontend.web-ui';

import { Exchanges, exchangesData } from '@smartfolly/common.exchanges';

import { ClearableInput } from '../Common';

const hintLinks = {
    [Exchanges.OKX]: 'https://www.okex.com/account/my-api',
};

export const ExchangeKeys = memo(function ExchangeKeys({
    exchange,
    onSubmit,
}: {
    exchange: Exchanges;
    onSubmit: (data: {
        exchange: Exchanges;
        apiKey: string;
        secretKey: string;
        passphrase: string;
    }) => void;
}) {
    // States

    const [hasApiKey, setHasApiKey] = useState<boolean>(false);
    const [hasSecretKey, setHasSecretKey] = useState<boolean>(false);
    const [hasPassphrase, setHasPassphrase] = useState<boolean>(false);

    // Refs

    const apiKey = useRef<string>();
    const secretKey = useRef<string>();
    const passphrase = useRef<string>();

    // Actions

    const setApiKey: ChangeEventHandler<HTMLInputElement> = useCallback(
        ({ target: { value } }) => {
            const hasValue = !!value;
            if (hasApiKey !== hasValue) {
                setHasApiKey(hasValue);
            }
            apiKey.current = value;
        },
        [hasApiKey],
    );

    const setSecretKey: ChangeEventHandler<HTMLInputElement> = useCallback(
        ({ target: { value } }) => {
            const hasValue = !!value;
            if (hasSecretKey !== hasValue) {
                setHasSecretKey(hasValue);
            }
            secretKey.current = value;
        },
        [hasSecretKey],
    );

    const setPassphrase: ChangeEventHandler<HTMLInputElement> = useCallback(
        ({ target: { value } }) => {
            const hasValue = !!value;
            if (hasPassphrase !== hasValue) {
                setHasPassphrase(hasValue);
            }
            passphrase.current = value;
        },
        [hasPassphrase],
    );

    const submit = useCallback(() => {
        if (onSubmit && apiKey.current && secretKey.current && passphrase.current) {
            onSubmit({
                exchange,
                apiKey: apiKey.current,
                secretKey: secretKey.current,
                passphrase: passphrase.current,
            });
        }
    }, [onSubmit, exchange]);

    // Render

    return (
        <div>
            <div className="m-b-1 title title-normal">{`Enter ${exchangesData[exchange].name} keys`}</div>
            <div className="m-b-1">
                <ClearableInput
                    className="back-secondary color-text-bw border-secondary p-b-1 p-t-1 p-l-1 p-r-1"
                    placeholder="API Key"
                    onChange={setApiKey}
                />
            </div>
            <div className="m-b-1">
                <ClearableInput
                    className="back-secondary color-text-bw border-secondary p-b-1 p-t-1 p-l-1 p-r-1"
                    placeholder="Secret Key"
                    onChange={setSecretKey}
                />
            </div>
            <div className="m-b-1">
                <ClearableInput
                    className="back-secondary color-text-bw border-secondary p-b-1 p-t-1 p-l-1 p-r-1"
                    placeholder="Passphrase"
                    type="password"
                    onChange={setPassphrase}
                />
            </div>
            <div className="m-t-1">
                <Button
                    onClick={submit}
                    className="d-block w-100 p-t-1.5 p-b-1.5 rounded-3 action action-normal btn btn-primary h-auto disabled:back-secondary"
                    disabled={!hasApiKey || !hasSecretKey || !hasPassphrase}
                >
                    Connect
                </Button>
            </div>
            <div className="m-t-1.5 m-b-1 text-center">
                <a href={hintLinks[exchange]} target="_blank" rel="noreferrer">
                    Where do I get the keys I need?
                </a>
            </div>
        </div>
    );
});
