import classnames from 'classnames/bind';
import { memo, useCallback, useMemo, useState } from 'react';
import { ItemContent, Virtuoso } from 'react-virtuoso';

import type { Blockchain, Exchange, Token, Wallet } from '@smartfolly/frontend.assets-service';
import { Button, Flex, FlexContainer, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { useFormattedWallet } from '../../hooks';

import { CryptoIcon } from '../CryptoIcon';

import styles from './Filters.module.scss';

const cnb = classnames.bind(styles);

const TokenCell = memo(function TokenCell({
    item,
    itemToggle,
    isChecked,
}: {
    item: Token;
    itemToggle: (item: Token) => void;
    isChecked: boolean;
}) {
    // Actions

    const toggle = useCallback(() => itemToggle(item), [itemToggle, item]);

    // Render

    const iconLeft = useMemo<IButtonIcon>(
        () => ({
            icon: isChecked ? <Icon icon="checkcircle-on-fill" /> : <Icon icon="checkcircle-off" />,
        }),
        [isChecked],
    );

    return (
        <FlexContainer
            key={item.id}
            className={`${cnb('toggleable-asset')} ${
                isChecked ? cnb('checked') : ''
            } grid-bordered__item p-t-0.25 p-b-0.25`}
            role="button"
            justify="stretch"
            align="center"
            onClick={toggle}
        >
            <Flex>
                <div className="icon small">
                    <img src={item.icon} alt={item.name} />
                </div>
            </Flex>
            <Flex grow={1}>
                <span className="action action-normal m-r-0.5">{item.name}</span>
                <span className="paragraph paragraph-normal color-text-secondary">
                    {item.symbol}
                </span>
            </Flex>
            <Flex>
                <div className="paragraph paragraph-normal">
                    <Button iconLeft={iconLeft} className={cnb('btn-check')} />
                </div>
            </Flex>
        </FlexContainer>
    );
});

const BlockchainCell = memo(function BlockchainCell({
    item,
    itemToggle,
    isChecked,
}: {
    item: Blockchain;
    itemToggle: (item: Blockchain) => void;
    isChecked: boolean;
}) {
    // Actions

    const toggle = useCallback(() => itemToggle(item), [itemToggle, item]);

    // Render

    const iconLeft = useMemo<IButtonIcon>(
        () => ({
            icon: isChecked ? <Icon icon="checkcircle-on-fill" /> : <Icon icon="checkcircle-off" />,
        }),
        [isChecked],
    );

    return (
        <FlexContainer
            key={item.id}
            className={`${cnb('toggleable-asset')} ${
                isChecked ? cnb('checked') : ''
            } grid-bordered__item p-t-0.25 p-b-0.25`}
            role="button"
            justify="stretch"
            align="center"
            onClick={toggle}
        >
            <Flex>
                <div className="icon small">
                    <CryptoIcon icon={`${item.id}-network`} defaultIcon="default-network" />
                </div>
            </Flex>
            <Flex grow={1}>
                <span className="action action-normal m-r-0.5">{item.name}</span>
                <span className="paragraph paragraph-normal color-text-secondary" />
            </Flex>
            <Flex>
                <div className="paragraph paragraph-normal">
                    <Button iconLeft={iconLeft} className={cnb('btn-check')} />
                </div>
            </Flex>
        </FlexContainer>
    );
});

const ExchangeCell = memo(function ExchangeCell({
    item,
    itemToggle,
    isChecked,
}: {
    item: Exchange;
    itemToggle: (item: Exchange) => void;
    isChecked: boolean;
}) {
    // Actions

    const toggle = useCallback(() => itemToggle(item), [itemToggle, item]);

    // Render

    const iconLeft = useMemo<IButtonIcon>(
        () => ({
            icon: isChecked ? <Icon icon="checkcircle-on-fill" /> : <Icon icon="checkcircle-off" />,
        }),
        [isChecked],
    );

    return (
        <FlexContainer
            key={item.id}
            className={`${cnb('toggleable-asset')} ${
                isChecked ? cnb('checked') : ''
            } grid-bordered__item p-t-0.25 p-b-0.25`}
            role="button"
            justify="stretch"
            align="center"
            onClick={toggle}
        >
            <Flex>
                <div className="icon small">
                    <CryptoIcon icon={`${item.id}-exchange`} defaultIcon="default-exchange" />
                </div>
            </Flex>
            <Flex grow={1}>
                <span className="action action-normal m-r-0.5">{item.name}</span>
                <span className="paragraph paragraph-normal color-text-secondary" />
            </Flex>
            <Flex>
                <div className="paragraph paragraph-normal">
                    <Button iconLeft={iconLeft} className={cnb('btn-check')} />
                </div>
            </Flex>
        </FlexContainer>
    );
});

const WalletCell = memo(function WalletCell({
    item,
    itemToggle,
    isChecked,
}: {
    item: Wallet;
    itemToggle: (item: Wallet) => void;
    isChecked: boolean;
}) {
    // Hooks

    const formattedWallet = useFormattedWallet(item);

    // Actions

    const toggle = useCallback(() => itemToggle(item), [itemToggle, item]);

    // Render

    const iconLeft = useMemo<IButtonIcon>(
        () => ({
            icon: isChecked ? <Icon icon="checkcircle-on-fill" /> : <Icon icon="checkcircle-off" />,
        }),
        [isChecked],
    );

    return (
        <FlexContainer
            key={'address' in item ? item.address : item.sourceId}
            className={`${cnb('toggleable-asset')} ${
                isChecked ? cnb('checked') : ''
            } grid-bordered__item p-t-0.25 p-b-0.25`}
            role="button"
            justify="stretch"
            align="center"
            onClick={toggle}
        >
            <Flex>
                <div className="icon small">
                    {'blockchain' in item ? (
                        <CryptoIcon
                            icon={`${item.blockchain.id}-wallet`}
                            defaultIcon="default-wallet"
                        />
                    ) : (
                        <CryptoIcon
                            icon={`${item.exchange.id}-wallet`}
                            defaultIcon="default-wallet"
                        />
                    )}
                </div>
            </Flex>
            <Flex grow={1}>
                <span className="action action-normal m-r-0.5">{formattedWallet}</span>
                <span className="paragraph paragraph-normal color-text-secondary" />
            </Flex>
            <Flex>
                <div className="paragraph paragraph-normal">
                    <Button iconLeft={iconLeft} className={cnb('btn-check')} />
                </div>
            </Flex>
        </FlexContainer>
    );
});

export type FilterSelectItem = Token | Blockchain | Exchange | Wallet;

export const FilterSelector = memo(function FilterSelector({
    type,
    items,
    selectedItems,
    onSelect,
}: {
    type: 'tokens' | 'networks' | 'exchanges' | 'wallets';
    items: FilterSelectItem[];
    selectedItems: FilterSelectItem[] | undefined;
    onSelect: (items: FilterSelectItem[]) => void;
}) {
    // States
    const [selected, setSelected] = useState<FilterSelectItem[]>(selectedItems ?? []);
    const [totalHeight, setTotalHeight] = useState<number>(0);

    // Getters

    const listStyle = useMemo(
        () => ({
            height: totalHeight,
            maxHeight: 'calc(100vh - 380px)',
            minHeight: '64px',
        }),
        [totalHeight],
    );

    const resetDisabled = useMemo(() => selected.length === 0, [selected]);

    // Actions

    const handleTotalListHeightChanged = useCallback((h: number) => {
        setTotalHeight(h);
    }, []);

    const toggleItem = useCallback(
        (item: FilterSelectItem) => {
            const newSelected = [...selected];
            const index = newSelected.indexOf(item as FilterSelectItem);
            if (index >= 0) {
                newSelected.splice(index, 1);
            } else {
                newSelected.push(item as FilterSelectItem);
            }

            setSelected(newSelected);
            onSelect(newSelected);
        },
        [selected, onSelect],
    );

    const reset = useCallback(() => {
        setSelected([]);
        onSelect([]);
    }, [onSelect]);

    // Render

    const renderCell: ItemContent<FilterSelectItem, FilterSelectItem[]> = useCallback(
        (_index, item, context) => {
            type PropsType<G extends FilterSelectItem> = {
                item: G;
                itemToggle: (item: G) => void;
                isChecked: boolean;
            };
            const props: PropsType<FilterSelectItem> = {
                item,
                itemToggle: toggleItem,
                isChecked: context.includes(item),
            };
            if (type === 'tokens') {
                return <TokenCell {...(props as PropsType<Token>)} />;
            }
            if (type === 'networks') {
                return <BlockchainCell {...(props as PropsType<Blockchain>)} />;
            }
            if (type === 'exchanges') {
                return <ExchangeCell {...(props as PropsType<Exchange>)} />;
            }
            if (type === 'wallets') {
                return <WalletCell {...(props as PropsType<Wallet>)} />;
            }
            return null;
        },
        [type, toggleItem],
    );

    return (
        <>
            <FlexContainer justify="stretch" align="center">
                <Flex grow={1}>
                    <div className="title title-normal text-capitalize">{type}</div>
                </Flex>
                <Flex>
                    <Button className="btn-link" onClick={reset} disabled={resetDisabled}>
                        Reset
                    </Button>
                </Flex>
            </FlexContainer>
            <Virtuoso<FilterSelectItem>
                className="scrollable"
                style={listStyle}
                context={selected}
                data={items}
                itemContent={renderCell}
                fixedItemHeight={52}
                totalListHeightChanged={handleTotalListHeightChanged}
            />
        </>
    );
});
