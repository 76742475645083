import classnames from 'classnames/bind';
import { memo, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Button, Flex, FlexContainer, Icon } from '@smartfolly/frontend.web-ui';

import { authService } from '../../../services';

import { showToast } from '../../../utils';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

function permalinkCopied() {
    showToast('Link copied to clipboard');
}

export const SessionLink = memo(function SessionLink() {
    // Stores

    const { exportSession } = authService;

    // States

    const [permalink, setPermalink] = useState<string>('');

    // Life-cycle

    useEffect(() => {
        async function getPermalink() {
            const link = await exportSession();
            setPermalink(`${window.location.host}/session/${link}`);
        }

        getPermalink();
    }, [exportSession]);

    return (
        <FlexContainer
            direction="column"
            justify="stretch"
            align="stretch"
            className={cnb('profile-modal-link-box')}
        >
            <Flex className="title title-normal">Session link</Flex>
            <Flex className="title title-normal">
                <FlexContainer direction="row" justify="stretch" align="center">
                    <Flex>
                        <Icon icon="link-mini" />
                    </Flex>
                    <Flex className={`action action-default ${cnb('profile-modal-link')}`}>
                        {permalink}
                    </Flex>
                    <Flex>
                        <CopyToClipboard text={permalink} onCopy={permalinkCopied}>
                            <Button className={cnb('profile-modal-copy-btn')}>
                                <Icon icon="copy-mini" />
                            </Button>
                        </CopyToClipboard>
                    </Flex>
                </FlexContainer>
            </Flex>
            <Flex className="paragraph paragraph-small color-text-secondary">
                This secret link will expire once you log out
            </Flex>
        </FlexContainer>
    );
});
