import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';

import type { TokenGroup } from '@smartfolly/frontend.assets-service';

import { Flex, FlexContainer, Icon } from '@smartfolly/frontend.web-ui';

import { usePercentClassName } from '../../../hooks';

import { InfoModal } from '../InfoModal';

import styles from '../Assets.module.scss';

const cnb = classnames.bind(styles);

export const TokenCell = observer(function TokenCell({
    group,
}: {
    /**
     * A token group.
     * Note: it's MobX observable.
     */
    group: TokenGroup;
}) {
    const { token, totalPrice, totalBalance, portion } = group;

    // States

    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    // Getters
    const percentClassName = usePercentClassName(token.price.percentChange24h.value);

    // Actions

    const showModal = useCallback(() => setShowInfoModal(true), []);
    const hideModal = useCallback(() => setShowInfoModal(false), []);

    // Render

    return (
        <>
            {showInfoModal && <InfoModal group={group} hideModal={hideModal} />}
            <td role="presentation" onClick={showModal}>
                <FlexContainer direction="row" justify="start">
                    <Flex>
                        <div className={cnb('icon')}>
                            {token.icon ? (
                                <img src={token.icon} alt="token.symbol" />
                            ) : (
                                <Icon icon="default-token" />
                            )}
                        </div>
                    </Flex>
                    <Flex className={cnb('info')} grow={1}>
                        <FlexContainer direction="column" justify="space-between" align="stretch">
                            <Flex className="action action-normal">
                                {token.name}{' '}
                                <span className="color-text-secondary">{token.symbol}</span>
                            </Flex>
                            <Flex className="paragraph paragraph-small color-text-secondary">
                                {portion?.string}
                            </Flex>
                        </FlexContainer>
                    </Flex>
                </FlexContainer>
            </td>
            <td role="presentation" onClick={showModal}>
                <FlexContainer
                    className={cnb('balance')}
                    direction="column"
                    justify="space-between"
                    align="stretch"
                >
                    <Flex
                        grow={1}
                        className="paragraph paragraph-normal"
                        title={totalPrice.change24h.string}
                    >
                        {totalPrice.change24h.string}
                    </Flex>
                    <Flex className={`${percentClassName} paragraph paragraph-small`}>
                        <span>{totalPrice.percentChange24h.string}</span>
                    </Flex>
                </FlexContainer>
            </td>
            <td role="presentation" onClick={showModal}>
                <FlexContainer
                    className={cnb('balance')}
                    direction="column"
                    justify="space-between"
                    align="stretch"
                >
                    <Flex grow={1} className="paragraph paragraph-normal" title={totalPrice.string}>
                        {totalPrice.string}
                    </Flex>
                    <Flex className="paragraph paragraph-small color-text-secondary">
                        {totalBalance.string} {token.symbol}
                    </Flex>
                </FlexContainer>
            </td>
        </>
    );
});
