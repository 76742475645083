import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '@smartfolly/frontend.web-ui/assets/styles/index.scss';
import * as amplitude from '@amplitude/analytics-browser';
import { useScript } from '@smartfolly/frontend.web-ui';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import './assets/styles/app.scss';

import { Layout } from './components/Layout';
import { BoardPage } from './pages/Board';
import { JoinAddress } from './pages/JoinAddress';
import { Home } from './pages/Home';
import { Monitor } from './pages/Monitor';
import { UserWithSession } from './pages/UserWithSession';
import { SharedBoard } from './pages/SharedBoard';

import { pirschCode, amplitudeKey } from '../configs';

amplitude.init(amplitudeKey, undefined, {
    defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true,
    },
});

const pirschScriptOptions = {
    defer: true,
    id: 'pirschjs',
    dataCode: pirschCode,
};

function App() {
    useScript('https://api.pirsch.io/pirsch.js', pirschScriptOptions);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index path="/" element={<Home />} />
                    <Route path="/overview">
                        <Route path="" element={<Monitor />} />
                        <Route path=":address" element={<Monitor />} />
                    </Route>
                    <Route path="/boards/:boardName" element={<BoardPage />} />
                    <Route path="/session/:session" element={<UserWithSession />} />
                    <Route path="/pro" element={<Monitor />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Route>
                <Route path="/share/:boardId" element={<SharedBoard />} />
                <Route path="/join/:address" element={<JoinAddress />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
