import classNames from 'classnames/bind';
import { memo, useMemo } from 'react';

import type { Blockchain, Exchange, Token, Wallet } from '@smartfolly/frontend.assets-service';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { useFormattedWallet } from '../../hooks';

import styles from './Filters.module.scss';

const cnb = classNames.bind(styles);

export type FilterItem = Token | Blockchain | Exchange | Wallet;

export const FilterItemButton = memo(function FilterButton<T extends FilterItem>({
    title,
    selected,
    showSymbol,
    onClick,
}: {
    title?: string;
    selected?: Array<T> | undefined;
    showSymbol?: boolean;
    onClick: () => void;
}) {
    // Getters
    const firstItem = useMemo(() => selected?.[0], [selected]);

    const formattedWallet = useFormattedWallet(
        firstItem && ('address' in firstItem || 'sourceId' in firstItem) ? firstItem : null,
    );

    const name = useMemo(() => {
        if (showSymbol) {
            return selected && firstItem && 'symbol' in firstItem
                ? firstItem.symbol
                : formattedWallet;
        }

        return firstItem && 'name' in firstItem ? firstItem.name : formattedWallet;
    }, [showSymbol, firstItem, selected, formattedWallet]);

    // Render

    return (
        <FlexContainer
            key={title}
            className={`action action-special ${cnb('filter-button', selected ? 'selected' : '')}`}
            justify="space-between"
            align="center"
            onClick={onClick}
        >
            <Flex>
                {selected && selected.length === 1 && <span>{name}</span>}
                {selected && selected.length > 1 && (
                    <>
                        <span>{name}</span>
                        <span>&nbsp;+&nbsp;{selected.length - 1}</span>
                    </>
                )}
                {!selected && <>All {title}</>}
            </Flex>
        </FlexContainer>
    );
});
