import classnames from 'classnames/bind';
import { memo, useCallback, useMemo, useState } from 'react';
import { ItemContent, Virtuoso } from 'react-virtuoso';

import type {
    Asset,
    BlockchainGroup,
    ExchangeGroup,
    TokenGroup,
    WalletGroup,
} from '@smartfolly/frontend.assets-service';

import { Flex, FlexContainer, Modal } from '@smartfolly/frontend.web-ui';

import { useFormattedWallet, usePercentClassName } from '../../../hooks';

import { CryptoIcon } from '../../CryptoIcon';

import styles from '../Assets.module.scss';

import { AssetCell } from './AssetCell';

const cnb = classnames.bind(styles);

export const InfoModal = memo(function InfoModal<
    G extends TokenGroup | BlockchainGroup | ExchangeGroup | WalletGroup,
>({ group, hideModal }: { group: G; hideModal: () => void }) {
    const { assets, totalPrice } = group;

    // States

    const [totalHeight, setTotalHeight] = useState<number>(0);

    // Getters

    const percentClassName = usePercentClassName(totalPrice.percentChange24h.value);

    const formattedWallet = useFormattedWallet('wallet' in group ? group.wallet : null);

    const listStyle = useMemo(
        () => ({
            height: totalHeight,
            maxHeight: 'calc(100vh - 260px)',
            minHeight: '64px',
        }),
        [totalHeight],
    );

    // Actions

    const handleTotalListHeightChanged = useCallback((h: number) => setTotalHeight(h), []);

    // Render

    const renderAsset: ItemContent<Asset, unknown> = useCallback(
        (_index, asset) => {
            return <AssetCell asset={asset} group={group} />;
        },
        [group],
    );

    return (
        <Modal
            className={cnb('info-modal')}
            show
            onHide={hideModal}
            header={<div className="title title-normal">Assets</div>}
        >
            <FlexContainer
                direction="row"
                justify="stretch"
                align="center"
                className={`${cnb('info-modal-main-info')} m-t-1.5`}
            >
                <Flex grow={1}>
                    <FlexContainer direction="column" justify="stretch" align="stretch">
                        <Flex className="title title-small">
                            {'token' in group ? group.token.name : ''}
                            {'blockchain' in group ? group.blockchain.name : ''}
                            {'exchange' in group ? group.exchange.name : ''}
                            {'wallet' in group ? formattedWallet : ''}
                        </Flex>
                        <Flex className="action action-huge">{totalPrice.string ?? ''}</Flex>
                        <Flex>
                            <FlexContainer direction="row" justify="start" align="center">
                                <Flex
                                    className={`${percentClassName} paragraph paragraph-small ${cnb(
                                        'change24',
                                    )}`}
                                >
                                    {totalPrice.change24h.string} ·{' '}
                                    {totalPrice.percentChange24h.string}
                                    <span
                                        className={`paragraph paragraph-small color-text-secondary ${cnb(
                                            'today',
                                        )}`}
                                    >
                                        Today
                                    </span>
                                </Flex>
                            </FlexContainer>
                        </Flex>
                    </FlexContainer>
                </Flex>
                <Flex>
                    <div className={cnb('icon')}>
                        {'token' in group ? (
                            <img src={group.token.icon} alt={group.token.name} />
                        ) : (
                            ''
                        )}

                        {'blockchain' in group ? (
                            <CryptoIcon
                                icon={`${group.blockchain.id}-network`}
                                defaultIcon="default-network"
                            />
                        ) : (
                            ''
                        )}

                        {'exchange' in group ? (
                            <CryptoIcon
                                icon={`${group.exchange.id}-exchange`}
                                defaultIcon="default-exchange"
                            />
                        ) : (
                            ''
                        )}

                        {'wallet' in group &&
                            ('blockchain' in group.wallet ? (
                                <CryptoIcon
                                    icon={`${group.wallet.blockchain.id}-wallet`}
                                    defaultIcon="default-network"
                                />
                            ) : (
                                <CryptoIcon
                                    icon={`${group.wallet.exchange.id}-wallet`}
                                    defaultIcon="default-exchange"
                                />
                            ))}
                    </div>
                </Flex>
            </FlexContainer>
            <Virtuoso
                className="scrollable"
                style={listStyle}
                data={assets}
                itemContent={renderAsset}
                fixedItemHeight={64}
                totalListHeightChanged={handleTotalListHeightChanged}
            />
        </Modal>
    );
});
