import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';

import { Log } from '@smartfolly/common.utilities';

import { assetsService } from '../../../services';

import { showToast } from '../../../utils';

import { useEditingWallets } from '../contexts';

const log = new Log('RemoveButton');

export const RemoveButton = observer(function RemoveButton({ onHide }: { onHide: () => void }) {
    // Stores

    const {
        providedAddressesWithWallets,
        providedExchangesWithWallets,
        removeAddress,
        removeExchange,
    } = assetsService;

    // States

    const [isRemoving, setIsRemoving] = useState<boolean>(false);

    const { selectedWallets, setSelectedWallets } = useEditingWallets();

    // Actions

    const removeSelectedWallets = useCallback(async () => {
        if (selectedWallets.length > 0) {
            setIsRemoving(true);
            try {
                // Remove the selected wallets addresses
                await Promise.all(
                    selectedWallets.map(async itemId => {
                        if (
                            providedAddressesWithWallets.find(({ address }) => itemId === address)
                        ) {
                            await removeAddress(itemId);
                        } else if (
                            providedExchangesWithWallets.find(({ sourceId }) => itemId === sourceId)
                        ) {
                            await removeExchange(itemId);
                        } else {
                            log.error('No way to remove the selected wallet:', itemId);
                        }
                    }),
                );

                // Logout if all the addresses are removed
                if (
                    selectedWallets.length ===
                    providedAddressesWithWallets.length + providedExchangesWithWallets.length
                ) {
                    // Hide the Edit Wallets modal
                    onHide();
                }

                // Set no selected wallets
                setSelectedWallets([]);

                showToast('Successfully deleted');
            } catch (error) {
                log.error('Failed to remove the selected wallets with error:', error);

                showToast('Something went wrong');
            } finally {
                setIsRemoving(false);
            }
        }
    }, [
        selectedWallets,
        providedAddressesWithWallets,
        providedExchangesWithWallets,
        setSelectedWallets,
        removeAddress,
        removeExchange,
        onHide,
    ]);

    // Render

    return (
        <Button
            className="d-block w-100 p-t-1 p-b-1 rounded-3 action action-normal btn-danger"
            onClick={removeSelectedWallets}
            disabled={isRemoving || selectedWallets.length === 0}
        >
            Remove
        </Button>
    );
});
