import { memo, useRef, useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { Backdrop } from '../components/Common';

import { joinAddress } from '../helpers';

const SomethingGoneWrong = function SomethingGoneWrong() {
    return (
        <FlexContainer direction="column" align="start" justify="center" className="m-t-2">
            <Flex className="title title-small">Oops!</Flex>
            <Flex className="action action-huge">Something&apos;s gone wrong</Flex>
            <Flex className="paragraph paragraph-small color-text-secondary">
                Refresh the page or try again later
            </Flex>
        </FlexContainer>
    );
};

export const JoinAddress = memo(function JoinAddress() {
    // Refs

    const joiningAddress = useRef<boolean>(false);

    // States

    const [joinError, setJoinError] = useState<Error | undefined>();

    // Hooks

    const { address } = useParams();
    const navigate = useNavigate();

    // Life-cycle

    useEffect(() => {
        // Check if already joining with address
        if (joiningAddress.current) {
            return;
        }

        // Join an address
        (async () => {
            // Mark the address is joining
            joiningAddress.current = true;
            try {
                // Call the "join" function
                // Note: it includes all the required navigation logic in it
                await joinAddress({ address, navigate });
            } catch (error) {
                // Show an error if any
                setJoinError(error);
            } finally {
                // Mark the address is stopped joining
                joiningAddress.current = false;
            }
        })();
    }, [address, navigate]);

    useEffect(() => {
        // Remove the sidebar-open class from the body
        document.body.classList.remove('sidebar-open');
    }, []);

    // Actions

    // Render

    return (
        <div className="app-layout">
            <div className="app-content">
                <div className="main-content">
                    <div>
                        {
                            // Display the spinner while joining the address
                            // or an error if any has happened
                            joinError == null ? (
                                <Backdrop>
                                    <Spinner animation="border" className="spinner-icon" />
                                </Backdrop>
                            ) : (
                                <SomethingGoneWrong />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});
