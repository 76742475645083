import type { Board } from '../../types';

/**
 * GraphQL might return the "selectedAssets" property anyway even if it's empty.
 * Delete it if any from the board to properly deal with the boards of different types in TS.
 */
export function deleteEmptyGraphQLPropertiesIfNeeded(board: Board): Board {
    if ('selectedAssets' in board) {
        const { selectedAssets, ...rest } = board;
        if (selectedAssets == null) {
            return rest as Board;
        }
    }

    return board;
}
