import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback } from 'react';

import { Log } from '@smartfolly/common.utilities';

import { Button, Icon } from '@smartfolly/frontend.web-ui';

import { assetsService } from '../../../services';

import { showToast } from '../../../utils';

import { Tooltip } from '../../Common';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

const log = new Log('RescanButton');

export const RescanButton = observer(function RescanButton() {
    const { rescanAssets, isRescanningAssets } = assetsService;

    // Actions

    const rescanAssetsFn = useCallback(async () => {
        try {
            const scanned = await rescanAssets();
            if (scanned) {
                showToast('The data has been updated');
            } else {
                showToast('Something went wrong');
            }
        } catch (error) {
            log.error('Failed to rescan assets with error:', error);

            showToast('Something went wrong');
        }
    }, [rescanAssets]);

    // Render

    return (
        <Tooltip content="Rescan assets">
            <Button
                onClick={rescanAssetsFn}
                disabled={isRescanningAssets}
                className={cnb('refresh-btn')}
                aria-label="Rescan assets"
            >
                <Icon icon="refresh" />
            </Button>
        </Tooltip>
    );
});
