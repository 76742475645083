import classnames from 'classnames/bind';
import { memo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import type {
    Asset,
    BlockchainGroup,
    ExchangeGroup,
    TokenGroup,
    WalletGroup,
} from '@smartfolly/frontend.assets-service';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { isAssetStaked } from '../../../helpers';

import { useAssetBalance, useFormattedWallet } from '../../../hooks';

import { showToast } from '../../../utils';

import { CryptoIcon } from '../../CryptoIcon';

import styles from '../Assets.module.scss';

const cnb = classnames.bind(styles);

function addressCopied(address: string) {
    if (address) {
        showToast('Address copied to clipboard');
    }
}

function apiKeyCopied(apiKey: string) {
    if (apiKey) {
        showToast('Api key copied to clipboard');
    }
}

export const AssetCell = memo(function AssetCell<
    G extends TokenGroup | BlockchainGroup | ExchangeGroup | WalletGroup,
>({ asset, group }: { asset: Asset; group: G }) {
    const { assetId, price, token, wallet } = asset;

    // Getters

    const address = 'address' in wallet ? wallet.address : '';
    const formattedWallet = useFormattedWallet(wallet);
    const balance = useAssetBalance(asset); // specify the precision if needed
    const isStaked = isAssetStaked(asset);

    // Render

    return (
        <CopyToClipboard
            key={assetId}
            text={address}
            onCopy={'address' in wallet ? addressCopied : apiKeyCopied}
        >
            <FlexContainer
                className={cnb('info-modal-asset')}
                direction="row"
                justify="space-between"
                align="start"
            >
                {'token' in group &&
                    ('blockchain' in wallet ? (
                        <>
                            <Flex>
                                <div className={cnb('icon', 'small')}>
                                    <CryptoIcon
                                        icon={`${wallet.blockchain.id}-network`}
                                        defaultIcon="default-network"
                                    />
                                </div>
                            </Flex>
                            <Flex>
                                <div className={cnb('icon', 'small')}>
                                    <CryptoIcon
                                        icon={`${wallet.blockchain.id}-wallet`}
                                        defaultIcon="default-wallet"
                                    />
                                </div>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex>
                                <div className={cnb('icon', 'small')}>
                                    <CryptoIcon
                                        icon={`${wallet.exchange.id}-exchange`}
                                        defaultIcon="default-exchange"
                                    />
                                </div>
                            </Flex>
                            <Flex>
                                <div className={cnb('icon', 'small')}>
                                    <CryptoIcon
                                        icon={`${wallet.exchange.id}-wallet`}
                                        defaultIcon="default-wallet"
                                    />
                                </div>
                            </Flex>
                        </>
                    ))}

                {'blockchain' in group && (
                    <>
                        <Flex>
                            <div className={cnb('icon', 'small')}>
                                <img src={token.icon} alt={token.name} />
                            </div>
                        </Flex>
                        <Flex>
                            <div className={cnb('icon', 'small')}>
                                <CryptoIcon
                                    icon={`${group.blockchain.id}-wallet`}
                                    defaultIcon="default-wallet"
                                />
                            </div>
                        </Flex>
                    </>
                )}

                {'exchange' in group && (
                    <>
                        <Flex>
                            <div className={cnb('icon', 'small')}>
                                <img src={token.icon} alt={token.name} />
                            </div>
                        </Flex>
                        <Flex>
                            <div className={cnb('icon', 'small')}>
                                <CryptoIcon
                                    icon={`${group.exchange.id}-wallet`}
                                    defaultIcon="default-wallet"
                                />
                            </div>
                        </Flex>
                    </>
                )}

                {'wallet' in group &&
                    ('blockchain' in wallet ? (
                        <>
                            <Flex>
                                <div className={cnb('icon', 'small')}>
                                    <img src={token.icon} alt={token.name} />
                                </div>
                            </Flex>
                            <Flex>
                                <div className={cnb('icon', 'small')}>
                                    <CryptoIcon
                                        icon={`${wallet.blockchain.id}-network`}
                                        defaultIcon="default-network"
                                    />
                                </div>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex>
                                <div className={cnb('icon', 'small')}>
                                    <img src={token.icon} alt={token.name} />
                                </div>
                            </Flex>
                            <Flex>
                                <div className={cnb('icon', 'small')}>
                                    <CryptoIcon
                                        icon={`${wallet.exchange.id}-exchange`}
                                        defaultIcon="default-exchange"
                                    />
                                </div>
                            </Flex>
                        </>
                    ))}

                <Flex grow={1}>
                    <FlexContainer direction="column" justify="start" align="start">
                        <Flex>
                            {token.name}
                            {isStaked ? ' · Staked' : ''}
                        </Flex>
                        <Flex className="paragraph paragraph-small color-text-secondary">
                            {formattedWallet}
                        </Flex>
                    </FlexContainer>
                </Flex>

                <Flex>
                    <FlexContainer direction="column" justify="end" align="end">
                        <Flex className="paragraph paragraph-normal">{price.string}</Flex>
                        <Flex className="paragraph paragraph-small color-text-secondary">
                            {balance}
                        </Flex>
                    </FlexContainer>
                </Flex>
            </FlexContainer>
        </CopyToClipboard>
    );
});
