import { computed, makeObservable, observable } from 'mobx';

import { CommonStore, appStorage } from '@smartfolly/common.utilities';

import type { BoardTemplates } from '@smartfolly/sdk';

import type { IAuthService } from '@smartfolly/frontend.auth-service';
import type { IAssetsService } from '@smartfolly/frontend.assets-service';
import type { AddBoardOptions, Board, IBoardsService } from '@smartfolly/frontend.boards-service';

import { currenciesService } from '@smartfolly/frontend.currencies-service';

import type { ITemplatesService } from '../types';

import { suitableBoardTemplates } from './helpers';
import { templateBoardOptions } from './templates';

export type TemplatesServiceOptions = {
    /**
     * An instance of the AuthService to work with when dealing with the templates.
     * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
     */
    authService: IAuthService;

    /**
     * An instance of the AssetsService to work with when dealing with the templates.
     * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
     */
    assetsService: IAssetsService;

    /**
     * An instance of the BoardsService to work with when dealing with the templates.
     * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
     */
    boardsService: IBoardsService;
};

export class TemplatesService extends CommonStore implements ITemplatesService {
    // Properties

    /**
     * An instance of the AuthService to work with when dealing with the templates.
     */
    private authService: IAuthService;

    /**
     * An instance of the AssetsService to work with when dealing with the templates.
     */
    private assetsService: IAssetsService;

    /**
     * An instance of the BoardsService to work with when dealing with the templates.
     */
    private boardsService: IBoardsService;

    /**
     * A MobX-observable flag that the templates to add were shown.
     */
    private templatesToAddWereShownFlag: boolean | undefined = undefined;

    // Constructor

    public constructor(options: TemplatesServiceOptions) {
        super();

        this.authService = options.authService;
        this.assetsService = options.assetsService;
        this.boardsService = options.boardsService;

        makeObservable<TemplatesService, 'templatesToAddWereShownFlag' | 'templatesToAddWereShown'>(
            this,
            {
                addedTemplateKinds: computed,
                readyToAddTemplateKinds: computed,
                readyToShowTemplatesToAdd: computed,
                templatesToAddWereShownFlag: observable,
                templatesToAddWereShown: computed,
            },
        );
    }

    // Interface

    protected onLoad = async () => {
        // Ensure `authService` is initialized to work with
        if (!this.authService.initialized) {
            throw new Error('AuthService instance is not initialized to load TemplatesService');
        }

        // Ensure `assetsService` is initialized to work with
        if (!this.assetsService.initialized) {
            throw new Error('AssetsService instance is not initialized to load TemplatesService');
        }

        // Ensure `boardsService` is initialized to work with
        if (!this.boardsService.initialized) {
            throw new Error('BoardsService instance is not initialized to load TemplatesService');
        }

        // Load the local flag value awaiting
        await this.loadTemplatesToAddWereShownFlag();
    };

    // eslint-disable-next-line class-methods-use-this
    protected onUnload = async () => {
        // Reset the "templatesToAddWereShown" flag once the service is unloading
        // since its value depends on the current authorized user
        this.templatesToAddWereShown = undefined;
    };

    public addBoardWithTemplate = async (kind: BoardTemplates): Promise<false | Board> => {
        // Form the template board options to add
        const options: AddBoardOptions = templateBoardOptions[kind];

        // Check if options present
        if (!options) {
            throw new Error(`"${kind}" board template kind is not supported to be added`);
        }

        // Add the board
        return this.boardsService.addBoard(options);
    };

    public get addedTemplateKinds(): Set<BoardTemplates> | undefined {
        // Check if the service is loaded
        if (!this.initialized) {
            return undefined;
        }

        // Check if the boards service is loading the boards
        if (this.boardsService.isLoadingBoards) {
            // The set is unknown yet
            return undefined;
        }

        // Build the set of already added template kinds
        return this.boardsService.boards.reduce<Set<BoardTemplates>>((acc, board) => {
            // Check if the board is made with the template
            if (board.template) {
                acc.add(board.template.kind as BoardTemplates);
            }

            return acc;
        }, new Set());
    }

    public get readyToAddTemplateKinds(): Set<BoardTemplates> | undefined {
        // Check if the service is loaded
        if (!this.initialized) {
            return undefined;
        }

        // Check if the boards service is loading the boards
        if (this.boardsService.isLoadingBoards) {
            // The set is unknown yet
            return undefined;
        }

        // Check if the already added template kinds are ready
        const { addedTemplateKinds } = this;
        if (!addedTemplateKinds) {
            // The set is unknown yet
            return undefined;
        }

        // Check if the assets service is loading the assets
        if (this.assetsService.isLoadingAssets) {
            // The set is unknown yet
            return undefined;
        }

        // Build the set of "ready-to-add" template kinds based on all available assets
        return this.assetsService.assets.reduce<Set<BoardTemplates>>((acc, asset) => {
            // Get the list of suitable board templates for an asset
            const templates = suitableBoardTemplates(asset, currenciesService.stablecoins);

            // Add not added templates to the set of "ready-to-add" template kinds
            templates.forEach(template => {
                if (!addedTemplateKinds.has(template)) {
                    acc.add(template);
                }
            });

            return acc;
        }, new Set());
    }

    public get readyToShowTemplatesToAdd(): boolean | undefined {
        // Check if we know the state that the templates to add were shown
        if (this.templatesToAddWereShown === undefined) {
            // Not ready to show while the flag value is unknown
            return undefined;
        }

        // Check if the templates to add have been shown already
        if (this.templatesToAddWereShown === true) {
            // No need to show the templates to add
            return false;
        }

        // Ready to show the templates to add
        return true;
    }

    public markTemplatesToAddAsShown = async (): Promise<void> => {
        // Mark the templates to add were shown
        this.templatesToAddWereShown = true;

        // Save the "templatesToAddWereShown" flag value as "true"
        return this.saveTemplatesToAddWereShownFlag(true);
    };

    // Internals

    /**
     * Mob-X computed getter to learn if the templates to add were shown.
     */
    private get templatesToAddWereShown(): boolean | undefined {
        return this.templatesToAddWereShownFlag;
    }

    /**
     * Mob-X actioned setter to mark if the templates to add were shown.
     */
    private set templatesToAddWereShown(templatesToAddWereShown: boolean | undefined) {
        this.templatesToAddWereShownFlag = templatesToAddWereShown;
    }

    /**
     * Getter for the storage key where the "templatesToAddWereShown" flag value is kept.
     */
    private get templatesToAddWereShownKey(): string {
        // Note: the key depends on the current user
        return this.authService.getStorageKey('templatesToAddWereShown');
    }

    /**
     * Method to load the "templatesToAddWereShown" flag value from the local storage.
     */
    private async loadTemplatesToAddWereShownFlag() {
        // Load the templates to add were shown flag value from the storage
        const templatesToAddWereShown = await appStorage.getItem(this.templatesToAddWereShownKey);

        // Check if it's present
        if (templatesToAddWereShown) {
            // Try to parse the present value as boolean
            try {
                this.templatesToAddWereShown = JSON.parse(templatesToAddWereShown) as boolean;
            } catch (error) {
                this.log.error(
                    'Failed to parse the "templatesToAddWereShown" flag value from the local storage',
                );

                // In case of an error consider that the templates to add were not shown
                this.templatesToAddWereShown = false;
            }
        } else {
            // Since the flag value is not present the templates to add were not shown
            this.templatesToAddWereShown = false;
        }
    }

    /**
     * Method to save the "templatesToAddWereShown" flag value in the local storage.
     * @param shown - a flag value to be saved in the local storage.
     */
    private async saveTemplatesToAddWereShownFlag(shown: boolean): Promise<void> {
        await appStorage.setItem(this.templatesToAddWereShownKey, shown.toString());
    }
}
