import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

import type { ExtendedBigNumber } from '@smartfolly/common.utilities';

import type { HistoricalPrice } from '@smartfolly/frontend.assets-service';

import { priceToString } from '@smartfolly/frontend.currencies-service';
import type { Currency } from '@smartfolly/frontend.currencies-service';

export type CumulativeProfitLossChartPoint = {
    /**
     * A date of the chart point.
     */
    date: string;
    /**
     * The calculated cumulative profit & loss of the chart point.
     */
    profitLoss: ExtendedBigNumber & { asNumber: number };
    /**
     * The profit & loss currency.
     */
    currency: Currency;
};

/**
 * Max number of charts points to work with by design
 */
const pointsAmount = 7;

/**
 * Hook to get the cumulative profit & loss chart data
 * from the sorted list of historical total prices.
 * @param prices - a sorted list of historical total prices.
 * @returns the built cumulative profit & loss chart data.
 */

export function useCumulativeProfitLossChartData(
    prices: HistoricalPrice[],
): CumulativeProfitLossChartPoint[] {
    return useMemo(() => {
        // Note we show only the desired amount of chart points by the design
        const pricesSlice = prices.slice(-pointsAmount);

        // Allocate the variable to store the previous profit & loss value
        let previousProfitLossValue: BigNumber | undefined;

        // Map the prices into the profit & loss points to show
        return pricesSlice.map(({ date, price }, index) => {
            // The profit & loss value on the first item is always zero
            if (index === 0) {
                // Set the profit & loss value for the first point as zero
                const profitLossValue = new BigNumber(0);

                // Store the "previous" profit & loss value
                previousProfitLossValue = profitLossValue;

                // Return the chart point data
                return {
                    date,
                    profitLoss: {
                        string: priceToString(profitLossValue, price.currency),
                        value: profitLossValue,
                        asNumber: profitLossValue.toNumber(),
                    },
                    currency: price.currency,
                };
            }

            // The cumulative profit & loss value is calculated
            // as the sum of differences between the current and previous point
            const previousTotalPrice = pricesSlice[index - 1]!.price;
            const profitLossValue = (price.value ?? new BigNumber(0))
                .minus(previousTotalPrice.value ?? new BigNumber(0))
                .plus(previousProfitLossValue ?? new BigNumber(0));

            // Store the "previous" profit & loss value
            previousProfitLossValue = profitLossValue;

            // Return the chart point data
            return {
                date,
                profitLoss: {
                    string: priceToString(profitLossValue, price.currency),
                    value: profitLossValue,
                    asNumber: profitLossValue.toNumber(),
                },
                currency: price.currency,
            };
        });
    }, [prices]);
}
