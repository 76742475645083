import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

import type { ExtendedBigNumber } from '@smartfolly/common.utilities';

import type { HistoricalPrice } from '@smartfolly/frontend.assets-service';

import { priceToString } from '@smartfolly/frontend.currencies-service';
import type { Currency } from '@smartfolly/frontend.currencies-service';

export type DailyProfitLossChartPoint = {
    /**
     * A date of the chart point.
     */
    date: string;
    /**
     * The calculated daily profit & loss of the chart point.
     */
    profitLoss: ExtendedBigNumber & { asNumber: number };
    /**
     * The profit & loss currency.
     */
    currency: Currency;
    /**
     * The flag to indicate if the profit & loss value is negative.
     */
    isNegative: boolean;
};

/**
 * Max number of charts points to work with by design
 */
const pointsAmount = 7;

/**
 * Hook to get the daily profit & loss chart data from the sorted list of historical total prices.
 * @param prices - a sorted list of historical total prices.
 * @returns the built daily profit & loss chart data.
 */

export function useDailyProfitLossChartData(
    prices: HistoricalPrice[],
): DailyProfitLossChartPoint[] {
    return useMemo(() => {
        // Note we show only the desired amount of chart points by the design
        const pricesSlice = prices.slice(-pointsAmount);

        // Map the prices into the profit & loss points to show
        return pricesSlice.map(({ date, price }, index) => {
            // The profit & loss value on the first item is always zero
            if (index === 0) {
                // Set the profit & loss value for the first point as zero
                const profitLossValue = new BigNumber(0);

                // Return the chart point data
                return {
                    date,
                    profitLoss: {
                        string: priceToString(profitLossValue, price.currency),
                        value: profitLossValue,
                        asNumber: profitLossValue.toNumber(),
                    },
                    currency: price.currency,
                    isNegative: false,
                };
            }

            // The daily profit & loss value is calculated
            // as the diff between the current and previous point
            const previousTotalPrice = pricesSlice[index - 1]!.price;
            const profitLossValue = (price.value ?? new BigNumber(0)).minus(
                previousTotalPrice.value ?? new BigNumber(0),
            );

            // Return the chart point data
            return {
                date,
                profitLoss: {
                    string: priceToString(profitLossValue, price.currency),
                    value: profitLossValue,
                    asNumber: profitLossValue.toNumber(),
                },
                currency: price.currency,
                isNegative: new BigNumber(0).isGreaterThan(profitLossValue),
            };
        });
    }, [prices]);
}
