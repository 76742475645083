import { useMemo } from 'react';

/**
 * Hook to pluralize the noun.
 * @param noun - a noun to pluralize.
 * @param count - an amount of nouns.
 * @param suffix - a suffix to append.
 * @returns the pluralized noun.
 */
export function usePluralize(noun: string, count: number, suffix = 's') {
    return useMemo(() => `${count} ${noun}${count !== 1 ? suffix : ''}`, [count, noun, suffix]);
}
