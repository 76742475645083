import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { priceToString } from '@smartfolly/frontend.currencies-service';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { CumulativeProfitLossChartPoint, useCalculateTextWidth } from '../../../hooks';

import {
    ChartsTooltip,
    contentStyle,
    dateFormatter,
    priceFormatter,
    wrapperStyle,
} from './ChartsTooltip';

const tickStyle = {
    fontSize: '10px',
    fontWeight: '500',
};
const tickStroke = '#A4A6A8';
const gridStroke = '#21282C';
const positiveColor = '#26DA72';
const negativeColor = '#FF4747';

const xAxisTickFormatter = (date: string) => new Date(date).getDate().toString();

const yAxisTickFormatter =
    (data: CumulativeProfitLossChartPoint[]) => (value: number, index: number) =>
        `${value > 0 ? '+' : ''}${priceToString(
            new BigNumber(value),
            data[index]?.currency ?? 'USD',
            0,
        )}`;

export const CumulativeProfitLossGraph = function CumulativeProfitLossGraph({
    data,
}: {
    data: CumulativeProfitLossChartPoint[];
}) {
    const yTickFormatter = useMemo(() => {
        return yAxisTickFormatter(data);
    }, [data]);

    const lineColor = useMemo(() => {
        const lastElement = data.at(-1);
        if (lastElement) {
            return lastElement.profitLoss.asNumber > 0 ? positiveColor : negativeColor;
        }
        return positiveColor;
    }, [data]);

    const maxAbsProfitLoss = useMemo(() => {
        return Math.max(...data.map(point => Math.abs(point.profitLoss.asNumber)));
    }, [data]);

    const yAxisLabelWidth = useCalculateTextWidth(
        priceToString(new BigNumber(maxAbsProfitLoss), 'USD', 4), // 2 decimal places + 1 for the sign + 1 for possible additional digit in tick
        10,
        'Manrope,sans-serif',
    );

    return (
        <FlexContainer direction="column" align="stretch" justify="center">
            <Flex>
                <FlexContainer direction="row" align="center" justify="space-between">
                    <Flex grow={1}>
                        <div className="title title-normal">Cumulative P&L</div>
                    </Flex>
                </FlexContainer>
            </Flex>
            <Flex className="m-t-1">
                <ResponsiveContainer width="100%" height={180}>
                    <LineChart data={data}>
                        <XAxis
                            dataKey="date"
                            tickFormatter={xAxisTickFormatter}
                            stroke={tickStroke}
                            style={tickStyle}
                            axisLine={false}
                            tickLine={false}
                        />
                        <YAxis
                            dataKey="profitLoss.asNumber"
                            tickFormatter={yTickFormatter}
                            stroke={tickStroke}
                            style={tickStyle}
                            axisLine={false}
                            tickLine={false}
                            orientation="right"
                            tickMargin={8}
                            width={yAxisLabelWidth}
                        />
                        <CartesianGrid vertical={false} stroke={gridStroke} />
                        <Line
                            type="monotone"
                            dot={false}
                            dataKey="profitLoss.asNumber"
                            stroke={lineColor}
                            strokeWidth={2}
                        />
                        <Tooltip
                            content={
                                <ChartsTooltip
                                    customLabelFormatter={dateFormatter}
                                    customValueFormatter={priceFormatter}
                                />
                            }
                            contentStyle={contentStyle}
                            wrapperStyle={wrapperStyle}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Flex>
        </FlexContainer>
    );
};
