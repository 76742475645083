import { serverConnector } from '@smartfolly/middleware.server-connector';

import type {
    LoginRequestParameters,
    LoginRequestResponse,
    ResponseError,
} from '@smartfolly/server';

import { AuthUserRequestPath } from '../constants';

/**
 * A request to login and obtain the user session.
 */
export async function authLogin(params: LoginRequestParameters): Promise<LoginRequestResponse> {
    const loginResponse = await serverConnector.sendPOSTRequest<
        LoginRequestParameters,
        LoginRequestResponse
    >({
        params,
        path: AuthUserRequestPath.Login,
    });

    // Check for errors in response
    if ('error' in loginResponse) {
        const { error } = loginResponse as ResponseError;
        // TODO: process `errorCode` from the response if needed
        throw new Error(error);
    }

    return loginResponse;
}
