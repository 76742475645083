import classnames from 'classnames/bind';
import { memo } from 'react';

import { Flex, FlexContainer, Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import type { ActiveGroup } from '../types';

import styles from '../Assets.module.scss';

const cnb = classnames.bind(styles);

export const EmptyPlaceholder = memo(function EmptyPlaceholder({
    active,
    cancelIcon,
    addIcon,
    onClearFilters,
    onAddWallet,
}: {
    active: ActiveGroup;
    cancelIcon: IButtonIcon | undefined;
    addIcon: IButtonIcon | undefined;
    onClearFilters: () => void;
    onAddWallet: () => void;
}) {
    return (
        <tbody>
            <tr className={cnb('no-matches')}>
                <td colSpan={3}>
                    <FlexContainer
                        className={cnb('no-matches-container')}
                        direction="column"
                        justify="space-between"
                        align="center"
                    >
                        <Flex className="action action-normal">
                            <div className={cnb('icon', 'quadric')}>
                                {active === 'tokens' && <Icon icon="default-token" />}
                                {active === 'exchanges' && <Icon icon="default-exchange" />}
                                {active === 'blockchains' && <Icon icon="default-network" />}
                                {active === 'wallets' && <Icon icon="default-wallet" />}
                            </div>
                        </Flex>
                        <Flex className="action action-normal">No exact matches</Flex>
                        <Flex className="paragraph paragraph-small color-text-secondary">
                            Try changing or removing some of your filters or add more wallets.
                        </Flex>
                        <Flex className={cnb('no-matches-actions')}>
                            <Button
                                color="default"
                                size="medium"
                                disabled={false}
                                iconLeft={cancelIcon}
                                onClick={onClearFilters}
                            >
                                Clear filters
                            </Button>
                            <Button
                                color="default"
                                size="medium"
                                disabled={false}
                                iconLeft={addIcon}
                                onClick={onAddWallet}
                                title="Connect"
                                aria-label="Connect"
                            >
                                Connect
                            </Button>
                        </Flex>
                    </FlexContainer>
                </td>
            </tr>
        </tbody>
    );
});
