import classnames from 'classnames/bind';
import { memo } from 'react';

import { Flex, FlexContainer, Modal } from '@smartfolly/frontend.web-ui';

import { EditButton } from './EditButton';
import { UserWallets } from './UserWallets';

import styles from '../Connect.module.scss';
import { AddButton } from './AddButton';

const cnb = classnames.bind(styles);

export const UserWalletsModal = memo(function UserWalletsModal({
    showModal,
    onHide,
    showAddModalFn,
    showEditModalFn,
}: {
    showModal: boolean;
    onHide: () => void;
    showAddModalFn: () => void;
    showEditModalFn: () => void;
}) {
    // Render

    return (
        <Modal
            className={cnb('wallets-modal')}
            show={showModal}
            onHide={onHide}
            header={
                <FlexContainer justify="space-between" align="center">
                    <Flex className="title title-normal widget-text-primary">Wallets</Flex>
                </FlexContainer>
            }
        >
            <FlexContainer direction="column" justify="center" align="stretch">
                <Flex>
                    <UserWallets />
                </Flex>
                <Flex>
                    <EditButton onPress={showEditModalFn} />
                </Flex>
                <Flex>
                    <AddButton onPress={showAddModalFn} />
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
