import classnames from 'classnames/bind';
import { useMemo, useCallback } from 'react';
import { Dropdown, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

import { Log } from '@smartfolly/common.utilities';

import { BoardTemplates } from '@smartfolly/sdk';

import type { Board, IBoardsService } from '@smartfolly/frontend.boards-service';
import {
    Button,
    CustomToggle,
    Flex,
    FlexContainer,
    IButtonIcon,
    Icon,
} from '@smartfolly/frontend.web-ui';

import { boardsService as myBoardsService, pirschClient } from '../../services';

import { showToast } from '../../utils';

import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

const log = new Log('BoardComponentHeader');

function linkCopied() {
    showToast('Share link copied to clipboard');
}

const inputIconLeft: IButtonIcon = {
    icon: <Icon icon="copy-mini" />,
    animation: 'none',
};

export function BoardComponentHeader({
    board,
    boardsService,
    onEditBoard,
    onRenameBoard,
}: {
    board: Board;
    boardsService: IBoardsService;
    onEditBoard?: () => void;
    onRenameBoard?: () => void;
}) {
    const navigate = useNavigate();
    const { boardId } = board;

    const { deleteBoard } = boardsService;

    // Hooks

    // Getters

    const isTemplateBoard = useMemo(() => board.template != null, [board.template]);

    const shareLink = useMemo(() => {
        if (board) {
            return `${window.location.protocol}//${window.location.host}/share/${board.boardId}`;
        }
        return '';
    }, [board]);

    // Actions

    const deleteBoardFn = useCallback(async () => {
        try {
            const boardDelete = await deleteBoard({ boardId });
            if (boardDelete) {
                showToast('Board deleted');
            } else {
                showToast('Something went wrong');
            }
        } catch (error) {
            log.error('Failed to delete the board with error:', error);

            showToast('Something went wrong');
        }
    }, [boardId, deleteBoard]);

    const duplicateBoardFn = useCallback(async () => {
        try {
            const result = await boardsService.addBoard({
                // Copy the board name
                name: `${board.name} (copy)`,
                // Include "filters" only if the filters are present, i.e. a dynamic board,
                // or include "selectedAssets" otherwise
                ...(board.filters
                    ? {
                          filters: {
                              pickTokens: board.filters.tokens,
                              pickWallets: board.filters.wallets,
                              pickBlockchains: board.filters.blockchains,
                              pickExchanges: board.filters.exchanges,
                              pickContractTypes: board.filters.contractTypes,
                              pickTokenTypes: board.filters.tokenTypes,
                          },
                      }
                    : {
                          selectedAssets: board.selectedAssets.map(asset => asset.assetId),
                      }),
            });
            if (result) {
                pirschClient.event('Copied board');
                showToast('Board saved');
                navigate(`/boards/${result.boardId}#rename`);
            } else {
                showToast('Something went wrong');
            }
        } catch (error) {
            log.error('Failed to duplicate the board with error:', error);

            showToast('Something went wrong');
        }
    }, [boardsService, board, navigate]);

    // Render

    const desc = useMemo(() => {
        if (board.template?.kind === BoardTemplates.Total) {
            return 'All assets on all wallets';
        }
        if (board.template?.kind === BoardTemplates.CoreCrypto) {
            return 'Leading market assets';
        }
        if (board.template?.kind === BoardTemplates.Stablecoins) {
            return 'Fiat in crypto world';
        }
        return board.description;
    }, [board]);

    return (
        <FlexContainer className="m-t-1" justify="space-between" align="center" direction="row">
            <Flex>
                <FlexContainer
                    className="m-t-1"
                    justify="space-between"
                    align="start"
                    direction="column"
                >
                    <Flex className="title title-small">Portfolio</Flex>
                </FlexContainer>
                <FlexContainer
                    className="m-b-1"
                    justify="space-between"
                    align="start"
                    direction="column"
                >
                    <Flex className="action action-huge m-t-0.25 m-b-0.25">{board.name}</Flex>
                    <Flex className="paragraph paragraph-small color-text-secondary">
                        {desc}&nbsp;
                    </Flex>
                </FlexContainer>
            </Flex>
            <Flex>
                {!(board.template?.kind === BoardTemplates.Total) && (
                    <Dropdown align="end">
                        <Dropdown.Toggle
                            as={CustomToggle}
                            icon="more-horizontal"
                            backdrop
                            variant="transparent"
                            id="dropdown-basic"
                            // Check if can edit the board as we can do it only for my boards
                            disabled={boardsService !== myBoardsService}
                        />

                        <Dropdown.Menu className="custom-dropdown-menu">
                            <Dropdown.Item className="action action-normal" onClick={onRenameBoard}>
                                Rename <Icon icon="cursor-text-mini" className="float-end" />
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="action action-normal"
                                onClick={onEditBoard}
                                disabled={isTemplateBoard}
                            >
                                Edit <Icon icon="edit-mini" className="float-end" />
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="action action-normal"
                                onClick={duplicateBoardFn}
                            >
                                Duplicate <Icon icon="copy-mini" className="float-end" />
                            </Dropdown.Item>
                            <Dropdown.Item className="action action-normal" onClick={deleteBoardFn}>
                                Delete <Icon icon="delete-mini" className="float-end" />
                            </Dropdown.Item>
                            <Dropdown.Item className="action action-normal cursor-default no-hover">
                                <div className="m-b-0.75">Share to web</div>
                                <CopyToClipboard text={shareLink} onCopy={linkCopied}>
                                    <InputGroup className={cnb('share-input-group')}>
                                        <Form.Control
                                            className="action action-normal unselectable"
                                            role="button"
                                            type="text"
                                            placeholder="Link to share"
                                            value={shareLink}
                                            readOnly
                                            required
                                        />
                                        <Button iconLeft={inputIconLeft} />
                                    </InputGroup>
                                </CopyToClipboard>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </Flex>
        </FlexContainer>
    );
}
