import type { AuthProvider } from '../../../../constants';
import type { SessionStorage } from '../../../../types';

import type { StorageSessionInfo } from '../types';

import { getSessionInfoFromStorage } from './getSessionInfoFromStorage';

type CheckForSessionInfoOptions = {
    /**
     * A storage to get the session info from.
     */
    storage: SessionStorage;

    /**
     * A provider to get the session info for.
     */
    provider: AuthProvider;

    /**
     * An optional extra key to deal with several sessions.
     */
    key?: string;
};

type CheckForSessionInfoResponse = {
    /**
     * A stored session info or `null`.
     */
    sessionInfo: StorageSessionInfo | null;

    /**
     * Flag either the current provider is actual.
     * Note: should be defined in case the session info is present.
     */
    isActualProvider?: boolean;

    /**
     * Flag either the session is expired.
     * Note: should be defined in case the session info is present.
     */
    isExpired?: boolean;
};

/**
 * Function to check the session info in the storage.
 * @param options - contain the parameters to check the session info for.
 * @returns an object with the current session info if any.
 */
export async function checkForSessionInfoInStorage({
    storage,
    provider,
    key,
}: CheckForSessionInfoOptions): Promise<CheckForSessionInfoResponse> {
    const { sessionInfo, isExpired } = await getSessionInfoFromStorage({ storage, key });
    if (!sessionInfo) {
        return {
            sessionInfo: null,
        };
    }

    const isActualProvider = sessionInfo.session.provider.name === provider;

    return {
        sessionInfo,
        isActualProvider,
        isExpired: !!isExpired,
    };
}
