import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { logEvent } from '@amplitude/analytics-browser';

import { Log } from '@smartfolly/common.utilities';

import { AuthProvider } from '@smartfolly/sdk';

import { Button, Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { authService, userService } from '../../../services';

import { showToast } from '../../../utils';

import { AuthWithTelegramModal } from '../AuthWithTelegramModal';

import styles from '../Connect.module.scss';

const cnb = classNames.bind(styles);

const log = new Log('SubscriptionsView');

export const SubscriptionsView = observer(function SubscriptionsView() {
    // Stores

    const { isAuthorizing } = authService;

    const {
        authProvider,
        isSubscribedToWhales,
        isSubscribingToWhales,
        isUnsubscribingFromWhales,
        createWhalesSubscription,
        removeWhalesSubscription,
    } = userService;

    // States

    const [showAuthWithTelegram, setShowAuthWithTelegram] = useState<boolean>(false);

    // Getters

    const buttonTitle = useMemo(() => {
        if (isSubscribingToWhales || isUnsubscribingFromWhales) {
            return <Spinner animation="border" className="spinner-icon" />;
        }

        return isSubscribedToWhales ? 'Unfollow Whales' : 'Follow Whales';
    }, [isSubscribedToWhales, isSubscribingToWhales, isUnsubscribingFromWhales]);

    // Actions

    const showAuthWithTelegramModal = useCallback(() => {
        logEvent('Auth with Telegram Bot to subscribe clicked');

        setShowAuthWithTelegram(true);
    }, []);

    const hideAuthWithTelegramModal = useCallback(() => {
        setShowAuthWithTelegram(false);
    }, []);

    const createWhalesSubscriptionFn = useCallback(async () => {
        try {
            logEvent('Follow whales started');

            const result = await createWhalesSubscription();

            logEvent('Follow whales finished', { result });

            if (result) {
                showToast('Followed whales');
            } else {
                showToast('Something went wrong');
            }
        } catch (error) {
            log.error('Failed to follow whales with error:', error);

            showToast('Something went wrong');
        }
    }, [createWhalesSubscription]);

    const unsubscribeFromWhalesFn = useCallback(async () => {
        try {
            logEvent('Unfollow whales started');

            const result = await removeWhalesSubscription();

            logEvent('Unfollow whales finished', { result });

            if (result) {
                showToast('Unfollowed whales');
            } else {
                showToast('Something went wrong');
            }
        } catch (error) {
            log.error('Failed to unfollow whales with error:', error);

            showToast('Something went wrong');
        }
    }, [removeWhalesSubscription]);

    const onSubscribeUnsubscribe = useCallback(() => {
        if (isSubscribedToWhales == null) {
            // The subscriptions state is unknown. Do nothing
        } else if (authProvider == null) {
            // The provider is unknown. Do nothing
        } else if (authProvider !== AuthProvider.TelegramBot) {
            showAuthWithTelegramModal();
        } else if (isSubscribedToWhales) {
            unsubscribeFromWhalesFn(); // no need to await
        } else {
            createWhalesSubscriptionFn(); // no need to await
        }
    }, [
        authProvider,
        isSubscribedToWhales,
        showAuthWithTelegramModal,
        createWhalesSubscriptionFn,
        unsubscribeFromWhalesFn,
    ]);

    // Events

    const onAuthWithTelegramStarted = useCallback(async () => {
        logEvent('Auth with Telegram Bot to subscribe started');
    }, []);

    const onAuthWithTelegramFinished = useCallback(
        async (result: boolean) => {
            logEvent('Auth with Telegram Bot to subscribe finished', { result });

            if (result) {
                hideAuthWithTelegramModal();

                createWhalesSubscriptionFn();
            }
        },
        [hideAuthWithTelegramModal, createWhalesSubscriptionFn],
    );

    // Render

    if (isSubscribedToWhales == null) {
        // The subscriptions state is unknown
        return null;
    }

    if (!authProvider) {
        // The provider is unknown
        return null;
    }

    // Display a subscribe / unsubscribe button which should also authorize the user via Telegram
    return (
        <>
            <FlexContainer
                direction="column"
                justify="stretch"
                align="stretch"
                className={cnb('profile-subscriptions-box')}
            >
                <FlexContainer direction="row" align="center" className="p-b-0.5">
                    <Flex className="title title-normal" grow={1}>
                        Subscriptions
                    </Flex>
                    <Flex className="paragraph paragraph-small text-secondary">via Telegram</Flex>
                </FlexContainer>
                <Button
                    color={isSubscribedToWhales ? 'default' : 'primary'}
                    disabled={isAuthorizing || isSubscribingToWhales || isUnsubscribingFromWhales}
                    onClick={onSubscribeUnsubscribe}
                >
                    {buttonTitle}
                </Button>
                {!isSubscribedToWhales && (
                    <Flex className="p-t-0.5 paragraph paragraph-small color-text-secondary">
                        By proceeding, you acknowledge and agree the information provided is not
                        intended as financial or investment advice
                    </Flex>
                )}
            </FlexContainer>
            <AuthWithTelegramModal
                mode="addAuth"
                show={showAuthWithTelegram}
                onHide={hideAuthWithTelegramModal}
                onAuthStarted={onAuthWithTelegramStarted}
                onAuthFinished={onAuthWithTelegramFinished}
            />
        </>
    );
});
