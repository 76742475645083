import BigNumber from 'bignumber.js';

import { findIndexToInsert } from '@smartfolly/common.utilities';

import type { Asset, WalletGroup } from '../types';

import { appendTotalPriceAndPortionToGroup } from './appendTotalPriceAndPortionToGroup';

/**
 * Function to group the assets by wallets.
 * @param assets - a list of assets to group.
 * @returns a wallet group list.
 */
export function groupAssetsByWallets(assets: Asset[]): WalletGroup[] {
    // Calculate the total price of given assets
    let totalPrice: BigNumber | undefined;

    // Group assets by wallets
    const filteredWallets = Object.values(
        assets.reduce<{ [walletKey: string]: Omit<WalletGroup, 'totalPrice'> }>((acc, asset) => {
            // Create a unique wallet key to deal with duplicates
            let walletKey: string;

            // Get the asset wallet
            const { wallet } = asset;

            // Check if the asset has an address to be grouped with
            if ('address' in wallet) {
                walletKey = `${wallet.address}:${wallet.blockchain.id}`;

                // Create a wallet group if not yet present
                if (!acc[walletKey]) {
                    acc[walletKey] = {
                        wallet, // same wallet properties for any asset in the group
                        assets: [],
                    };
                }
            } else {
                // If no, group by custodian wallets, a.k.a. exchanges
                walletKey = `${wallet.sourceId}:${wallet.exchange.id}`;

                // Create a wallet group if not yet present
                if (!acc[walletKey]) {
                    acc[walletKey] = {
                        wallet, // same wallet properties for any asset in the group
                        assets: [],
                    };
                }
            }

            // Find the proper placement of the asset to insert it in the descending order
            const indexToInsert = findIndexToInsert(acc[walletKey]!.assets, asset, (a, b) => {
                // Consider the price of the asset with an unknown value as zero
                const aAssetPrice = a.price.value ?? new BigNumber(0);
                const bAssetPrice = b.price.value ?? new BigNumber(0);

                // Sort the assets by the price in the descending order
                return bAssetPrice.comparedTo(aAssetPrice);
            });

            // Add the asset to the wallet group
            acc[walletKey]!.assets.splice(indexToInsert, 0, asset);

            // Increase the total price of the group as well
            if (asset.price.value) {
                totalPrice = asset.price.value.plus(totalPrice ?? new BigNumber(0));
            }

            return acc;
        }, {}),
    );

    // Return an empty array if no wallet groups are filtered
    if (!filteredWallets.length) {
        return [];
    }

    // Append the total price and the portion to each wallet group
    return filteredWallets.map<WalletGroup>(
        group => appendTotalPriceAndPortionToGroup(group, totalPrice) as WalletGroup,
    );
}
