// Inspired by https://github.com/christsim/multicoin-address-validator

import { Blockchains } from '../../constants';

import { isValidP2PKHandP2SHAddress, isValidSegwitAddress } from './validators';

import { validatingBlockchains } from './validatingBlockchains';

/**
 * Check if the given address is valid for LTC blockchain.
 * @param address - an address to check.
 * @returns a result of the check.
 */
export function isValidLtcAddress(address: string) {
    const validatingBlockchain = validatingBlockchains[Blockchains.LTC];
    return (
        isValidP2PKHandP2SHAddress(address, validatingBlockchain) ||
        isValidSegwitAddress(address, validatingBlockchain)
    );

    // Legacy regex
    // return /(?:^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$)/.test(address);
}
