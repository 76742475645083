import { Environment, LogSettings, argsToString, BuildType } from '@smartfolly/common.utilities';

import * as sentry from '@sentry/browser';

import { webDsn } from '../../../../configs';

class SentryLogger {
    public constructor() {
        const { buildType } = Environment;

        // Don't init logger for developer builds (localhost and dev-stage)
        if (buildType === BuildType.Development) {
            return;
        }

        sentry.init({
            dsn: webDsn,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });
    }

    // eslint-disable-next-line class-methods-use-this
    public log(level: sentry.SeverityLevel, message: string) {
        const { buildType } = Environment;

        sentry.captureMessage(`[${buildType}] ${message}`, level);
    }
}

/**
 * Function to setup logs to behave properly,
 * e.g. work for development and send them to such services as Sentry in production.
 */
export function setupLogs() {
    const sentryLogger = new SentryLogger();

    // Setup the shared log settings
    LogSettings.shared.writer = {
        debug(...args: unknown[]) {
            console.log(...args);
        },
        info(...args: unknown[]) {
            console.info(...args);
            sentryLogger.log('info', argsToString(args));
        },
        warning(...args: unknown[]) {
            console.warn(...args);
            sentryLogger.log('warning', argsToString(args));
        },
        error(...args: unknown[]) {
            console.error(...args);
            sentryLogger.log('error', argsToString(args));
        },
    };

    // Disable debug logs for production
    if (Environment.isProduction) {
        LogSettings.shared.disableDebug();
    }
}
