import { computed, makeObservable, observable } from 'mobx';

export class ObservableFlags {
    // Properties

    /**
     * A private observable instance of the `isLoadingAssets` flag.
     */
    private isLoadingAssetsFlag: boolean = false;

    /**
     * A private observable instance of the `isAddingAddress` flag.
     */
    private isAddingAddressFlag: boolean = false;

    /**
     * A private observable instance of the `isRemovingAddress` flag.
     */
    private isRemovingAddressFlag: boolean = false;

    /**
     * A private observable instance of the `isAddingExchange` flag.
     */
    private isAddingExchangeFlag: boolean = false;

    /**
     * A private observable instance of the `isRemovingExchanges` flag.
     */
    private isRemovingExchangeFlag: boolean = false;

    /**
     * A private observable instance of the `isRescanningAssets` flag.
     */
    private isRescanningAssetsFlag: boolean = false;

    // Constructor

    public constructor() {
        makeObservable<
            ObservableFlags,
            | 'isLoadingAssetsFlag'
            | 'isAddingAddressFlag'
            | 'isRemovingAddressFlag'
            | 'isAddingExchangeFlag'
            | 'isRemovingExchangeFlag'
            | 'isRescanningAssetsFlag'
        >(this, {
            isLoadingAssets: computed,
            isLoadingAssetsFlag: observable,
            isAddingAddress: computed,
            isAddingAddressFlag: observable,
            isAddingExchange: computed,
            isAddingExchangeFlag: observable,
            isRemovingExchange: computed,
            isRemovingExchangeFlag: observable,
            isRemovingAddress: computed,
            isRemovingAddressFlag: observable,
            isRescanningAssets: computed,
            isRescanningAssetsFlag: observable,
        });
    }

    public get isLoadingAssets(): boolean {
        return this.isLoadingAssetsFlag;
    }

    public set isLoadingAssets(isLoadingAssets: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isLoadingAssetsFlag = isLoadingAssets;
    }

    public get isAddingAddress(): boolean {
        return this.isAddingAddressFlag;
    }

    public set isAddingAddress(isAddingAddress: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isAddingAddressFlag = isAddingAddress;
    }

    public get isRemovingAddress(): boolean {
        return this.isRemovingAddressFlag;
    }

    public set isRemovingAddress(isRemovingAddress: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isRemovingAddressFlag = isRemovingAddress;
    }

    public get isAddingExchange(): boolean {
        return this.isAddingExchangeFlag;
    }

    public set isAddingExchange(isAddingExchange: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isAddingExchangeFlag = isAddingExchange;
    }

    public get isRemovingExchange(): boolean {
        return this.isRemovingExchangeFlag;
    }

    public set isRemovingExchange(isRemovingExchange: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isRemovingExchangeFlag = isRemovingExchange;
    }

    public get isRescanningAssets(): boolean {
        return this.isRescanningAssetsFlag;
    }

    public set isRescanningAssets(isRescanningAssets: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isRescanningAssetsFlag = isRescanningAssets;
    }
}
