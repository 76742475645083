import type { SessionStorage } from '../../../../types';

import { SESSION_INFO_KEY } from '../constants';

import type { StorageSessionInfo } from '../types';

type GetSessionInfoOptions = {
    /**
     * A storage to get the session info from.
     */
    storage: SessionStorage;

    /**
     * An extra key to deal with several sessions.
     * Note: the key is appended as a suffix to the {@link SESSION_INFO_KEY} if defined.
     */
    key: string | undefined;
};

type GetSessionInfoResponse = {
    /**
     * A stored session info or `null`.
     */
    sessionInfo: StorageSessionInfo | null;

    /**
     * Flag either the session is expired.
     * Note: should be defined in case the session info is present.
     */
    isExpired?: boolean;
};

/**
 * Function to get the session info from the storage.
 * @param options - contain the parameters to get the session info for.
 * @returns an object with the current session info if any.
 */
export async function getSessionInfoFromStorage({
    storage,
    key,
}: GetSessionInfoOptions): Promise<GetSessionInfoResponse> {
    let sessionInfoKey = SESSION_INFO_KEY;
    if (key) {
        sessionInfoKey += `:${key}`;
    }

    const sessionInfoString = await storage.getItem(sessionInfoKey);
    if (!sessionInfoString) {
        return {
            sessionInfo: null,
        };
    }

    const sessionInfo = JSON.parse(sessionInfoString) as StorageSessionInfo;
    const isExpired = sessionInfo.session.expire < Date.now();

    return {
        sessionInfo,
        isExpired,
    };
}
