import { observer } from 'mobx-react';

import { Connect } from '../Connect';

export const Header = observer(function Header() {
    // Render

    // TODO: add more components if needed
    return <Connect />;
});
