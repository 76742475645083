import type { SessionInfo } from '../../../../types';

import type { StorageSessionInfo } from '../types';

/**
 * Function to convert the session info stored in the storage
 * into the pure session info without any session and secret keys!
 * @param sessionInfo - a stored session info
 * @returns a pure session info object
 */
export function convertStoredSessionInfo(sessionInfo: StorageSessionInfo): SessionInfo {
    return {
        userId: sessionInfo.userId,
        expire: sessionInfo.session.expire,
        provider: sessionInfo.session.provider,
    };
}
