import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useState, useMemo, useCallback } from 'react';

import type { Asset, FilteringOptions } from '@smartfolly/frontend.assets-service';
import { Button, Flex, FlexContainer, Modal } from '@smartfolly/frontend.web-ui';

import { usePluralize } from '../../hooks';

import { assetsService } from '../../services';

import { GroupsSelector, SelectingGroup } from './GroupsSelector';

import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

export const FilterModal = observer(function FilterModal({
    onHide,
    type,
    applyFilters,
}: {
    onHide: () => void;
    type: 'tokens' | 'networks' | 'exchanges' | 'wallets';
    applyFilters: (filters: FilteringOptions, assets: Asset[]) => void;
}) {
    const { groupedTokens, groupedBlockchains, groupedExchanges, groupedWallets } = assetsService;

    const [selectedGroups, setSelectedGroups] = useState<SelectingGroup[]>([]);

    // Render

    const selectedAssetsCount = useMemo(() => {
        return selectedGroups.reduce(
            (acc, group) => acc + group.assets.length + (group.hiddenAssets?.length ?? 0),
            0,
        );
    }, [selectedGroups]);

    const pluralizedAssets = usePluralize('asset', selectedAssetsCount);

    const applyAction = useCallback(() => {
        const filters = selectedGroups.reduce<FilteringOptions>((acc, group) => {
            if ('token' in group) {
                if (!acc.pickTokens) {
                    acc.pickTokens = [];
                }
                acc.pickTokens.push(group.token.id);
            }

            if ('blockchain' in group) {
                if (!acc.pickBlockchains) {
                    acc.pickBlockchains = [];
                }
                acc.pickBlockchains.push(group.blockchain.id);
            }

            if ('exchange' in group) {
                if (!acc.pickExchanges) {
                    acc.pickExchanges = [];
                }
                acc.pickExchanges.push(group.exchange.id);
            }

            if ('wallet' in group) {
                if (!acc.pickWallets) {
                    acc.pickWallets = [];
                }
                if ('blockchain' in group.wallet) {
                    acc.pickWallets.push({
                        address: group.wallet.address,
                        blockchain: group.wallet.blockchain.id,
                    });
                } else if ('exchange' in group.wallet) {
                    acc.pickWallets.push({
                        sourceId: group.wallet.sourceId,
                        exchange: group.wallet.exchange.id,
                    });
                }
            }

            return acc;
        }, {});
        const assets = selectedGroups.flatMap(group =>
            group.assets.concat(group.hiddenAssets ?? []),
        );
        applyFilters(filters, assets);
    }, [applyFilters, selectedGroups]);

    return (
        <Modal
            className={cnb('filter-modal')}
            show
            onHide={onHide}
            header={<div className="text-center" />}
        >
            {type === 'tokens' && (
                <GroupsSelector
                    type={type}
                    groupedAssets={groupedTokens}
                    onSelect={setSelectedGroups}
                />
            )}
            {type === 'networks' && (
                <GroupsSelector
                    type={type}
                    groupedAssets={groupedBlockchains}
                    onSelect={setSelectedGroups}
                />
            )}
            {type === 'exchanges' && (
                <GroupsSelector
                    type={type}
                    groupedAssets={groupedExchanges}
                    onSelect={setSelectedGroups}
                />
            )}
            {type === 'wallets' && (
                <GroupsSelector
                    type={type}
                    groupedAssets={groupedWallets}
                    onSelect={setSelectedGroups}
                />
            )}
            <FlexContainer justify="stretch" align="center" className="m-t-1">
                <Flex grow={1}>
                    <Button
                        className="d-block w-100 p-t-0.5 p-b-0.5 rounded-3 h-auto btn-primary"
                        onClick={applyAction}
                    >
                        <div className="action action-normal">Apply</div>
                        <div className="paragraph paragraph-tiny">{pluralizedAssets}</div>
                    </Button>
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
