import classnames from 'classnames/bind';

import { forwardRef, useCallback } from 'react';
import { Form, FormControlProps, InputGroup } from 'react-bootstrap';

import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import styles from './Clearable.module.scss';

const cnb = classnames.bind(styles);

type ButtonCustomProps = {
    btnClassName?: string;
    prepend?: React.ReactNode;
};

const btnIconLeft: IButtonIcon = {
    icon: <Icon icon="clear" />,
    animation: 'none',
};

/* @TODO: Move to web-ui with as it grows in something bigger with more features */
export const ClearableInput = forwardRef(function ClearableInputContainer(
    props: FormControlProps & ButtonCustomProps,
    ref: React.Ref<HTMLInputElement>,
) {
    const { btnClassName, prepend, ...controlProps } = props;
    const { onChange } = controlProps;

    const handleClear = useCallback(
        (ev: React.MouseEvent<HTMLButtonElement>) => {
            ev.preventDefault();
            if (onChange) {
                onChange({
                    target: { value: '' },
                } as React.ChangeEvent<HTMLInputElement>);
            }
        },
        [onChange],
    );

    return (
        <InputGroup className={cnb('clearable-input-group')}>
            {prepend}
            <Form.Control {...controlProps} ref={ref} as="input" />
            {props.value && (
                <Button
                    type="button"
                    className={btnClassName ?? ''}
                    onClick={handleClear}
                    iconLeft={btnIconLeft}
                />
            )}
        </InputGroup>
    );
});
