import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useState, useEffect, useMemo, useCallback } from 'react';

import { Button, Flex, FlexContainer, Modal } from '@smartfolly/frontend.web-ui';

import type { Blockchain, Exchange, Token, Wallet } from '@smartfolly/frontend.assets-service';

import { assetsService } from '../../services';

import { FilterItemButton } from './FilterItemButton';
import { FilterSelectorModal } from './FilterSelectorModal';
import type { FilterSelectItem } from './FilterSelector';

import styles from './Filters.module.scss';

const cnb = classNames.bind(styles);

export const FiltersModal = observer(function FiltersModal({
    show,
    onHide,
}: {
    show: boolean;
    onHide: () => void;
}) {
    const {
        availableBlockchains,
        availableExchanges,
        availableTokens,
        availableWallets,
        appliedFilters: { pickWallets, pickBlockchains, pickExchanges, pickTokens },
        filter,
    } = assetsService;

    // States

    const [showFilter, setShowFilter] = useState<
        'tokens' | 'networks' | 'exchanges' | 'wallets' | null
    >(null);

    // Getters

    const [selectedTokens, setSelectedTokens] = useState<Token[] | undefined>(undefined);
    const [selectedBlockchains, setSelectedBlockchains] = useState<Blockchain[] | undefined>(
        undefined,
    );
    const [selectedExchanges, setSelectedExchanges] = useState<Exchange[] | undefined>(undefined);
    const [selectedWallets, setSelectedWallets] = useState<Wallet[] | undefined>(undefined);

    useEffect(() => {
        if (pickTokens) {
            // Find the available tokens among filtered
            const tokens = availableTokens.filter(({ id }) => pickTokens.includes(id));
            // Select them if found
            if (tokens.length > 0) {
                setSelectedTokens(tokens);
            }
        }

        if (pickBlockchains) {
            // Find the available blockchains among filtered
            const blockchains = availableBlockchains.filter(({ id }) =>
                pickBlockchains.includes(id),
            );
            // Select them if found
            if (blockchains.length > 0) {
                setSelectedBlockchains(blockchains);
            }
        }

        if (pickExchanges) {
            // Find the available exchanges among filtered
            const exchanges = availableExchanges.filter(({ id }) => pickExchanges.includes(id));
            // Select them if found
            if (exchanges.length > 0) {
                setSelectedExchanges(exchanges);
            }
        }

        if (pickWallets) {
            // Find the available wallets among filtered
            const wallets = availableWallets.filter(availableWallet => {
                if ('address' in availableWallet) {
                    return pickWallets.find(
                        wallet =>
                            'address' in wallet &&
                            wallet.address === availableWallet.address &&
                            wallet.blockchain === availableWallet.blockchain.id,
                    );
                }

                return pickWallets.find(
                    wallet =>
                        'sourceId' in wallet &&
                        wallet.sourceId === availableWallet.sourceId &&
                        wallet.exchange === availableWallet.exchange.id,
                );
            });
            // Select them if found
            if (wallets.length > 0) {
                setSelectedWallets(wallets);
            }
        }
    }, [
        pickTokens,
        pickBlockchains,
        pickExchanges,
        pickWallets,
        availableTokens,
        availableBlockchains,
        availableWallets,
        availableExchanges,
    ]);

    const items = useMemo(() => {
        switch (showFilter) {
            case 'tokens':
                return availableTokens;
            case 'networks':
                return availableBlockchains;
            case 'exchanges':
                return availableExchanges;
            case 'wallets':
                return availableWallets;
            default:
                return [];
        }
    }, [showFilter, availableTokens, availableBlockchains, availableExchanges, availableWallets]);

    const selectedItems = useMemo(() => {
        switch (showFilter) {
            case 'tokens':
                return selectedTokens;
            case 'networks':
                return selectedBlockchains;
            case 'exchanges':
                return selectedExchanges;
            case 'wallets':
                return selectedWallets;
            default:
                return [];
        }
    }, [showFilter, selectedTokens, selectedBlockchains, selectedExchanges, selectedWallets]);

    // Actions

    const showTokensFilterModal = useCallback(() => {
        setShowFilter('tokens');
    }, [setShowFilter]);

    const showNetworksFilterModal = useCallback(() => {
        setShowFilter('networks');
    }, [setShowFilter]);

    const showExchangesFilterModal = useCallback(() => {
        setShowFilter('exchanges');
    }, [setShowFilter]);

    const showWalletsFilterModal = useCallback(() => {
        setShowFilter('wallets');
    }, [setShowFilter]);

    const hideFilterModal = useCallback(() => {
        setShowFilter(null);
    }, [setShowFilter]);

    const onModalHide = useCallback(() => {
        hideFilterModal();
        onHide();
    }, [hideFilterModal, onHide]);

    const clearFilters = useCallback(() => {
        setSelectedTokens(undefined);
        setSelectedBlockchains(undefined);
        setSelectedExchanges(undefined);
        setSelectedWallets(undefined);
    }, []);

    const changeFilters = useCallback(
        (newItems: FilterSelectItem[]) => {
            if (showFilter === 'tokens') {
                setSelectedTokens(
                    newItems && newItems.length > 0 ? (newItems as Token[]) : undefined,
                );
            }
            if (showFilter === 'networks') {
                setSelectedBlockchains(
                    newItems && newItems.length > 0 ? (newItems as Blockchain[]) : undefined,
                );
            }
            if (showFilter === 'exchanges') {
                setSelectedExchanges(
                    newItems && newItems.length > 0 ? (newItems as Exchange[]) : undefined,
                );
            }
            if (showFilter === 'wallets') {
                setSelectedWallets(
                    newItems && newItems.length > 0 ? (newItems as Wallet[]) : undefined,
                );
            }
        },
        [showFilter],
    );

    const applyFilters = useCallback(() => {
        filter({
            pickTokens: selectedTokens?.map(token => token.id),
            pickBlockchains: selectedBlockchains?.map(blockchain => blockchain.id),
            pickExchanges: selectedExchanges?.map(exchange => exchange.id),
            pickWallets: selectedWallets?.map(wallet => {
                if ('address' in wallet) {
                    return {
                        address: wallet.address,
                        blockchain: wallet.blockchain.id,
                    };
                }

                return {
                    sourceId: wallet.sourceId,
                    exchange: wallet.exchange.id,
                };
            }),
        });
        onModalHide();
    }, [
        filter,
        selectedTokens,
        selectedBlockchains,
        selectedExchanges,
        selectedWallets,
        onModalHide,
    ]);

    // Render

    return (
        <>
            <Modal
                className={cnb('filters-modal')}
                show={show}
                onHide={onModalHide}
                header={<div className="title title-normal">Filters</div>}
            >
                <FlexContainer direction="column" justify="stretch" align="start" className="m-t-1">
                    {
                        // Note: some tokens must be always available to filter
                        <Flex className="m-b-1">
                            <FilterItemButton
                                title="tokens"
                                selected={selectedTokens}
                                onClick={showTokensFilterModal}
                            />
                        </Flex>
                    }
                    {
                        // Note: blockchains might be not available to filter
                        availableBlockchains.length > 0 ? (
                            <Flex className="m-b-1">
                                <FilterItemButton
                                    title="networks"
                                    selected={selectedBlockchains}
                                    onClick={showNetworksFilterModal}
                                />
                            </Flex>
                        ) : undefined
                    }
                    {
                        // Note: exchanges might be not available to filter
                        availableExchanges.length > 0 ? (
                            <Flex className="m-b-1">
                                <FilterItemButton
                                    title="exchanges"
                                    selected={selectedExchanges}
                                    onClick={showExchangesFilterModal}
                                />
                            </Flex>
                        ) : undefined
                    }
                    {
                        // Note: some wallets must be always available to filter
                        <Flex>
                            <FilterItemButton
                                title="wallets"
                                selected={selectedWallets}
                                onClick={showWalletsFilterModal}
                            />
                        </Flex>
                    }
                </FlexContainer>
                <FlexContainer
                    direction="column"
                    justify="stretch"
                    align="start"
                    className="m-t-0.5"
                >
                    <Flex grow={1} className="m-t-1.5 m-b-0.75">
                        <Button
                            className="p-t-0.5 p-b-0.5 rounded-3 h-auto btn-primary"
                            onClick={applyFilters}
                        >
                            <div className="action action-normal">Apply Filters</div>
                        </Button>
                        <Button
                            className="p-t-0.5 p-b-0.5 m-l-0.5 rounded-3 h-auto"
                            onClick={clearFilters}
                        >
                            <div className="action action-normal">Clear all</div>
                        </Button>
                    </Flex>
                </FlexContainer>
            </Modal>
            {!!showFilter && (
                <FilterSelectorModal
                    items={items}
                    selectedItems={selectedItems}
                    onHide={hideFilterModal}
                    type={showFilter}
                    changeFilters={changeFilters}
                />
            )}
        </>
    );
});
