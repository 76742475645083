import { serverConnector } from '@smartfolly/middleware.server-connector';

import { userAddressesSubscription, usersCollection } from '../../constants';

import type {
    ProvidedAddress,
    ProvidedAddresses,
    UserAddressesListener,
    UserAddressesListenerDisposer,
} from '../../types';

type UserWithAddresses = {
    /**
     * An ID of the user.
     */
    userId: string;

    /**
     * An array of the addresses provided by the user to be scanned in order to find assets.
     */
    providedAddresses: ProvidedAddress[];
};

/**
 * A function to subscribe to the user addresses data change.
 * @param userId - an ID of the user to subscribe to the addresses data change for.
 * @param listener - a user addresses listener which should resolve updated addresses.
 * @returns a user addresses listener disposer to stop the subscription.
 */
export function subscribeToAddresses(
    userId: string,
    listener: UserAddressesListener,
): UserAddressesListenerDisposer {
    // Proxy a listener to converting the resolved structure into the map of user addresses
    const proxyListener = (error: Error | undefined, user?: UserWithAddresses | null) => {
        const providedAddresses =
            user != null
                ? user.providedAddresses.reduce<ProvidedAddresses>((acc, { address, ...rest }) => {
                      acc[address] = rest;
                      return acc;
                  }, {})
                : null;
        listener(error, providedAddresses);
    };

    return serverConnector.subscribe<{ userId: string }, UserWithAddresses>(
        {
            collection: usersCollection,
            query: userAddressesSubscription,
            variables: {
                userId,
            },
        },
        proxyListener,
    );
}
