import { useMemo } from 'react';

/**
 * Hook to present the address as truncated.
 * @param address - an address to truncate.
 * @returns a truncated address.
 */
export function useTruncateAddress(address: string) {
    return useMemo(() => {
        if (address.length > 9) {
            return `${address.slice(0, 5)} ···· ${address.slice(-4)}`;
        }

        return address;
    }, [address]);
}
