import { browserName } from 'react-device-detect';

import { MetamaskExtensions } from '../../constants';

/**
 * Method to open MetaMask extension.
 */
export const openMetaMask = () => {
    if (browserName in MetamaskExtensions) {
        window
            .open(
                MetamaskExtensions[browserName as keyof typeof MetamaskExtensions],
                '_blank',
                'noreferrer',
            )
            ?.focus();
    } else {
        window.open(MetamaskExtensions.Chrome, '_blank', 'noreferrer')?.focus();
    }
};
