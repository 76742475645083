/* eslint-disable react/no-danger */
import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';

import { Button, Flex, FlexContainer, Modal } from '@smartfolly/frontend.web-ui';

import { releasesService } from '../../services';

import styles from './Connect.module.scss';

const cnb = classnames.bind(styles);

export const ReleaseModal = observer(function ReleaseModal() {
    // Getters

    const { markReleaseNotesAsShown, readyToShowReleaseNotes } = releasesService;

    const releaseNotes = useMemo(() => {
        return sanitizeHtml(marked(readyToShowReleaseNotes || ''));
    }, [readyToShowReleaseNotes]);

    // Render

    // Show the release notes only if ready
    if (!readyToShowReleaseNotes) {
        return null;
    }

    return (
        <Modal
            className={cnb('new-release-modal')}
            show
            onHide={markReleaseNotesAsShown}
            header={
                <FlexContainer justify="space-between" align="center">
                    <Flex className="title title-large widget-text-primary">What’s new?</Flex>
                </FlexContainer>
            }
        >
            <FlexContainer direction="column" justify="stretch" align="stretch">
                <Flex className={`paragraph paragraph-normal ${cnb('release-notes')}`}>
                    <div dangerouslySetInnerHTML={{ __html: releaseNotes }} />
                </Flex>
                <Flex>
                    <Button
                        className="btn btn-primary btn-large m-t-0.75 m-b-0.75 w-100 p-t-1 p-b-1 h-auto"
                        onClick={markReleaseNotesAsShown}
                    >
                        <div className="action action-normal">OK, let’s go</div>
                    </Button>
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
