import { Flex, FlexContainer, Icon } from '@smartfolly/frontend.web-ui';
import { memo, useCallback } from 'react';
import { ItemContent, Virtuoso } from 'react-virtuoso';

import type { PieChartSlice } from '../../helpers';

const AssetRow = memo(function AssetRow({ group }: { group: PieChartSlice }) {
    // Getters

    return (
        <FlexContainer
            className="p-t-0.5 p-b-0.5 grid-bordered__item"
            direction="row"
            align="center"
            justify="space-between"
        >
            <Flex>
                {group.token ? (
                    <div className="icon small">
                        <img src={group.token.icon} alt={group.token.name} />
                    </div>
                ) : (
                    <div className="icon small">
                        <Icon icon="default-token" />
                    </div>
                )}
            </Flex>
            <Flex grow={1} className="me-1 text-nowrap text-truncate">
                <div className="action action-default">{group.name}</div>
            </Flex>
            <Flex className="text-nowrap color-text-secondary">{group.portion}</Flex>
        </FlexContainer>
    );
});

export const PieChartTokensList = memo(function PieChartTokensList({
    list,
}: {
    list: PieChartSlice[];
}) {
    const renderAsset: ItemContent<PieChartSlice, unknown> = useCallback(
        (_index, group) => <AssetRow group={group} />,
        [],
    );

    return (
        <FlexContainer direction="column" align="stretch" justify="center">
            <Flex className="m-b-0.5">
                <FlexContainer direction="row" align="center" justify="space-between">
                    <Flex grow={1}>
                        <div className="title title-normal">Tokens</div>
                    </Flex>
                </FlexContainer>
            </Flex>
            <Flex className="m-t-1">
                <Virtuoso
                    className="scrollable grid-bordered grid-bordered--no-last-border"
                    useWindowScroll
                    data={list}
                    fixedItemHeight={40}
                    itemContent={renderAsset}
                />
            </Flex>
        </FlexContainer>
    );
});
