import { Md5 } from 'ts-md5';

import type { ExchangeSourceData } from '../types';

/**
 * Calculate an immutable ID for the exchange source.
 * Note: it should not change for the specified parameters.
 * @param data - a given exchange source data.
 * @param userId - a user ID this exchange source belongs to.
 * @returns a unique stable ID for the exchange source and the user.
 */
export async function calculateSourceIdForExchange(data: ExchangeSourceData, userId: string) {
    // Build a unique stable source ID as the MD5 of some exchange source unchangeable properties
    // starting with the exchange ID itself and its user keys to work with the API
    let exchangeProperties = `${data.exchange}:${data.keys.apiKey}:${data.keys.secretKey}`;

    // Check if the exchange source has a passphrase
    if (data.keys.passphrase) {
        exchangeProperties += `:${data.keys.passphrase}`;
    }

    // Append the user ID property
    exchangeProperties += `:${userId}`;

    // Return the exchange source properties hash string as a unique stable ID
    return Md5.hashStr(exchangeProperties);
}
