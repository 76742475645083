import * as amplitude from '@amplitude/analytics-browser';
import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useState } from 'react';
import { ItemContent, Virtuoso } from 'react-virtuoso';

import { Log } from '@smartfolly/common.utilities';

import type { BoardTemplates } from '@smartfolly/sdk';

import { Button, Flex, FlexContainer, Icon, Modal } from '@smartfolly/frontend.web-ui';

import { templateBoardNames } from '@smartfolly/frontend.templates-service';

import { usePossiblyNeedToShowReleaseNotes } from '../../hooks';

import { templatesService } from '../../services';

import { showToast } from '../../utils';

import styles from './Connect.module.scss';

const cnb = classnames.bind(styles);

const log = new Log('BoardTemplatesModal');

const TemplateItem = observer(function CurrencyItem({
    template,
    isSelected,
    onItemClicked,
}: {
    template: BoardTemplates;
    isSelected: boolean;
    onItemClicked: (template: BoardTemplates) => void;
}) {
    // Getters

    const icon = useMemo(() => {
        return isSelected ? <Icon icon="checkbox-on" /> : <Icon icon="checkbox-off" />;
    }, [isSelected]);

    // Actions

    const itemClicked = useCallback(() => {
        onItemClicked(template);
    }, [template, onItemClicked]);

    // Render

    return (
        <FlexContainer
            role="button"
            className={cnb('currency-item')}
            justify="space-between"
            align="center"
            onClick={itemClicked}
        >
            <Flex className="m-r-0.5">{icon}</Flex>
            <Flex grow={1} className="action action-normal widget-text-primary">
                {templateBoardNames[template]}
            </Flex>
            <Flex className={cnb('btn-check-wrapper')}>
                <Button className={`${cnb('btn-check')} bg-transparent`} />
            </Flex>
        </FlexContainer>
    );
});

export const BoardTemplatesModal = observer(function BoardTemplatesModal() {
    // States

    const {
        addBoardWithTemplate,
        readyToAddTemplateKinds,
        readyToShowTemplatesToAdd,
        markTemplatesToAddAsShown,
    } = templatesService;

    const [selectedTemplates, setSelectedTemplates] = useState<BoardTemplates[]>([]);

    const [totalHeight, setTotalHeight] = useState<number>(0);

    // Getters

    const possiblyNeedToShowReleaseNotes = usePossiblyNeedToShowReleaseNotes();

    const listStyle = useMemo(
        () => ({
            height: totalHeight,
            maxHeight: 'calc(100vh - 260px)',
            minHeight: '56px',
        }),
        [totalHeight],
    );

    const templateBoards = useMemo(() => {
        if (!readyToAddTemplateKinds) {
            return [];
        }

        return Array.from(readyToAddTemplateKinds);
    }, [readyToAddTemplateKinds]);

    // Actions

    const addSelectedTemplates = useCallback(async () => {
        try {
            await Promise.all(selectedTemplates.map(template => addBoardWithTemplate(template)));

            selectedTemplates.forEach(template =>
                amplitude.logEvent('Created template board from modal', { template }),
            );

            showToast('Boards saved');
            markTemplatesToAddAsShown();
        } catch (error) {
            log.error('Failed to add/edit the board with error:', error);

            showToast('Something went wrong');
        }
    }, [addBoardWithTemplate, markTemplatesToAddAsShown, selectedTemplates]);

    const handleTotalListHeightChanged = useCallback((h: number) => setTotalHeight(h), []);

    const onItemClicked = useCallback(
        (item: BoardTemplates) => {
            if (selectedTemplates.includes(item)) {
                setSelectedTemplates(prev => prev.filter(i => i !== item));
            } else {
                setSelectedTemplates(prev => prev.concat(item));
            }
        },
        [selectedTemplates],
    );

    // Render

    const renderTemplateItem: ItemContent<BoardTemplates, unknown> = useCallback(
        (_index, template) => (
            <TemplateItem
                template={template}
                isSelected={selectedTemplates.includes(template)}
                onItemClicked={onItemClicked}
            />
        ),
        [onItemClicked, selectedTemplates],
    );

    // Show the templates to add only in case we definitely don't need to show the releases notes
    if (possiblyNeedToShowReleaseNotes) {
        return null;
    }

    // Show the templates to add only if ready and there are templates to add
    if (!readyToShowTemplatesToAdd || !templateBoards.length) {
        return null;
    }

    return (
        <Modal
            className={cnb('template-modal')}
            show
            onHide={markTemplatesToAddAsShown}
            header={
                <FlexContainer justify="space-between" align="center">
                    <Flex className="title title-large widget-text-primary">
                        Ready to use boards
                    </Flex>
                </FlexContainer>
            }
        >
            <FlexContainer direction="column" justify="stretch" align="stretch">
                <Flex className="paragraph paragraph-small color-text-secondary m-b-1">
                    Choose one or more of the templates we have prepared for&nbsp;you to look at
                    your funds from different angles.
                </Flex>
                <Flex>
                    <div className={cnb('templates-grid')}>
                        <Virtuoso
                            style={listStyle}
                            data={templateBoards}
                            totalCount={templateBoards.length}
                            itemContent={renderTemplateItem}
                            fixedItemHeight={56}
                            totalListHeightChanged={handleTotalListHeightChanged}
                        />
                    </div>
                </Flex>
                <Flex>
                    <Button
                        className="btn btn-primary btn-large m-t-0.75 w-100 p-t-1 p-b-1 h-auto disabled:back-secondary"
                        disabled={!selectedTemplates.length}
                        onClick={addSelectedTemplates}
                    >
                        <div className="action action-normal">Add Boards</div>
                    </Button>
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
