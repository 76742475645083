import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';

import type { WalletGroup } from '@smartfolly/frontend.assets-service';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { usePluralize, usePercentClassName, useFormattedWallet } from '../../../hooks';

import { CryptoIcon } from '../../CryptoIcon';

import { InfoModal } from '../InfoModal';

import styles from '../Assets.module.scss';

const cnb = classnames.bind(styles);

export const WalletCell = observer(function WalletCell({
    group,
}: {
    /**
     * A wallet group.
     * Note: it's MobX observable.
     */
    group: WalletGroup;
}) {
    const { wallet, totalPrice, portion, assets } = group;

    // States

    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    // Getters
    const percentClassName = usePercentClassName(totalPrice.percentChange24h.value);

    const pluralizedTokens = usePluralize('token', assets.length);

    const formattedWallet = useFormattedWallet(wallet);

    // Actions

    const showModal = useCallback(() => setShowInfoModal(true), []);
    const hideModal = useCallback(() => setShowInfoModal(false), []);

    // Render

    return (
        <>
            {showInfoModal && <InfoModal group={group} hideModal={hideModal} />}
            <td role="presentation" onClick={showModal}>
                <FlexContainer direction="row" justify="start" align="stretch">
                    <Flex>
                        <div className={cnb('icon')}>
                            <CryptoIcon
                                icon={`${
                                    'blockchain' in wallet
                                        ? wallet.blockchain.id
                                        : wallet.exchange.id
                                }-wallet`}
                                defaultIcon="default-wallet"
                            />
                        </div>
                    </Flex>
                    <Flex className={cnb('info')}>
                        <FlexContainer direction="column" justify="space-between" align="stretch">
                            <Flex className="action action-normal">{formattedWallet}</Flex>
                            <Flex className="paragraph paragraph-small color-text-secondary">
                                {portion?.string}
                            </Flex>
                        </FlexContainer>
                    </Flex>
                </FlexContainer>
            </td>
            <td role="presentation" onClick={showModal}>
                <FlexContainer
                    className={cnb('balance')}
                    direction="column"
                    justify="start"
                    align="stretch"
                >
                    <Flex grow={1} className="paragraph paragraph-normal">
                        {totalPrice.change24h.string}
                    </Flex>
                    <Flex className={`paragraph paragraph-small ${percentClassName}`}>
                        <span>{totalPrice.percentChange24h.string}</span>
                    </Flex>
                </FlexContainer>
            </td>
            <td role="presentation" onClick={showModal}>
                <FlexContainer
                    className={cnb('balance')}
                    direction="column"
                    justify="start"
                    align="stretch"
                >
                    <Flex grow={1} className="paragraph paragraph-normal" title={totalPrice.string}>
                        {totalPrice.string}
                    </Flex>
                    <Flex className="paragraph paragraph-small color-text-secondary">
                        {pluralizedTokens}
                    </Flex>
                </FlexContainer>
            </td>
        </>
    );
});
