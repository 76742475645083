import { computed, makeObservable } from 'mobx';

import type { Board as SDKBoard } from '@smartfolly/sdk';

import {
    GroupedAssets,
    TokenGroup,
    WalletGroup,
    couldToggleHidingLowPrices,
    getTotalPrice,
    groupAssetsByTokens,
    groupAssetsByWallets,
} from '@smartfolly/frontend.assets-service';

import type { Board } from '../../types';

import type { ExtendedAssetsStore } from '../types';

type ObservableBoardOptions = {
    /**
     * A store with the assets data to enrich the board instance with.
     */
    assetsStore: ExtendedAssetsStore;
    /**
     * An optional template flag to indicate either the board is a template.
     */
    template?: Required<Board>['template'];
};

/**
 * Function to create a MobX observable board instance from the plain board data and known assets.
 * @param board - a plain board instance to enrich.
 * @param options - options to use to create an enriched observable board instance.
 * @returns an enriched observable board instance.
 */
export function enrichBoard(
    board: SDKBoard,
    { assetsStore, ...rest }: ObservableBoardOptions,
): Board {
    // Return the enriched observable boards
    return makeObservable<
        Board & {
            allTokenGroups: GroupedAssets<TokenGroup>;
            allWalletGroups: GroupedAssets<WalletGroup>;
        }
    >(
        {
            // Include the plain board properties
            ...board,
            // Include the "template" and rest properties of the board
            ...rest,
            // Set enriched selected assets
            get selectedAssets() {
                // Enrich the boards assets with the data from the `assetsService`
                return 'selectedAssets' in board
                    ? assetsStore.getAssetsForIDs(board.selectedAssets)
                    : assetsStore.getAssetsForFilters(board.filters);
            },
            // Set the flag either the board could toggle hiding assets with the low price
            get couldToggleHidingLowPrices() {
                return couldToggleHidingLowPrices(this.selectedAssets);
            },
            // Set enriched not selected assets if needed
            get notSelectedAssets() {
                return 'notSelectedAssets' in board && board.notSelectedAssets
                    ? assetsStore.getAssetsForIDs(board.notSelectedAssets)
                    : undefined;
            },
            // Set the total price of the board from the not filtered selected assets
            get totalPrice() {
                return getTotalPrice(
                    this.selectedAssets,
                    assetsStore.assetsService.selectedExchangeCurrency,
                );
            },
            // Set the list of historical total prices of the assets in the board
            get historicalTotalPrices() {
                return assetsStore.historicalTotalPricesForAssets(this.selectedAssets);
            },
            // Form a list of token groups (as well as possible hidden token groups)
            // by excluding assets with the low price if needed and sorting the resulted lists
            get allTokenGroups() {
                return assetsStore.filterGroupsExcludingAssetsWithLowPriceIfNeeded(
                    groupAssetsByTokens(this.selectedAssets),
                );
            },
            get tokenGroups() {
                return this.allTokenGroups.groups;
            },
            get hiddenTokenGroups() {
                return this.allTokenGroups.hiddenGroups;
            },
            // Form a list of wallet groups (as well as possible hidden wallet groups)
            // by excluding assets with the low price if needed and sorting the resulted lists
            get allWalletGroups() {
                return assetsStore.filterGroupsExcludingAssetsWithLowPriceIfNeeded(
                    groupAssetsByWallets(this.selectedAssets),
                );
            },
            get walletGroups() {
                return this.allWalletGroups.groups;
            },
            get hiddenWalletGroups() {
                return this.allWalletGroups.hiddenGroups;
            },
        },
        {
            selectedAssets: computed,
            notSelectedAssets: computed,
            totalPrice: computed,
            historicalTotalPrices: computed,
            allTokenGroups: computed,
            tokenGroups: computed,
            hiddenTokenGroups: computed,
            allWalletGroups: computed,
            walletGroups: computed,
            hiddenWalletGroups: computed,
        },
        {
            name: `Observable board: ${board.boardId}`,
        },
    );
}
