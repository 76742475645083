import type { Asset } from '../../../types';

/**
 * Function to get a link on the 1Inch swapper.
 */
export function get1InchLink(): string {
    return 'https://app.1inch.io';
}

/**
 * Function to get a link on the 1Inch swapper for an asset.
 * @param asset - asset to get a link for.
 */
export function get1InchLinkForAsset(asset: Asset): string | undefined {
    // Check if the asset has a blockchain to be swapped
    if (!('blockchain' in asset.wallet)) {
        return undefined;
    }

    return `https://app.1inch.io/#/1/simple/swap/${asset.token.symbol}`;
}
