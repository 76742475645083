import classnames from 'classnames/bind';

import styles from './Assets.module.scss';

const cnb = classnames.bind(styles);

export function FixedHeaderContent() {
    return (
        <tr className={cnb('table-header')}>
            <th className="title title-small w-50">Name</th>
            <th className="title title-small w-25 text-right">Profit/Loss (24H)</th>
            <th className="title title-small w-25 text-right">Value</th>
        </tr>
    );
}

export function FixedFooterContent() {
    return (
        <tfoot>
            <tr>
                <td colSpan={3}>
                    <div className="p-b-4" />
                </td>
            </tr>
        </tfoot>
    );
}
