import classnames from 'classnames/bind';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { Board } from '@smartfolly/frontend.boards-service';
import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import type { Token } from '@smartfolly/frontend.assets-service';
import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

export const BoardMiniCard = memo(function BoardMiniCard({
    board,
    hideModal,
}: {
    board: Board;
    hideModal: () => void;
}) {
    const navigate = useNavigate();
    const navigateToBoard = useCallback(() => {
        navigate(`/boards/${board.boardId}`);
        hideModal();
    }, [navigate, hideModal, board.boardId]);

    return (
        <Flex role="button" onClick={navigateToBoard} className={cnb('board-mini-card')}>
            <FlexContainer
                className="back-primary b-r-4 p-3 m-r-0.5"
                direction="column"
                justify="stretch"
                align="stretch"
            >
                <Flex className="title title-small m-b-0.5 text-nowrap">{board.name}</Flex>
                <Flex>
                    <FlexContainer
                        direction="row"
                        justify="stretch"
                        align="stretch"
                        className={cnb('icon-list')}
                    >
                        {board.tokenGroups.slice(0, 5).map(tokenGroup => (
                            <BoardAssetIcon
                                key={`${board.boardId}-${tokenGroup.token.id}`}
                                token={tokenGroup.token}
                            />
                        ))}
                    </FlexContainer>
                </Flex>
            </FlexContainer>
        </Flex>
    );
});

const BoardAssetIcon = memo(function BoardAssetIcon({ token }: { token: Token }) {
    return (
        <Flex className="icon small">
            <img src={token.icon} alt={token.name} />
        </Flex>
    );
});
