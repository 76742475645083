import { observer } from 'mobx-react';
import { memo, useMemo, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import Joyride, { CallBackProps, STATUS, TooltipRenderProps } from 'react-joyride';

import { Button, Flex, FlexContainer, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { usePossiblyNeedToShowTemplatesToAdd } from '../../hooks';

import { authService } from '../../services';

import { isOnboardingFinished, markOnboardingAsFinished } from '../../utils';

const StepContent = memo(function StepContent({
    title,
    content,
}: {
    title: string;
    content: string;
}) {
    return (
        <div>
            <div className="title title-normal m-b-0.5">{title}</div>
            <div className="paragraph paragraph-special">{content}</div>
        </div>
    );
});

const steps = [
    {
        target: '.onboarding-step-1',
        content: (
            <StepContent
                title="Explore your funds"
                content="Gain insight into your assets across various wallets and blockchains."
            />
        ),
        isFixed: true,
        disableBeacon: true,
    },
    {
        target: '.onboarding-step-2  > div > div:nth-child(1) button',
        content: (
            <StepContent
                title="Use personalized groups"
                content="Create boards using templates or  by hand and take informed decisions."
            />
        ),
        placement: 'right' as const,
        disableBeacon: true,
    },
    {
        target: '.onboarding-step-4',
        content: (
            <StepContent
                title="Add more wallets"
                content="Connect multiple accounts to unleash the full potential and see a big picture."
            />
        ),
        disableBeacon: true,
    },
    {
        target: '.onboarding-step-5',
        content: (
            <StepContent
                title="Choose display currency"
                content="Select and change the display of your funds at any time."
            />
        ),
        disableBeacon: true,
    },
    {
        target: '.onboarding-step-6',
        content: (
            <StepContent
                title="Move your coins around"
                content="Always at your fingertips: buying, selling, swapping or staking."
            />
        ),
        disableBeacon: true,
    },
    {
        target: '.onboarding-step-7',
        content: (
            <StepContent
                title="Keep in touch"
                content="Don't hesitate to reach out to us for any inquiries or features you need."
            />
        ),
        disableBeacon: true,
    },
];

const tooltipStyles = { width: '280px' };

const backIconLeft: IButtonIcon = {
    icon: <Icon icon="arrow-left-mini" />,
    animation: 'none',
};
const nextIconRight: IButtonIcon = {
    icon: <Icon icon="arrow-right-mini" />,
    animation: 'none',
};

const Tooltip = memo(function Tooltip({
    backProps,
    index,
    isLastStep,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
}: TooltipRenderProps) {
    return (
        <div {...tooltipProps} className="back-primary p-3 b-r-3" style={tooltipStyles}>
            {step.content}
            <FlexContainer
                className="m-t-0.75"
                direction="row"
                justify="space-between"
                align="center"
            >
                <Flex>
                    {!isLastStep && (
                        <a className="color-text-secondary hover:color-text-bw" {...skipProps}>
                            Skip
                        </a>
                    )}
                </Flex>
                <Flex>
                    {index > 0 && (
                        <Button
                            {...backProps}
                            className="m-r-0.25 bg-transparent color-text-secondary hover:color-text-bw"
                            iconLeft={backIconLeft}
                        />
                    )}
                    {isLastStep ? (
                        <Button {...primaryProps} className="btn-primary">
                            Great, let’s go
                        </Button>
                    ) : (
                        <Button {...primaryProps} className="btn-primary" iconRight={nextIconRight}>
                            Next
                        </Button>
                    )}
                </Flex>
            </FlexContainer>
        </div>
    );
});

const styles = {
    options: {
        arrowColor: 'transparent',
    },
    spotlight: {
        borderRadius: '12px',
    },
};

export const Onboarding = observer(function Onboarding() {
    // Getters

    const { session } = authService;

    const isFinished = useMemo(isOnboardingFinished, []);

    const possiblyNeedToShowTemplatesToAdd = usePossiblyNeedToShowTemplatesToAdd();

    // Events

    const handleJoyrideCallback = useCallback((data: CallBackProps) => {
        const { status } = data;

        // Update body class to fix Safari's bug with blending background
        if (STATUS.RUNNING === status) {
            document.body.classList.add('onboarding-open');
        } else {
            document.body.classList.remove('onboarding-open');
        }

        if (STATUS.FINISHED === status || STATUS.SKIPPED === status) {
            markOnboardingAsFinished();
        }
    }, []);

    // Render

    // Show the onboarding only in case we definitely don't need to show the templates to add
    if (possiblyNeedToShowTemplatesToAdd) {
        return null;
    }

    // Show the onboarding only on if authorized, on desktop and it wasn't shown before
    if (isFinished || isMobile || session === undefined || session === null) {
        return null;
    }

    return (
        <Joyride
            run
            continuous
            hideCloseButton
            scrollToFirstStep
            showProgress
            showSkipButton
            disableScrolling
            steps={steps}
            callback={handleJoyrideCallback}
            tooltipComponent={Tooltip}
            styles={styles}
        />
    );
});
