import type { IAuthService } from '@smartfolly/frontend.auth-service';

import type { IUserService } from '../types';

import { UserService } from './UserService';

/**
 * Create the UserService instance which should work with the provided "AuthService" instance.
 * Note: such service should be able to deal with the data in "Write" mode (i.e. change data).
 * @returns an instance of the UserService.
 */
export function createWithAuthService(authService: IAuthService): IUserService {
    return new UserService({ authService });
}

/**
 * Create the AssetsService instance which should work with the provided "userId".
 * Note: such service should be able to deal with the data in "Read" mode (i.e. only view data).
 * @returns an instance of the UserService.
 */
export function createWithUserId(userId: string): IUserService {
    return new UserService({ userId });
}
