import { memo } from 'react';

import classNames from 'classnames/bind';

import styles from './Backdrop.module.scss';

const cnb = classNames.bind(styles);

export const Backdrop = memo(function Backdrop({ children }: { children: React.ReactNode }) {
    return <div className={cnb('backdrop')}>{children}</div>;
});
