import { serverConnector } from '@smartfolly/middleware.server-connector';

import type {
    LoginRequestResponse,
    ResponseError,
    SimpleLoginRequestParameters,
} from '@smartfolly/server';

import { AuthUserRequestPath } from '../constants';

/**
 * A request to login and obtain the anonymous session.
 */
export async function authSimpleLogin(): Promise<LoginRequestResponse> {
    // Send unauthorized "login" request to obtain the session info
    const loginResponse = await serverConnector.sendPOSTRequest<
        SimpleLoginRequestParameters,
        LoginRequestResponse
    >({
        params: {
            permanent: true, // For MVP we decided to create permanent sessions
        },
        path: AuthUserRequestPath.SimpleLogin,
    });

    // Check for errors in response
    if ('error' in loginResponse) {
        const { error } = loginResponse as ResponseError;
        // TODO: process `errorCode` from the response if needed
        throw new Error(error);
    }

    return loginResponse;
}
