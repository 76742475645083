/* eslint-disable max-lines */
import * as amplitude from '@amplitude/analytics-browser';
import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, InputGroup } from 'react-bootstrap';
import {
    Components,
    GroupContent,
    GroupItemContent,
    GroupedVirtuoso,
    VirtuosoHandle,
} from 'react-virtuoso';

import type { Exchanges } from '@smartfolly/common.exchanges';

import {
    Asset,
    TokenGroup,
    WalletGroup,
    exportAssetsToCSV,
    lookupAssetsInGroups,
} from '@smartfolly/frontend.assets-service';
import type { Board, IBoardsService } from '@smartfolly/frontend.boards-service';

import {
    Button,
    CustomToggle,
    Flex,
    FlexContainer,
    IButtonIcon,
    Icon,
} from '@smartfolly/frontend.web-ui';

import {
    useCumulativeProfitLossChartData,
    useDailyProfitLossChartData,
    useFormattedWallet,
    usePerformanceChartData,
    useTrendingGroups,
    useWorthChartData,
} from '../../hooks';

import { downloadStringAsFile, showToast } from '../../utils';

import {
    ClearableInput,
    CumulativeProfitLossGraph,
    DailyProfitLoss,
    GroupHeader,
    PerformanceGraph,
    PieGraph,
    Tooltip,
    Trending,
    WorthGraph,
} from '../Common';

import { AddWalletModal } from '../Connect';

import { BoardAsset } from './BoardAsset';
import { BoardComponentHeader } from './BoardComponentHeader';
import { BoardEmptyPlaceholder } from './BoardEmptyPlaceholder';

import { assetsService } from '../../services';
import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

type ContextType = {
    board: Board;
    boardsService: IBoardsService;
    searchQuery: string;
    clearSearch: () => void;
};

const BoardEmpty = observer(function BoardEmpty({ context }: { context?: ContextType }) {
    const {
        clearSearch = () => {
            /* do nothing */
        },
        searchQuery = '',
    } = context ?? {};

    if (searchQuery === '') {
        return null;
    }
    return <BoardEmptyPlaceholder onClearSearch={clearSearch} />;
});

const BoardFooter = observer(function BoardFooter({ context }: { context?: ContextType }) {
    const { board, boardsService, searchQuery } = context ?? {};

    const { toggleHidingLowPrices, areLowPricesHidden } = boardsService ?? {};

    // Check if can toggle hiding the assets with low prices
    // Consider it false by default if the board is not passed via the context
    const couldToggleHidingLowPrices = board?.couldToggleHidingLowPrices ?? false;

    // Actions

    const toggleHidingLowPricesFn = useCallback(() => {
        toggleHidingLowPrices?.(!areLowPricesHidden);
    }, [toggleHidingLowPrices, areLowPricesHidden]);

    // Render

    return (
        <div className="p-t-0.75 p-b-0.75 p-l-0.5 p-r-0.5 p-b-4">
            {couldToggleHidingLowPrices && !searchQuery && (
                <Button
                    color="transparent"
                    className={`color-text-secondary hover:color-text-bw p-x-0 action action-normal ${cnb(
                        'hide-low-balances',
                    )}`}
                    onClick={toggleHidingLowPricesFn}
                >
                    {areLowPricesHidden ? (
                        <>
                            <Icon icon="eye-open-mini" /> Show small value
                        </>
                    ) : (
                        <>
                            <Icon icon="eye-close-mini" /> Hide small value
                        </>
                    )}
                </Button>
            )}
        </div>
    );
});

const addPlusIcon: IButtonIcon = {
    icon: <Icon icon="add-plus" className="m-r-0.5" />,
    animation: 'none',
};

export const NothingToDisplay = memo(function NothingToDisplay() {
    // States

    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    // Actions

    const showAddModalFn = useCallback(() => {
        setShowAddModal(true);
    }, []);

    const hideAddModalFn = useCallback(() => {
        setShowAddModal(false);
    }, []);

    const onAddressAdded = useCallback(
        (result: boolean, error?: Error) => {
            if (!error) {
                hideAddModalFn();

                if (result) {
                    showToast('Address was added');
                } else {
                    showToast('No assets found');
                }
            } else {
                // Let user retry adding an address in case of an error,
                // hereby don't close the add wallet modal
            }
        },
        [hideAddModalFn],
    );

    const onExchangeAdded = useCallback(
        (_exchange: Exchanges, result: boolean, error?: Error) => {
            if (!error) {
                hideAddModalFn();

                if (result) {
                    showToast('Exchange was added');
                } else {
                    showToast('No assets found');
                }
            } else {
                // Let user retry adding an exchange in case of an error,
                // hereby don't close the add wallet modal
            }
        },
        [hideAddModalFn],
    );

    // Render

    return (
        <>
            <FlexContainer
                className="p-t-4 p-b-2 p-l-2 p-r-2"
                direction="column"
                justify="center"
                align="center"
            >
                <Flex className="action action-normal">
                    <div className={cnb('icon', 'quadric')}>
                        <Icon icon="default-token" />
                    </div>
                </Flex>
                <Flex className="p-t-1">Nothing to display</Flex>
                <Flex className="paragraph paragraph-small color-text-secondary">
                    Try adding more wallets with assets
                </Flex>
                <Button onClick={showAddModalFn} className="m-t-1" iconLeft={addPlusIcon}>
                    Connect
                </Button>
            </FlexContainer>

            <AddWalletModal
                confirmed={showAddModal}
                onHide={hideAddModalFn}
                onAddressAddedViaMetaMaskExt={onAddressAdded}
                onAddressAddedViaSurfKeeper={onAddressAdded}
                onAddressAddedViaWalletConnect={onAddressAdded}
                onAddressAddedByOthers={onAddressAdded}
                onAddressAddedManually={onAddressAdded}
                onExchangeAdded={onExchangeAdded}
            />
        </>
    );
});

const listComponents: Components<Asset, ContextType> = {
    Footer: BoardFooter,
    TopItemList: GroupHeader,
    EmptyPlaceholder: BoardEmpty,
};

const onFocus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.target.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
    });
};

type SelectedGroup = TokenGroup | WalletGroup;

export const GridGroupHeader = memo(function GridGroupHeader({ group }: { group: SelectedGroup }) {
    const formattedWallet = useFormattedWallet('wallet' in group ? group.wallet : null);

    return (
        <FlexContainer
            direction="row"
            justify="start"
            align="center"
            className={cnb('group-header')}
        >
            <Flex grow={1} className="title title-small">
                {'token' in group && group.token.symbol}
                {formattedWallet}
                <span className="color-text-secondary m-l-0.5">{group.totalPrice.string}</span>
            </Flex>
        </FlexContainer>
    );
});

const downloadIcon: IButtonIcon = {
    icon: <Icon icon="download-mini" className="m-r-0" />,
    animation: 'none',
};

const groupByTooltipConfig = {
    followCursor: true,
    offset: [22, 22] as [number, number],
};

const downloadTooltipConfig = {
    followCursor: true,
    offset: [22, 22] as [number, number],
};

export const BoardComponent = observer(function BoardComponent({
    board,
    boardsService,
    onEditBoard,
    onRenameBoard,
}: {
    /**
     * A board instance.
     * Note: it's MobX observable.
     */
    board: Board;
    boardsService: IBoardsService;
    onEditBoard?: () => void;
    onRenameBoard?: () => void;
}) {
    // States

    const [searchQuery, setSearchQuery] = useState<string>('');

    const [sortBy, setSortBy] = useState<'value' | 'name'>('value');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    const [groupBy, setGroupBy] = useState<'tokens' | 'wallets'>('tokens');

    // Getters
    const {
        boardId,
        tokenGroups,
        hiddenTokenGroups,
        walletGroups,
        hiddenWalletGroups,
        totalPrice,
        historicalTotalPrices,
    } = board;

    const { historicalBTCPrices, historicalETHPrices } = assetsService;

    const groups: SelectedGroup[] = useMemo(() => {
        if (groupBy === 'wallets') {
            return walletGroups;
        }
        return tokenGroups;
    }, [groupBy, tokenGroups, walletGroups]);

    const hiddenGroups: SelectedGroup[] | undefined = useMemo(() => {
        if (groupBy === 'wallets') {
            return hiddenWalletGroups;
        }
        return hiddenTokenGroups;
    }, [groupBy, hiddenTokenGroups, hiddenWalletGroups]);

    const allFilteredGroups = useMemo(() => {
        if (searchQuery) {
            return lookupAssetsInGroups(
                searchQuery,
                groups.concat(hiddenGroups ?? []),
                true, // include hidden assets
            );
        }
        return groups;
    }, [searchQuery, groups, hiddenGroups]);

    const allFilteredAndSortedGroups = useMemo<SelectedGroup[]>(() => {
        if (sortBy === 'value' && sortDirection === 'asc') {
            return allFilteredGroups
                .slice()
                .reverse()
                .map(g => ({ ...g, assets: g.assets.slice().reverse() }));
        }
        if (sortBy === 'name') {
            if (sortDirection === 'asc') {
                return allFilteredGroups
                    .slice()
                    .sort((a, b) => {
                        if ('wallet' in a && 'wallet' in b) {
                            const aWalletName =
                                'address' in a.wallet
                                    ? a.wallet.addressData?.info?.name ?? a.wallet.address
                                    : a.wallet.exchangeData?.info?.name ?? a.wallet.exchange.name;
                            const bWalletName =
                                'address' in b.wallet
                                    ? b.wallet.addressData?.info?.name ?? b.wallet.address
                                    : b.wallet.exchangeData?.info?.name ?? b.wallet.exchange.name;
                            return bWalletName.localeCompare(aWalletName);
                        }
                        if ('token' in a && 'token' in b) {
                            return b.token.symbol.localeCompare(a.token.symbol);
                        }
                        return 0;
                    })
                    .map(g => ({
                        ...g,
                        assets: g.assets
                            .slice()
                            .sort((a, b) => a.token.symbol.localeCompare(b.token.symbol)),
                    }));
            }
            return allFilteredGroups
                .slice()
                .sort((a, b) => {
                    if ('wallet' in a && 'wallet' in b) {
                        const aWalletName =
                            'address' in a.wallet
                                ? a.wallet.addressData?.info?.name ?? a.wallet.address
                                : a.wallet.exchangeData?.info?.name ?? a.wallet.exchange.name;
                        const bWalletName =
                            'address' in b.wallet
                                ? b.wallet.addressData?.info?.name ?? b.wallet.address
                                : b.wallet.exchangeData?.info?.name ?? b.wallet.exchange.name;
                        return aWalletName.localeCompare(bWalletName);
                    }
                    if ('token' in a && 'token' in b) {
                        return a.token.symbol.localeCompare(b.token.symbol);
                    }
                    return 0;
                })
                .map(g => ({
                    ...g,
                    assets: g.assets
                        .slice()
                        .sort((a, b) => b.token.symbol.localeCompare(a.token.symbol)),
                }));
        }
        return allFilteredGroups;
    }, [allFilteredGroups, sortBy, sortDirection]);

    // Refs

    const virtuoso = useRef<VirtuosoHandle>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    // Getters

    const worthChartData = useWorthChartData(historicalTotalPrices);
    const performanceChartData = usePerformanceChartData(
        historicalTotalPrices,
        historicalBTCPrices,
        historicalETHPrices,
    );
    const dailyProfitLoss = useDailyProfitLossChartData(historicalTotalPrices);
    const cumulativeProfitLossChartData = useCumulativeProfitLossChartData(historicalTotalPrices);

    // Build the context
    const clearSearch = useCallback(() => {
        setSearchQuery('');
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    }, []);

    const context = useMemo<ContextType>(
        () => ({
            board,
            boardsService,
            clearSearch,
            searchQuery,
        }),
        [board, boardsService, clearSearch, searchQuery],
    );

    const { groupCounts, flatAssets } = useMemo(
        () =>
            allFilteredAndSortedGroups.reduce<{
                groupCounts: number[];
                flatAssets: Asset[];
            }>(
                (acc, { assets }) => {
                    acc.groupCounts.push(assets.length);
                    acc.flatAssets.push(...assets);
                    return acc;
                },
                { groupCounts: [], flatAssets: [] },
            ),
        [allFilteredAndSortedGroups],
    );

    // Hooks

    // Clear search on board navigation
    useEffect(() => {
        return () => clearSearch();
    }, [boardId, clearSearch]);

    // Get all token groups (including hidden)
    const allTokenGroups = useMemo(
        () => tokenGroups.concat(hiddenTokenGroups ?? []),
        [hiddenTokenGroups, tokenGroups],
    );

    // Find trending groups
    const trending = useTrendingGroups(allTokenGroups);

    // Actions

    const scrollToGroup = useCallback(
        (token: TokenGroup) => {
            if (virtuoso.current) {
                const idx = flatAssets.findIndex(t => t.token.id === token.token.id);
                virtuoso.current.scrollToIndex({
                    index: idx,
                    align: 'start',
                    behavior: 'auto',
                });
            }
        },
        [virtuoso, flatAssets],
    );

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setSearchQuery(event.target.value);
        },
        [setSearchQuery],
    );

    const onKeyUp = useCallback(
        async (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                const target = event.target as HTMLTextAreaElement;
                setSearchQuery(target.value);
            }
            event.stopPropagation();
        },
        [setSearchQuery],
    );

    const setupSortBy = useCallback(
        (column: 'value' | 'name') => {
            if (sortBy === column) {
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            } else {
                setSortBy(column);
                setSortDirection('desc');
            }
        },
        [sortBy, sortDirection],
    );

    const sortByValue = useCallback(() => {
        setupSortBy('value');
    }, [setupSortBy]);

    const sortByName = useCallback(() => {
        setupSortBy('name');
    }, [setupSortBy]);

    const setGroupByTokens = useCallback(() => {
        setGroupBy('tokens');
    }, []);

    const setGroupByWallets = useCallback(() => {
        setGroupBy('wallets');
    }, []);

    const downloadBalances = useCallback(() => {
        const csvString = exportAssetsToCSV(board.selectedAssets);
        const boardName = board.name.replace(/ /g, '-');
        const filename = `Portfolio-${boardName}-${new Date().toISOString()}.csv`;
        downloadStringAsFile(csvString, filename);
        amplitude.logEvent('Balance downloaded');
    }, [board.selectedAssets, board.name]);

    // Render

    const renderGroup: GroupContent = useCallback(
        index => {
            const group = allFilteredAndSortedGroups[index]!;
            return <GridGroupHeader group={group} />;
        },
        [allFilteredAndSortedGroups],
    );

    const renderAsset: GroupItemContent<Asset, ContextType> = useCallback(
        (index, groupIndex, _data, { boardsService: assetBoardsService }) => {
            const group = allFilteredAndSortedGroups[groupIndex]!;
            const asset = flatAssets[index]!;
            return <BoardAsset asset={asset} group={group} boardsService={assetBoardsService} />;
        },
        [allFilteredAndSortedGroups, flatAssets],
    );

    return (
        <>
            <BoardComponentHeader
                board={board}
                boardsService={boardsService}
                {...(onEditBoard ? { onEditBoard } : {})}
                {...(onRenameBoard ? { onRenameBoard } : {})}
            />
            {((groupCounts && groupCounts.length > 0) || !!searchQuery) && (
                <>
                    <FlexContainer
                        className={`mt-4 ${cnb('graph-and-trending')}`}
                        direction="row"
                        align="stretch"
                        justify="space-between"
                    >
                        <Flex className={cnb('graph')}>
                            <FlexContainer
                                className="back-primary p-3 b-r-3"
                                direction="column"
                                align="stretch"
                                justify="space-between"
                            >
                                <Flex>
                                    <PieGraph
                                        allTokenGroups={allTokenGroups}
                                        totalPrice={totalPrice}
                                    />
                                </Flex>
                            </FlexContainer>
                        </Flex>
                        <Flex className={cnb('trending')}>
                            <FlexContainer
                                className="back-primary p-3 b-r-3"
                                direction="column"
                                align="stretch"
                                justify="space-between"
                            >
                                <Flex>
                                    <Trending trending={trending} onItemClicked={scrollToGroup} />
                                </Flex>
                            </FlexContainer>
                        </Flex>
                    </FlexContainer>

                    <FlexContainer
                        className={`mt-4 ${cnb('graph-and-trending')}`}
                        direction="row"
                        align="stretch"
                        justify="space-between"
                    >
                        <Flex className={cnb('graph')}>
                            <FlexContainer
                                className="back-primary p-3 b-r-3"
                                direction="column"
                                align="stretch"
                                justify="space-between"
                            >
                                <Flex>
                                    <WorthGraph data={worthChartData} />
                                </Flex>
                            </FlexContainer>
                        </Flex>
                        <Flex className={cnb('trending')}>
                            <FlexContainer
                                className="back-primary p-3 b-r-3"
                                direction="column"
                                align="stretch"
                                justify="space-between"
                            >
                                <Flex>
                                    <PerformanceGraph data={performanceChartData} />
                                </Flex>
                            </FlexContainer>
                        </Flex>
                    </FlexContainer>

                    <FlexContainer
                        className={`mt-4 ${cnb('graph-and-trending')}`}
                        direction="row"
                        align="stretch"
                        justify="space-between"
                    >
                        <Flex className={cnb('graph')}>
                            <FlexContainer
                                className="back-primary p-3 b-r-3"
                                direction="column"
                                align="stretch"
                                justify="space-between"
                            >
                                <Flex>
                                    <DailyProfitLoss data={dailyProfitLoss} />
                                </Flex>
                            </FlexContainer>
                        </Flex>
                        <Flex className={cnb('trending')}>
                            <FlexContainer
                                className="back-primary p-3 b-r-3"
                                direction="column"
                                align="stretch"
                                justify="space-between"
                            >
                                <Flex>
                                    <CumulativeProfitLossGraph
                                        data={cumulativeProfitLossChartData}
                                    />
                                </Flex>
                            </FlexContainer>
                        </Flex>
                    </FlexContainer>

                    <FlexContainer className="m-t-3" align="center">
                        <Flex className="title title-large">Assets</Flex>
                        <Flex className="m-l-1">
                            <Tooltip content="Group the assets" config={groupByTooltipConfig}>
                                <Dropdown className={cnb('group-by')}>
                                    <Dropdown.Toggle
                                        as={CustomToggle}
                                        icon="dropdown"
                                        variant="transparent"
                                        className="color-text-bw b-r-3 py-1 p-l-0.75"
                                    >
                                        <span className="action action-special lh-1.6">
                                            by {groupBy}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="custom-dropdown-menu">
                                        <Dropdown.Item
                                            className="action action-special"
                                            onClick={setGroupByTokens}
                                        >
                                            <span className="d-flex align-items-center">
                                                <span className="flex-grow-1">by tokens</span>
                                                {groupBy === 'tokens' && (
                                                    <span>
                                                        <Icon icon="check-mini" />
                                                    </span>
                                                )}
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="action action-special"
                                            onClick={setGroupByWallets}
                                        >
                                            <span className="d-flex align-items-center">
                                                <span className="flex-grow-1">by wallets</span>
                                                {groupBy === 'wallets' && (
                                                    <span>
                                                        <Icon icon="check-mini" />
                                                    </span>
                                                )}
                                            </span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Tooltip>
                        </Flex>
                        <Flex grow={1} className="text-right">
                            <Tooltip content="Download .CSV" config={downloadTooltipConfig}>
                                <Button
                                    onClick={downloadBalances}
                                    iconLeft={downloadIcon}
                                    className="bg-transparent color-text-secondary hover:color-text-bw p-r-0 box-shadow-none"
                                >
                                    <span className="action action-special">Balances</span>
                                </Button>
                            </Tooltip>
                        </Flex>
                    </FlexContainer>

                    <FlexContainer
                        justify="space-between"
                        align="start"
                        direction="column"
                        className="m-t-2 m-b-1"
                    >
                        <ClearableInput
                            className="back-secondary color-text-bw border-secondary p-b-0.5 p-t-0.5 p-l-0.5 p-r-0.5"
                            btnClassName="back-secondary p-l-1.5 p-r-1.5 p-b-0.25 p-t-0.25 h-auto"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={onChange}
                            onKeyUp={onKeyUp}
                            onFocus={onFocus}
                            prepend={
                                <InputGroup.Text className="back-secondary border-secondary p-r-0.25">
                                    <Icon icon="search-mini" />
                                </InputGroup.Text>
                            }
                        />
                    </FlexContainer>
                    <FlexContainer
                        className={cnb('grid-header')}
                        direction="row"
                        justify="start"
                        align="center"
                    >
                        <Flex className="title title-small w-70 user-select-none">
                            <span
                                role="button"
                                tabIndex={0}
                                className={`hover:color-text-bw ${
                                    sortBy === 'name' && 'color-text-secondary'
                                }`}
                                onClick={sortByName}
                                onKeyDown={sortByName}
                            >
                                Name{sortBy === 'name' && (sortDirection === 'asc' ? ' ↑' : ' ↓')}
                            </span>
                        </Flex>
                        <Flex className="title title-small text-right w-30 user-select-none">
                            <span
                                role="button"
                                tabIndex={0}
                                className={`hover:color-text-bw ${
                                    sortBy === 'value' && 'color-text-secondary'
                                }`}
                                onClick={sortByValue}
                                onKeyDown={sortByValue}
                            >
                                Value{sortBy === 'value' && (sortDirection === 'asc' ? ' ↑' : ' ↓')}
                            </span>
                        </Flex>
                    </FlexContainer>
                    <GroupedVirtuoso<Asset, ContextType>
                        ref={virtuoso}
                        context={context}
                        className={`${cnb('grid-wrapper')} scrollable`}
                        useWindowScroll
                        groupCounts={groupCounts}
                        groupContent={renderGroup}
                        itemContent={renderAsset}
                        components={listComponents}
                    />
                </>
            )}
            {groupCounts && groupCounts.length === 0 && !searchQuery && <NothingToDisplay />}
        </>
    );
});
