import { Button, Flex, FlexContainer, Modal } from '@smartfolly/frontend.web-ui';
import classnames from 'classnames/bind';
import { memo, useState, useCallback } from 'react';

import styles from './Filters.module.scss';
import { FilterSelectItem, FilterSelector } from './FilterSelector';

const cnb = classnames.bind(styles);

export const FilterSelectorModal = memo(function FilterSelectorModal({
    onHide,
    type,
    items,
    selectedItems,
    changeFilters,
}: {
    onHide: () => void;
    type: 'tokens' | 'networks' | 'exchanges' | 'wallets';
    items: FilterSelectItem[];
    selectedItems: FilterSelectItem[] | undefined;
    changeFilters: (items: FilterSelectItem[]) => void;
}) {
    const [selected, setSelected] = useState<FilterSelectItem[]>(selectedItems ?? []);

    // Action
    const apply = useCallback(() => {
        changeFilters(selected);
        onHide();
    }, [changeFilters, onHide, selected]);

    // Render

    return (
        <Modal
            className={cnb('filter-selector-modal')}
            show
            onHide={onHide}
            header={<div className="text-center" />}
        >
            <FilterSelector
                type={type}
                items={items}
                selectedItems={selected}
                onSelect={setSelected}
            />
            <FlexContainer justify="stretch" align="center" className="m-t-1">
                <Flex grow={1}>
                    <Button
                        className="d-block w-100 p-t-0.5 p-b-0.5 rounded-3 h-auto btn-primary"
                        onClick={apply}
                    >
                        <div className="action action-normal">Apply</div>
                    </Button>
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
