import { logEvent } from '@amplitude/analytics-browser';
import classnames from 'classnames/bind';
import { memo, useCallback, useEffect, useState } from 'react';

import { Button, Flex, FlexContainer, Modal } from '@smartfolly/frontend.web-ui';

import { useLocation, useNavigate } from 'react-router-dom';

import { authService } from '../../../services';

import { useStore } from '../../../stores';

import versionFile from '../../../version.json';

import { GetProModal } from '../GetProModal';

import styles from '../Connect.module.scss';

import { GetProButton } from './GetProButton';
import { SessionLink } from './SessionLink';
import { SubscriptionsView } from './SubscriptionsView';

const cnb = classnames.bind(styles);

export const ProfileModal = memo(function ProfileModal({
    show,
    setShow,
}: {
    show: boolean;
    setShow: (confirmed: boolean) => void;
}) {
    // Stores

    const { logout } = authService;
    const {
        getProStore: { markAsShown },
    } = useStore();

    // States

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [showGetPro, setShowGetPro] = useState<boolean>(false);

    // Life-cycle

    useEffect(() => {
        if (pathname === '/pro') {
            setShowGetPro(true);
        }
    }, [pathname]);

    // Actions

    const onHide = useCallback(() => setShow(false), [setShow]);

    const showGetProModal = useCallback(() => {
        setShowGetPro(true);
        markAsShown();
        logEvent('Get Pro modal shown');
    }, [markAsShown]);

    const hideGetProModal = useCallback(() => {
        setShowGetPro(false);
        // In case if user is on the PRO page, redirect him to the home page to avoid modal re-opening on page refresh
        if (pathname === '/pro') {
            navigate('/overview');
        }
    }, [navigate, pathname]);

    // Render

    return (
        <>
            <Modal
                className={cnb('connect-modal')}
                show={show || showGetPro}
                onHide={onHide}
                header={
                    <FlexContainer justify="space-between" align="center">
                        <Flex className="title title-normal widget-text-primary">Profile</Flex>
                    </FlexContainer>
                }
            >
                <FlexContainer direction="column" justify="stretch" align="stretch">
                    <Flex>
                        <SubscriptionsView />
                    </Flex>
                    <Flex>
                        <SessionLink />
                    </Flex>
                    <Flex>
                        <GetProButton onShowGetProModal={showGetProModal} />
                    </Flex>
                    <Flex className={cnb('profile-modal-logout-wrapper')}>
                        <Button
                            className={`${cnb('profile-modal-logout-btn')} action action-normal`}
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Flex>
                    <Flex className="text-center paragraph paragraph-tiny color-text-secondary">
                        v. {versionFile.version}
                    </Flex>
                </FlexContainer>
            </Modal>
            {showGetPro && <GetProModal hide={hideGetProModal} />}
        </>
    );
});
