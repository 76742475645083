import BigNumber from 'bignumber.js';

import type { Group } from '../types';

/**
 * Function to sort the groups of assets by the total price value.
 * @param groups - the given groups of assets.
 * @returns the groups sorted by the total price value.
 */
export function sortGroupsByTotalPrice<T extends Group>(groups: T[]): T[] {
    return groups.sort((a, b) => {
        // Consider the total price of the group with an unknown value as zero
        const aGroupPrice = a.totalPrice.value ?? new BigNumber(0);
        const bGroupPrice = b.totalPrice.value ?? new BigNumber(0);

        // Sort the groups by the total price in the descending order
        return bGroupPrice.comparedTo(aGroupPrice);
    });
}
