import type { Asset } from '../types';

// import { areAllAssetsWithLowPrice } from './areAllAssetsWithLowPrice';
// import { hasAssetsWithLowPrice } from './hasAssetsWithLowPrice';
import { isPriceLow } from './isPriceLow';

/**
 * A function to find out either we could toggle hiding low prices for the assets.
 * Can be used to display the corresponding button on the frontend.
 * Note: we can toggle hiding the low prices only if there are some such assets present,
 * and not all of them have the low price since we are to show all of them in that case.
 * @param assets - a list of asset to check.
 * @returns the result of the check
 */
export function couldToggleHidingLowPrices(assets: Asset[]): boolean {
    // Straightforward way >>>
    // return hasAssetsWithLowPrice(assets) && !areAllAssetsWithLowPrice(assets);

    // OR make a check in a single run >>>
    const { hasAssetsWithLowPrice, areAllAssetsWithLowPrice } = assets.reduce<{
        hasAssetsWithLowPrice: boolean;
        areAllAssetsWithLowPrice: boolean;
    }>(
        (acc, asset) => {
            if (isPriceLow(asset.price)) {
                acc.hasAssetsWithLowPrice = true;
            } else {
                acc.areAllAssetsWithLowPrice = false;
            }
            return acc;
        },
        { hasAssetsWithLowPrice: false, areAllAssetsWithLowPrice: true },
    );

    return hasAssetsWithLowPrice && !areAllAssetsWithLowPrice;
}
