import { serverConnector } from '@smartfolly/middleware.server-connector';

import { userInfoSubscription, usersCollection } from '../../constants';

import type { UserInfo, UserInfoListener, UserInfoListenerDisposer } from '../../types';

/**
 * A function to subscribe to the user info changes.
 * @param userId - an ID of the user to subscribe to the info changes for.
 * @param listener - a user info listener which should resolve an updated user info.
 * @returns a user info listener disposer to stop the subscription.
 */
export function subscribeToUserInfo(
    userId: string,
    listener: UserInfoListener,
): UserInfoListenerDisposer {
    return serverConnector.subscribe<{ userId: string }, UserInfo>(
        {
            collection: usersCollection,
            query: userInfoSubscription,
            variables: {
                userId,
            },
        },
        listener,
    );
}
