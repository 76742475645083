/* eslint-disable max-lines */ // TODO: decompose the file
import { computed, IReactionDisposer, makeObservable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';
import { Md5 } from 'ts-md5';

import { CommonStore } from '@smartfolly/common.utilities';

import { calculateSourceIdForExchange, exchangesData } from '@smartfolly/common.exchanges';

import { AddressProvider } from '@smartfolly/sdk';
import type { IUserAuth } from '@smartfolly/sdk';

import type { IAuthService } from '@smartfolly/frontend.auth-service';
import { blockchainsData, currenciesService } from '@smartfolly/frontend.currencies-service';
import type { Tokens } from '@smartfolly/frontend.currencies-service';

import type { ProvidedExchangeSourceInfo } from '@smartfolly/server';
import {
    couldToggleHidingLowPrices,
    getAvailableBlockchains,
    getAvailableExchanges,
    getAvailableTokens,
    getAvailableWallets,
    getProvidedAddressesWithWallets,
    getProvidedExchangesWithWallets,
    getTotalPrice,
} from '../utils';

import type {
    AddAddressOptions,
    AddExchangesOptions,
    AddressInfo,
    Asset,
    AssetsMap,
    Blockchain,
    BlockchainGroup,
    Exchange,
    ExchangeCurrency,
    ExchangeGroup,
    FilteringOptions,
    GroupedAssets,
    HistoricalAssets,
    HistoricalPrice,
    IAssetsService,
    Price,
    ProvidedAddressWithWallets,
    ProvidedExchangeWithWallets,
    Token,
    TokenGroup,
    Wallet,
    WalletConnectAddAddressOptions,
    WalletGroup,
} from '../types';

import {
    AddressesStore,
    AssetsStore,
    ExchangesStore,
    FiltersStore,
    HistoricalStore,
    RatesStore,
} from './stores';

import type { BlockchainAssetWithAddressData, ExchangeAssetWithExchangeData } from './types';

type AssetsServiceOptions =
    | {
          /**
           * An instance of AuthService with the user to work with when dealing with the assets.
           * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
           */
          authService: IAuthService;
      }
    | {
          /**
           * An ID of the user to work with when dealing with the assets.
           */
          userId: string;
      };

export class AssetsService extends CommonStore implements IAssetsService {
    // Properties

    /**
     * Options the service is created with.
     */
    private options: AssetsServiceOptions;

    /**
     * A store responsible for the assets loading.
     */
    private assetsStore: AssetsStore;

    /**
     * A store responsible for the user addresses data.
     */
    private addressesStore: AddressesStore;

    /**
     * A store responsible for the user exchanges data.
     */
    private exchangesStore: ExchangesStore;

    /**
     * A store responsible for the assets filtering.
     */
    private filtersStore: FiltersStore;

    /**
     * A store responsible for the rates data.
     * Note: the store loads the rates for all user assets tokens
     * as well as the {@link Tokens.bitcoin} and {@link Tokens.ethereum} tokens,
     * even if they are missing for the user.
     */
    private ratesStore: RatesStore;

    /**
     * A store responsible for the historical data.
     * Note: the store loads the historical data for all user assets tokens
     * as well as the {@link Tokens.bitcoin} and {@link Tokens.ethereum} tokens,
     * even if they are missing for the user.
     */
    private historicalStore: HistoricalStore;

    /**
     * Disposer for tokens hash reaction
     */
    private ratesDataDisposer?: IReactionDisposer;

    // Constructor

    public constructor(options: AssetsServiceOptions) {
        super();

        this.options = options;

        this.assetsStore = new AssetsStore(options);
        this.addressesStore = new AddressesStore(options);
        this.exchangesStore = new ExchangesStore(options);
        this.filtersStore = new FiltersStore(options);
        this.ratesStore = new RatesStore(options);
        this.historicalStore = new HistoricalStore(options);

        makeObservable<AssetsService, 'listenedTokens' | 'listenedTokensHash'>(this, {
            assets: computed,
            assetsMap: computed,
            listenedTokens: computed,
            listenedTokensHash: computed,
            providedAddressesWithWallets: computed,
            allTotalPrice: computed,
            availableTokens: computed,
            availableBlockchains: computed,
            availableExchanges: computed,
            availableWallets: computed,
            historicalAssets: computed,
            historicalFilteredTotalPrices: computed,
            historicalBTCPrices: computed,
            historicalETHPrices: computed,
            isAddingAddress: computed,
            isEditingAddressInfo: computed,
            isRemovingAddress: computed,
            isRescanningAssets: computed,
            areLowPricesHidden: computed,
            couldToggleHidingLowPrices: computed,
            filteredTokens: computed,
            filteredBlockchains: computed,
            filteredExchanges: computed,
            filteredWallets: computed,
            filteredTotalPrice: computed,
            groupedTokens: computed,
            groupedBlockchains: computed,
            groupedExchanges: computed,
            groupedWallets: computed,
            selectedExchangeCurrency: computed,
            appliedFilters: computed,
            userId: computed,
        });
    }

    // Interface

    protected onLoad = async () => {
        // Ensure `authService` is initialized to work with if present
        if ('authService' in this.options && !this.options.authService.initialized) {
            throw new Error('AuthService instance is not initialized to load AssetsService');
        }

        // Load the rates data
        // Note: We start loading the store ASAP, since it only awaits the cached exchange currency.
        await this.ratesStore.load();
        // Load the rates data with the current tokens list to listen
        this.ratesStore.selectListenedTokens(this.listenedTokens);

        // Load the historical data
        // Note: We start loading the store ASAP, since it only awaits the cached exchange currency.
        await this.historicalStore.load();
        // Load the historical data with the current tokens list to listen
        this.historicalStore.selectListenedTokens(this.listenedTokens);

        // Try to load assets filters now in order to apply them before assets are loaded.
        await this.filtersStore.load();

        // Load the addresses & exchanges data
        await Promise.all([this.addressesStore.load(), this.exchangesStore.load()]);

        // Load the assets
        await (async () => {
            // Load the assets, but before, once rates & historical stores are loaded,
            // let's create a reaction to receive the events to update the rates data
            // for the specified tokens list, which checks for the asset tokens list changes
            // by listening to the tokens list hash
            // Note: the reaction should be created right before the `assetsStore` itself is loaded!
            this.ratesDataDisposer = reaction(
                () => this.listenedTokensHash,
                (listenedTokensHash, previousListenedTokensHash) => {
                    if (listenedTokensHash === previousListenedTokensHash) {
                        // No need to update the rates data if the hash remained the same
                        return;
                    }

                    // Update the rates data with the updated tokens list to listen
                    this.ratesStore.selectListenedTokens(this.listenedTokens);

                    // Update the historical data with the updated tokens list to listen
                    this.historicalStore.selectListenedTokens(this.listenedTokens);
                },
                {
                    name: 'a reaction to update the rates data by listening to the tokens list changes',
                },
            );

            // And now load the assets store itself
            await this.assetsStore.load();
        })();
    };

    protected onUnload = async () => {
        // First unload the assets
        await (async () => {
            // Unload the assets, but before let's dispose the rates data updater reaction
            // to stop receiving the events and avoid possible unwanted rates data reloading
            // Note: the reaction should be disposed before unloading `assets` and `rates` stores!
            if (this.ratesDataDisposer) {
                this.ratesDataDisposer();
                delete this.ratesDataDisposer;
            }

            // Now unload the assets store itself
            await this.assetsStore.unload();
        })();

        // Unload the addresses & exchanges data
        await Promise.all([this.addressesStore.unload(), this.exchangesStore.unload()]);

        // Then reset filters
        await this.filtersStore.unload();

        // Unload the historical data
        await this.historicalStore.unload();

        // And unload the rates data at last
        await this.ratesStore.unload();
    };

    public get userId(): string | undefined {
        // Check if the userId was passed independently or indirectly with the AuthService instance
        return 'userId' in this.options
            ? this.options.userId
            : this.options.authService.session?.userId;
    }

    public get userAuth(): IUserAuth | undefined {
        // Check if the AuthService instance was passed
        return 'authService' in this.options ? this.options.authService.userAuth : undefined;
    }

    public get assets(): Asset[] {
        return Object.values(this.assetsMap);
    }

    public get assetsMap(): AssetsMap {
        return this.ratesStore.enrichAssets(this.pureAssetsWithExtraData);
    }

    public get isLoadingAssets(): boolean {
        return this.assetsStore.isLoadingAssets;
    }

    public get providedAddressesWithWallets(): ProvidedAddressWithWallets[] {
        return getProvidedAddressesWithWallets(this.assets);
    }

    public get providedExchangesWithWallets(): ProvidedExchangeWithWallets[] {
        return getProvidedExchangesWithWallets(this.assets);
    }

    public get allTotalPrice(): Price {
        return getTotalPrice(this.assets, this.selectedExchangeCurrency);
    }

    public get availableExchangeCurrencies(): ExchangeCurrency[] {
        return this.ratesStore.availableExchangeCurrencies;
    }

    public get selectedExchangeCurrency(): ExchangeCurrency {
        return this.ratesStore.selectedExchangeCurrency;
    }

    public get availableTokens(): Token[] {
        return this.ratesStore.enrichTokens(getAvailableTokens(this.assetsStore.assets));
    }

    public get availableBlockchains(): Blockchain[] {
        return getAvailableBlockchains(this.assetsStore.assets).map(blockchain => ({
            id: blockchain,
            name: blockchainsData[blockchain].name,
            nativeToken: blockchainsData[blockchain].nativeToken,
        }));
    }

    public get availableExchanges(): Exchange[] {
        return getAvailableExchanges(this.assetsStore.assets).map(exchange => ({
            id: exchange,
            name: exchangesData[exchange].name,
        }));
    }

    public get availableWallets(): Wallet[] {
        // Get available wallets passing the pure assets to include an available address data
        return getAvailableWallets(this.pureAssetsWithExtraData);
    }

    public get filteredTokens(): GroupedAssets<TokenGroup> {
        return this.filtersStore.filterTokens(this.assets);
    }

    public get filteredBlockchains(): GroupedAssets<BlockchainGroup> {
        return this.filtersStore.filterBlockchains(this.assets);
    }

    public get filteredExchanges(): GroupedAssets<ExchangeGroup> {
        return this.filtersStore.filterExchanges(this.assets);
    }

    public get filteredWallets(): GroupedAssets<WalletGroup> {
        return this.filtersStore.filterWallets(this.assets);
    }

    public get filteredTotalPrice(): Price {
        return getTotalPrice(
            this.filtersStore.filterAssets(this.assets),
            this.selectedExchangeCurrency,
        );
    }

    public get groupedTokens(): GroupedAssets<TokenGroup> {
        return this.filtersStore.groupTokens(this.assets);
    }

    public get groupedBlockchains(): GroupedAssets<BlockchainGroup> {
        return this.filtersStore.groupBlockchains(this.assets);
    }

    public get groupedExchanges(): GroupedAssets<ExchangeGroup> {
        return this.filtersStore.groupExchanges(this.assets);
    }

    public get groupedWallets(): GroupedAssets<WalletGroup> {
        return this.filtersStore.groupWallets(this.assets);
    }

    public get historicalFilteredTotalPrices(): HistoricalPrice[] {
        const { historicalAssets, selectedExchangeCurrency } = this;

        // Convert the historical assets into the historical total prices list
        return (
            Object.keys(historicalAssets)
                // Sort the dates
                .sort() // it's OK since they are just a date part of the ISO string
                // Map the dates into the historical total prices list
                .map(date => {
                    // Get the historical assets for the given date
                    const assetsMap = historicalAssets[date]!;
                    // Filter them as per the applied filters
                    const filteredAssets = this.filtersStore.filterAssets(Object.values(assetsMap));
                    // Return the historical price for the filtered assets on a date
                    return {
                        date,
                        price: getTotalPrice(filteredAssets, selectedExchangeCurrency),
                    };
                })
                // Append the current date total price value
                .concat([
                    {
                        date: new Date().toISOString().split('T')[0]!,
                        price: this.filteredTotalPrice,
                    },
                ])
        );
    }

    public get historicalBTCPrices(): HistoricalPrice[] {
        return this.historicalPricesForToken(currenciesService.tokens.bitcoin);
    }

    public get historicalETHPrices(): HistoricalPrice[] {
        return this.historicalPricesForToken(currenciesService.tokens.ethereum);
    }

    public get isAddingAddress(): boolean {
        return this.assetsStore.isAddingAddress;
    }

    public get isEditingAddressInfo(): boolean {
        return this.addressesStore.isEditingAddressInfo;
    }

    public get isRemovingAddress(): boolean {
        return this.assetsStore.isRemovingAddress;
    }

    public get isAddingExchange(): boolean {
        return this.assetsStore.isAddingExchange;
    }

    public get isEditingExchangeInfo(): boolean {
        return this.exchangesStore.isEditingExchangeInfo;
    }

    public get isRemovingExchange(): boolean {
        return this.assetsStore.isRemovingExchange;
    }

    public get isRescanningAssets(): boolean {
        return this.assetsStore.isRescanningAssets;
    }

    public get areLowPricesHidden(): boolean {
        return this.filtersStore.areLowPricesHidden;
    }

    public get couldToggleHidingLowPrices(): boolean {
        return couldToggleHidingLowPrices(this.assets);
    }

    public addAddressManually = async (
        address: string,
        { info, prepareToAddAddresses }: AddAddressOptions = {},
    ): Promise<boolean> => {
        // Add the address with related assets
        return this.assetsStore.provideAddress({
            provider: AddressProvider.SimpleAddress,
            parameters: { address, ...(info ? { info } : {}) },
            prepareToAddAddresses: async () => {
                // First run the function to prepare to add addresses,
                // e.g. in order to authorize the user before adding them
                if (prepareToAddAddresses) {
                    await prepareToAddAddresses();
                }

                // Add the adding address data to the local store if the info is present
                if (info) {
                    this.addressesStore.addAddressData(address, {
                        provider: AddressProvider.SimpleAddress,
                        info,
                    });
                }
            },
        });
    };

    public addAddressViaMetamask = async ({
        info,
        prepareToAddAddresses,
    }: AddAddressOptions = {}): Promise<boolean> => {
        // Note: no need to add any address data to the local store before it's being provided here,
        // since we don't know an adding address yet
        return this.assetsStore.provideAddress({
            provider: AddressProvider.MetaMaskExt,
            parameters: { ...(info ? { info } : {}) },
            ...(prepareToAddAddresses ? { prepareToAddAddresses } : {}),
        });
    };

    public addAddressViaPhantom = async ({
        info,
        prepareToAddAddresses,
    }: AddAddressOptions = {}): Promise<boolean> => {
        // Note: no need to add any address data to the local store before it's being provided here,
        // since we don't know an adding address yet
        return this.assetsStore.provideAddress({
            provider: AddressProvider.Phantom,
            parameters: { ...(info ? { info } : {}) },
            ...(prepareToAddAddresses ? { prepareToAddAddresses } : {}),
        });
    };

    public addAddressViaSolflare = async ({
        info,
        prepareToAddAddresses,
    }: AddAddressOptions = {}): Promise<boolean> => {
        // Note: no need to add any address data to the local store before it's being provided here,
        // since we don't know an adding address yet
        return this.assetsStore.provideAddress({
            provider: AddressProvider.Solflare,
            parameters: { ...(info ? { info } : {}) },
            ...(prepareToAddAddresses ? { prepareToAddAddresses } : {}),
        });
    };

    public addAddressViaSurfKeeper = async ({
        info,
        prepareToAddAddresses,
    }: AddAddressOptions = {}): Promise<boolean> => {
        // Note: no need to add any address data to the local store before it's being provided here,
        // since we don't know an adding address yet
        return this.assetsStore.provideAddress({
            provider: AddressProvider.SurfKeeperExt,
            parameters: { ...(info ? { info } : {}) },
            ...(prepareToAddAddresses ? { prepareToAddAddresses } : {}),
        });
    };

    public addAddressViaTonConnect = async ({
        info,
        prepareToAddAddresses,
    }: AddAddressOptions = {}): Promise<boolean> => {
        // Note: no need to add any address data to the local store before it's being provided here,
        // since we don't know an adding address yet
        return this.assetsStore.provideAddress({
            provider: AddressProvider.TonConnect,
            parameters: { ...(info ? { info } : {}) },
            ...(prepareToAddAddresses ? { prepareToAddAddresses } : {}),
        });
    };

    public addAddressViaWalletConnect = async ({
        info,
        prepareToAddAddresses,
        walletIds,
    }: AddAddressOptions & WalletConnectAddAddressOptions = {}): Promise<boolean> => {
        // Note: no need to add any address data to the local store before it's being provided here,
        // since we don't know an adding address yet
        return this.assetsStore.provideAddress({
            provider: AddressProvider.WalletConnect,
            parameters: {
                ...(info ? { info } : {}),
                ...(walletIds ? { walletIds } : {}),
            },
            ...(prepareToAddAddresses ? { prepareToAddAddresses } : {}),
        });
    };

    public editAddressInfo = async (address: string, info: AddressInfo): Promise<boolean> => {
        return this.addressesStore.editAddressInfo(address, info);
    };

    public removeAddress = async (address: string): Promise<boolean> => {
        // First remove the address with related assets
        const removed = await this.assetsStore.removeAddress(address);

        // Then remove the local address data
        if (removed) {
            this.addressesStore.removeAddressData(address);
        }

        // Return the result of the operation
        return removed;
    };

    public addExchange = async ({
        exchange,
        parameters,
        prepareToAddExchange,
    }: AddExchangesOptions): Promise<boolean> => {
        // Add the exchange source with related assets
        return this.assetsStore.provideExchange({
            exchange,
            parameters,
            prepareToAddExchange: async () => {
                // First run the function to prepare to add an exchange,
                // e.g. in order to authorize the user before adding them
                if (prepareToAddExchange) {
                    await prepareToAddExchange();
                }

                // Check if the user is present to add an exchange
                const { userId } = this;
                if (!userId) {
                    throw new Error('No user to add an exchange');
                }

                // Add the adding exchange data to the local store if the info is present
                if (parameters.info) {
                    // Build a source ID
                    const sourceId = await calculateSourceIdForExchange(
                        { exchange, keys: parameters.keys },
                        userId,
                    );

                    // Add an exchange data
                    this.exchangesStore.addExchangeData(sourceId, {
                        exchange,
                        ...parameters,
                    });
                }
            },
        });
    };

    public editExchangeInfo = async (
        sourceId: string,
        info: ProvidedExchangeSourceInfo,
    ): Promise<boolean> => {
        return this.exchangesStore.editExchangeInfo(sourceId, info);
    };

    public removeExchange = async (sourceId: string): Promise<boolean> => {
        // First remove the exchange with related assets
        const removed = await this.assetsStore.removeExchange(sourceId);

        // Then remove the local exchange data
        if (removed) {
            this.exchangesStore.removeExchangeData(sourceId);
        }

        // Return the result of the operation
        return removed;
    };

    public rescanAssets = async (): Promise<boolean> => {
        return this.assetsStore.rescanAssets();
    };

    public toggleHidingLowPrices = async (hideLowPrices: boolean): Promise<void> => {
        return this.filtersStore.toggleHidingLowPrices(hideLowPrices);
    };

    public filter = async (options: FilteringOptions) => {
        return this.filtersStore.filter(options);
    };

    public get appliedFilters(): FilteringOptions {
        return this.filtersStore.appliedFilters;
    }

    public selectExchangeCurrency = async (exchangeCurrency: ExchangeCurrency) => {
        await Promise.all([
            this.ratesStore.selectExchangeCurrency(exchangeCurrency),
            this.historicalStore.selectExchangeCurrency(exchangeCurrency),
        ]);
    };

    public get historicalAssets(): HistoricalAssets {
        return this.historicalStore.enrichAssetsWithHistoricalData(this.pureAssetsWithExtraData);
    }

    // Internals

    /**
     * A MobX computed list of assets enriched with some extra data such as address & exchange data.
     */
    private get pureAssetsWithExtraData(): (
        | BlockchainAssetWithAddressData
        | ExchangeAssetWithExchangeData
    )[] {
        return this.exchangesStore.enrichAssets(
            this.addressesStore.enrichAssets(this.assetsStore.assets),
        );
    }

    /**
     * A sorted list of tokens to listen to, formed from the tokens included in all user assets.
     * Note: mandatorily includes the {@link Tokens.bitcoin} and {@link Tokens.ethereum} tokens,
     * even if they are missing in the user assets.
     */
    private get listenedTokens(): Tokens[] {
        return getAvailableTokens(this.assetsStore.assets, [
            currenciesService.tokens.bitcoin,
            currenciesService.tokens.ethereum,
        ]).sort();
    }

    /**
     * A hash built with the sorted list of listened tokens.
     * @see {@link AssetsService.listenedTokens}.
     */
    private get listenedTokensHash(): string {
        return Md5.hashStr(JSON.stringify(this.listenedTokens));
    }

    /**
     * A MobX computed function to get the historical prices for a specified token.
     * @param token - a token to get the available historical prices for.
     * @returns the list of historical prices for the given token if available (or an empty list).
     */
    private historicalPricesForToken = computedFn((token: Tokens): HistoricalPrice[] => {
        const { historicalStore, ratesStore } = this;

        // Get the historical tokens data
        const historicalTokensData = historicalStore.enrichTokenWithHistoricalData(token);

        // Convert the historical data for the token into the historical prices list
        return (
            Object.keys(historicalTokensData)
                // Sort the dates
                .sort() // it's OK since they are just a date part of the ISO string
                // Map the dates into the historical prices list
                .map(date => {
                    // Get the price for the given token
                    const { price } = historicalTokensData[date]!;
                    return { date, price };
                })
                // Append the current date token price value
                .concat([
                    {
                        date: new Date().toISOString().split('T')[0]!,
                        price: ratesStore.enrichTokens([token])[0]!.price,
                    },
                ])
        );
    });
}
