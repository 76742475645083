import type { SessionStorage } from '../../../../types';

import { SESSION_INFO_KEY } from '../constants';

type DeleteSessionInfoOptions = {
    /**
     * A storage to delete the session info from.
     */
    storage: SessionStorage;

    /**
     * An extra key to deal with several sessions.
     * Note: the key is appended as a suffix to the {@link SESSION_INFO_KEY} if defined.
     */
    key: string | undefined;
};

type DeleteSessionInfoResponse = {
    /**
     * A successful result of the removal operation.
     */
    result: true;
};

/**
 * A function to delete the session info in the storage.
 * @param options - contain the parameters to delete the session info.
 * @returns an object with the result of the removal operation.
 * The `result` should always be `true`, otherwise the function @throws an error.
 */
export async function deleteSessionInfoFromStorage({
    storage,
    key,
}: DeleteSessionInfoOptions): Promise<DeleteSessionInfoResponse> {
    let sessionInfoKey = SESSION_INFO_KEY;
    if (key) {
        sessionInfoKey += `:${key}`;
    }

    await storage.removeItem(sessionInfoKey);
    return { result: true };
}
