import type { Asset } from '../../types';

/**
 * Delete empty "blockchain" related properties from the if any.
 */
function deleteEmptyBlockchainPropertiesIfNeeded(asset: Asset): Asset {
    if ('blockchain' in asset) {
        if ('contract' in asset) {
            const { blockchain, address, contract, contractType, ...rest } = asset;

            if (blockchain == null) {
                if (address != null) {
                    // Should not happen normally!
                    throw new Error('Not empty address for missing blockchain');
                }

                if (contract != null) {
                    // Should not happen normally!
                    throw new Error('Not empty contract for missing blockchain');
                }

                if (contractType != null) {
                    // Should not happen normally!
                    throw new Error('Not empty contract type for missing blockchain');
                }

                return rest as Asset;
            }

            if (contract == null) {
                if (contractType != null) {
                    // Should not happen normally!
                    throw new Error('Not empty contract type for missing contract');
                }

                return { blockchain, address, ...rest } as Asset;
            }
        }

        const { blockchain, address, ...rest } = asset;

        if (blockchain == null) {
            if (address != null) {
                // Should not happen normally!
                throw new Error('Not empty address for missing blockchain');
            }

            return rest as Asset;
        }
    }

    return asset;
}

/**
 * Delete empty "exchange" related properties from the if any.
 */
function deleteEmptyExchangePropertiesIfNeeded(asset: Asset): Asset {
    if ('exchange' in asset) {
        const { exchange, sourceId, ...rest } = asset;

        if (exchange == null) {
            if (sourceId != null) {
                // Should not happen normally!
                throw new Error('Not empty sourceId for missing exchange');
            }

            return rest as Asset;
        }
    }

    return asset;
}

/**
 * GraphQL returns "blockchain", "exchange" and "contract[Type]" properties even if they're empty.
 * Delete them if any from the asset to properly deal with the assets of different types in TS.
 */
export function deleteEmptyGraphQLPropertiesIfNeeded(asset: Asset): Asset {
    // Delete "blockchain" and "exchange" related properties from the asset if any
    return deleteEmptyBlockchainPropertiesIfNeeded(deleteEmptyExchangePropertiesIfNeeded(asset));
}
