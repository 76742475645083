import { observer } from 'mobx-react';
import { useMemo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { Board } from '@smartfolly/frontend.boards-service';

import { BoardComponent, CustomBoardModal, RenameBoardModal } from '../components/Board';

import { boardsService } from '../services';

export const BoardPage = observer(function BoardPage() {
    const { featuredBoards, boards } = boardsService;

    const navigate = useNavigate();

    const { boardName } = useParams();
    const { hash } = window.location;

    // States

    const [showEditBoardModal, setShowEditBoardModal] = useState(false);
    const [showRenameBoardModal, setShowRenameBoardModal] = useState(hash === '#rename');

    // Getters

    const selectedBoard = useMemo<Board | undefined>(() => {
        // Check if the board name is provided by the parameters
        if (!boardName) {
            return undefined;
        }

        // Try to find the board in all available boards of the service knowing its name
        return featuredBoards
            .concat(boards)
            .find(
                board =>
                    board.template?.kind.toLowerCase() === boardName.toLowerCase() ||
                    board.boardId === boardName,
            );
    }, [boardName, boards, featuredBoards]);

    const shouldShowRenameToast = useMemo(() => hash !== '#rename', [hash]);

    // Life-cycle

    useEffect(() => {
        if (hash === '#rename') {
            setShowRenameBoardModal(true);
        }
    }, [hash]);

    useEffect(() => {
        // Navigate to Home if the board is not found
        if (!selectedBoard) {
            navigate('/');
        }
    }, [navigate, selectedBoard]);

    // Actions

    const showEditModal = useCallback(() => setShowEditBoardModal(true), []);
    const hideEditModal = useCallback(() => setShowEditBoardModal(false), []);

    const showRenameModal = useCallback(() => setShowRenameBoardModal(true), []);
    const hideRenameModal = useCallback(() => {
        if (hash === '#rename' && selectedBoard) {
            navigate(`/boards/${selectedBoard.boardId}`);
        }
        setShowRenameBoardModal(false);
    }, [hash, navigate, selectedBoard]);

    // Render

    return (
        <div className="main-content">
            {selectedBoard && (
                <>
                    <BoardComponent
                        board={selectedBoard}
                        boardsService={boardsService}
                        onEditBoard={showEditModal}
                        onRenameBoard={showRenameModal}
                    />
                    {showEditBoardModal && (
                        <CustomBoardModal board={selectedBoard} hideModal={hideEditModal} />
                    )}
                    {showRenameBoardModal && (
                        <RenameBoardModal
                            board={selectedBoard}
                            hideModal={hideRenameModal}
                            shouldShowToast={shouldShowRenameToast}
                        />
                    )}
                </>
            )}
        </div>
    );
});
