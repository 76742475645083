import { makeAutoObservable } from 'mobx';
import { authService } from '../services';

const GET_PRO_SHOWN_KEY = 'getProShown';

export interface IGetProModalStore {
    /**
     * A MobX-observable flag that the modal was shown.
     */
    readonly modalWasShown: boolean | undefined;

    /**
     * Method to mark the modal as being shown.
     */
    markAsShown(): Promise<void>;
}

type ModalShownType = { [userId: string]: boolean };

/**
 * Store to handle the Get Pro modal.
 */
export default class GetProStore implements IGetProModalStore {
    /**
     * A MobX-observable flag that the modal was shown.
     */
    private getProModalWasShown: ModalShownType = {};

    // Constructor
    public constructor() {
        makeAutoObservable(this);
    }

    public markAsShown = async (): Promise<void> => {
        // Check if the session is active
        if (!authService.session) {
            return;
        }

        // Calculate the session of the current user
        const key = authService.getStorageKey(GET_PRO_SHOWN_KEY);

        // Set the flag
        this.getProModalWasShown[authService.session.userId] = true;

        // Save to the local storage
        localStorage.setItem(key, JSON.stringify(true));
    };

    // Properties
    public get modalWasShown(): boolean | undefined {
        // Check if the session is active
        if (!authService.session) {
            return undefined;
        }

        // Calculate the session of the current user
        const key = authService.getStorageKey(GET_PRO_SHOWN_KEY);
        const { userId } = authService.session;

        // Check if the current state is known
        if (this.getProModalWasShown[userId] !== undefined) {
            return this.getProModalWasShown[userId];
        }

        // Get the value from the storage
        const shown = localStorage.getItem(key);

        // Set the flag for the user
        this.getProModalWasShown[userId] = shown ? JSON.parse(shown) : false;

        // Return the value
        return this.getProModalWasShown[userId];
    }
}
