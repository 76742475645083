import BigNumber from 'bignumber.js';
import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { Spinner } from 'react-bootstrap';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import { priceToString } from '@smartfolly/frontend.currencies-service';

import { assetsService } from '../../../services';

import { useAddingWallet } from '../contexts';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

export const AddingExchange = observer(function AddingExchange() {
    // Stores

    const { selectedExchangeCurrency } = assetsService;

    // States

    const { addingExchange } = useAddingWallet();

    // Render

    return (
        <>
            <Flex className={`${cnb('main-address')} title title-small color-text-secondary`}>
                Adding wallet
            </Flex>
            <Flex>
                <FlexContainer
                    direction="row"
                    justify="stretch"
                    align="stretch"
                    className={cnb('wallets-management-item')}
                >
                    <Flex className={cnb('icon')}>
                        <Spinner animation="border" className="spinner-icon" />
                    </Flex>
                    <Flex grow={1} className={cnb('info')}>
                        <FlexContainer direction="column" justify="space-between" align="stretch">
                            <Flex className="action action-normal">
                                {addingExchange || 'Adding exchange…'}
                            </Flex>
                            <Flex className="paragraph paragraph-small color-text-secondary">
                                Scanning&hellip;
                            </Flex>
                        </FlexContainer>
                    </Flex>
                    <Flex className="color-text-secondary">{`${priceToString(
                        new BigNumber(0),
                        selectedExchangeCurrency,
                    )}`}</Flex>
                </FlexContainer>
            </Flex>
        </>
    );
});
