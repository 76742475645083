import classNames from 'classnames/bind';
import { memo, useCallback, useState } from 'react';

import { Flex, FlexContainer, Icon, Modal } from '@smartfolly/frontend.web-ui';

import styles from '../Connect.module.scss';

const cnb = classNames.bind(styles);

export const UnauthorizedInfoModal = memo(function UnauthorizedInfoModal() {
    // States

    const [show, setShow] = useState<boolean>(true);

    // Actions

    const closeModal = useCallback(() => setShow(false), []);

    // Render

    return (
        <Modal show={show} className={cnb('info-modal', 'disclosure-modal')} backdrop={false}>
            <FlexContainer direction="row" justify="stretch" align="start">
                <Flex className="m-r-0.5">
                    <Icon icon="shield-mini" className="color-text-primary" />
                </Flex>
                <Flex className="paragraph paragraph-special color-text-primary">
                    <div>
                        We only request your public wallet address to read an open data and show an
                        information about your portfolio and analytics.
                    </div>
                    <div
                        className="action action-special m-t-0.75"
                        role="button"
                        onClick={closeModal}
                        tabIndex={0}
                        aria-hidden="true"
                    >
                        Great!
                    </div>
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
