import type { Blockchains } from '@smartfolly/frontend.currencies-service';

import type { Asset } from '@smartfolly/sdk';

/**
 * Make a list of all available blockchains from the given assets.
 * @param assets - a list of the assets to get the available blockchains for.
 * @returns the list of available blockchains.
 */
export function getAvailableBlockchains(assets: Asset[]): Blockchains[] {
    // Use Set to get the blockchains without duplicates fast
    return Array.from(
        assets.reduce((acc, asset) => {
            // Check if the asset has a blockchain data
            if ('blockchain' in asset) {
                // Put the blockchain into the Set
                acc.add(asset.blockchain);
            }
            return acc;
        }, new Set<Blockchains>()),
    );
}
