import classNames from 'classnames/bind';

import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import styles from './Feedback.module.scss';
import { Tooltip } from '../Common';

const cnb = classNames.bind(styles);

const navigateToDiscord = () => {
    window.open('https://discord.gg/dRJczHKmVB', '_blank', 'noreferrer')?.focus();
};

const iconLeft: IButtonIcon = {
    icon: <Icon icon="lighting-energy-mini" />,
    animation: 'none',
};

const discordIcon: IButtonIcon = {
    icon: <Icon icon="discord" />,
    animation: 'none',
};

const tooltipConfig = {
    offset: [-20, 6] as [number, number],
};

export const Feedback = function Feedback() {
    return (
        <div className="onboarding-step-7">
            <Tooltip content="Write to us">
                <Button
                    className={`${cnb('feedback-btn')} m-r-0.5`}
                    iconLeft={iconLeft}
                    data-tally-open="waOXaB"
                    data-tally-emoji-text="👋"
                    data-tally-emoji-animation="wave"
                    aria-label="Feedback"
                >
                    <span className="action action-special d-none d-sm-block">Feedback</span>
                </Button>
            </Tooltip>
            <Tooltip content="Join community" config={tooltipConfig}>
                <Button
                    className={cnb('discord-btn')}
                    iconLeft={discordIcon}
                    onClick={navigateToDiscord}
                    aria-label="Join community"
                />
            </Tooltip>
        </div>
    );
};
