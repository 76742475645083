import { encode } from 'url-safe-base64';

import type { StorageSessionInfo } from '../types';

/**
 * Function to create a serialized session string from the storage session info.
 * @returns an encoded URL-safe serialized session string.
 */
export async function serializeSession({
    session: {
        expire,
        secretKey,
        sessionKey,
        // Note: we don't serialize the `provider` property because we consider
        // the provider of the session to be defined as .SimpleAuth when deserializing.
    },
}: StorageSessionInfo): Promise<string> {
    // Create a buffer with `expire` + `secretKey` + `sessionKey` string
    const sessionBuff = Buffer.from([expire, secretKey, sessionKey].join(';'), 'utf8');

    // Present it as `base64`
    const sessionBase64 = sessionBuff.toString('base64');

    // Encode it as an URL-safe parameter
    return encode(sessionBase64);
}
