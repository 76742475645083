import { serverConnector } from '@smartfolly/middleware.server-connector';

import type {
    ResponseError,
    ShortenRequestParametersAuth,
    ShortenRequestResponse,
} from '@smartfolly/server';

import { AuthUserRequestPath } from '../constants';

/**
 * A request to shorten an auth info in order to be able sharing it (e.g. for the TelegramBot).
 */
export async function authShorten(
    payload: ShortenRequestParametersAuth['payload'],
): Promise<ShortenRequestResponse> {
    // Pass the "authId" and the "signature" to the "shorten" service
    const shortenResponse = await serverConnector.sendPOSTRequest<
        ShortenRequestParametersAuth,
        ShortenRequestResponse
    >({
        params: {
            payload,
        },
        path: AuthUserRequestPath.Shorten,
    });

    // Check for errors in response
    if ('error' in shortenResponse) {
        const { error } = shortenResponse as ResponseError;
        // TODO: process `errorCode` from the response if needed
        throw new Error(error);
    }

    return shortenResponse;
}
