import classnames from 'classnames/bind';
import { memo } from 'react';

import { Flex, FlexContainer, Modal } from '@smartfolly/frontend.web-ui';

import { EditingWalletsProvider } from '../contexts';

import styles from '../Connect.module.scss';

import { RemoveButton } from './RemoveButton';
import { EditWallets } from './EditWallets';

const cnb = classnames.bind(styles);

export const EditWalletsModal = memo(function EditWalletsModal({
    showModal,
    onHide,
}: {
    showModal: boolean;
    onHide: () => void;
}) {
    return (
        <Modal
            className={cnb('connect-modal')}
            show={showModal}
            onHide={onHide}
            header={
                <FlexContainer justify="space-between" align="center">
                    <Flex className="title title-large widget-text-primary">Edit</Flex>
                </FlexContainer>
            }
        >
            <FlexContainer direction="column" justify="stretch" align="stretch">
                <EditingWalletsProvider>
                    <EditWallets />

                    <Flex className="m-t-1">
                        <RemoveButton onHide={onHide} />
                    </Flex>
                </EditingWalletsProvider>
            </FlexContainer>
        </Modal>
    );
});
