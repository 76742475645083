import type { BoardFilters } from '@smartfolly/sdk';

import type { FilteringOptions } from '@smartfolly/frontend.assets-service';

/**
 * Function to convert the filtering options from the `AssetsService` into the filters of the board.
 * @param filteringOptions - the given filtering options.
 * @returns an object suitable for the filters of the board.
 * Note: we differ the `null` and `undefined` filters, as `null` tends to remove them from the board,
 * while `undefined` does nothing since such values are not received and processed by the server.
 */
type FilteringOptionsToBoardFilters<T> = T extends undefined
    ? undefined
    : T extends null
    ? null
    : BoardFilters;
export function filteringOptionsToBoardFilters<T extends FilteringOptions | null | undefined>(
    filteringOptions: T,
): FilteringOptionsToBoardFilters<T> {
    if (filteringOptions === undefined) {
        return undefined as FilteringOptionsToBoardFilters<T>;
    }

    if (filteringOptions === null) {
        return null as FilteringOptionsToBoardFilters<T>;
    }

    const filters: BoardFilters = {};
    if (filteringOptions.pickTokens) {
        filters.tokens = filteringOptions.pickTokens;
    }
    if (filteringOptions.pickBlockchains) {
        filters.blockchains = filteringOptions.pickBlockchains;
    }
    if (filteringOptions.pickExchanges) {
        filters.exchanges = filteringOptions.pickExchanges;
    }
    if (filteringOptions.pickWallets) {
        filters.wallets = filteringOptions.pickWallets;
    }
    if (filteringOptions.pickContractTypes) {
        filters.contractTypes = filteringOptions.pickContractTypes;
    }
    if (filteringOptions.pickTokenTypes) {
        filters.tokenTypes = filteringOptions.pickTokenTypes;
    }
    // TODO: deal with the prices once they are supported by the FilteringOptions.

    return filters as FilteringOptionsToBoardFilters<T>;
}
