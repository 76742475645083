import classnames from 'classnames/bind';
import { memo } from 'react';

import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

const addIconLeft: IButtonIcon = {
    icon: <Icon icon="add-plus" />,
    animation: 'none',
};

export const AddButton = memo(function AddButton({ onPress }: { onPress: () => void }) {
    // Render

    return (
        <Button
            className={`${cnb('add-wallet-btn')} btn-link`}
            iconLeft={addIconLeft}
            onClick={onPress}
            aria-label="Connect"
        >
            Connect
        </Button>
    );
});
