import type { IAssetsService } from '@smartfolly/frontend.assets-service';

import type { IAssetsRescanner } from '../types';

import { AssetsRescanner } from './AssetsRescanner';

/**
 * Create the AssetsRescanner instance with the AssetsService.
 * @param options - options which include an instance of "AuthService", the appVersion
 * and the flag either this is the first app launch to deal with the release notes.
 * @returns an instance of the ReleasesService.
 */
export function createWithAssetsService(assetsService: IAssetsService): IAssetsRescanner {
    return new AssetsRescanner({ assetsService });
}
