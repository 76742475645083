/**
 * @param args - different types arguments array
 * @returns resulting string
 * Function to convert args to a string message.
 */
export function argsToString(args: unknown[]): string {
    return args
        .map<string>(arg => {
            // Check for regular string
            if (typeof arg === 'string') {
                return arg;
            }

            // Check for String instance
            if (arg instanceof String) {
                return arg.toString();
            }

            // Check for Error instance
            if (arg instanceof Error) {
                return arg.toString();
            }

            // Check for rest kind of Objects
            if (arg instanceof Object) {
                return JSON.stringify(arg);
            }

            // Number or Boolean
            return `${arg}`;
        })
        .join(' ');
}
