import type { ITemplatesService } from '../types';

import { TemplatesServiceOptions, TemplatesService } from './TemplatesService';

/**
 * Create the TemplatesService instance which should work
 * with the provided "AuthService" and "BoardsService" instances.
 * @param options - options which include services to create an instance of TemplatesService with.
 * @returns an instance of the TemplatesService.
 */
export function createWithServices(options: TemplatesServiceOptions): ITemplatesService {
    return new TemplatesService(options);
}
