import classnames from 'classnames/bind';
import { observer } from 'mobx-react';

import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { assetsService } from '../../../services';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

const editIconLeft: IButtonIcon = {
    icon: <Icon icon="edit-mini" />,
    animation: 'none',
};

export const EditButton = observer(function EditButton({ onPress }: { onPress: () => void }) {
    // Stores

    const { providedAddressesWithWallets, providedExchangesWithWallets } = assetsService;

    // Render

    return (
        <Button
            className={`${cnb('add-wallet-btn')} btn-link`}
            iconLeft={editIconLeft}
            onClick={onPress}
            aria-label="Edit"
            disabled={
                providedAddressesWithWallets.length === 0 &&
                providedExchangesWithWallets.length === 0
            }
        >
            Edit
        </Button>
    );
});
