import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';

import { Button } from '@smartfolly/frontend.web-ui';

import { assetsService } from '../../../services';

import { Tooltip } from '../../Common';

import { CurrencyModal } from '../CurrencyModal';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

export const CurrencyButton = observer(function CurrencyButton() {
    const { selectedExchangeCurrency } = assetsService;

    // States

    const [showCurrencyModal, setShowCurrencyModal] = useState<boolean>(false);

    // Actions

    const onShowCurrency = useCallback(() => setShowCurrencyModal(true), []);

    // Render

    return (
        <>
            <Tooltip content="Choose currency">
                <Button
                    className={`${cnb('currency-btn')} onboarding-step-5`}
                    onClick={onShowCurrency}
                    aria-label="Choose currency"
                >
                    {selectedExchangeCurrency}
                </Button>
            </Tooltip>
            <CurrencyModal show={showCurrencyModal} setShow={setShowCurrencyModal} />
        </>
    );
});
