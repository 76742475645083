import BigNumber from 'bignumber.js';
import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Flex, FlexContainer, Percentage } from '@smartfolly/frontend.web-ui';

import type { FilteringOptions } from '@smartfolly/frontend.assets-service';
import { priceToString } from '@smartfolly/frontend.currencies-service';

import { Assets } from '../components/Assets';
import { Filters, FiltersButton } from '../components/Filters';

import {
    usePercentClassName,
    usePerformanceChartData,
    usePieChartData,
    useWorthChartData,
    useCumulativeProfitLossChartData,
    useDailyProfitLossChartData,
} from '../hooks';

import { assetsService, authService } from '../services';

import {
    PieChartTokensList,
    PieGraph,
    WorthGraph,
    PerformanceGraph,
    CumulativeProfitLossGraph,
    DailyProfitLoss,
} from '../components/Common';

import styles from './Monitor.module.scss';

const cnb = classnames.bind(styles);

const Info = observer(function Info() {
    const { filteredTotalPrice, selectedExchangeCurrency } = assetsService;

    const percentClassName = usePercentClassName(filteredTotalPrice.percentChange24h.value);

    return (
        <FlexContainer direction="column" justify="space-between" align="start">
            <Flex className="title title-small">Overview balance</Flex>
            <Flex className="action action-huge m-t-0.25 m-b-0.25">
                {filteredTotalPrice.string ??
                    `${priceToString(new BigNumber(0), selectedExchangeCurrency)}`}
            </Flex>
            <Flex className={`paragraph paragraph-small ${percentClassName}`}>
                {filteredTotalPrice.change24h.string ??
                    `${priceToString(new BigNumber(0), selectedExchangeCurrency)}`}{' '}
                ·{' '}
                <Percentage
                    value={
                        filteredTotalPrice.percentChange24h.string ??
                        `${priceToString(new BigNumber(0), selectedExchangeCurrency)}`
                    }
                    className={percentClassName}
                />{' '}
                <span className={`${cnb('today')} color-text-secondary`}>Today</span>
            </Flex>
        </FlexContainer>
    );
});

export const Monitor = observer(function Monitor() {
    const { address } = useParams();
    const navigate = useNavigate();
    const {
        assets,
        availableBlockchains,
        availableExchanges,
        availableWallets,
        filteredTokens,
        filteredTotalPrice,
        historicalFilteredTotalPrices,
        historicalBTCPrices,
        historicalETHPrices,
    } = assetsService;

    // Getters

    const allFilteredTokenGroups = filteredTokens.groups.concat(filteredTokens.hiddenGroups ?? []);
    const isAssetsEmpty = useMemo(() => assets.length === 0, [assets]);

    // Lifecycle

    useEffect(() => {
        if (isAssetsEmpty) {
            navigate('/');
        }
    }, [isAssetsEmpty, navigate]);

    // Filter the overview assets with the address (if given)
    useEffect(() => {
        if (address) {
            const pickWallets = availableWallets.reduce<
                Exclude<FilteringOptions['pickWallets'], undefined>
            >((acc, wallet) => {
                if ('address' in wallet && wallet.address === address) {
                    acc.push({ address, blockchain: wallet.blockchain.id });
                }
                return acc;
            }, []);

            assetsService.filter({ pickWallets });
        }
    }, [address, availableWallets]);

    // Find trending groups
    const pieChartTokensList = usePieChartData(allFilteredTokenGroups, 'Others');

    const worthChartData = useWorthChartData(historicalFilteredTotalPrices);
    const performanceChartData = usePerformanceChartData(
        historicalFilteredTotalPrices,
        historicalBTCPrices,
        historicalETHPrices,
    );
    const dailyProfitLoss = useDailyProfitLossChartData(historicalFilteredTotalPrices);
    const cumulativeProfitLossChartData = useCumulativeProfitLossChartData(
        historicalFilteredTotalPrices,
    );

    const { session } = authService;

    // Check if the the session is unauthorized or unknown
    if (session == null) {
        return null;
    }

    return (
        <div className="main-content">
            <FlexContainer
                className={`${cnb('header')} p-x-0 m-t-2 m-b-1`}
                direction="row"
                justify="space-between"
                align="center"
            >
                <Flex className={cnb('info')}>
                    <Info />
                </Flex>
                <Flex className={cnb('filters')}>
                    {/* Fit all filters in a single button when we have more than 3 */}
                    {availableBlockchains.length > 0 && availableExchanges.length > 0 ? (
                        <FiltersButton />
                    ) : (
                        <Filters />
                    )}
                </Flex>
                <Flex className={cnb('filters-button')}>
                    <FiltersButton />
                </Flex>
            </FlexContainer>
            <div className={cnb('content')}>
                <FlexContainer
                    className={`mb-4 ${cnb('two-columns')}`}
                    direction="row"
                    align="stretch"
                    justify="space-between"
                >
                    <Flex className={cnb('first')}>
                        <FlexContainer
                            className="back-primary p-3 b-r-3"
                            direction="column"
                            align="stretch"
                            justify="space-between"
                        >
                            <Flex>
                                <PieGraph
                                    allTokenGroups={allFilteredTokenGroups}
                                    totalPrice={filteredTotalPrice}
                                    header={MonitorPieGraphHeader}
                                    showLegend={false}
                                    containerHeight={236}
                                    innerRadius={100}
                                    outerRadius={116}
                                />
                            </Flex>
                        </FlexContainer>
                    </Flex>
                    <Flex className={cnb('second')}>
                        <FlexContainer
                            className="back-primary p-3 b-r-3"
                            direction="column"
                            align="stretch"
                            justify="space-between"
                        >
                            <Flex>
                                <PieChartTokensList list={pieChartTokensList} />
                            </Flex>
                        </FlexContainer>
                    </Flex>
                </FlexContainer>

                <FlexContainer
                    className={`mb-4 ${cnb('two-columns')}`}
                    direction="row"
                    align="stretch"
                    justify="space-between"
                >
                    <Flex className={cnb('first')}>
                        <FlexContainer
                            className="back-primary p-3 b-r-3"
                            direction="column"
                            align="stretch"
                            justify="space-between"
                        >
                            <Flex>
                                <WorthGraph data={worthChartData} />
                            </Flex>
                        </FlexContainer>
                    </Flex>
                    <Flex className={cnb('second')}>
                        <FlexContainer
                            className="back-primary p-3 b-r-3"
                            direction="column"
                            align="stretch"
                            justify="space-between"
                        >
                            <Flex>
                                <PerformanceGraph data={performanceChartData} />
                            </Flex>
                        </FlexContainer>
                    </Flex>
                </FlexContainer>

                <FlexContainer
                    className={`m-b-4 ${cnb('two-columns')}`}
                    direction="row"
                    align="stretch"
                    justify="space-between"
                >
                    <Flex className={cnb('first')}>
                        <FlexContainer
                            className="back-primary p-3 b-r-3"
                            direction="column"
                            align="stretch"
                            justify="space-between"
                        >
                            <Flex>
                                <DailyProfitLoss data={dailyProfitLoss} />
                            </Flex>
                        </FlexContainer>
                    </Flex>
                    <Flex className={cnb('second')}>
                        <FlexContainer
                            className="back-primary p-3 b-r-3"
                            direction="column"
                            align="stretch"
                            justify="space-between"
                        >
                            <Flex>
                                <CumulativeProfitLossGraph data={cumulativeProfitLossChartData} />
                            </Flex>
                        </FlexContainer>
                    </Flex>
                </FlexContainer>
                <Assets />
            </div>
        </div>
    );
});

const MonitorPieGraphHeader = <div className="title title-normal">Allocations</div>;
