import classnames from 'classnames/bind';
import { observer } from 'mobx-react';

import { Button, Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import styles from './Board.module.scss';
import { assetsService } from '../../services';

const cnb = classnames.bind(styles);

export const CustomBoardFiltersButtons = observer(function CustomBoardFiltersButtons({
    showTokensFilterModal,
    showNetworksFilterModal,
    showExchangesFilterModal,
    showWalletsFilterModal,
}: {
    showTokensFilterModal: () => void;
    showNetworksFilterModal: () => void;
    showExchangesFilterModal: () => void;
    showWalletsFilterModal: () => void;
}) {
    // Stores

    const { availableBlockchains, availableExchanges } = assetsService;

    // Render

    return (
        <FlexContainer className="b-r-4 m-t-1" direction="column" justify="stretch" align="stretch">
            <Flex className="m-b-1">
                <div className="title title-small">Custom</div>
            </Flex>
            <Flex>
                <Button
                    onClick={showTokensFilterModal}
                    className={`me-2 mb-2 d-sm-inline ${cnb('filter-button')}`}
                >
                    Tokens
                </Button>
                {availableBlockchains.length > 0 && (
                    <Button
                        onClick={showNetworksFilterModal}
                        className={`me-2 mb-2 d-sm-inline ${cnb('filter-button')}`}
                    >
                        Networks
                    </Button>
                )}
                {availableExchanges.length > 0 && (
                    <Button
                        onClick={showExchangesFilterModal}
                        className={`me-2 mb-2 d-sm-inline ${cnb('filter-button')}`}
                    >
                        Exchanges
                    </Button>
                )}
                <Button
                    onClick={showWalletsFilterModal}
                    className={`mb-2 d-sm-inline ${cnb('filter-button')}`}
                >
                    Wallets
                </Button>
            </Flex>
        </FlexContainer>
    );
});
