import { useMemo } from 'react';

const to19: string[] = [
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
];
const tens: string[] = [
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
];

const others: string[] = ['Thousand', 'Million', 'Billion'];

function formatNumber(num: number): string[] {
    if (num === 0) {
        return [];
    }
    if (num < 20) {
        return [to19[num - 1]!];
    }
    if (num < 100) {
        return [tens[Math.floor(num / 10) - 2]!].concat(formatNumber(num % 10));
    }
    if (num < 1000) {
        return [to19[Math.floor(num / 100) - 1]!, 'Hundred'].concat(formatNumber(num % 100));
    }
    for (let i: number = 0; i < others.length; i += 1) {
        if (num < 1000 ** (i + 2)) {
            return formatNumber(Math.floor(num / 1000 ** (i + 1))).concat(
                [others[i]!],
                formatNumber(num % 1000 ** (i + 1)),
            );
        }
    }
    return [];
}

/**
 * Hook to humanize numbers. EN version.
 * @param num - a number.
 * @returns a humanized number.
 */
export function useNumberToWords(num: number) {
    return useMemo(() => {
        if (num === 0) {
            return 'Zero';
        }
        return formatNumber(num).join(' ');
    }, [num]);
}
