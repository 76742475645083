import { createContext, useContext } from 'react';
import WarningModalStore from './WarningModalStore';
import GetProStore from './GetProModalStore';

interface Store {
    warningModalStore: WarningModalStore;
    getProStore: GetProStore;
}

export const store: Store = {
    warningModalStore: new WarningModalStore(),
    getProStore: new GetProStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
