import { useMemo } from 'react';

import { Asset, formatAssetBalance } from '@smartfolly/frontend.assets-service';

/**
 * Hook to present the formatted asset balance.
 * @param asset - an asset to preset the formatted balance for.
 * @param precision - an option precision to be used when formatting the balance.
 * @returns the formatted asset balance.
 */
export function useAssetBalance(asset: Asset, precision?: number) {
    return useMemo(() => formatAssetBalance(asset, precision), [asset, precision]);
}
