import classnames from 'classnames/bind';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { observer } from 'mobx-react';

import { Log } from '@smartfolly/common.utilities';
import type { Board } from '@smartfolly/frontend.boards-service';
import { Flex, FlexContainer, Modal } from '@smartfolly/frontend.web-ui';

import { boardsService } from '../../services';

import { showToast } from '../../utils';

import { ClearableInput } from '../Common';

import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

const log = new Log('RenameBoardModal');

/**
 * Type for the Form Control Element. See {@link Form.Control}.
 */
type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

const EditButton = observer(function EditButton() {
    const { isEditingBoard } = boardsService;

    return (
        <Button
            type="submit"
            form="rename-board"
            variant="transparent"
            className="d-block w-100 action action-normal btn-link"
            disabled={isEditingBoard}
        >
            OK
        </Button>
    );
});

export const RenameBoardModal = observer(function RenameBoardModal({
    board,
    hideModal,
    shouldShowToast,
}: {
    board: Board;
    hideModal: () => void;
    shouldShowToast?: boolean;
}) {
    const { editBoard } = boardsService; // Note: `editBoard` method isn't MobX-computed

    const nameRef = useRef<HTMLInputElement>(null);

    // Lifecycle

    useEffect(() => {
        if (nameRef.current) {
            nameRef.current.focus();
        }
    }, []);

    // Getters

    const [name, setName] = useState(board ? board.name : '');
    const [description, setDescription] = useState(board ? board.description : '');

    // Actions

    const hideModalFn = useCallback(() => {
        setName(board.name);
        hideModal();
    }, [hideModal, setName, board.name]);

    const renameBoard = useCallback(
        async (ev: FormEvent) => {
            ev.preventDefault();
            try {
                const renamedBoard = await editBoard({
                    boardId: board.boardId,
                    name,
                    description: description || null,
                });
                if (renamedBoard) {
                    if (shouldShowToast) {
                        showToast('Board renamed');
                    }
                    hideModal();
                } else {
                    showToast('Something went wrong');
                }
            } catch (error) {
                log.error('Failed to edit the board with error:', error);

                showToast('Something went wrong');
            }
        },
        [editBoard, board.boardId, name, hideModal, description, shouldShowToast],
    );

    const changeName = useCallback(
        (e: React.ChangeEvent<FormControlElement>) => setName(e.target.value),
        [],
    );

    const changeDescription = useCallback(
        (e: React.ChangeEvent<FormControlElement>) => setDescription(e.target.value),
        [],
    );

    // Render

    return (
        <Modal
            className={cnb('info-modal')}
            show
            onHide={hideModalFn}
            header={<div className="title title-normal">Board</div>}
        >
            <FlexContainer className="b-r-4" direction="column" justify="stretch" align="stretch">
                <Flex className="m-b-1">
                    <Form id="rename-board" onSubmit={renameBoard}>
                        <Form.Group className="mb-3" controlId="formName">
                            <ClearableInput
                                ref={nameRef}
                                className="back-secondary color-text-bw border-secondary p-b-1 p-t-1 p-l-1 p-r-1"
                                placeholder="Name"
                                value={name}
                                onChange={changeName}
                                type="text"
                                btnClassName="back-secondary p-l-1.5 p-r-1.5 h-auto"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <ClearableInput
                                className="back-secondary color-text-bw border-secondary p-b-1 p-t-1 p-l-1 p-r-1"
                                placeholder="Description"
                                value={description ?? ''}
                                onChange={changeDescription}
                                type="text"
                                btnClassName="back-secondary p-l-1.5 p-r-1.5 h-auto"
                            />
                        </Form.Group>
                    </Form>
                </Flex>
                <Flex className="m-t-0">
                    <EditButton />
                </Flex>
            </FlexContainer>
        </Modal>
    );
});
