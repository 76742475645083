import { computed, makeObservable, observable } from 'mobx';

export class ObservableFlags {
    // Properties

    /**
     * A private observable instance of the `isLoadingBoards` flag.
     */
    private isLoadingBoardsFlag: boolean = false;

    /**
     * A private observable instance of the `isRefreshingBoards` flag.
     */
    private isRefreshingBoardsFlag: boolean = false;

    /**
     * A private observable instance of the `isAddingBoard` flag.
     */
    private isAddingBoardFlag: boolean = false;

    /**
     * A private observable instance of the `isEditingBoard` flag.
     */
    private isEditingBoardFlag: boolean = false;

    /**
     * A private observable instance of the `isDeletingBoard` flag.
     */
    private isDeletingBoardFlag: boolean = false;

    // Constructor

    public constructor() {
        makeObservable<
            ObservableFlags,
            | 'isLoadingBoardsFlag'
            | 'isRefreshingBoardsFlag'
            | 'isAddingBoardFlag'
            | 'isEditingBoardFlag'
            | 'isDeletingBoardFlag'
        >(this, {
            isLoadingBoards: computed,
            isLoadingBoardsFlag: observable,
            isRefreshingBoards: computed,
            isRefreshingBoardsFlag: observable,
            isAddingBoard: computed,
            isAddingBoardFlag: observable,
            isEditingBoard: computed,
            isEditingBoardFlag: observable,
            isDeletingBoard: computed,
            isDeletingBoardFlag: observable,
        });
    }

    public get isLoadingBoards(): boolean {
        return this.isLoadingBoardsFlag;
    }

    public set isLoadingBoards(isLoadingBoards: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isLoadingBoardsFlag = isLoadingBoards;
    }

    public get isRefreshingBoards(): boolean {
        return this.isRefreshingBoardsFlag;
    }

    public set isRefreshingBoards(isRefreshingBoards: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isRefreshingBoardsFlag = isRefreshingBoards;
    }

    public get isAddingBoard(): boolean {
        return this.isAddingBoardFlag;
    }

    public set isAddingBoard(isAddingBoard: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isAddingBoardFlag = isAddingBoard;
    }

    public get isEditingBoard(): boolean {
        return this.isEditingBoardFlag;
    }

    public set isEditingBoard(isEditingBoard: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isEditingBoardFlag = isEditingBoard;
    }

    public get isDeletingBoard(): boolean {
        return this.isDeletingBoardFlag;
    }

    public set isDeletingBoard(isDeletingBoard: boolean) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.isDeletingBoardFlag = isDeletingBoard;
    }
}
