import { useMemo } from 'react';

import { Log } from '@smartfolly/common.utilities';

import type { Wallet } from '@smartfolly/frontend.assets-service';
import { currenciesService } from '@smartfolly/frontend.currencies-service';

const log = new Log('useFormattedWallet');

/**
 * Hook to present the wallet as a formatted string.
 * @param wallet - a wallet to present.
 * @param options - options to format the string with.
 */
export function useFormattedWallet(
    wallet: Wallet | null,
    {
        shortenBlockchainName,
    }: {
        /**
         * Shorten a blockchain name when formatting, i.e. use its native token symbol.
         */
        shortenBlockchainName?: boolean;
    } = {},
) {
    return useMemo(() => {
        if (!wallet) {
            return '';
        }

        // Non-custodian
        if ('address' in wallet) {
            const address =
                wallet.address.length > 9
                    ? `${wallet.address.slice(0, 5)} ···· ${wallet.address.slice(-4)}`
                    : wallet.address;

            if (shortenBlockchainName) {
                const nativeToken = currenciesService.tokensData[wallet.blockchain.nativeToken];
                if (nativeToken) {
                    return `${nativeToken.symbol} ${address}`;
                }

                log.warning('Failed to shorten the blockchain name:', wallet.blockchain.name);
            }

            return `${wallet.blockchain.name} ${address}`;
        }

        // Custodian
        if ('exchange' in wallet) {
            if (wallet.exchangeData) {
                return `${wallet.exchange.name} ···· ${wallet.exchangeData.keys.apiKey.slice(-4)}`;
            }

            return wallet.exchange.name;
        }

        throw new Error('Wallet kind is not supported');
    }, [shortenBlockchainName, wallet]);
}
