import { computed, makeObservable, observable } from 'mobx';

import {
    appStorage,
    buildStorageKeyForUser,
    CommonStore,
    DocumentLoaderListener,
    Log,
    MemoDocumentLoader,
} from '@smartfolly/common.utilities';

import { IUserManager, UserManager, UserInfo as UserData } from '@smartfolly/sdk';

import type { IAuthService } from '@smartfolly/frontend.auth-service';

import type { IUserService } from '../types';

import { ObservableFlags } from './ObservableFlags';

const log = new Log('UserService');

const USER_DATA_KEY = 'UserService:UserData';

type UserServiceOptions =
    | {
          /**
           * An instance of AuthService with the user to work with when dealing with the user.
           * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
           */
          authService: IAuthService;
      }
    | {
          /**
           * An ID of the user to work with when dealing with that user.
           */
          userId: string;
      };

export class UserService extends CommonStore implements IUserService {
    // Properties

    /**
     * A private instance of MobX observable flags used by the store.
     */
    private flags = new ObservableFlags();

    /**
     * Options the store is created with.
     */
    private options: UserServiceOptions;

    /**
     * An instance of the UserManager to work with when dealing with the user data.
     */
    private userManager: IUserManager;

    /**
     * A private observable value of the user data provided by {@link userManager}.
     */
    private userDataValue: UserData | undefined = undefined;

    /**
     * A memo loader of the user data.
     */
    private memoDataLoader?: MemoDocumentLoader<UserData>;

    /**
     * A listener to update the user data.
     */
    private dataLoaderListener?: DocumentLoaderListener<UserData>;

    /**
     * A GraphQL User data subscription disposer.
     */
    private subscriptionDisposer?: () => void;

    // Constructor

    public constructor(options: UserServiceOptions) {
        super();

        this.options = options;

        this.userManager = new UserManager(
            'authService' in options
                ? { userAuth: options.authService.userAuth }
                : { userId: options.userId },
        );

        makeObservable<UserService, 'userDataValue' | 'userData'>(this, {
            userDataValue: observable,
            userData: computed,
            authProvider: computed,
            isSubscribedToWhales: computed,
            isSubscribingToWhales: computed,
            isUnsubscribingFromWhales: computed,
        });
    }

    // Getters & Setters

    /**
     * A computed value of the user data.
     */
    private get userData(): UserData | undefined {
        return this.userDataValue;
    }

    /**
     * Set the user data value.
     */
    private set userData(userData: UserData | undefined) {
        // As per MobX docs "Setters are automatically marked as actions."
        // See: https://mobx.js.org/computeds.html#computed-setter
        this.userDataValue = userData;
    }

    // Interface

    protected onLoad = async () => {
        // Load the user data
        this.loadUserData();
    };

    protected onUnload = async () => {
        // Unload the user data
        this.unloadUserData();
    };

    public get authProvider(): UserData['authProvider'] | undefined {
        return this.userData?.authProvider;
    }

    public get isSubscribedToWhales(): boolean | undefined {
        // Check if the whales record exists
        return !!this.userData?.services?.whales;
    }

    public get isSubscribingToWhales(): boolean {
        return this.flags.isSubscribingToWhales;
    }

    public get isUnsubscribingFromWhales(): boolean {
        return this.flags.isUnsubscribingFromWhales;
    }

    public createWhalesSubscription = async (): Promise<boolean> => {
        this.flags.isSubscribingToWhales = true;
        try {
            const result = await this.userManager.createWhalesSubscription();

            // Updates services locally to reflect the changes ASAP
            if (result) {
                const { userData } = this;
                if (userData && !userData.services?.whales) {
                    this.userData = {
                        ...userData,
                        services: {
                            ...userData.services,
                            whales: {
                                created: Date.now(),
                            },
                        },
                    };
                }
            }

            return result;
        } catch (error) {
            log.error('Failed to subscribe to whales with error:', error);

            throw error;
        } finally {
            this.flags.isSubscribingToWhales = false;
        }
    };

    public removeWhalesSubscription = async (): Promise<boolean> => {
        this.flags.isUnsubscribingFromWhales = true;
        try {
            const result = await this.userManager.removeWhalesSubscription();

            // Updates services locally to reflect the changes ASAP
            if (result) {
                const { userData } = this;
                if (userData && userData.services) {
                    const { whales, ...restServices } = userData.services;
                    if (whales) {
                        this.userData = {
                            ...userData,
                            services: restServices,
                        };
                    }
                }
            }

            return result;
        } catch (error) {
            log.error('Failed to unsubscribe from whales with error:', error);

            throw error;
        } finally {
            this.flags.isUnsubscribingFromWhales = false;
        }
    };

    // Internals

    /**
     * Method to load user data using memo loader.
     * @throws a variety of errors if the user is not authorized or the data is already loading.
     */
    private loadUserData() {
        // Check if the data loader already exist to avoid loading the store twice
        if (this.memoDataLoader) {
            throw new Error('User data is already loading');
        }

        // Get a storage key to memo the user data
        // Note: could throw if the user is not authorized
        const storageKey = this.getStorageKey(USER_DATA_KEY);

        // Get the user data via memo loader
        this.memoDataLoader = new MemoDocumentLoader<UserData>({
            key: storageKey,
            storage: appStorage,
            documentFetcher: (listener: DocumentLoaderListener<UserData>) => {
                // Save the listener in order to reflect the data changes made by other methods
                this.dataLoaderListener = listener;

                // Get the user data from the backend
                (async () => {
                    try {
                        const userInfo = await this.userManager.getUserInfo();
                        listener(undefined, userInfo);
                    } catch (error) {
                        listener(error);
                    }
                })();

                // Subscribe to the server to update the user data
                this.subscriptionDisposer = this.userManager.subscribeToUserInfo(
                    (error: Error | undefined, userInfo?: UserData | null) => {
                        if (error) {
                            listener(error);
                        }

                        if (userInfo) {
                            listener(undefined, userInfo);
                        }
                    },
                );
            },
            stopDocumentFetching: () => {
                // Dispose the subscription if any
                if (this.subscriptionDisposer) {
                    this.subscriptionDisposer();
                    delete this.subscriptionDisposer;
                }

                // Delete the data listener
                delete this.dataLoaderListener;
            },
        });

        // Listen to the user data changes
        this.memoDataLoader.subscribe((error: Error | undefined, userData?: UserData) => {
            if (error) {
                log.error('Failed to fetch the user data with error:', error);
                return;
            }

            if (!userData) {
                log.error('No user data have been loaded');
                return;
            }

            this.userData = userData;
        });
    }

    /**
     * Methods to unload the user data with the memo loader.
     */
    private unloadUserData() {
        // Unsubscribe from the memo data loader
        if (this.memoDataLoader) {
            this.memoDataLoader.unsubscribe();

            // And delete the loader itself
            delete this.memoDataLoader;
        }

        // Remove the user data
        this.userData = undefined;
    }

    /**
     * Method to get a storage key for the current user and a specified key.
     * @param key - a provided key to get the storage key for.
     * @returns the key bound to the current user.
     */
    private getStorageKey(key: string): string {
        if ('userId' in this.options) {
            return buildStorageKeyForUser(key, this.options.userId);
        }

        return this.options.authService.getStorageKey(key);
    }
}
