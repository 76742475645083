import { BoardTemplates } from '@smartfolly/sdk';

import { Asset, doesAssetCorrespondsToFilteringOptions } from '@smartfolly/frontend.assets-service';

import type { StablecoinsMap } from '@smartfolly/frontend.currencies-service';

import { templateBoardOptions } from '../templates';

/**
 * Function to provide a list of suitable board templates for an asset.
 * Note: should skip the .Total board template as per the design.
 * @param asset - an asset to get the list of suitable board templates for.
 * @param stablecoins - map with all supported stablecoins.
 */
export function suitableBoardTemplates(
    asset: Asset,
    stablecoins: StablecoinsMap,
): BoardTemplates[] {
    return Object.values(BoardTemplates).filter(template => {
        // Skip the .Total board
        if (template === BoardTemplates.Total) {
            return false;
        }

        // Get the filtering options for the template
        const { filters } = templateBoardOptions[template];
        if (!filters) {
            return false;
        }

        // Check if the asset corresponds to the given filters
        return doesAssetCorrespondsToFilteringOptions(asset, filters, stablecoins);
    });
}
