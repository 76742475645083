import BigNumber from 'bignumber.js';
import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import type { Asset } from '@smartfolly/frontend.assets-service';
import type { IBoardsService } from '@smartfolly/frontend.boards-service';
import { Button, Flex, FlexContainer, Icon, Modal, Percentage } from '@smartfolly/frontend.web-ui';

import { isAssetStaked } from '../../helpers';

import {
    useAssetBalance,
    useExplorerLink,
    useFormattedWallet,
    usePercentClassName,
} from '../../hooks';

import { boardsService as myBoardsService } from '../../services';

import { showToast } from '../../utils';

import { CryptoIcon } from '../CryptoIcon';

import { Tooltip } from '../Common';
import styles from './Board.module.scss';
import { BoardMiniCard } from './BoardMiniCard';
import { QuickLinks } from './QuickLInks';

const cnb = classnames.bind(styles);

function addressCopied() {
    showToast('Address copied to clipboard');
}

const tooltipConfig = {
    followCursor: true,
    offset: [22, 22] as [number, number],
};

export const BoardAssetModal = observer(function BoardAssetModal({
    asset,
    boardsService,
    hideModal,
}: {
    asset: Asset;
    boardsService: IBoardsService;
    hideModal: () => void;
}) {
    const { assetId, price, token, wallet } = asset;

    // Getters

    const assetBoards = boardsService.getAssetBoards(asset); // Note: the method isn't MobX-computed
    const formattedAddress = useFormattedWallet(wallet);
    const percentClassName = usePercentClassName(price.percentChange24h.value);
    const isStaked = isAssetStaked(asset);
    const balance = useAssetBalance(asset);

    // Hooks

    const exploreAddress = useExplorerLink(asset);

    // Render

    const priceLineWidth = useMemo(() => {
        if (
            token.price.value != null &&
            token.price.low24h.value != null &&
            token.price.high24h.value != null &&
            !token.price.low24h.value.eq(token.price.high24h.value)
        ) {
            return `${token.price.value
                .minus(token.price.low24h.value)
                .dividedBy(token.price.high24h.value.minus(token.price.low24h.value))
                .times(new BigNumber(100.0))
                .toFixed(2)}%`;
        }

        return '0%';
    }, [token.price.value, token.price.low24h.value, token.price.high24h.value]);

    return (
        <Modal
            className={cnb('info-modal')}
            show
            onHide={hideModal}
            header={
                <div className="title title-normal">
                    {token.name}
                    {isStaked ? ' · Staked' : ''}
                </div>
            }
        >
            {/* Main info */}
            <FlexContainer
                className="back-primary b-r-4 p-3 m-b-1"
                direction="column"
                justify="stretch"
                align="stretch"
            >
                <Flex>
                    <FlexContainer direction="row" justify="stretch" align="center">
                        <Flex grow={1}>
                            <FlexContainer direction="column" justify="stretch" align="stretch">
                                <Flex className="title title-small color-text-secondary">
                                    {balance}
                                </Flex>
                                <Flex className="action action-huge">{price.string}</Flex>
                                <Flex className="paragraph paragraph-small">
                                    <span className={percentClassName}>
                                        {price.change24h.string} ·{' '}
                                        <Percentage
                                            value={price.percentChange24h.string}
                                            className={percentClassName}
                                        />
                                    </span>{' '}
                                    <span className="text-color-secondary m-l-0.25">Today</span>
                                </Flex>
                            </FlexContainer>
                        </Flex>
                        <Flex>
                            <div className="icon">
                                <img src={token.icon} alt={token.name} />
                            </div>
                        </Flex>
                    </FlexContainer>
                </Flex>
            </FlexContainer>
            {/* Quick actions */}
            <QuickLinks asset={asset} />
            {/* Wallet address info */}
            {
                // Show the wallet address info only if the address is present
                'address' in wallet &&
                    // Check if can view the asset data as we can do it only for my boards by design
                    boardsService === myBoardsService && (
                        <FlexContainer
                            className="back-primary b-r-4 p-3 m-b-1"
                            direction="column"
                            justify="stretch"
                            align="stretch"
                        >
                            <Flex className="title title-normal m-b-1.25">Wallet address</Flex>
                            <Flex>
                                <FlexContainer direction="row" justify="stretch" align="start">
                                    <Flex>
                                        <div className="icon">
                                            <CryptoIcon
                                                icon={`${wallet.blockchain.id}-wallet`}
                                                defaultIcon="default-wallet"
                                            />
                                        </div>
                                    </Flex>
                                    <Flex grow={1}>
                                        <FlexContainer
                                            direction="column"
                                            justify="center"
                                            align="stretch"
                                        >
                                            <Flex className="paragraph paragraph-small color-text-secondary">
                                                {formattedAddress}
                                            </Flex>
                                            <Flex className="action action-default text-break">
                                                {wallet.address}
                                            </Flex>
                                        </FlexContainer>
                                    </Flex>
                                    <Flex>
                                        <CopyToClipboard
                                            key={assetId}
                                            text={wallet.address}
                                            onCopy={addressCopied}
                                        >
                                            <Button
                                                color="transparent"
                                                className={`p-r-0.5 ${cnb('copy-btn')}`}
                                            >
                                                <Icon icon="copy-mini" />
                                            </Button>
                                        </CopyToClipboard>
                                    </Flex>
                                </FlexContainer>
                                <FlexContainer>
                                    <Flex className="m-t-0.75 m-b-0.5 m-l-3">
                                        {!!exploreAddress && (
                                            <Tooltip
                                                content="View on explorer"
                                                config={tooltipConfig}
                                            >
                                                <a
                                                    className="action action-special"
                                                    href={exploreAddress}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    See transactions
                                                </a>
                                            </Tooltip>
                                        )}
                                    </Flex>
                                </FlexContainer>
                            </Flex>
                        </FlexContainer>
                    )
            }
            {/* Coin info */}
            <FlexContainer
                className="back-primary b-r-4 p-3 m-b-1"
                direction="column"
                justify="stretch"
                align="stretch"
            >
                <Flex className="title title-normal m-b-1">Coin</Flex>

                <Flex>
                    <FlexContainer direction="row" justify="space-around" align="start">
                        <Flex className="w-50">
                            <div className="paragraph paragraph-small color-text-secondary">
                                Current price
                            </div>
                            <div className="action action-default m-t-0.25">
                                {token.price.string}
                                <sup className={`m-l-0.25 ${percentClassName}`}>
                                    {token.price.percentChange24h.string}
                                </sup>
                            </div>
                        </Flex>
                        <Flex className="w-50">
                            <FlexContainer direction="column" justify="stretch" align="stretch">
                                <Flex>
                                    <div className="paragraph paragraph-small color-text-secondary">
                                        Day range
                                    </div>
                                </Flex>
                                <Flex className="m-t-0.25 m-b-0.25">
                                    <div className={cnb('price-line')}>
                                        <div style={{ width: priceLineWidth }} />
                                    </div>
                                </Flex>
                                <Flex>
                                    <FlexContainer
                                        direction="row"
                                        justify="space-between"
                                        align="center"
                                    >
                                        <Flex className="w-50">
                                            <div className="paragraph paragraph-tiny">
                                                {token.price.low24h.string}
                                            </div>
                                        </Flex>
                                        <Flex>
                                            <div className="paragraph paragraph-tiny text-right">
                                                {token.price.high24h.string}
                                            </div>
                                        </Flex>
                                    </FlexContainer>
                                </Flex>
                            </FlexContainer>
                        </Flex>
                    </FlexContainer>
                </Flex>
                <Flex>
                    <FlexContainer
                        direction="row"
                        justify="space-around"
                        align="start"
                        className="m-t-1.25"
                    >
                        <Flex className="w-50">
                            <div className="paragraph paragraph-small color-text-secondary">
                                All-time low
                            </div>
                            <div className="action action-default m-t-0.25">
                                {token.price.lowAllTime?.string}
                            </div>
                        </Flex>
                        <Flex className="w-50">
                            <div className="paragraph paragraph-small color-text-secondary">
                                All-time high
                            </div>
                            <div className="action action-default m-t-0.25">
                                {token.price.highAllTime?.string}
                            </div>
                        </Flex>
                    </FlexContainer>
                </Flex>
            </FlexContainer>
            {/* Board with this asset */}
            {
                // Check if can view the asset data as we can do it only for my boards by design
                boardsService === myBoardsService && (
                    <FlexContainer direction="column" justify="stretch" align="stretch">
                        <Flex className="title title-normal m-b-1 m-t-0.5 m-l-0.5">Boards</Flex>
                        <Flex>
                            <FlexContainer
                                direction="row"
                                justify="stretch"
                                align="stretch"
                                className="overflow-auto"
                            >
                                {assetBoards.map(board => (
                                    <BoardMiniCard
                                        key={board.boardId}
                                        board={board}
                                        hideModal={hideModal}
                                    />
                                ))}
                            </FlexContainer>
                        </Flex>
                    </FlexContainer>
                )
            }
        </Modal>
    );
});
