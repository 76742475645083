import BigNumber from 'bignumber.js';

import { localePercentage } from '@smartfolly/common.utilities';

import type {
    BlockchainGroup,
    Token,
    TokenGroup,
    WalletGroup,
} from '@smartfolly/frontend.assets-service';

import { priceToString } from '@smartfolly/frontend.currencies-service';

export type PieChartSlice = {
    /**
     * The name of the pie slice.
     */
    name: string;
    /**
     * The value of the slice (between 0 and 1).
     */
    value: number | undefined;
    /**
     * The stringified portion value of the slice.
     */
    portion: string | undefined;
    /**
     * The total assets price value of the slice.
     */
    price: string | undefined;
    /**
     * The token of the slice.
     * Note: this property is used to get the token icon.
     * Note: this property is optional as the slice can be a blockchain or a wallet.
     * Note: this property is not used for the "Others" slice.
     */
    token: Token | undefined;
};

export type PieChartData = PieChartSlice[];

/**
 * Function to build the pie chart data from the provided sorted groups.
 * @param groups - the given sorted groups to build the pie chart data for.
 * @param othersName - the name of the "Others" slice.
 * @returns the built pie chart data.
 */
export function buildPieChartData(
    groups: (TokenGroup | BlockchainGroup | WalletGroup)[],
    othersName: string = 'Others',
): PieChartData {
    // Prepare the first groups (no more than five as per the design)
    const topGroups: PieChartSlice[] = groups
        .slice(0, 5)
        .map(group => {
            // Get the slice name regarding the type of the group
            let name = '';
            if ('token' in group) {
                // Take the name from the token symbol
                // Note: to display a full name we can use `group.token.name`
                name = group.token.symbol;
            } else if ('blockchain' in group) {
                // Take the name from the blockchain
                name = group.blockchain.name;
            } else if ('wallet' in group) {
                // Take the name as the following format,
                // which joins the name of the wallet blockchain
                // and the last four wallet address symbols
                name =
                    'blockchain' in group.wallet
                        ? `${group.wallet.blockchain.name} ···· ${group.wallet.address.substring(
                              group.wallet.address.length - 4,
                          )}`
                        : group.wallet.exchange.name;
            } else {
                throw new Error('Group format is incorrect');
            }

            // Get the slice value
            // Note: the value should be presented as a numeric value between 0 and 1
            const value = group.portion?.value?.toNumber();

            // Get the slice portion string (with percents)
            const portion = group.portion?.string;

            // Get the slice portion price string
            const price = group.totalPrice.string;

            const token = 'token' in group ? group.token : undefined;

            return {
                name,
                value,
                portion,
                price,
                token,
            };
        })
        // Exclude those with `undefined` values if any
        .filter(({ value }) => value !== undefined);

    // Prepare the slice with left groups
    const others = groups.slice(5).reduce(
        (acc, item) => {
            acc.portion = acc.portion.plus(item.portion?.value ?? new BigNumber(0));
            acc.price = acc.price.plus(item.totalPrice?.value ?? new BigNumber(0));
            return acc;
        },
        { portion: new BigNumber(0), price: new BigNumber(0) },
    );

    // Return the resulted charts data (by appending the others slice if present)
    return others.portion.gt(new BigNumber(0))
        ? topGroups.concat({
              // Get the others slice name (passed via the parameter)
              name: othersName,
              // Get the others slice value (a numeric value between 0 and 1)
              value: others.portion.toNumber(),
              // Get the others slice portion string (using the proper utility)
              portion: localePercentage(others.portion),
              // Get the others slice price string (using the proper utility)
              // Note: find the proper currency from the provided groups
              price: priceToString(others.price, groups[0]?.totalPrice.currency ?? 'USD'),
              // Note: the others slice does not have a token
              token: undefined,
          })
        : topGroups;
}
