import { Log } from '@smartfolly/common.utilities';

const log = new Log('onboardingFinished');

const ONBOARDING_FINISHED_KEY = 'onboardindFinished';

/**
 * Function to check if the onboarding is finished.
 * @returns the result of the check.
 */
export function isOnboardingFinished() {
    try {
        return JSON.parse(localStorage.getItem(ONBOARDING_FINISHED_KEY) ?? 'false') as boolean;
    } catch (e) {
        log.error(e);
        return false;
    }
}

/**
 * Function to mark the onboarding as finished.
 */
export function markOnboardingAsFinished() {
    localStorage.setItem(ONBOARDING_FINISHED_KEY, JSON.stringify(true));
}
