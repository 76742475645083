import { action, computed, makeObservable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { CommonStore } from '@smartfolly/common.utilities';

import { Board as SDKBoard, BoardFilters, BoardTemplates } from '@smartfolly/sdk';

import {
    Group,
    GroupedAssets,
    HistoricalPrice,
    areAllAssetsWithLowPrice,
    filterGroupsExcludingAssetsWithLowPrice,
    getTotalPrice,
    sortGroupsByTotalPrice,
} from '@smartfolly/frontend.assets-service';
import type { Asset, IAssetsService } from '@smartfolly/frontend.assets-service';

import { currenciesService } from '@smartfolly/frontend.currencies-service';

import type { Board } from '../../types';

import { doesAssetCorrespondsToBoardFilters } from '../../utils';

import { enrichBoard } from '../helpers';

import type { ExtendedAssetsStore } from '../types';

// Note: the code bellow is not used at the moment due to the fact that
// this flag value is taken from the `assetsService` now as a global one:

// const log = new Log('BoardsService:Assets');

// const ARE_LOW_PRICES_HIDDEN_KEY = 'BoardsService:AreLowPricesHidden';

// /**
//  * A default `areLowPricesHidden` flag value.
//  * Note: it's `true` by the design.
//  */
// const DEFAULT_ARE_LOW_PRICES_HIDDEN_VALUE: boolean = true;

type AssetsStoreOptions = {
    /**
     * An instance of the AssetsService to work with when dealing with the boards.
     * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
     */
    assetsService: IAssetsService;
};

export class AssetsStore extends CommonStore implements ExtendedAssetsStore {
    // Properties

    /**
     * An instance of the AssetsService to work with when dealing with the boards.
     */
    public readonly assetsService: IAssetsService;

    // Note: the code bellow is not used at the moment due to the fact that
    // this flag value is taken from the `assetsService` now as a global one:

    // /**
    //  * A private observable instance of the `areLowPricesHidden` flag.
    //  */
    // private areLowPricesHiddenFlag: boolean = DEFAULT_ARE_LOW_PRICES_HIDDEN_VALUE;

    // Constructor

    public constructor({ assetsService }: AssetsStoreOptions) {
        super();

        this.assetsService = assetsService;

        // Note: some code bellow is not used at the moment due to the fact that
        // this flag value is taken from the `assetsService` now as a global one:

        makeObservable<AssetsStore /* , 'areLowPricesHiddenFlag' */>(this, {
            // areLowPricesHiddenFlag: observable,
            totalBoard: computed,
            toggleHidingLowPrices: action,
            areLowPricesHidden: computed,
        });
    }

    // Interface

    // eslint-disable-next-line class-methods-use-this
    protected onLoad = async () => {
        // Note: the code bellow is not used at the moment due to the fact that
        // this flag value is taken from the `assetsService` now as a global one:
        // // Load the `areLowPricesHidden` flag from the storage
        // await this.loadAreLowPricesHiddenFlag();
    };

    // eslint-disable-next-line class-methods-use-this
    protected onUnload = async () => {
        // Note: the code bellow is not used at the moment due to the fact that
        // this flag value is taken from the `assetsService` now as a global one:
        // // Unload the `areLowPricesHidden` flag
        // this.unloadAreLowPricesHiddenFlag();
    };

    public enrichBoards = computedFn((boards: SDKBoard[]): Board[] => {
        return boards.map(board => {
            // Return the enriched observable board
            return enrichBoard(board, { assetsStore: this });
        });
    });

    public toggleHidingLowPrices = async (hideLowPrices: boolean) => {
        return this.assetsService.toggleHidingLowPrices(hideLowPrices);

        // Note: the code bellow is not used at the moment due to the fact that
        // this flag value is taken from the `assetsService` now as a global one:

        // // Apply MobX action to set the corresponding flag
        // this.areLowPricesHidden = hideLowPrices;

        // // Save it to the storage
        // try {
        //     await this.saveAreLowPricesHiddenFlag(hideLowPrices);
        // } catch (error) {
        //     // Note: it's OK to fail saving this flag
        //     // as it doesn't affect the service workflow.
        //     log.error('Failed to save the `areLowPricesHidden` flag with error:', error);
        // }
    };

    public get areLowPricesHidden(): boolean {
        return this.assetsService.areLowPricesHidden;

        // Note: the code bellow is not used at the moment due to the fact that
        // this flag value is taken from the `assetsService` now as a global one:

        // return this.areLowPricesHiddenFlag;
    }

    // Note: the code bellow is not used at the moment due to the fact that
    // this flag value is taken from the `assetsService` now as a global one:

    // private set areLowPricesHidden(areLowPricesHidden: boolean) {
    //     // As per MobX docs "Setters are automatically marked as actions."
    //     // See: https://mobx.js.org/computeds.html#computed-setter
    //     this.areLowPricesHiddenFlag = areLowPricesHidden;
    // }

    public get totalBoard(): Board | undefined {
        if (!this.initialized) {
            // The board is not yet ready, as the store is not yet initialized,
            // i.e. the `assetsService` is also not initialized
            return undefined;
        }

        // Find the user ID
        const { userId } = this.assetsService;
        if (!userId) {
            // The board is not yet ready, as the user is not ready or not authorized
            return undefined;
        }

        // Specify no filters to be used for the .Total board
        const filters: BoardFilters = {};

        // Form the plain board instance
        const board: SDKBoard = {
            // Set the board creation time as `-1`, since we don't have it for this board kind
            created: -1,
            // Set the sorting index as `-1`, since we don't have it for the featured boards
            // as their order should be predefined
            sortingIndex: -1,
            // Build-up the board ID
            boardId: `${BoardTemplates.Total}:${userId}`,
            // Set the name of the board as "Total Board",
            // but note that it's better to be localized at the frontend client!
            name: 'Total Board',
            // Set the ID of the user this board belongs to
            userId,
            // Set the filters
            filters,
        };

        // Set the kind of the template board as .Total
        const template = {
            kind: BoardTemplates.Total,
        };

        // Get the enriched observable board
        const enrichedBoard = enrichBoard(board, { assetsStore: this, template });

        // Do not provide the board if it doesn't have any selected assets
        if (!enrichedBoard.selectedAssets.length) {
            return undefined;
        }

        // Return the enriched observable board
        return enrichedBoard;
    }

    // Extended interface methods

    public filterGroupsExcludingAssetsWithLowPriceIfNeeded = computedFn(
        <G extends Group>(groups: G[]): GroupedAssets<G> => {
            // Check if need to filter the groups
            if (
                // Check the flag if we need to hide asset with the low price
                !this.areLowPricesHidden ||
                // Also do not hide assets with the low price if all of them have the low price
                areAllAssetsWithLowPrice(groups.flatMap(({ assets }) => assets))
            ) {
                // No need to filter, just return the sorted "groups"
                return { groups: sortGroupsByTotalPrice(groups) };
            }

            // Filter the groups
            const { filteredGroups, excludedGroups } =
                filterGroupsExcludingAssetsWithLowPrice(groups);

            // Return the sorted "groups" and "hiddenGroups" if any
            return {
                groups: sortGroupsByTotalPrice(filteredGroups),
                ...(excludedGroups ? { hiddenGroups: sortGroupsByTotalPrice(excludedGroups) } : {}),
            };
        },
    );

    public getAssetsForIDs = computedFn((assetIDs: string[]): Asset[] => {
        return assetIDs.reduce<Asset[]>((acc, assetId) => {
            // Get the asset from `assetsService`
            const asset = this.assetsService.assetsMap[assetId];

            // Add it to the accumulator if it's present
            if (asset) {
                acc.push(asset);
            }

            // Return the accumulator
            return acc;
        }, []);
    });

    public getAssetsForFilters = computedFn((filters: BoardFilters): Asset[] => {
        return this.assetsService.assets.filter(asset =>
            doesAssetCorrespondsToBoardFilters(asset, filters, currenciesService.stablecoins),
        );
    });

    public historicalTotalPricesForAssets = computedFn((assets: Asset[]): HistoricalPrice[] => {
        const { historicalAssets, selectedExchangeCurrency } = this.assetsService;

        // Convert the historical assets into the historical total prices list
        return (
            Object.keys(historicalAssets)
                // Sort the dates
                .sort() // it's OK since they are just a date part of the ISO string
                // Map the dates into the historical total prices list
                .map(date => {
                    // Get the historical assets for the given date
                    const assetsMap = historicalAssets[date]!;
                    // Filter them as per the provided assets list
                    const filteredAssets = assets.reduce<Asset[]>((acc, { assetId }) => {
                        const foundAsset = assetsMap[assetId];
                        if (foundAsset) {
                            acc.push(foundAsset);
                        }
                        return acc;
                    }, []);
                    // Return the historical price for the filtered assets on a date
                    return {
                        date,
                        price: getTotalPrice(filteredAssets, selectedExchangeCurrency),
                    };
                })
                // Append the current date total price value
                .concat([
                    {
                        date: new Date().toISOString().split('T')[0]!,
                        // Calculate the total price for the current date from the given assets,
                        // since the assets are considered to be "actual" by the function design
                        price: getTotalPrice(assets, selectedExchangeCurrency),
                    },
                ])
        );
    });

    // Internals

    // Note: the code bellow is not used at the moment due to the fact that
    // this flag value is taken from the `assetsService` now as a global one:

    // /**
    //  * Method to load the `areLowPricesHidden` flag from the storage.
    //  */
    // private async loadAreLowPricesHiddenFlag() {
    //     // Get a storage key to keep the `areLowPricesHidden` flag
    //     // Note: could throw if the user is not authorized
    //     const storageKey = getStorageKey(ARE_LOW_PRICES_HIDDEN_KEY);

    //     // Get a stored `areLowPricesHidden` flag value
    //     const areLowPricesHidden = await appStorage.getItem(storageKey);

    //     // Set the `areLowPricesHidden` flag to the stored one if present.
    //     if (areLowPricesHidden) {
    //         this.areLowPricesHidden = JSON.parse(areLowPricesHidden) as boolean;
    //     }
    // }

    // /**
    //  * Method to unload the `areLowPricesHidden` flag  to its default state.
    //  */
    // private unloadAreLowPricesHiddenFlag() {
    //     this.areLowPricesHidden = DEFAULT_ARE_LOW_PRICES_HIDDEN_VALUE;
    // }

    // /**
    //  * Method to save the `areLowPricesHidden` flag value in the storage.
    //  * @param areLowPricesHidden - the `areLowPricesHidden` flag value value to store.
    //  */
    // private async saveAreLowPricesHiddenFlag(areLowPricesHidden: boolean) {
    //     // Get a storage key to keep the `areLowPricesHidden` flag
    //     // Note: could throw if the user is not authorized
    //     const storageKey = getStorageKey(ARE_LOW_PRICES_HIDDEN_KEY);

    //     // Save the selected exchange currency into the storage
    //     await appStorage.setItem(storageKey, areLowPricesHidden.toString());
    // }
}
