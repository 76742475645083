import { computed, makeObservable } from 'mobx';

import { CommonStore } from '@smartfolly/common.utilities';

import type { Asset, IAssetsService } from '@smartfolly/frontend.assets-service';

import { filterBoardsWithAsset } from '../utils';

import type {
    AddBoardOptions,
    Board,
    DeleteBoardOptions,
    EditBoardOptions,
    IBoardsService,
} from '../types';

import { AssetsStore, BoardsStore } from './stores';

type BoardsServiceOptions = {
    /**
     * An instance of the AssetsService to work with when dealing with the boards.
     * Note: the passed service MUST be loaded and initialized, i.e. be ready to work with.
     */
    assetsService: IAssetsService;
};

export class BoardsService extends CommonStore implements IBoardsService {
    // Properties

    /**
     * An instance of the AssetsService to work with when dealing with the boards.
     */
    private assetsService: IAssetsService;

    /**
     * A store responsible for the assets data.
     */
    private assetsStore: AssetsStore;

    /**
     * A store responsible for the boards loading.
     */
    private boardsStore: BoardsStore;

    // Constructor

    public constructor(options: BoardsServiceOptions) {
        super();

        this.assetsService = options.assetsService;

        this.assetsStore = new AssetsStore(options);
        this.boardsStore = new BoardsStore(options);

        makeObservable<BoardsService>(this, {
            boards: computed,
            featuredBoards: computed,
            isRefreshingBoards: computed,
            isAddingBoard: computed,
            isEditingBoard: computed,
            isDeletingBoard: computed,
            areLowPricesHidden: computed,
        });
    }

    // Interface

    protected onLoad = async () => {
        // Ensure `assetsService` is initialized to work with
        if (!this.assetsService.initialized) {
            throw new Error('AssetsService instance is not initialized to load BoardsService');
        }

        // Load the assets data first (i.e. ensure it's present)
        await this.assetsStore.load();

        // Load the boards
        await this.boardsStore.load();
    };

    protected onUnload = async () => {
        // Unload the boards
        await this.boardsStore.unload();

        // Unload the assets data
        await this.assetsStore.unload();
    };

    public get boards(): Board[] {
        return this.assetsStore.enrichBoards(this.boardsStore.boards);
    }

    public get featuredBoards(): Board[] {
        // Prepare a list of featured boards
        const boards = [];

        // Add the "Total" board if it's ready
        // Note: currently no other "featured" boards, but "Total" are supported
        const { totalBoard } = this.assetsStore;
        if (totalBoard) {
            boards.push(totalBoard);
        }

        // Return the featured boards
        return boards;
    }

    public get isLoadingBoards(): boolean {
        return this.boardsStore.isLoadingBoards;
    }

    public get isRefreshingBoards(): boolean {
        return this.boardsStore.isRefreshingBoards;
    }

    public get isAddingBoard(): boolean {
        return this.boardsStore.isAddingBoard;
    }

    public get isEditingBoard(): boolean {
        return this.boardsStore.isEditingBoard;
    }

    public get isDeletingBoard(): boolean {
        return this.boardsStore.isDeletingBoard;
    }

    public get areLowPricesHidden(): boolean {
        return this.assetsStore.areLowPricesHidden;
    }

    public refreshBoards = async (): Promise<boolean> => {
        return this.boardsStore.refreshBoards();
    };

    public addBoard = async (options: AddBoardOptions): Promise<Board | false> => {
        const board = await this.boardsStore.addBoard(options);
        if (!board) {
            return false;
        }

        return this.assetsStore.enrichBoards([board])[0]!;
    };

    public editBoard = async (options: EditBoardOptions): Promise<Board | false> => {
        const board = await this.boardsStore.editBoard(options);
        if (!board) {
            return false;
        }

        return this.assetsStore.enrichBoards([board])[0]!;
    };

    public deleteBoard = async (options: DeleteBoardOptions): Promise<boolean> => {
        return this.boardsStore.deleteBoard(options);
    };

    public toggleHidingLowPrices = async (hideLowPrices: boolean) => {
        return this.assetsStore.toggleHidingLowPrices(hideLowPrices);
    };

    public getAssetBoards = (asset: Asset): Board[] => {
        const foundFeaturedBoards = filterBoardsWithAsset(this.featuredBoards, asset);
        const foundRegularBoards = filterBoardsWithAsset(this.boards, asset);
        return foundFeaturedBoards.concat(foundRegularBoards);
    };
}
