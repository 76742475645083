import { templatesService } from '../services';

// TODO: implement a service which should manage the ordering of modals to show
// in order to replace the following hook with it

/**
 * Hook to learn if we possible need to show the templates to add.
 * Note: this can be used to find out if we can show other kinds of modals.
 */
export function usePossiblyNeedToShowTemplatesToAdd() {
    const { readyToAddTemplateKinds, readyToShowTemplatesToAdd } = templatesService;

    // Check if "ready-to-show" status is unknown
    if (readyToShowTemplatesToAdd === undefined) {
        // Possible we'll need to show the templates to add.
        return true;
    }

    // Check if "ready-to-add" status is unknown
    if (readyToAddTemplateKinds === undefined) {
        // Possible we'll need to show the templates to add.
        return true;
    }

    // Check if "ready-to-show" status is positive and we have template kinds to add
    if (readyToShowTemplatesToAdd === true && readyToAddTemplateKinds.size > 0) {
        // We'll definitely need to show the templates to add
        return true;
    }

    // No need to show the templates to add
    return false;
}
