import { memo, useRef, useState } from 'react';

import * as amplitude from '@amplitude/analytics-browser';

import { Button, Flex, FlexContainer, Icon, Modal } from '@smartfolly/frontend.web-ui';

import classNames from 'classnames/bind';

import CopyToClipboard from 'react-copy-to-clipboard';
import { ClearableInput } from '../Common';
import styles from './Connect.module.scss';
import { createWaiter, type WaitlistEntry } from '../../helpers';
import { validateEmail } from '../../utils/validation';
import { showToast } from '../../utils';

const cnb = classNames.bind(styles);

const linkCopied = () => {
    showToast('Referral link copied to clipboard');
};

export const GetProModal = memo(function GetProModal({ hide }: { hide: () => void }) {
    const [waitlistData, setWaitlistData] = useState<WaitlistEntry>();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    // Refs

    const nameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);

    // Actions

    const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submitWaiter(emailRef.current?.value ?? '', nameRef.current?.value ?? '');
    };

    const submitWaiter = async (email: string, name: string) => {
        setError(undefined);
        if (!email) {
            setError('Please enter your email');
            return;
        }
        if (validateEmail(email) === false) {
            setError('Please enter a valid email');
            return;
        }

        setLoading(true);

        //  * No try/catch here, as it was it handled inside the function
        const responseData = await createWaiter({ email, name });
        if (responseData === null) {
            setError('Something went wrong. Please try again later.');
        } else {
            amplitude.logEvent('Get Pro Waitlist waiter added');
        }
        setWaitlistData(responseData ?? undefined);

        setLoading(false);
    };

    const clearError = () => {
        setError(undefined);
    };

    return (
        <Modal
            className={cnb('get-pro-modal')}
            show
            onHide={hide}
            header={
                <FlexContainer justify="space-between" align="center">
                    {!waitlistData ? (
                        <Flex className="title title-large widget-text-primary">
                            Subscription plan coming soon from&nbsp;$6.99 per month
                        </Flex>
                    ) : (
                        <Flex className="title title-large widget-text-primary">
                            Thank you for signing up!
                        </Flex>
                    )}
                </FlexContainer>
            }
        >
            <div>
                {!waitlistData ? (
                    <form onSubmit={formSubmit}>
                        <FlexContainer direction="column">
                            <Flex className="paragraph paragraph-medium color-text-secondary">
                                Unleash the Full Mooli Potential! Upgrade to our Pro plan
                                and&nbsp;unlock advanced features designed to elevate
                                your&nbsp;experience to new heights.
                            </Flex>
                            <Flex className="m-t-1">
                                <ul>
                                    <li className="action action-normal">
                                        Explore transactions from your wallets
                                    </li>
                                    <li className="action action-normal">
                                        Follow Whales portfolios
                                    </li>
                                    <li className="action action-normal">
                                        Download monthly balances
                                    </li>
                                    <li className="action action-normal">
                                        Explore transactions from your wallets
                                    </li>
                                    <li className="action action-normal">
                                        Advanced token & market indicators
                                    </li>
                                </ul>
                            </Flex>
                            <Flex className="m-t-1">
                                <div className="title title-normal color-text-accent border border-accent b-r-3 p-3">
                                    Leave your email today and enjoy a special 30% discount
                                    for&nbsp;the first 3 months!
                                </div>
                            </Flex>
                            <Flex className="m-t-1">
                                <ClearableInput
                                    ref={nameRef}
                                    type="text"
                                    className="back-secondary color-text-bw border-secondary p-b-0.75 p-t-0.75 p-l-0.75 p-r-0.5"
                                    btnClassName="back-secondary p-l-1.5 p-r-1.5 p-b-0.25 p-t-0.25"
                                    placeholder="Your name"
                                    onChange={clearError}
                                />
                            </Flex>
                            <Flex className="m-t-1">
                                <ClearableInput
                                    ref={emailRef}
                                    type="text"
                                    className="back-secondary color-text-bw border-secondary p-b-0.75 p-t-0.75 p-l-0.75 p-r-0.5"
                                    btnClassName="back-secondary p-l-1.5 p-r-1.5 p-b-0.25 p-t-0.25"
                                    placeholder="Email"
                                    onChange={clearError}
                                />
                            </Flex>
                            <Flex>
                                {error && (
                                    <div className="text-center m-t-1 text-negative">{error}</div>
                                )}
                            </Flex>
                            <Flex className="m-t-1">
                                <Button
                                    className="btn btn-primary btn-large m-b-0.25 w-100 p-t-1 p-b-1 h-auto disabled:back-secondary"
                                    disabled={loading}
                                >
                                    <span className="action action-normal">Join the waitlist</span>
                                </Button>
                            </Flex>
                        </FlexContainer>
                    </form>
                ) : (
                    <FlexContainer direction="column">
                        <Flex className="paragraph paragraph-medium color-text-secondary">
                            We will let you know once PRO subscription is available. In case of
                            query please reach out to us via Discord chat.
                        </Flex>
                        <Flex className="m-t-1">
                            <FlexContainer
                                direction="column"
                                justify="stretch"
                                align="stretch"
                                className={cnb('profile-modal-link-box')}
                            >
                                <Flex className="title title-normal m-b-0.5">Referral link</Flex>
                                <Flex className="title title-normal">
                                    <FlexContainer direction="row" justify="stretch" align="center">
                                        <Flex>
                                            <Icon icon="link-mini" />
                                        </Flex>
                                        <Flex className="action action-default">
                                            {waitlistData.referral_link}
                                        </Flex>
                                        <Flex>
                                            <CopyToClipboard
                                                text={waitlistData.referral_link}
                                                onCopy={linkCopied}
                                            >
                                                <Button className={cnb('profile-modal-copy-btn')}>
                                                    <Icon icon="copy-mini" />
                                                </Button>
                                            </CopyToClipboard>
                                        </Flex>
                                    </FlexContainer>
                                </Flex>
                                <Flex className="paragraph paragraph-small color-text-secondary" />
                            </FlexContainer>
                        </Flex>
                        <Flex className="m-t-1">
                            <Button
                                className="btn btn-primary btn-large m-b-0.25 w-100 p-t-1 p-b-1 h-auto disabled:back-secondary"
                                disabled={loading}
                                onClick={hide}
                            >
                                <span className="action action-normal">OK</span>
                            </Button>
                        </Flex>
                    </FlexContainer>
                )}
            </div>
        </Modal>
    );
});
