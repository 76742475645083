import type { Currency } from '@smartfolly/frontend.currencies-service';

import type { Asset } from '../../types';

import {
    get1InchLink,
    get1InchLinkForAsset,
    getCelerBridgeLink,
    getCelerBridgeLinkForAsset,
    getMercuryoLink,
    getMercuryoLinkForAsset,
} from './providers';

import type { QuickActionsLinks } from './types';
import { getStakingLinkForAsset } from './stakingLinks';

/**
 * Function get the quick actions links to buy, sell, bridge, stake and swap.
 */
export function getQuickActionsLinks(): QuickActionsLinks {
    return {
        buy: getMercuryoLink('buy'),

        sell: getMercuryoLink('sell'),

        swap: get1InchLink(),

        bridge: getCelerBridgeLink(),

        stake: 'https://www.stakingrewards.com/providers', // the list of all known providers
    };
}

/**
 * Function get the quick actions links to buy, sell, bridge, stake and swap for an asset.
 * @param asset - an asset to get the links for.
 * @param fiatCurrency - a fiat currency the work with when dealing with the action over the asset.
 */
export function getQuickActionsLinksForAsset(
    asset: Asset,
    fiatCurrency: Currency,
): QuickActionsLinks {
    return {
        buy: getMercuryoLinkForAsset({ asset, fiatCurrency, type: 'buy' }),

        sell: getMercuryoLinkForAsset({ asset, fiatCurrency, type: 'sell' }),

        swap: get1InchLinkForAsset(asset),

        bridge: getCelerBridgeLinkForAsset(asset),

        stake: getStakingLinkForAsset(asset),
    };
}
