import { memo } from 'react';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

export const UnauthorizedModalHeader = memo(function UnauthorizedModalHeader() {
    return (
        <FlexContainer justify="space-between" align="center">
            <Flex className="title title-large widget-text-primary">Start tracking</Flex>
        </FlexContainer>
    );
});
