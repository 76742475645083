import { serverConnector } from '@smartfolly/middleware.server-connector';

import { userExchangesSubscription, usersCollection } from '../../constants';

import type {
    ProvidedExchange,
    ProvidedExchanges,
    UserExchangesListener,
    UserExchangesListenerDisposer,
} from '../../types';

type UserWithExchanges = {
    /**
     * An ID of the user.
     */
    userId: string;

    /**
     * An array of the exchanges provided by the user to be scanned in order to find assets.
     */
    providedExchanges: ProvidedExchange[];
};

/**
 * A function to subscribe to the user exchanges data change.
 * @param userId - an ID of the user to subscribe to the exchanges data change for.
 * @param listener - a user exchanges listener which should resolve updated exchanges.
 * @returns a user exchanges listener disposer to stop the subscription.
 */
export function subscribeToExchanges(
    userId: string,
    listener: UserExchangesListener,
): UserExchangesListenerDisposer {
    // Proxy a listener to converting the resolved structure into the map of user exchanges
    const proxyListener = (error: Error | undefined, user?: UserWithExchanges | null) => {
        const providedExchanges =
            user != null
                ? user.providedExchanges.reduce<ProvidedExchanges>((acc, { sourceId, ...rest }) => {
                      acc[sourceId] = rest;
                      return acc;
                  }, {})
                : null;
        listener(error, providedExchanges);
    };

    return serverConnector.subscribe<{ userId: string }, UserWithExchanges>(
        {
            collection: usersCollection,
            query: userExchangesSubscription,
            variables: {
                userId,
            },
        },
        proxyListener,
    );
}
