import { isDesktop } from 'react-device-detect';

import {
    ethereumMainnetChain,
    getTonConnectWallets,
    getWalletConnectWallets,
} from '@smartfolly/common.providers';
import { Async, Log, WaitWithPromise } from '@smartfolly/common.utilities';

import { AssetsManagerError } from '@smartfolly/sdk';

import { Icon } from '@smartfolly/frontend.web-ui';

// Use an `assetsService` instance bound to the current user,
// since this is the only one which can provide a wallet data
import { assetsService } from '../../services';

const log = new Log('loadWalletProviders');

type ProvideOptions = {
    /**
     * A function to be called before adding provided addresses.
     * Note: might be useful in case we want to authorize the user right before adding addresses.
     */
    prepareToAddAddresses: () => Promise<void>;
};

export type WalletProvider = {
    /**
     * A name of the wallet.
     */
    name: string;

    /**
     * An image of the wallet.
     * Can either be an URL or a React component.
     */
    image: string | React.ReactElement;

    /**
     * A method to provide addresses of the wallet.
     * @param options - options to provide the addresses with.
     */
    provide: (options: ProvideOptions) => Promise<boolean>;
};

/**
 * Function to get the wallet providers list.
 * @returns the list of wallet providers.
 */
async function getWalletProviders(): Promise<WalletProvider[]> {
    // Prepare the list of wallet providers
    const walletProviders: WalletProvider[] = [];

    // Add MetaMask wallet provider
    walletProviders.push({
        name: 'MetaMask',
        image: <Icon icon="metamask" />,
        provide: async ({ prepareToAddAddresses }) => {
            if (!isDesktop) {
                throw AssetsManagerError.Providers.MetaMaskExt.NoProviderFound;
            }

            return assetsService.addAddressViaMetamask({ prepareToAddAddresses });
        },
    });

    // Add Phantom wallet provider
    walletProviders.push({
        name: 'Phantom',
        image: <Icon icon="phantom" />,
        provide: async ({ prepareToAddAddresses }) => {
            if (!isDesktop) {
                throw AssetsManagerError.Providers.Phantom.NoProviderFound;
            }

            return assetsService.addAddressViaPhantom({ prepareToAddAddresses });
        },
    });

    // Add Solflare wallet provider
    walletProviders.push({
        name: 'Solflare',
        image: <Icon icon="solflare" />,
        provide: async ({ prepareToAddAddresses }) => {
            if (!isDesktop) {
                throw AssetsManagerError.Providers.Solflare.NoProviderFound;
            }

            return assetsService.addAddressViaSolflare({ prepareToAddAddresses });
        },
    });

    // Add Surf Keeper wallet provider
    walletProviders.push({
        name: 'Surf Keeper',
        image: <Icon icon="surf-keeper" />,
        provide: async ({ prepareToAddAddresses }) => {
            if (!isDesktop) {
                throw AssetsManagerError.Providers.SurfKeeperExt.NoProviderFound;
            }

            return assetsService.addAddressViaSurfKeeper({ prepareToAddAddresses });
        },
    });

    // Add TonConnect wallets providers
    const tonConnectWallets = await getTonConnectWallets();
    tonConnectWallets.forEach(wallet => {
        walletProviders.push({
            name: wallet.name,
            image: wallet.imageUrl,
            provide: async ({ prepareToAddAddresses }) => {
                return assetsService.addAddressViaTonConnect({ prepareToAddAddresses });
            },
        });
    });

    // Add WalletConnect wallets providers based on WalletConnect Protocol v2
    const walletConnectWallets = await getWalletConnectWallets({
        version: 2,
        chains: ethereumMainnetChain.id, // for now we work only with wallets which support Ethereum
    });
    walletConnectWallets.forEach(wallet => {
        walletProviders.push({
            name: wallet.name,
            image: wallet.imageUrl,
            provide: async ({ prepareToAddAddresses }) => {
                return assetsService.addAddressViaWalletConnect({
                    prepareToAddAddresses,
                    walletIds: [wallet.id],
                });
            },
        });
    });

    // Return available wallet providers
    return walletProviders;
}

/**
 * A awaiting promise instance to cache the available list of wallet providers.
 */
let waitWithPromise: WaitWithPromise<WalletProvider[]> | undefined;

/**
 * Function to load the wallet providers list and cache it.
 * @returns the list of wallet providers.
 */
export async function loadWalletProviders(): Promise<WalletProvider[]> {
    if (waitWithPromise) {
        return waitWithPromise.value;
    }

    waitWithPromise = Async.waitWithPromise<WalletProvider[]>();

    try {
        const walletProviders = await getWalletProviders();

        if (waitWithPromise) {
            waitWithPromise.resolve(walletProviders);
        }

        return walletProviders;
    } catch (error) {
        log.error('Failed to load wallet providers with error:', error);

        if (waitWithPromise) {
            waitWithPromise.reject(error);
            // Clear the awaiting promise in order to allow it be re-loaded on the next try
            waitWithPromise = undefined;
        }

        throw error;
    }
}
