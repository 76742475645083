import type { ProviderName } from '@smartfolly/common.providers';

import { AddressProvider, AssetsManagerError } from '../../constants';

import type { ProvideAddressesOptions } from '../../types';

import {
    metaMastExtProvider,
    type IAddressProvider,
    phantomProvider,
    solflareProvider,
    surfKeeperExtProvider,
    tonConnectProvider,
    walletConnectProvider,
    WalletConnectProvider,
} from './providers';

/**
 * Find the provider instance to get and address from.
 * @param provider - a name of the provider to get the provider instance for.
 * @throws - a {@link AssetsManagerError.UnsupportedProvider} error if the provider is not found.
 */
export function findAddressProvider<Provider extends AddressProvider>(
    provider: Provider,
    parameters: ProvideAddressesOptions<Provider>['parameters'],
): IAddressProvider<ProviderName> {
    if (provider === AddressProvider.MetaMaskExt) {
        return metaMastExtProvider;
    }

    if (provider === AddressProvider.Phantom) {
        return phantomProvider;
    }

    if (provider === AddressProvider.Solflare) {
        return solflareProvider;
    }

    if (provider === AddressProvider.SurfKeeperExt) {
        return surfKeeperExtProvider;
    }

    if (provider === AddressProvider.TonConnect) {
        return tonConnectProvider;
    }

    if (provider === AddressProvider.WalletConnect) {
        // Check if recommended wallet IDs are provided
        const { walletIds } =
            parameters as ProvideAddressesOptions<AddressProvider.WalletConnect>['parameters'];

        // Return the regular wallet connect provider if not
        if (!walletIds) {
            return walletConnectProvider;
        }

        // Create the one for the given wallets list
        return new WalletConnectProvider(walletIds);
    }

    throw AssetsManagerError.UnsupportedProvider;
}
