import BigNumber from 'bignumber.js';

import { calculateAndLocalePercentage, reflectValueChanges } from '@smartfolly/common.utilities';

import { priceChangeToString, priceToString } from '@smartfolly/frontend.currencies-service';
import type { Currency } from '@smartfolly/frontend.currencies-service';

import type { Asset, Price } from '../types';

/**
 * Function to get the total price of the given assets.
 * @param assets - a list of the assets to get the total price for.
 * @param currency - a currency specified for the total price to be calculated in.
 * Note: given assets should have the same currency as specified in the {@link currency} parameter.
 * @returns the total price value.
 */
export function getTotalPrice(assets: Asset[], currency: Currency): Price {
    if (!assets.length) {
        // No assets means a zero total price
        const value = new BigNumber(0);

        // Return a price object for zero total price
        return {
            currency,
            // price change is undefined in this case
            change24h: {
                string: undefined,
                value: undefined,
            },
            high24h: {
                string: undefined,
                value: undefined,
            },
            low24h: {
                string: undefined,
                value: undefined,
            },
            percentChange24h: {
                string: undefined,
                value: undefined,
            },
            value,
            string: priceToString(value, currency),
        };
    }

    // Find the total price of the assets
    const value = assets.reduce<BigNumber | undefined>((acc, asset) => {
        // Check the asset has the same currency in its price as the specified currency
        if (asset.price.currency !== currency) {
            throw new Error(
                'Attempt to add an asset price to the total price with different currency',
            );
        }

        // Add the asset balance to the total price if the price is present
        return asset.price.value ? asset.price.value.plus(acc ?? new BigNumber(0)) : acc;
    }, undefined);

    // Find the lowest total price during the 24 hours period as
    // asset1.price.low24h + ... + assetN.price.low24
    const lowestTotalPrice24h: BigNumber | undefined =
        value != null // there is no need to try calculating if the total price value is missing
            ? assets.reduce<BigNumber>((acc, asset) => {
                  return asset.price.low24h.value != null
                      ? acc.plus(asset.price.low24h.value)
                      : acc;
              }, new BigNumber(0))
            : undefined;

    // Find the highest total price during the 24 hours period as
    // asset1.price.high24h + ... + assetN.price.high24
    const highestTotalPrice24h: BigNumber | undefined =
        value != null // there is no need to try calculating if the total price value is missing
            ? assets.reduce<BigNumber>((acc, asset) => {
                  return asset.price.high24h.value != null
                      ? acc.plus(asset.price.high24h.value)
                      : acc;
              }, new BigNumber(0))
            : undefined;

    // Find the total price change during the 24 hours period as
    // asset1.price.change24h + ... + assetN.price.change24h
    const totalPriceChange24h: BigNumber | undefined =
        value != null // there is no need to try calculating if the total price value is missing
            ? assets.reduce<BigNumber>((acc, asset) => {
                  return asset.price.change24h.value != null
                      ? acc.plus(asset.price.change24h.value)
                      : acc;
              }, new BigNumber(0))
            : undefined;

    // Find the precise total price change during the 24 hours period as
    // asset1.price * asset1.percentChange24h + ... + assetN.price * assetN.percentChange24h
    // to calculate the percent change during the 24 hours period properly
    // since CoinMarketCap returns not a precise `change24` value for assets, i.e.
    // asset.price.change24h COULD BE NOT EQUAL to asset.price.value * asset.price.percentChange24h,
    // where `percentChange24h` is also received from the CoinMarketCap.
    // Note: with this approach we solve the problem of displaying the same data
    // for a single asset and a group of assets with a single asset!
    const preciseTotalPriceChange24h: BigNumber | undefined =
        value != null // there is no need to try calculating if the total price value is missing
            ? assets.reduce<BigNumber>((acc, asset) => {
                  return asset.price.value != null && asset.price.percentChange24h.value != null
                      ? acc.plus(asset.price.value.times(asset.price.percentChange24h.value))
                      : acc;
              }, new BigNumber(0))
            : undefined;

    // Find the total price percent change using the total price change during the 24 hours period
    const percentChange24h =
        preciseTotalPriceChange24h != null && value != null
            ? reflectValueChanges(calculateAndLocalePercentage(preciseTotalPriceChange24h, value))
            : { string: undefined, value: undefined };

    // Return the total price object
    return {
        currency,
        change24h: {
            string:
                totalPriceChange24h != null
                    ? priceChangeToString(totalPriceChange24h, currency)
                    : undefined,
            value: totalPriceChange24h,
        },
        high24h: {
            string:
                highestTotalPrice24h != null
                    ? priceToString(highestTotalPrice24h, currency)
                    : undefined,
            value: highestTotalPrice24h,
        },
        low24h: {
            string:
                lowestTotalPrice24h != null
                    ? priceToString(lowestTotalPrice24h, currency)
                    : undefined,
            value: lowestTotalPrice24h,
        },
        percentChange24h,
        string: value != null ? priceToString(value, currency) : undefined,
        value,
    };
}
