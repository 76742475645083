import classnames from 'classnames/bind';
import { memo } from 'react';

import { Flex, FlexContainer } from '@smartfolly/frontend.web-ui';

import styles from '../Connect.module.scss';

const cnb = classnames.bind(styles);

export const UserExchanges = memo(function UserExchanges() {
    // Render

    return (
        <FlexContainer direction="column" justify="stretch" align="stretch">
            <Flex className={`${cnb('main-address')} title title-small color-text-secondary`}>
                <FlexContainer direction="row" justify="stretch" align="center">
                    <Flex>Exchanges</Flex>
                </FlexContainer>
            </Flex>
        </FlexContainer>
    );
});
