import type { Asset } from '@smartfolly/frontend.assets-service';

import type { Board } from '../types';

/**
 * Function to filter to boards which have the specified asset.
 * @param boards - a list of boards to filter.
 * @param asset - an asset which should belong to the board.
 * @returns a list of boards with the specified asset.
 */
export function filterBoardsWithAsset(boards: Board[], { assetId }: Asset): Board[] {
    return boards.filter(
        // Check if the board has a selected asset with an ID, which is equal to the given asset ID
        board => !!board.selectedAssets.find(boardAsset => boardAsset.assetId === assetId),
    );
}
