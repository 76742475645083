import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import type { ItemContent, TableComponents } from 'react-virtuoso';
import { TableVirtuoso } from 'react-virtuoso';

import type { TokenGroup } from '@smartfolly/frontend.assets-service';

import { assetsService } from '../../../services';

import { ActiveEmptyPlaceholder } from '../ActiveEmptyPlaceholder';
import { FixedFooterContent, FixedHeaderContent } from '../FixedListContent';
import { Footer } from '../Footer';

import styles from '../Assets.module.scss';

import { TokenCell } from './TokenCell';

const cnb = classnames.bind(styles);

function EmptyTokensPlaceholder() {
    return <ActiveEmptyPlaceholder active="tokens" />;
}

const listComponents: TableComponents<TokenGroup> = {
    EmptyPlaceholder: EmptyTokensPlaceholder,
    TableFoot: Footer,
};

export const ActiveTokens = observer(function ActiveTokens() {
    const {
        filteredTokens: { groups },
    } = assetsService;

    const renderTokens: ItemContent<TokenGroup, unknown> = useCallback(
        (_index, group) => <TokenCell group={group} />,
        [],
    );

    return (
        <TableVirtuoso
            className={cnb('grid-wrapper', 'table-wrapper')}
            data={groups}
            totalCount={groups.length}
            fixedHeaderContent={FixedHeaderContent}
            fixedFooterContent={FixedFooterContent}
            itemContent={renderTokens}
            fixedItemHeight={56}
            components={listComponents}
            useWindowScroll
        />
    );
});
