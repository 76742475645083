import { createWithOptions } from '@smartfolly/frontend.releases-service';

import { isOnboardingFinished } from '../../utils';

import versionFile from '../../version.json';

import { authService } from '../AuthService';

// Create the ReleasesService for my user and the current app version to work with
export const releasesService = createWithOptions({
    authService,
    appVersion: versionFile.version,
    isFirstAppLaunch: !isOnboardingFinished(), // a first app launch if the onboarding isn't finish
});
