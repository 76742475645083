import { WalletInfo, WalletsListManager } from '@tonconnect/ui';

/**
 * Utility to get a list of wallets which support TonConnect.
 * @returns a list of wallets.
 */
export async function getTonConnectWallets(): Promise<WalletInfo[]> {
    const walletsListManager = new WalletsListManager();
    return walletsListManager.getWallets();
}
