import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import type { ItemContent, TableComponents } from 'react-virtuoso';
import { TableVirtuoso } from 'react-virtuoso';

import type { BlockchainGroup } from '@smartfolly/frontend.assets-service';

import { assetsService } from '../../../services';

import { ActiveEmptyPlaceholder } from '../ActiveEmptyPlaceholder';
import { FixedFooterContent, FixedHeaderContent } from '../FixedListContent';
import { Footer } from '../Footer';

import styles from '../Assets.module.scss';

import { BlockchainCell } from './BlockchainCell';

const cnb = classnames.bind(styles);

function EmptyBlockchainsPlaceholder() {
    return <ActiveEmptyPlaceholder active="blockchains" />;
}

const listComponents: TableComponents<BlockchainGroup> = {
    EmptyPlaceholder: EmptyBlockchainsPlaceholder,
    TableFoot: Footer,
};

export const ActiveBlockchains = observer(function ActiveBlockchains() {
    const {
        filteredBlockchains: { groups },
    } = assetsService;

    const renderBlockchain: ItemContent<BlockchainGroup, unknown> = useCallback(
        (_index, group) => <BlockchainCell group={group} />,
        [],
    );

    return (
        <TableVirtuoso
            className={cnb('grid-wrapper', 'table-wrapper')}
            data={groups}
            totalCount={groups.length}
            fixedHeaderContent={FixedHeaderContent}
            fixedFooterContent={FixedFooterContent}
            itemContent={renderBlockchain}
            fixedItemHeight={56}
            components={listComponents}
            useWindowScroll
        />
    );
});
