import { createContext, useContext, useMemo, useState } from 'react';

type EditingWalletsContextType = {
    /**
     * Selected wallets to edit.
     */
    readonly selectedWallets: string[];

    /**
     * Set the selected wallets list.
     */
    setSelectedWallets: React.Dispatch<React.SetStateAction<string[]>>;
};

const EditingWalletsContext = createContext<EditingWalletsContextType | undefined>(undefined);

/**
 * Hook to work with the adding address.
 */
export function useEditingWallets(): EditingWalletsContextType {
    const value = useContext(EditingWalletsContext);

    if (!value) {
        throw new Error('Have you forgot to wrap component with `EditingWalletsProvider`?');
    }

    return value;
}

export function EditingWalletsProvider({ children }: React.PropsWithChildren) {
    // States

    const [selectedWallets, setSelectedWallets] = useState<string[]>([]);

    // Getters

    const value = useMemo(() => ({ selectedWallets, setSelectedWallets }), [selectedWallets]);

    // Render

    return (
        <EditingWalletsContext.Provider value={value}>{children}</EditingWalletsContext.Provider>
    );
}
