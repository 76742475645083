/* eslint-disable max-lines */
import { memo } from 'react';

export const CurrencyIcon = memo(function CurrencyIcon({
    icon,
    className = '',
}: {
    icon: string;
    className?: string;
}) {
    switch (icon.toLocaleLowerCase()) {
        case 'usd':
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_5146_6655)">
                        <path
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                            fill="#F0F0F0"
                        />
                        <path
                            d="M11.4785 11.9996H24.0002C24.0002 10.9165 23.8559 9.86725 23.5868 8.86914H11.4785V11.9996Z"
                            fill="#D80027"
                        />
                        <path
                            d="M11.4785 5.73885H22.239C21.5044 4.54016 20.5651 3.48065 19.47 2.6084H11.4785V5.73885Z"
                            fill="#D80027"
                        />
                        <path
                            d="M12.0001 23.9993C14.8243 23.9993 17.4201 23.0232 19.4699 21.3906H4.53027C6.58012 23.0232 9.17591 23.9993 12.0001 23.9993Z"
                            fill="#D80027"
                        />
                        <path
                            d="M1.76146 18.2603H22.2389C22.8286 17.298 23.286 16.2462 23.5868 15.1299H0.413574C0.714371 16.2462 1.17173 17.298 1.76146 18.2603Z"
                            fill="#D80027"
                        />
                        <path
                            d="M5.55863 1.87397H6.65217L5.63498 2.61295L6.02353 3.80869L5.00639 3.0697L3.98925 3.80869L4.32487 2.7757C3.42928 3.52172 2.64431 4.39575 1.99744 5.36963H2.34783L1.70034 5.84002C1.59947 6.0083 1.50272 6.17925 1.41 6.35273L1.71919 7.30434L1.14234 6.88523C0.998953 7.18903 0.867797 7.49967 0.749906 7.81678L1.09055 8.86528H2.34783L1.33064 9.60427L1.71919 10.8L0.702047 10.061L0.0927656 10.5037C0.0317812 10.9939 0 11.4932 0 12H12C12 5.37262 12 4.59131 12 0C9.62944 0 7.41961 0.687656 5.55863 1.87397ZM6.02353 10.8L5.00639 10.061L3.98925 10.8L4.3778 9.60427L3.36061 8.86528H4.61789L5.00639 7.66955L5.39489 8.86528H6.65217L5.63498 9.60427L6.02353 10.8ZM5.63498 6.10861L6.02353 7.30434L5.00639 6.56536L3.98925 7.30434L4.3778 6.10861L3.36061 5.36963H4.61789L5.00639 4.17389L5.39489 5.36963H6.65217L5.63498 6.10861ZM10.3279 10.8L9.31073 10.061L8.29359 10.8L8.68214 9.60427L7.66495 8.86528H8.92223L9.31073 7.66955L9.69923 8.86528H10.9565L9.93933 9.60427L10.3279 10.8ZM9.93933 6.10861L10.3279 7.30434L9.31073 6.56536L8.29359 7.30434L8.68214 6.10861L7.66495 5.36963H8.92223L9.31073 4.17389L9.69923 5.36963H10.9565L9.93933 6.10861ZM9.93933 2.61295L10.3279 3.80869L9.31073 3.0697L8.29359 3.80869L8.68214 2.61295L7.66495 1.87397H8.92223L9.31073 0.678234L9.69923 1.87397H10.9565L9.93933 2.61295Z"
                            fill="#0052B4"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_5146_6655">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case 'eur':
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_5146_2184)">
                        <path
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                            fill="#0052B4"
                        />
                        <path
                            d="M12.0003 4.69531L12.3888 5.89105H13.646L12.6289 6.63003L13.0174 7.82577L12.0003 7.08673L10.9831 7.82577L11.3716 6.63003L10.3545 5.89105H11.6117L12.0003 4.69531Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M6.83523 6.83479L7.95554 7.40554L8.84453 6.5166L8.64779 7.75832L9.76806 8.32912L8.52629 8.5258L8.32961 9.76762L7.75881 8.64735L6.51709 8.84409L7.40607 7.9551L6.83523 6.83479Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M4.6958 11.9993L5.89153 11.6107V10.3535L6.63047 11.3707L7.82625 10.9822L7.08718 11.9993L7.82625 13.0164L6.63047 12.6279L5.89153 13.6451V12.3878L4.6958 11.9993Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M6.83523 17.1642L7.40603 16.0439L6.51709 15.1549L7.75886 15.3517L8.32956 14.2314L8.52629 15.4732L9.76801 15.6699L8.64789 16.2407L8.84453 17.4824L7.95554 16.5934L6.83523 17.1642Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M12.0003 19.3042L11.6117 18.1085H10.3545L11.3717 17.3695L10.9831 16.1738L12.0003 16.9128L13.0174 16.1738L12.6289 17.3695L13.646 18.1085H12.3888L12.0003 19.3042Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M17.1652 17.1642L16.0449 16.5935L15.1559 17.4825L15.3526 16.2406L14.2324 15.6699L15.4741 15.4732L15.6708 14.2314L16.2416 15.3517L17.4833 15.1549L16.5943 16.044L17.1652 17.1642Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M19.3047 11.9993L18.1089 12.3878V13.6451L17.37 12.6279L16.1743 13.0164L16.9133 11.9993L16.1743 10.9822L17.37 11.3707L18.1089 10.3535V11.6108L19.3047 11.9993Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M17.1652 6.83474L16.5944 7.95506L17.4833 8.84404L16.2415 8.64726L15.6708 9.76752L15.4741 8.52576L14.2324 8.32902L15.3526 7.75827L15.1559 6.5166L16.0449 7.40554L17.1652 6.83474Z"
                            fill="#FFDA44"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_5146_2184">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case 'cny':
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_5146_2175)">
                        <path
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                            fill="#D80027"
                        />
                        <path
                            d="M6.56719 7.30273L7.60312 10.4902H10.9547L8.24531 12.4637L9.28125 15.6512L6.56719 13.6824L3.85313 15.6512L4.89375 12.4637L2.17969 10.4902H5.53125L6.56719 7.30273Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M14.2265 18.5857L13.4343 17.6107L12.2624 18.0654L12.9421 17.0107L12.1499 16.0311L13.364 16.3545L14.0483 15.2998L14.114 16.5561L15.3327 16.8795L14.1562 17.3295L14.2265 18.5857Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M15.8015 15.7264L16.1765 14.5264L15.1499 13.7998L16.4062 13.7811L16.7765 12.5811L17.1843 13.7717L18.4405 13.7576L17.4327 14.5076L17.8358 15.6982L16.8093 14.9717L15.8015 15.7264Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M17.9251 8.80723L17.372 9.93691L18.272 10.8135L17.0298 10.6354L16.4767 11.7604L16.261 10.5229L15.0142 10.3447L16.1298 9.75879L15.9142 8.5166L16.8142 9.39316L17.9251 8.80723Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M14.2592 5.38574L14.1654 6.63731L15.3326 7.11074L14.1092 7.41074L14.0201 8.66699L13.3592 7.59824L12.1357 7.89824L12.9467 6.9373L12.2811 5.87324L13.4482 6.34668L14.2592 5.38574Z"
                            fill="#FFDA44"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_5146_2175">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case 'gbp':
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_5146_2210)">
                        <path
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                            fill="#F0F0F0"
                        />
                        <path
                            d="M2.48081 4.69434C1.5382 5.92073 0.827434 7.33424 0.413574 8.86982H6.65629L2.48081 4.69434Z"
                            fill="#0052B4"
                        />
                        <path
                            d="M23.5869 8.86977C23.173 7.33424 22.4622 5.92073 21.5196 4.69434L17.3442 8.86977H23.5869Z"
                            fill="#0052B4"
                        />
                        <path
                            d="M0.413574 15.1299C0.82748 16.6654 1.53825 18.0789 2.48081 19.3053L6.65615 15.1299H0.413574Z"
                            fill="#0052B4"
                        />
                        <path
                            d="M19.3058 2.48037C18.0794 1.53776 16.6659 0.826992 15.1304 0.413086V6.65576L19.3058 2.48037Z"
                            fill="#0052B4"
                        />
                        <path
                            d="M4.69434 21.5191C5.92073 22.4617 7.33424 23.1725 8.86977 23.5864V17.3438L4.69434 21.5191Z"
                            fill="#0052B4"
                        />
                        <path
                            d="M8.86973 0.413086C7.33419 0.826992 5.92068 1.53776 4.69434 2.48032L8.86973 6.65571V0.413086Z"
                            fill="#0052B4"
                        />
                        <path
                            d="M15.1309 23.5864C16.6664 23.1725 18.0799 22.4617 19.3063 21.5191L15.1309 17.3438V23.5864Z"
                            fill="#0052B4"
                        />
                        <path
                            d="M17.3442 15.1299L21.5196 19.3053C22.4622 18.079 23.173 16.6654 23.5869 15.1299H17.3442Z"
                            fill="#0052B4"
                        />
                        <path
                            d="M23.8984 10.4348H13.5653H13.5652V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4348H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4348V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5652H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348V10.4348Z"
                            fill="#D80027"
                        />
                        <path
                            d="M15.1304 15.1309L20.4852 20.4857C20.7315 20.2395 20.9664 19.9821 21.1906 19.7153L16.6061 15.1309H15.1304V15.1309Z"
                            fill="#D80027"
                        />
                        <path
                            d="M8.86951 15.1309H8.86941L3.51465 20.4856C3.76084 20.7319 4.01823 20.9668 4.28504 21.191L8.86951 16.6064V15.1309Z"
                            fill="#D80027"
                        />
                        <path
                            d="M8.86976 8.8696V8.86951L3.51495 3.51465C3.26866 3.76084 3.03373 4.01823 2.80957 4.28504L7.39409 8.86955H8.86976V8.8696Z"
                            fill="#D80027"
                        />
                        <path
                            d="M15.1304 8.8698L20.4852 3.5149C20.239 3.26862 19.9817 3.03368 19.7148 2.80957L15.1304 7.39409V8.8698Z"
                            fill="#D80027"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_5146_2210">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case 'jpy':
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_5146_2228)">
                        <path
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                            fill="#F0F0F0"
                        />
                        <path
                            d="M12.0001 17.217C14.8816 17.217 17.2175 14.8811 17.2175 11.9996C17.2175 9.11812 14.8816 6.78223 12.0001 6.78223C9.11861 6.78223 6.78271 9.11812 6.78271 11.9996C6.78271 14.8811 9.11861 17.217 12.0001 17.217Z"
                            fill="#D80027"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_5146_2228">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case 'aed':
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_5146_2204)">
                        <path
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                            fill="#F0F0F0"
                        />
                        <path
                            d="M6.78271 16.1738L7.8262 23.2536C9.12614 23.736 10.5322 23.9999 12.0001 23.9999C17.1596 23.9999 21.5582 20.7434 23.2537 16.1738H6.78271Z"
                            fill="black"
                        />
                        <path
                            d="M6.78271 7.82611L7.8262 0.746297C9.12614 0.263906 10.5322 0 12.0001 0C17.1596 0 21.5582 3.2565 23.2537 7.82611H6.78271Z"
                            fill="#6DA544"
                        />
                        <path
                            d="M0 11.9997C0 17.1593 3.25655 21.5578 7.82611 23.2533V0.746094C3.25655 2.44161 0 6.84012 0 11.9997Z"
                            fill="#A2001D"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_5146_2204">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case 'brl':
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_5146_2196)">
                        <path
                            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                            fill="#6DA544"
                        />
                        <path
                            d="M11.9999 4.69531L21.913 11.9997L11.9999 19.304L2.08691 11.9997L11.9999 4.69531Z"
                            fill="#FFDA44"
                        />
                        <path
                            d="M12.0001 16.174C14.3052 16.174 16.174 14.3052 16.174 12.0001C16.174 9.69489 14.3052 7.82617 12.0001 7.82617C9.69489 7.82617 7.82617 9.69489 7.82617 12.0001C7.82617 14.3052 9.69489 16.174 12.0001 16.174Z"
                            fill="#F0F0F0"
                        />
                        <path
                            d="M9.91325 11.7393C9.18743 11.7393 8.48698 11.8496 7.82764 12.0545C7.85684 14.3345 9.71328 16.1741 12.0002 16.1741C13.4143 16.1741 14.6633 15.4701 15.4183 14.3943C14.1266 12.7773 12.139 11.7393 9.91325 11.7393Z"
                            fill="#0052B4"
                        />
                        <path
                            d="M16.0966 12.7997C16.1469 12.5408 16.174 12.2737 16.174 12.0001C16.174 9.69484 14.3053 7.82617 12.0001 7.82617C10.2801 7.82617 8.80355 8.86684 8.16455 10.3527C8.72939 10.2356 9.31421 10.174 9.91322 10.174C12.3375 10.174 14.5306 11.1817 16.0966 12.7997Z"
                            fill="#0052B4"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_5146_2196">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        default:
            return null;
    }
});
