import classnames from 'classnames/bind';
import { memo } from 'react';

import { Button, Flex, FlexContainer, Icon } from '@smartfolly/frontend.web-ui';

import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

export const BoardEmptyPlaceholder = memo(function BoardEmptyPlaceholder({
    onClearSearch,
}: {
    onClearSearch: () => void;
}) {
    return (
        <div className={cnb('no-matches')}>
            <FlexContainer
                className={cnb('no-matches-container')}
                direction="column"
                justify="space-between"
                align="center"
            >
                <Flex className="action action-normal">
                    <div className={cnb('icon', 'quadric')}>
                        <Icon icon="default-token" />
                    </div>
                </Flex>
                <Flex className="action action-normal">No exact matches</Flex>
                <Flex className="paragraph paragraph-small color-text-secondary text-center">
                    We couldn&#39;t find anything matching yoursearch, try again with a different
                    term.
                </Flex>
                <Flex className={cnb('no-matches-actions')}>
                    <Button color="default" size="medium" disabled={false} onClick={onClearSearch}>
                        Clear filters
                    </Button>
                </Flex>
            </FlexContainer>
        </div>
    );
});
