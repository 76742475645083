import { computed, makeObservable } from 'mobx';

import { BoardsManager } from '@smartfolly/sdk';

import { createWithUserId } from '../module';
import type { Board, IBoardsService } from '../types';

type LoadBoardOptions = {
    /**
     * An ID of the board to load.
     */
    boardId: string;
};

export type LoaderBoardResponse = {
    /**
     * A MobX computed loaded board instance.
     */
    board: Board | undefined;

    /**
     * A BoardsService instance responsible for the board loading.
     */
    boardsService: IBoardsService;

    /**
     * A method to unload the services listening to the board data changes.
     */
    unload: () => Promise<void>;
};

/**
 * Function to load the board with the provided options.
 * @param options - contains a boardId or a userId with the board kind.
 * @returns an MobX observable object with the computed `board` to get it
 * and the method to unload the services listening to the board data changes.
 */
export async function loadBoard(options: LoadBoardOptions): Promise<LoaderBoardResponse> {
    // Create a dummy boards manager with an empty userId
    // to get the board data knowing its ID.
    // Note: we don't need to specify any userId to get the board data!
    const boardsManager = new BoardsManager({ userId: '' });

    // Load the board to find the user id belongs to
    const { userId } = await boardsManager.getBoard({ boardId: options.boardId });

    // Load the boardsService for the user
    const { boardsService, unload } = await createWithUserId(userId);

    // Return a computed board inside an observable object
    return makeObservable<LoaderBoardResponse>(
        {
            get board() {
                return boardsService.boards.find(({ boardId }) => boardId === options.boardId);
            },
            boardsService,
            unload,
        },
        { board: computed },
    );
}
