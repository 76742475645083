import BigNumber from 'bignumber.js';
import { memo } from 'react';
import type { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import type { TooltipProps } from 'recharts/types/component/Tooltip';

import { priceToString } from '@smartfolly/frontend.currencies-service';
import type { Currency } from '@smartfolly/frontend.currencies-service';

export const contentStyle = {
    backgroundColor: '#21282C',
    border: 'none',
};

export const wrapperStyle = {
    backgroundColor: '#21282C',
    outline: 'none',
    borderRadius: '0.75rem',
    padding: '0.5rem',
};

export const cursor: React.SVGProps<SVGElement> = {
    stroke: '#21282C',
    strokeWidth: 1,
};

export const dateFormatter = (date: string) => new Date(date).toLocaleDateString();

export const priceFormatter = (value: ValueType | undefined, payload?: { currency: Currency }) => {
    if (typeof value === 'number' || typeof value === 'string') {
        return `${+value > 0 ? '+' : ''}${priceToString(
            new BigNumber(value),
            payload?.currency ?? 'USD',
            0,
        )}`;
    }
    return '';
};

export const percentageFormatter = (value: ValueType | undefined) => {
    if (typeof value === 'number') {
        return `${value > 0 ? '+' : ''}${value.toFixed(2).replace('-', '−')}%`;
    }
    if (typeof value === 'string') {
        return `${+value > 0 ? '+' : ''}${value}%`;
    }
    return '';
};

export type ChartsTooltipProps = TooltipProps<ValueType, NameType> & {
    customLabelFormatter?: (label: string) => string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customValueFormatter?: (value: ValueType | undefined, payload?: any) => string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customNameFormatter?: (name: NameType | undefined, payload?: any) => NameType | undefined;
};

export const ChartsTooltip = memo(function ChartsTooltip({
    active,
    payload,
    label,
    customLabelFormatter,
    customValueFormatter,
    customNameFormatter,
}: ChartsTooltipProps) {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <div className="title title-small color-text-secondary m-b-0.25">
                    {customLabelFormatter ? customLabelFormatter(label) : label}
                </div>
                <div>
                    {payload.map(pld => (
                        <div key={`${pld.name}-${pld.value}`} className="action action-small">
                            {customNameFormatter && (
                                <span className="m-r-0.5">
                                    {customNameFormatter(pld.name, pld.payload)}
                                </span>
                            )}
                            {customValueFormatter
                                ? customValueFormatter(pld.value, pld.payload)
                                : pld.value}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return null;
});
