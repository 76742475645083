import classnames from 'classnames/bind';
import { memo, useState, useCallback } from 'react';

import type { Asset, TokenGroup, WalletGroup } from '@smartfolly/frontend.assets-service';
import type { IBoardsService } from '@smartfolly/frontend.boards-service';

import { Flex, FlexContainer, Icon } from '@smartfolly/frontend.web-ui';

import { isAssetStaked } from '../../helpers';

import { useAssetBalance, useFormattedWallet } from '../../hooks';

import { CryptoIcon } from '../CryptoIcon';

import { BoardAssetModal } from './BoardAssetModal';

import styles from './Board.module.scss';

const cnb = classnames.bind(styles);

export const BoardAsset = memo(function BoardAsset({
    asset,
    group,
    boardsService,
}: {
    asset: Asset;
    group: TokenGroup | WalletGroup;
    boardsService: IBoardsService;
}) {
    // States

    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    // Getters

    const { price, token, wallet } = asset;

    const formattedAddress = useFormattedWallet(wallet);
    const balance = useAssetBalance(asset, 4); // specify the precision if needed

    const isStaked = isAssetStaked(asset);

    // Actions

    const showModal = useCallback(() => setShowInfoModal(true), []);
    const hideModal = useCallback(() => setShowInfoModal(false), []);

    // Render

    return (
        <>
            {showInfoModal && (
                <BoardAssetModal
                    asset={asset}
                    hideModal={hideModal}
                    boardsService={boardsService}
                />
            )}
            <FlexContainer
                onClick={showModal}
                className={cnb('asset-item')}
                direction="row"
                justify="start"
                align="center"
            >
                <Flex className="w-60">
                    <FlexContainer direction="row" justify="start" align="center">
                        <Flex>
                            <div className={cnb('icon')}>
                                {token.icon ? (
                                    <img src={token.icon} alt="token.symbol" />
                                ) : (
                                    <Icon icon="default-token" />
                                )}
                            </div>
                        </Flex>
                        <Flex className={cnb('info')} grow={1}>
                            <FlexContainer
                                direction="column"
                                justify="space-between"
                                align="stretch"
                            >
                                <Flex className="action action-normal">
                                    {token.name}
                                    {isStaked ? ' · Staked' : ''}
                                </Flex>
                                {'blockchain' in wallet ? (
                                    <Flex className="paragraph paragraph-small color-text-secondary">
                                        <CryptoIcon
                                            className={cnb('wallet-icon')}
                                            icon={`${wallet.blockchain.id}-wallet`}
                                            defaultIcon="default-wallet"
                                        />
                                        <span className={cnb('blockchain-name')}>
                                            {'wallet' in group
                                                ? wallet.blockchain.name
                                                : formattedAddress}
                                        </span>
                                    </Flex>
                                ) : undefined}
                                {'exchange' in wallet ? (
                                    <Flex className="paragraph paragraph-small color-text-secondary">
                                        <CryptoIcon
                                            className={cnb('wallet-icon')}
                                            icon={`${wallet.exchange.id}-wallet`}
                                            defaultIcon="default-wallet"
                                        />
                                        <span className={cnb('exchange-name')}>
                                            {'wallet' in group
                                                ? wallet.exchange.name
                                                : formattedAddress}
                                        </span>
                                    </Flex>
                                ) : undefined}
                            </FlexContainer>
                        </Flex>
                    </FlexContainer>
                </Flex>
                <Flex className="w-40 text-right">
                    {price.string}
                    <div className="paragraph paragraph-small color-text-secondary">{balance}</div>
                </Flex>
            </FlexContainer>
        </>
    );
});
